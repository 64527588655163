// defines the crpp module

// require([
    //controllers
import * as angular from 'angular';
import page_events from "./components/events/events";
import modal_migrate_olc_event from "./components/events/modal-migrate-olc-event";
import modal_add_olc_event from "./components/events/modal-add-olc-event";
import modal_create_olc_subproject from "./components/events/modal-create-olc-subproject";
//import events_search from "./components/events/events-search-bu";
import events_search from "./components/events/events-search";

// ], function () {
const  olc_module = angular.module('OlcModule', ['ui.bootstrap', 'ngResource']);
    olc_module.controller('ModalAddOlcEventCtrl', modal_add_olc_event);
    olc_module.controller('ModalCreateOlcSubprojectCtrl', modal_create_olc_subproject);
    olc_module.controller('OlcEventsCtrl', page_events);
    olc_module.controller('OlcMigrateEventCtrl', modal_migrate_olc_event);
    olc_module.controller('OlcEventsSearchCtrl', events_search);
// });

export default olc_module;

