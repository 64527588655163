import * as angular from 'angular';
import * as moment from 'moment';
import {SDE_FEATURECLASS_TAXLOTQUERY} from "../../../../config";
import {getByField} from "../../../../core/common/common-functions";

var appraisal_map = ['$scope', '$route', '$routeParams', 'DatasetService', '$uibModal', '$location', '$window', '$rootScope', 'ProjectService',
    'CommonService','UserService',
    	function ($scope, $route, $routeParams, DatasetService, $uibModal, $location, $window, $rootScope, ProjectService,CommonService, UserService) {
			console.log("Inside appraisalMapController...");
			console.log("$routeParams.Id = " + $routeParams.Id);

            //TODO: a nicer global route authorization scheme...
            if (!$scope.Profile.hasRole("DECD"))
                angular.rootScope.go("/unauthorized");

            $scope.parcelShowing = false;
            $scope.MapSearchResults = { 'Message': "" };


            $scope.setParcel = function (parcel) {
              $scope.outAllotment = parcel;
            }

            // expose a method for handling clicks ON THE MAP - this is linked to from the Map.js directive
            // $scope.click = function(e){
            //     $scope.map.loading = true;
            //     $scope.clearAll();
            //     $scope.map.reposition(); //this is important or else we end up with our map points off somehow.
            //
            //     $scope.map.querySelectParcel(e.mapPoint, null, function(features){
            //
            //         $scope.parcelShowing = false;
            //         $scope.MapSearchResults.Message = "";
            //
            //         if (features.length == 0) {
            //           alert('No parcel found at that location.');
            //           $scope.map.loading = false;
            //           $scope.MapSearchResults.Message = "Not found.";
            //           $scope.$apply(); //bump angular
            //           return;
            //         }
            //
            //         $scope.map.addParcelToMap(features[0]);
            //
            //         //show the infowindow
            //         $scope.map.infoWindow.resize(250, 300);
            //         $scope.map.infoWindow.setContent($scope.getInfoWindowContent(features[0]));
            //         $scope.map.infoWindow.show($scope.map.selectedGraphic.geometry.getExtent().getCenter());
            //
            //         var objectid = $scope.map.selectedFeature.attributes.OBJECTID;
            //         console.log("Found objectid: " + objectid);
            //
            //         $scope.filterGridForParcel(features[0].attributes.PARCELID);
            //
            //         $scope.parcelShowing = features[0].attributes.OBJECTID;
            //
            //         $scope.$apply(); //bump angular
            //
            //
            //
            //     });
            // };

            $scope.clearGrid = function() {
              $scope.agGridOptions.api.setFilterModel(null)
                $scope.agGridOptions.api.onFilterChanged();
                $scope.agGridOptions.api.deselectAll();
                $scope.MapSearchResults.Message = "";
            }

            $scope.clickedMap = function(parcel) {

                console.log("searching for : " + $scope.SearchParcelId);
                $scope.SearchParcelId = parcel.PARCELID
                if (!$scope.SearchParcelId)
                    return;

                $scope.SearchParcelId = parcel

                //clear
                // $scope.map.clearGraphics();
                // $scope.map.infoWindow.hide();
                $scope.clearEverything = true;
                $scope.clearGrid();
                // $scope.agGridOptions.api.setFilterModel(null)
                // $scope.agGridOptions.api.onFilterChanged();
                // $scope.agGridOptions.api.deselectAll();
                // $scope.MapSearchResults.Message = "";
                $scope.outAllotment = $scope.SearchParcelId;
                // $scope.findOnMap($scope.SearchParcelId);
                $scope.filterGridForParcel($scope.SearchParcelId);
                $scope.clearEverything = false;
            }

                        $scope.searchParcel = function() {
                console.log("searching for : " + $scope.SearchParcelId);

                if (!$scope.SearchParcelId)
                    return;

                $scope.SearchParcelId = $scope.SearchParcelId.toUpperCase();

                //clear
                // $scope.map.clearGraphics();
                // $scope.map.infoWindow.hide();
                $scope.clearEverything = true;
                $scope.clearGrid();
                // $scope.agGridOptions.api.setFilterModel(null)
                // $scope.agGridOptions.api.onFilterChanged();
                // $scope.agGridOptions.api.deselectAll();
                // $scope.MapSearchResults.Message = "";
                $scope.outAllotment = $scope.SearchParcelId;
                // $scope.findOnMap($scope.SearchParcelId);
                $scope.filterGridForParcel($scope.SearchParcelId);
                $scope.clearEverything = false;
            }

            // MOVED TO PERMIT MAP DIRECTIVE
            // $scope.findOnMap = function (in_allotment) {
            //     console.log("finding on map " + in_allotment);
            //     $scope.parcelShowing = false;
            //     $scope.MapSearchResults.Message = "";
            //
            //     $scope.map.queryMatchParcel(in_allotment, function (features) {
            //         if (features.length == 0) {
            //             console.log("allotment not found: " + in_allotment);
            //             $scope.MapSearchResults.Message = "Not found on map.";
            //         }
            //         else {
            //             //that doesn't include geometry so we need to get it
            //             $scope.map.querySelectParcel(null, features[0].attributes.OBJECTID, function (geo_features) {
            //                 $scope.map.addParcelToMap(geo_features[0]);
            //                 $scope.map.centerAndZoomToGraphic($scope.map.selectedGraphic, 2);
            //
            //                 //show the infowindow
            //                 $scope.map.infoWindow.resize(250, 300);
            //                 $scope.map.infoWindow.setContent($scope.getInfoWindowContent(features[0]));
            //                 $scope.map.infoWindow.show($scope.map.selectedGraphic.geometry.getExtent().getCenter());
            //             });
            //
            //             $scope.parcelShowing = features[0].attributes.OBJECTID;
            //         }
            //         $scope.$apply(); //bump angular
            //     });
            //
            // }


            $scope.findSelection = function () {
                console.dir($scope.agGridOptions.selectedItems[0]);
                $scope.outAllotment = $scope.agGridOptions.selectedItems[0].Allotment;
                // moved to permit map directive
                // $scope.findOnMap($scope.agGridOptions.selectedItems[0].Allotment);
            }

            $scope.clearAll = function()
            {
                $scope.clearEverything = true;
                //$scope.activities = $scope.allActivities;
                //$scope.filteredActivities = undefined;
                // $scope.map.clearGraphics();
                // $scope.map.infoWindow.hide();
                $scope.agGridOptions.api.setFilterModel(null)
                $scope.agGridOptions.api.onFilterChanged();
                $scope.agGridOptions.api.deselectAll();
                $scope.SearchParcelId = "";
                $scope.parcelShowing = false;
                $scope.MapSearchResults.Message = "";
                $scope.clearEverything = false;
            };

            // MOVED TO PERMIT MAP DIRECTIVE
            // $scope.getInfoWindowContent = function(feature)
            // {
            //     var attributes = feature.attributes;
            //     //var location = getByField($scope.locationsArray,feature.attributes.OBJECTID,"SdeObjectId"); //is there already a location for this OBJECTID?
            //
            //     //if(location)
            //     //    var allotment = getByField($scope.activities,location.Id,"LocationId"); //is there already an allotment associated with this location?
            //
            //     $scope.map.infoWindow.setTitle(feature.attributes.PARCELID);
            //
            //     var html = "";
            //
            //     if(attributes.Address && attributes.Address.trim() != "")
            //         html += "<b>Address: </b>" + attributes.Address + "<br/>";
            //     if(attributes.OWNERSHIPS)
            //         html += "<b>Ownership: </b>" + attributes.OWNERSHIPS + "<br/>";
            //     if(attributes.ACRES_GIS)
            //         html += "<b>Acres (GIS): </b>" + attributes.ACRES_GIS;
            //
            //     //if(allotment && allotment.Id)
            //     //    html += "<br/><div class='right'><a href='#dataview/"+allotment.Id+"'>View</a></div>";
            //
            //     return html;
            //
            // };

            $scope.filterGridForParcel = function (parcel_id) {
                console.log(" filtering for " + parcel_id);
                var filter_component = $scope.agGridOptions.api.getFilterInstance('Allotment');
                filter_component.selectNothing();
                filter_component.selectValue(parcel_id);
                $scope.agGridOptions.api.onFilterChanged();
                //scope.dataGridOptions.api.deselectAll();

            };

            //to start an appraisal, we'll create a location for the selected parcel (with the OBJECTID from arcgis)
            // then hand off to data entry (edit) just like import.
            $scope.startAppraisal = function (parcel) {
                console.log("Find or create a location for : " + $scope.parcelShowing);

                // var acres = $scope.map.selectedFeature.attributes.ACRES_GIS;
                var acres = $scope.parcel.attributes.ACRES_GIS
                //check to see if this location already exists in our project, if not create it.
                var project_location = getByField($scope.project.Locations, $scope.parcelShowing, "SdeObjectId");

                if (!project_location) {
                    //create a new location from the map feature selected
                    console.log("create a new location from the map feature selected");
                    var new_location = {
                        //LocationTypeId: LOCATION_TYPE_APPRAISAL,
                        SdeFeatureClassId: SDE_FEATURECLASS_TAXLOTQUERY,
                        // SdeObjectId: $scope.map.selectedFeature.attributes.OBJECTID,
                        // Label: $scope.map.selectedFeature.attributes.PARCELID,
                        SdeObjectId: $scope.parcel.attributes.OBJECTID,
                        Label: $scope.parcel.attributes.PARCELID,
                    };

                    var location = CommonService.saveNewProjectLocation($scope.project.Id, new_location);

                    location.$promise.then(function () {

                        console.log("done saving project location and success!");
                        console.dir(location);


                        //bounce the user to the data entry form with that location selected.
                        $rootScope.imported_header = {
                            'Activity': { 'ActivityDate': moment().format('YYYY-MM-DD'), 'LocationId': location.Id },
                            'Acres': acres,
                            'Allotment': location.Label,
                        };

                        $rootScope.imported_rows = {};

                        $location.path("/dataentryform/" + $scope.dataset.Id);
                    });

                } else {
                    console.log("found a location, so handing off to edit");

                    //bounce the user to the data entry form with that location selected.
                    $rootScope.imported_header = {
                        'Activity': { 'ActivityDate': moment().format('YYYY-MM-DD'), 'LocationId': project_location.Id },
                        'Acres': acres,
                        'Allotment': project_location.Label
                    };

                    $rootScope.imported_rows = [];

                    $location.path("/dataentryform/"+$scope.dataset.Id);
                }
            }
		}
];

export default appraisal_map;
