import * as angular from 'angular';
import * as moment from 'moment';

import {
  getByField,
  getFilesArrayAsList,
  getParsedMetadataValues,
  instrumentsToPossibleValues, makeObjects, orderByAlpha
} from "../../../common/common-functions";
import {MAX_DATASET_RECORDS_THRESHOLD,
  serverUrl,
  REPORTSERVER_URL
} from "../../../../config";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var dataset_query = ['$scope', '$routeParams', 'DatasetService', '$location', '$uibModal', '$rootScope', 'ChartService',
  'ProjectService', 'CommonService', 'SubprojectService', 'GridService', '$timeout',
  function ($scope, $routeParams, DatasetService, $location, $uibModal, $rootScope, ChartService, ProjectService, CommonService, SubprojectService, GridService, $timeout) {
    $scope.existingChartConfigs = ['AdultWier', 'Appraisal', 'ArtificialProduction', 'BSample', 'CreelSurvey',
                'ElectroFishing', 'FishScales', 'Screwtrap', 'SnorkelFish', 'SpawningGroundSurvey', 'WaterQuality', 'WaterTemp','WaterTemp-standalone','WaterTemp-orig', 'WaterTemp-placeholder']
    $scope.system = {loading: true, messages: []};

    $scope.Criteria = {};
    $scope.criteriaList = [];
    $scope.AutoExecuteQuery = false;
    $scope.selectedRow = {};
    $scope.onField = {};

    $scope.instrumentIdList = [];
    $scope.instrumentAccuracyCheckList = [];

    $scope.prevTop = -1;
    $scope.lastRecord = 0;
    $scope.queryFullResults = [];
    $scope.queryTotalRecords = 0;
    $scope.queryTotalRecordsRun = false;
    $scope.DatasetTotalRecordsRun = false;
    $scope.DatasetTotalRecords = -1; //"...";
    $scope.RecordGroupSize = 30;
    $scope.prevStartRow = 0;
    $scope.endRow = $scope.RecordGroupSize;
    $scope.ScrollIndex = 0;
    $scope.scrollCount = 0;
    $scope.lastVisibleRow = 18;
    $scope.endPadding = 5;
    $scope.queryLoaded = false;
    $scope.datasource = null;
    $scope.NumberOfRecordsToPull = -1;
    $scope.NumberOfRecordsToPullSave = $scope.NumberOfRecordsToPull;
    $scope.infGridParams = null;
    $scope.fieldCriteriaRemoved = false;
    $scope.queryResult = {
      Error: false,
      ErrorMessage: null
    }
    $scope.runningQueryWithFieldCriteria = false;
    $scope.runningQueryToLoadAllRecords = false;
    $rootScope.infGridParams = null;
    $rootScope.datasetId = null;
    $rootScope.queryCriteriaList = null;

    $scope.dataset = DatasetService.getDataset($routeParams.Id);

    $scope.dataset.$promise.then(function () {

      $rootScope.datasetId = $scope.dataset.Id;

      /*if (typeof $scope.dataset.Config.NumberRecordsForQueryToPull !== 'undefined')
          $scope.NumberOfRecordsToPull = $scope.dataset.Config.NumberRecordsForQueryToPull;
      else
          $scope.NumberOfRecordsToPull = 10000;

      $scope.NumberOfRecordsToPullSave = $scope.NumberOfRecordsToPull;

      //$scope.dataAgGridOptions.cacheBlockSize = $scope.NumberOfRecordsToPull;
      */

      $scope.project = ProjectService.getProject($scope.dataset.ProjectId);

      if ($scope.dataset.Datastore.TablePrefix === "WaterTemp")
        $scope.instrumentAccuracyCheckList = ProjectService.getAllInstrumentAccuracyChecks();

      if (($scope.dataset.Config) && ($scope.dataset.Config.RedirectToReportsPage)) {

        var strUrl = $scope.dataset.Config.RedirectToReportsPage;
        window.open(strUrl, '_blank');
        var strParent = "activities/" + $scope.dataset.Id;
        $location.url(strParent);
      } else {

        $scope.project.$promise.then(function () {
          $scope.activateGrid();
        });

        $scope.query = $scope.buildQuery();
        $scope.query.criteria.GetTotalCount = true;

        $scope.query.TotalRecordCount = DatasetService.queryActivities($scope.query);
        $scope.query.TotalRecordCount.$promise.then(function () {

          if (!$scope.query.TotalRecordCount)
            return;
          else if (!$scope.query.TotalRecordCount.$resolved)
            return;

          $scope.query.criteria.GetTotalCount = false;

          // There should only be one...
          $scope.query.TotalRecordCount.forEach(function (item) {
            $scope.DatasetTotalRecords = item.TotalRecords;
          });

          if ($scope.DatasetTotalRecords >= MAX_DATASET_RECORDS_THRESHOLD) {
            //$location.path(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix);
            // First, get us off the dataset query page, and put us on the dataset activities page.
            location.replace("/activities/" + $scope.dataset.Id);
  
            // Next, open a new tab and with the report server url.
            //console.log(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix); 
            window.open(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix, '_blank').focus();
          }
        });
      }
    });

    $scope.clearValue = function () {
      $scope.Criteria.Value = null;
      console.dir($scope.Criteria);
    };

    //ag-grid - header + details --- alrid for multiple activities
    $scope.dataAgGridOptions = {
      rowBuffer: 0,
      animateRows: true,
      columnDefs: null,
      rowData: [],
      rowSelection: 'multiple',
      suppressPropertyNamesCheck: true, // this removes the console warning for custom properties, this is a known problem, see https://github.com/ag-grid/ag-grid/issues/2320
      //*rowModelType: 'infinite',
      //*serverSideStoreType: 'partial',
      //paginationPageSize: 30,
      //*cacheOverflowSize: 2,
      //maxBlocksInCache controls how many rows should be kept for viewing.  Default is all,
      // but when left off (default), it only shows 100.  So when scrolling down, only
      // the first 100 records show.
      //*maxBlocksInCache: 10000000,
      //cacheBlockSize controls how many rows to fetch.  The default is 100.
      //*cacheBlockSize: $scope.NumberOfRecordsToPull,
      //*maxConcurrentDatasourceRequests: 1,
      //infiniteInitialRowCount: 30,
      //*blockLoadDebounceMillis: 500,
      //maxBlocksInCache: 10,
      // Leave datasource out right now.  We will set it later,
      // when we are ready to load data.
      //*datasource: $scope.dataSource,
      //*gridOptions: any = this.gridOptions,

      onSelectionChanged: function (params) {
        $scope.selectedRow = $scope.dataAgGridOptions.api.getSelectedRows()[0];
        $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
      },

      onCellFocused: function (params) {
        //console.dir(params);
        if (!params.column)
          return;

        $scope.onField = (params.column.colDef.cdmsField);
        $scope.$apply();
      },

      onBodyScroll: function (params) {
        // Leaving this funciton in, in case we need it sometime in the future.
        //console.log(" scrolling ");
        //console.dir(params);
        //console.dir($scope.ag_grid);

        //$scope.scrollCount++;
        //console.log("$scope.scrollCount = " + $scope.scrollCount);
        //console.log("-----");
      },

      onGridReady: function (params) {
        console.log("GRID READY fired. ------------------------------------------>>>");
        $scope.system.loading = false;
        $scope.$apply();
        GridService.autosizeColumns($scope.dataAgGridOptions);
      },

      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
      },

      processCellForClipboard: $scope.processCellDataForOutput,
    };

    $scope.activateGrid = function () {
      console.log("activating grid!");
      //setup grid and coldefs and then go!
      $timeout(function () {

        //need to set some header field possible values manually BEFORE we load our coldefs - so that the named value will display in the grid.
        var instrument_coldef = getByField($scope.dataset.Fields, "InstrumentId", "DbColumnName");
        if (instrument_coldef) {
          instrument_coldef.Field.PossibleValues = instrumentsToPossibleValues($scope.project.Instruments);
        }

        //var hidden_header_controltypes = ["file", "hidden", "accuracy-check-select", "activity-text", "instrument-select", "post-accuracy-check-select", "qa-status-comment", "timezone-select"];
        var hidden_header_controltypes = ["file", "hidden", "activity-text", "instrument-select", "qa-status-comment"];
        //var hidden_grid_controltypes = ["hidden", "activity-text","accuracy-check-select","timezone-select","post-accuracy-check-select"];
        var hidden_grid_controltypes = ["hidden", "activity-text"];

        $scope.dataAgColumnDefs = GridService.getAgColumnDefs($scope.dataset);

        //setup any possible values that are needed - detail
        //note: the "hide" property hides the column in the results grid; the "hide_header" hides it in the header list in columns multiselect
        angular.forEach($scope.dataAgColumnDefs.DetailFields, function (fieldDef) {
          if (fieldDef.field == "QAStatusId") { //RowQAStatusId because we're in the details
            fieldDef.field = fieldDef.DbColumnName = "RowQAStatusId";
            fieldDef.PossibleValuesList = makeObjects($scope.dataset.RowQAStatuses, 'Id', 'Name');
            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
            fieldDef.hide_header = true;

          } else {
            fieldDef.PossibleValuesList = getParsedMetadataValues(fieldDef.PossibleValues);
          }

          if (fieldDef.ControlType == 'file' || fieldDef.ControlType == 'hidden')
            fieldDef.hide_header = true;

        });

        console.log("LocationId check section is next...");

        //setup activity fields to point to the right place
        //note: the "hide" property hides the column in the results grid; the "hide_header" hides it in the header list in columns multiselect
        angular.forEach($scope.dataAgColumnDefs.HeaderFields, function (fieldDef) {
          //console.dir(fieldDef);
          if (fieldDef.field == "LocationId") {

            // The commented-out part below (here to line 258) is residual from the old page, 
            // where users could add criteria at the top of the page.
            // Now the filtering is done in the grid.
            //----------------------------------------------
            //load the config so that we can check if we are supposed to include the habitat sites for this project
            /*try {
              $scope.project.Config = ($scope.project.Config) ? angular.fromJson($scope.project.Config) : {};
            } catch (e) {
              console.error("config could not be parsed for project" + $scope.project.Config);
              console.dir(e);
            }

            var dataset_locations = ProjectService.getDatasetLocations($scope.project.Id, $scope.dataset.Id);

            dataset_locations.$promise.then(function () {
              dataset_locations = dataset_locations.sort(orderByAlpha);

              fieldDef.PossibleValuesList = dataset_locations; //makeObjects(dataset_locations, 'Id', 'Label'); //used in the headers

              if (fieldDef.hasOwnProperty('setPossibleValues'))
                fieldDef.setPossibleValues(makeObjects(dataset_locations, 'Id', 'Label')); //used in the grid
            })
            */


          } else if (fieldDef.field == "QAStatusId") { //ActivityQAStatusId
            fieldDef.field = fieldDef.DbColumnName = "ActivityQAStatusId";
            fieldDef.PossibleValuesList = makeObjects($scope.dataset.QAStatuses, 'Id', 'Name');

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

            //fieldDef.hide_header = true; //hide in header
          } else if (fieldDef.field == "QAComments") { //ActivityQAStatusId
            fieldDef.field = fieldDef.DbColumnName = "ActivityQAComments";
          }

          if (fieldDef.ControlType == "select" || fieldDef.ControlType == "multiselect") {
            fieldDef.PossibleValuesList = getParsedMetadataValues(fieldDef.PossibleValues);

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

          } else if (fieldDef.ControlType == "instrument-select") {// || fieldDef.ControlType == "accuracy-check-select" || fieldDef.ControlType =="post-accuracy-check-select" || fieldDef.ControlType == "timezone-select") {
            fieldDef.PossibleValuesList = makeObjects(fieldDef.PossibleValues, 'Id', 'Label');

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

            //fieldDef.AccuracyChecks =

          } else if (fieldDef.ControlType == "fisherman-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

          } else if (fieldDef.ControlType == "number-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

          } else if (fieldDef.ControlType == "accuracy-check-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

          } else if (fieldDef.ControlType == "post-accuracy-check-select") {
            fieldDef.PossibleValuesList = fieldDef.PossibleValues;

            if (fieldDef.hasOwnProperty('setPossibleValues'))
              fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

          }

          //hidden headers
          // @ts-ignore
          if (hidden_header_controltypes.contains(fieldDef.ControlType))
            fieldDef.hide_header = true; //just the headers

          //hidden in grid
          // @ts-ignore
          if (hidden_grid_controltypes.contains(fieldDef.ControlType))
            fieldDef.hide = true; //just the detail


        });

        $scope.dataAgGridOptions.columnDefs = $scope.dataAgColumnDefs.HeaderFields.concat($scope.dataAgColumnDefs.DetailFields);

        //console.log("Ready to go!");
        //console.dir($scope.dataAgGridOptions.columnDefs);
        //console.dir($scope.imported_rows);


        var ag_grid_div = document.querySelector('#query-grid') as HTMLElement;    //get the container id...
        $scope.ag_grid = new Grid(ag_grid_div, $scope.dataAgGridOptions); //bind the grid to it.
        //$scope.dataAgGridOptions.api.showLoadingOverlay(); //show loading...

        //set the detail values into the grid
        //$scope.dataAgGridOptions.api.setRowData($scope.imported_rows);

        //$scope.dataAgGridOptions.api.redrawRows();
        //GridService.autosizeColumns($scope.dataAgGridOptions);

        //$scope.calculateStatistics();
        //-----

        $scope.query = undefined;
        $scope.query = $scope.buildQuery();
        $scope.query.criteria.GetTotalCount = false;
        $scope.executeQuery();

        //****** Infinite Scrolling *******
        // With the dataSource part here, and turned on,
        // When the page loads, it has already called the
        // backend and pull some rows.
        // We want to wait, for the user to click the
        // Execute button, before calling the back end.
        //var dataSource = {
        /*
        $scope.dataSource = {
        //$scope.setDataSource = {
            rowCount: null, // behave as infinite scroll

            getRows: function(params) {
                //console.log('asking for ' + params.startRow + ' to ' + params.endRow);
                $scope.infGridParams = angular.copy(params);

                if (params.filterModel !== {})
                {
                    // For now, we will just dump filter model.
                    // When we revamp the query page, we will handle things better then.
                    console.log("We don't currently user filterModel on the query page; ignoring filterModel.");
                    //params.filterModel = {};
                }

                // At this point in your code, you would call the server, using $http if in AngularJS 1.x.
                if (($scope.runningQueryToLoadAllRecords) && (typeof $scope.query !== 'undefined'))
                {
                    // The user clicked on "Load all records",
                    // the execution went to executeQuery, and then
                    // came back here.
                    params.startRow = 0;
                    params.endRow = $scope.DatasetTotalRecords;
                    params.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

                    $scope.executeQuery(params);
                }
                else if (typeof $scope.query === 'undefined')
                {
                    // We have no query yet, so we are pulling the first block.
                    // Could be from when the page loads,
                    // or after the user adds or removes field criteria,
                    // or if the user clicks on the Load all records button.
                    console.log("Initial pull...");
                    console.log('asking for ' + params.startRow + ' to ' + params.endRow);
                    $scope.queryTotalRecordsRun = false;
                    $scope.query = $scope.buildQuery(params);
                    $scope.executeQuery(params);

                    //$scope.queryGetRecordCount();
                }
                else if (($scope.query.criteria.Fields.length === 0) && ($scope.fieldCriteriaRemoved))
                {
                    // The user used criteria, and then removed it.

                    // Reset things.
                    $scope.fieldCriteriaRemoved = false;
                    params.startRow = 0;
                    params.endRow = $scope.NumberOfRecordsToPull;
                    $scope.queryTotalRecords = 0;

                    console.log('asking for ' + params.startRow + ' to ' + params.endRow);

                    $scope.query = $scope.buildQuery(params);
                    $scope.executeQuery(params);
                }
                else if (($scope.query.criteria.Fields.length === 0) &&
                    (typeof $scope.query.results !== 'undefined') && ($scope.query.results !== null) &&
                    ($scope.query.results.$resolved) && ($scope.query.results.length <= params.endRow))
                {
                    // We have a query, results, it completed, and the length < params.endRow,
                    // The user scrolled down the results grid, so we want to use the same query,
                    // just offset into the result set, to get more of the same results (params.starRow)
                    console.log("User scrolled down...");

                    //if ($scope.queryTotalRecords === $scope.DatasetTotalRecords)
                    //{
                    //    params.startRow = $scope.DatasetTotalRecords;
                    //    params.endRow = $scope.DatasetTotalRecords;
                    //}

                    console.log('asking for ' + params.startRow + ' to ' + params.endRow);
                    $scope.executeQuery(params);
                }
                else if ($scope.query.criteria.Fields.length > 0)
                {
                    // Coming from $scope.runQueryWithCriteria, we go here.
                    if ((typeof $scope.query.results === 'undefined') || ($scope.query.results === null))
                    {
                        // To get here, the users supplies field info,
                        // then the grid gets cleared, and we reset things,
                        // Here, we want to reset the params, before pulling the query afresh.
                        //params.startRow = 0;
                        //params.endRow = $scope.NumberOfRecordsToPull;

                        //$scope.query = $scope.buildQuery(params);
                        //$scope.queryTotalRecords = 0;

                        console.log('asking for ' + params.startRow + ' to ' + params.endRow);
                        $scope.query = $scope.buildQuery(params);
                        $scope.executeQuery(params);

                        //$scope.queryGetRecordCount();
                    }
                    //else if ($scope.query.results.$resolved)
                    //{
                    //    // The user ran the query with field info already.
                    //    // This time we are scrolling.
                    //    console.log("Results resolved...")
                    //    $scope.executeQuery(params);
                    //}
                    //else if (!$scope.query.results.$resolved)
                    //{
                    //    // The user ran the query with field info already.
                    //    // This time we are scrolling.
                    //    console.log("Results not resolved...")
                    //    $scope.executeQuery(params);
                    //}
                    else
                    {
                        // This time we are scrolling.
                        console.log("We have results; scrolling...")

                        $scope.executeQuery(params);
                    }

                }
                else if ((typeof $scope.query.results !== 'undefined') && ($scope.query.results !== null) &&
                    ($scope.query.results.$resolved) && ($scope.query.results.length <= params.endRow))
                {
                    // // Executing the query again, because the user scrolled down the results grid.
                    // so we want to use the same query, just offset into the result
                    // set, to get more of the same result set (params.starRow)
                    console.log("Query results exist and not null, and results length < endRow...");
                    console.log('asking for ' + params.startRow + ' to ' + params.endRow);
                    $scope.executeQuery(params);
                }
                else if (($scope.query.results) && ($scope.queryTotalRecords === $scope.DatasetTotalRecords))
                {
                    // We have all of our results, and we scrolled down.
                    params.startRow = 0;
                    params.endRow = $scope.DatasetTotalRecords;
                    params.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

                    $scope.executeQuery(params);
                }
                else if (($scope.query.results) && ($scope.query.results.length > params.endRow))
                {
                    // The data in this dataset pulled quick, so we
                    // fetched all the data in one pull.
                    // Do nothing.
                    //$scope.executeQuery(params);
                }
                else if (($scope.query.results) && ($scope.query.results.length <= params.endRow))
                {
                    // Removed critera, executed query, second pass.
                    //$scope.queryTotalRecords = 0;
                    $scope.executeQuery(params);
                }

                // call the success callback
                // This gets called in $scope.executeQuery.
                //params.successCallback($scope.query.results, $scope.lastRecord);

            },
        };
        */
        //$scope.dataAgGridOptions.api.setDatasource(dataSource);
        //$scope.dataAgGridOptions.api.setDatasource($scope.dataSource);
        //********************************

      }, 0);
    };


    $scope.addCriteria = function () {
      // Notes...
      // When we have a list of possible values, such as the sex of a fish (["Male","Female","Unknown"]),
      // and the list is an array, we pick the item, for example "Male", and store the value in the backend.
      // However, when the list is itself an object (Fishermen, or StreamName), the possible values are
      // a collection of key value pairs, and the list is NOT an array.  In this case the Id gets stored
      // in the backend, NOT the name on the screen.

      // When the user selects only 1 item from the list, it gets converted to a number (below).
      // However, when the user selects 2 or more items from the list, the value becomes an array.
      // Therefore, we must walk this list first, and check each item against the PossibleValues.

      //possible values that are associative arrays will need to dereference from the value to the key
      /*if ($scope.Criteria.ParamFieldSelect[0].PossibleValues && !Array.isArray($scope.Criteria.ParamFieldSelect[0].PossibleValues)) {
          Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValues).forEach(function (key) {
              if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] == $scope.Criteria.Value) {
                  //$scope.Criteria.DisplayName = $scope.Criteria.Value;
                  $scope.Criteria.Value = key; //convert it to the key instead of the value...
              }
          });
      }
      */

      if ($scope.Criteria.ParamFieldSelect[0].PossibleValues && !Array.isArray($scope.Criteria.ParamFieldSelect[0].PossibleValues)) {
        //if ((typeof $scope.Criteria.ParamFieldSelect[0].PossibleValues !== 'undefined') && (($scope.Criteria.ParamFieldSelect[0].PossibleValues) === null) || (!Array.isArray($scope.Criteria.ParamFieldSelect[0].PossibleValues))) {
        if (Array.isArray($scope.Criteria.Value)) {
          var arySearchList = [];
          var aryDisplayList = [];

          $scope.Criteria.Value.forEach(function (item) {
            // If the item is already a number, just add it to the list.
            // Otherwise, we must convert it to a number.
            if (parseInt(item)) {
              //Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValuesList).forEach(function (key) {
              $scope.Criteria.ParamFieldSelect[0].PossibleValuesList.forEach(function (key) {
                if (key.Id === parseInt(item)) {
                  arySearchList.push(item);
                  aryDisplayList.push(key.Label);
                }
              });
            } else {
              Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValuesList).forEach(function (key) {
                if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] === item) {
                  arySearchList.push(key);
                  aryDisplayList.push(item);
                }
              });
            }
          });

          $scope.Criteria.Value = "[" + arySearchList.join() + "]";
          $scope.Criteria.DisplayName = "[" + aryDisplayList.join() + "]";
        } else {
          Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValues).forEach(function (key) {

            if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] == $scope.Criteria.Value) {
              $scope.Criteria.DisplayName = $scope.Criteria.Value;
              $scope.Criteria.Value = key; //convert it to the key instead of the value...
            }

          });
        }
      } else {
        if (typeof $scope.Criteria.Value === 'string') {
          $scope.Criteria.DisplayName = $scope.Criteria.Value;
        } else if (typeof $scope.Criteria.Value === 'object') {
          if ($scope.Criteria.Value.hasOwnProperty('ParamFieldDateType') && $scope.Criteria.Value.ParamFieldDateType == 'between')
            $scope.Criteria.DisplayName = $scope.Criteria.Value.BetweenFromFieldDate + " - " + $scope.Criteria.Value.BetweenToFieldDate;
        } else {
          if ($scope.Criteria.ParamFieldSelect[0].PossibleValues)
            Object.keys($scope.Criteria.ParamFieldSelect[0].PossibleValues).forEach(function (key) {

              if ($scope.Criteria.ParamFieldSelect[0].PossibleValues[key] == $scope.Criteria.Value) {
                $scope.Criteria.DisplayName = $scope.Criteria.Value;
              }

            });
        }
      }


      $scope.criteriaList.push({
        DbColumnName: $scope.Criteria.ParamFieldSelect[0].DbColumnName,
        Id: $scope.Criteria.ParamFieldSelect[0].cdmsField.Id,
        Value: $scope.Criteria.Value,
        DisplayName: $scope.Criteria.DisplayName,
      });


      //console.log($scope.Criteria.ParamFieldSelect[0].PossibleValues
      //console.dir($scope.Criteria.ParamFieldSelect[0]);
      console.dir($scope.criteriaList);

      $scope.Criteria.Value = null;
      $scope.Criteria.DisplayName = null;

      if ($scope.AutoExecuteQuery) {
        //$scope.executeQuery();
        $scope.setDataSource();
      }
    };

    $scope.clearGrid = function () {
      // Dump the query results.
      $scope.query = null;

      // Take the grid out of Infinite Scrolling.
      $scope.lastRecord = -1;

      // *** This is the critical part ***
      // These actions are what actually clear the grid.

      // First, create a new datasource, fill it with an empty result set,
      // and take the grid out of infinite scrolling mode.
      var dataSource = {
        rowCount: 0, // behave as a regular grid.

        getRows: function (params) {
          // Empty result set.
          // Remember, a blank, null, or -1 puts the grid INTO
          // infinite scrolling mode, and a 0 takes us OUT of
          // infinite scrolling mode.
          params.successCallback([], 0);
        },

      };

      // Now, change the datasource to be the empty grid we just created.
      $scope.dataAgGridOptions.api.setDatasource(dataSource);
    };

    $scope.runQueryWithCriteria = function (params) {
      // When the page opens and the query first runs, it pulls the first set of
      // records, with no filters (get everything).
      // When the user clicks Execute Query, they have probably added
      // criteria.  Therefore, we must reset the query,
      // so that we don't append the new results onto the old result set.
      // Also, we want to start at the beginning,
      // rather than offsetting into the data (having a startRow > 0).

      // First dump the last results and reset things.
      $scope.clearGrid();
      $scope.query = undefined;

      $scope.queryTotalRecords = 0;
      $scope.DatasetTotalRecords = "...";
      $scope.queryTotalRecordsRun = false;
      $scope.DatasetTotalRecordsRun = false;
      $scope.resetQueryError();
      $scope.runningQueryWithFieldCriteria = true;
      $scope.NumberOfRecordsToPull = $scope.NumberOfRecordsToPullSave;

      // Now, set the datasource for the grid back to the real thing.
      $scope.dataAgGridOptions.api.setDatasource($scope.dataSource);

      // Update the offset and endRow.
      params.startRow = 0;
      params.endRow = $scope.NumberOfRecordsToPull;

      $scope.dataSource.getRows(params);
    };

    $scope.runQueryWithCriteriaLoadAllItems = function () {

      if ($scope.DatasetTotalRecords > 300000) {
        alert("The requested query results are currently too intense for this system.  Please contact the GIS office for support with this request.");
        //$scope.queryResult.Error = true;
        //$scope.queryResult.ErrorMessage = "The query results are currently too intense for this system.  Please contact the GIS office for support with this request.";
        return;
      }

      /*
      // Dump the last results and reset things.
      $scope.clearGrid();
      $scope.query = undefined;

      $scope.queryTotalRecords = 0;
      //$scope.queryTotalRecordsRun = false;  // Should be true still.
      //$scope.DatasetTotalRecordsRun = false; // Should be true still.
      $scope.resetQueryError();
      //$scope.runningQueryWithFieldCriteria = true; // More thoughts on this below.

      // Now, set the datasource for the grid back to the real thing.
      $scope.dataAgGridOptions.api.setDatasource($scope.dataSource);

      if ($scope.fieldCriteriaRemoved)
          $scope.runningQueryWithFieldCriteria = false;

      $scope.runningQueryToLoadAllRecords = true;
      */
      // Set the starting row and ending row, so that we can get all the records.
      $scope.infGridParams = null;
      $scope.infGridParams = {
        startRow: 0,
        endRow: $scope.DatasetTotalRecords,
        NumberOfRecordsToPull: $scope.DatasetTotalRecords
      };
      //$scope.infGridParams.startRow = 0;
      //$scope.infGridParams.endRow = $scope.DatasetTotalRecords;
      //$scope.infGridParams.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

      // Copy to $root, so that the next page can access it.
      $rootScope.infGridParams = angular.copy($scope.infGridParams);
      $rootScope.criteriaList = angular.copy($scope.criterialList);

      // $scope.NumberOfRecordsToPull is set initially when the page/dataset loads.
      $scope.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

      // Run the query.
      //$scope.dataSource.getRows($scope.infGridParams);


      //$location.path("/datasetqueryviewallrecords/" + $scope.dataset.Id);
      $location.path("https://paluutreports.ctuir.org/Reports/browse/" + $scope.dataset.Datastore.TablePrefix);

    };

    //$scope.executeQuery = function(params){
    $scope.executeQuery = function () {
      console.log("Inside dataset-query.ts, $scope.executeQuery...");
      $scope.query.loading = true;

      //$scope.query = $scope.buildQuery();

      //if ($scope.prevStartRow !== params.startRow)
      //{
      //    $scope.query.criteria.startRow = params.startRow;
      //    $scope.prevStartRow = params.startRow;
      //}

      //$scope.query.criteria.GetTotalCount = false;

      $scope.query.results = DatasetService.queryActivities($scope.query);

      $scope.query.results.$promise.then(function () {

        if (!$scope.query.results.$resolved)
          return;

        var intNumberOfRecordsReturned = -1;
        if ($scope.query.results.length)
        {
          intNumberOfRecordsReturned = $scope.query.results.length;
        }

        var blnTooManyRecords = (intNumberOfRecordsReturned > MAX_DATASET_RECORDS_THRESHOLD);
        //console.log("intNumberOfRecordsReturned = " + intNumberOfRecordsReturned + ", MAX_DATASET_RECORDS_THRESHOLD = " + MAX_DATASET_RECORDS_THRESHOLD);
        if (blnTooManyRecords)
        {
          // First, get us off the dataset query page, and put us on the dataset activities page.
          location.replace("/activities/" + $scope.dataset.Id);

          // Next, open a new tab and with the report server url.
          //console.log(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix); 
          window.open(REPORTSERVER_URL + $scope.dataset.Datastore.TablePrefix, '_blank').focus();
        }
        else
        {
          $scope.query.results.forEach(function (item) {
            if (item.SqlError) {
              $scope.query.loading = false;
              console.error("SQL Timeout in the backend...");

              $scope.queryResult.Error = true;
              $scope.queryResult.ErrorMessage = "The query results are currently too intense for this system.  Please contact the GIS office for support with this request.";
              //alert($scope.queryResult.ErrorMessage);
              return;
            }

            $scope.instrumentAccuracyCheckList.forEach(function (accCheck) {
              if (item.AccuracyCheckId === accCheck.Id) {
                item.AccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
              }
              if (item.PostAccuracyCheckId === accCheck.Id) {
                item.PostAccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
              }
            });

            if (item.Timezone) {
              var parsedTimezone = JSON.parse(item.Timezone);
              item.Timezone = parsedTimezone.Name;
            }

            if (item.LocationLabel)
              item.LocationId = item.LocationLabel;
            
            if (item.QAStatusName)
              item.ActivityQAStatusId = item.QAStatusName;

            if (item.InstrumentLabel)
              item.InstrumentId = item.InstrumentLabel;

            //$scope.queryFullResults.push(item);

            $scope.queryTotalRecords++;
          });

          if ($scope.queryResult.Error)
            return; // Get us out of here.

          //$scope.queryTotalRecordsRun = true;
          console.log("$scope.queryTotalRecords = " + $scope.queryTotalRecords);

          // Can only call setRowData when using normal row model (not infinite).
          //$scope.dataAgGridOptions.api.setRowData($scope.queryFullResults);
          $scope.dataAgGridOptions.api.setRowData($scope.query.results);
          $scope.query.loading = false;
          $scope.DatasetTotalRecords = $scope.query.results.length;

          // With serverSideStoreType = 'partial' (what we use for infinite scrolling),
          // we must set the lastRecord to -1, until we actually know what the
          // last record is.  Otherwise, when it does reach the end of the data,
          // it could leave you with lots of blank rows showing in the grid
          // (perhaps hundreds).
          //    $scope.lastRecord = -1;

          // How do we know, when to come out of infinite scroll mode?
          // Firstly, if the endRow is the same as the number NumberOfRecordsToPull,
          // it is because we are on our very first pull, not because we
          // have pulled all the records.
          // Secondly, checking the length of the results...
          // Via iterations, when we have pulled all the records,
          // the next pull will get zero (0) records.  Thus zero is the indicator.

          // Regarding switching the grid into / out of infinite scroll mode...
          // When lastRecord is blank (undefined, null, or -1),
          // it keeps the grid IN infinite scrolling mode.
          // When lastRecord >= 0 (ie the length of the results),
          // it sets the scrollbar to exactly fit the number of rows
          // that are in the results.  So we keep lastRecord set to -1,
          // until we have pulled all the records, and then we set it to the
          // length of the results, so they fit the grid exactly.
          //if ((params.endRow > $scope.NumberOfRecordsToPull) && ($scope.query.results.length === 0))
          //if (params.endRow > $scope.NumberOfRecordsToPull)
          /*
          if ($scope.query.results.length < $scope.NumberOfRecordsToPull)
          {
              // We pulled less records than NumberOfRecordsToPull, so we have them all.
              console.log($scope.query.results.length + "<" + $scope.NumberOfRecordsToPull);
              $scope.lastRecord = $scope.queryTotalRecords;
          }
          else if (($scope.runningQueryToLoadAllRecords) &&
              ($scope.query.results.length === $scope.DatasetTotalRecords) //&&
              //($scope.queryTotalRecordsRun)
              )
          {
              // We have all the records.
              console.log("Ran the query to pull all the records...");
              $scope.lastRecord = $scope.DatasetTotalRecords;

              // Reset the flag.
              $scope.runningQueryToLoadAllRecords = false;
          }
          else if (($scope.query.results.length === $scope.DatasetTotalRecords) &&
              ($scope.DatasetTotalRecords > 0)
              )
          {
              $scope.lastRecord = $scope.DatasetTotalRecords;
          }
          //else if (($scope.query.results.length === 0) && ($scope.DatasetTotalRecords > 0))
          //{
          //    // We have all the records.
          //    $scope.lastRecord = $scope.DatasetTotalRecords;
          //}

          //$scope.queryTotalRecordsRun = true;

          //else if ($scope.NumberOfRecordsToPull === $scope.DatasetTotalRecords)
          //{
          //    // We are pulling all the records, so we have them all now.
          //    console.log("$scope.NumberOfRecordsToPull = $scope.DatasetTotalRecords");
          //    $scope.lastRecord = $scope.queryTotalRecords;
          //}

          // ****** Infinite Scrolling ******
          // call the success callback
          params.successCallback($scope.query.results, $scope.lastRecord);
          // ********************************

          // This corrects an intermittant, probable, a-synch situation.
          // When we run the app with the debugger open, $scope.queryTotalRecords
          // always comes out correct.  However, when we close the debugger window,
          // $scope.queryTotalRecords is often double the value of $scope.DatasetTotalRecords
          // in certain cases, most notably and consistent in this case...
          //   The number of records in the results are less than the number of records to pull.
          //   For example, let's say we are pulling 1000 records per chunk, and the result is 225.
          //   Sometimes the 225 will become 500 (double), and sometimes it remains 225 (correct).
          //
          // When the user does the following...
          //   Select a field, select a criteria to filter for, and add the item.
          //   Click on Execute query.
          //   When the results come back, you will see that first the value is correct,
          //   and the grid is empty, then the grid fills in and the value is double
          //   that of $scope.DatasetTotalRecords.
          // If you click Execute query several times, sometimes the value is double,
          // and sometimes it is correct.
          // So this check solves the problem.  Ugly and dirty, yes, but effective and
          // must less time-consuming than tracking down the cause.
          if ($scope.queryTotalRecords === $scope.DatasetTotalRecords * 2 )
              $scope.queryTotalRecords = $scope.queryTotalRecords / 2;

          $scope.query.loading = false;

          // If we have not pulled the record count yet, we need to,
          // but we only want to run it once, when the page loads,
          // or when the user supplies some fields to filter on.
          if (!$scope.DatasetTotalRecordsRun)
          {
              $scope.queryGetRecordCount();
              $scope.DatasetTotalRecordsRun = true;
          }
          */
        }
      });
    };

    $scope.queryGetRecordCount = function () {

      $scope.query.criteria.GetTotalCount = true;

      $scope.query.TotalRecordCount = DatasetService.queryActivities($scope.query);
      $scope.query.TotalRecordCount.$promise.then(function () {
        // There should only be one...
        $scope.query.TotalRecordCount.forEach(function (item) {
          $scope.DatasetTotalRecords = item.TotalRecords;
        });
        $scope.query.criteria.GetTotalCount = undefined;
      });
    };


    //$scope.buildQuery = function(params){
    $scope.buildQuery = function () {

      //var queryCriteriaList = angular.copy($scope.criteriaList);
      $scope.queryCriteriaList = angular.copy($scope.criteriaList);
      $scope.queryCriteriaList.forEach(function (criteria) {
        try {
          if (Array.isArray(criteria.Value))
            criteria.Value = angular.toJson(criteria.Value);
        } catch (e) {
          //oh well.
        }
      });
      $rootScope.queryCriteriaList = angular.copy($scope.queryCriteriaList);

      var query = null;
      query =
        {
          criteria: {
            DatasetId: $scope.dataset.Id,
            //Fields: 	  queryCriteriaList,
            Fields: $scope.queryCriteriaList,
            TablePrefix: $scope.dataset.Datastore.TablePrefix,
            //startRow: params.startRow,
            //endRow: params.endRow,
            //sortModel: params.sortModel,
            //filterModel: params.filterModel,
            //NumberOfRecordsToPull: $scope.NumberOfRecordsToPull,
            //LastRecord:  $scope.lastRecord,
            //params: params,
          },
          loading: true,
        };

      //$scope.startRow = params.startRow;

      //console.log("query in buildQuery is next...");
      //console.dir(query);

      return query;
    };

    $scope.removeCriteria = function (idx) {
      $scope.criteriaList.splice(idx, 1);

      $scope.fieldCriteriaRemoved = true;

      if ($scope.AutoExecuteQuery)
        $scope.executeQuery();
    };


    $scope.openActivity = function () {
      console.dir($scope.selectedRow);
      $location.path("/dataview/" + $scope.selectedRow.ActivityId);
    };

    //export the data - button click
    $scope.doExport = function () {

      if (!$scope.ExportFilename) {
        alert("Please enter a name for your export file like: 'TucannonJune25Export'.");
        return;
      }

      var params = {
        fileName: $scope.ExportFilename + ".xlsx",
        processCellCallback: $scope.processCellDataForOutput,
      };

      $scope.dataAgGridOptions.api.exportDataAsExcel(params);
    };

    //this is used by both export and copy functions to de-reference our values
    $scope.processCellDataForOutput = function (params) {

      //here we do any special formatting since export does NOT call cell renderers or cell formatters...
      //if ((params.column.colDef.DbColumnName == "LocationId") && (params.column.colDef.PossibleValues !== null)) {
      if ((params.column.colDef.DbColumnName == "LocationId") && ($scope.dataset.Locations !== null)) {
        //return params.column.colDef.PossibleValues[params.value];

        // For locations, overwrite the params value with the Label,
        // rather than the Id, before it goes to the file, so that
        // the text name shows in the file, rather than the Id.
        $scope.dataset.Locations.forEach(function (item) {
          if (params.value == item.Id)
            params.value = item.Label;
          //return item.Label;
        });

      }

      if ((params.column.colDef.DbColumnName == "InstrumentId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "FishermanId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "StreamName") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "ActivityQAStatusId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if ((params.column.colDef.DbColumnName == "RowQAStatusId") && (params.column.colDef.PossibleValues !== null)) {
        return params.column.colDef.PossibleValues[params.value];
      }

      if (params.column.colDef.ControlType == 'file' || params.column.colDef.ControlType == 'link') {
        var retvalue = [];
        var files = getFilesArrayAsList(params.value);
        files.forEach(function (file) {
          if (params.column.colDef.ControlType == 'file') {
            if (file.Name)
              retvalue.push(file.Name);
            else
              retvalue.push(file);
          } else if (params.column.colDef.ControlType == 'link')
            retvalue.push(file.Link);
        });
        return retvalue.join();
      }

      if (params.column.colDef.ControlType == "datetime" || params.column.colDef.ControlType == "time") {

        let retval = params.value;
        try {
          retval = moment(params.value).format("YYYY-MM-DD HH:mm:ss");
        } catch (e) {
          console.log("problem converting: " + retval + " to datetime text");
          console.dir(e);
        }
        return retval;
      }

      if (params.column.colDef.ControlType == "date" || params.column.colDef.ControlType == "activity-date") {
        let retval = params.value;
        try {
          retval = moment(params.value).format("YYYY-MM-DD");
        } catch (e) {
          console.log("problem converting: " + retval + " to datetime text");
          console.dir(e);
        }
        return retval;
      }

      //default
      return params.value;

    };

    $scope.resetQueryError = function () {
      $scope.queryResult.Error = false;
      $scope.queryResult.ErrorMessage = null;
    };

  }];

export default dataset_query;
