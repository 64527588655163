// defines the covid module
import * as angular from 'angular';

// require([
//controllers
import covid_list from "./components/employees/list";


//modals

//service

import {serviceUrl} from "../../config";
import covid_services from "./covid-service";


// ], function () {
const covid_module = angular.module('CovidModule', ['ui.bootstrap', 'ngResource']);
covid_module.controller('CovidListController', covid_list);
covid_module.service('CovidService', covid_services);
covid_module.factory('MyEmployees', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/covid/supervisedemployees', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

covid_module.factory('MyEmployeesWork', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/covid/supervisedemployeeswork', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

covid_module.factory('SaveEmployees', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/covid/saveemployees');
}]);

covid_module.factory('RemoveEmployee', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/covid/removeemployee');
}]);

export default covid_module;
// });



