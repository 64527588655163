import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {EsriMapComponent} from "../esri-map/esri-map.component";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import {
  BING_KEY,
  datasetActivitiesBasemapConfig, 
  defaultLayer,
  GEOMETRY_SERVICE_URL,
  NAD83_SPATIAL_REFERENCE, 
  proxyUrl, 
  PUBLIC_OR_PRIVATE,
  ENVIRONMENT,
  CDMS_POINTS_TEST_URL,
  CDMS_POINTS_PROD_URL
} from "../../../appjsLegacy/config";
// import GeometryService from "@arcgis/core/tasks/GeometryService";
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import * as geometryServ from "@arcgis/core/rest/geometryService";
import BingMapsLayer from "@arcgis/core/layers/BingMapsLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import {addProxyRule} from "@arcgis/core/core/urlUtils";
import * as watchUtils from "@arcgis/core/core/watchUtils";

@Component({
  selector: 'habitat-map',
  templateUrl: './habitat-map.component.html',
  styleUrls: ['./habitat-map.component.scss']
})
export class HabitatMapComponent extends EsriMapComponent implements OnInit, OnChanges {
  @ViewChild('mapViewNode', {static: true}) private habitatViewElement: ElementRef;
  @Input() zoom: number;
  @Input() center: string;
  @Input() basemap: string;
  @Input() url: string;
  @Input() row: any;
  @Input() project: any;
  @Input() inPayload: any;
  @Input() deleteIds: any[];
  private SaveMessage: string;

  @Input()
  set gridSelectIds(val: any[]) {
    this.showSelectedLocationsById(val)
  }

  @Input()
  set locationObjectIds(val: any[]) {
    this.showLocationsById(val);
  }

  // @Output() handleUploadRemove: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateLocation: EventEmitter<any> = new EventEmitter<any>();
  @Output() sde: EventEmitter<any> = new EventEmitter<any>();

  // SdeObjectId: number;
  OldSdeObjectId: number;

  newPoint: Point;
  newGraphic: Graphic;

  // SaveMessage: string;
  selectedBasemap: string;
  basemaps: any[];
  map: Map;
  mapView: MapView;
  locationLayer: FeatureLayer;
  pointsLayer: GraphicsLayer;
  spatialReference = new SpatialReference({wkid: 102100});
  selectedFeature: any;
  selectedGraphic: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.createMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    // super.ngOnChanges(changes);
    console.log(changes);
    console.log("this.inPaylaod (ngOnChanges) is next...");
    console.log(this.inPayload);

    // Note:  ngOnChanges runs whenever these is a change to the value it has.
    // In this case, it has inPayload.
    // So, when in PayLoad changes, it causes this to run.
    // Therefore, we added IsFirstRun in modal-edit-location.ts, $scope.save,
    // so that this page can keep track of whether it has run already or not.
    // Thereby, we keep this to only run once (add a new location only once).
    if (this.inPayload && this.inPayload.IsFirstRun)
    {
      if (changes.hasOwnProperty('inPayload')) {
        if (this.inPayload != undefined && this.inPayload.hasOwnProperty('Location')) {
          if (this.inPayload.hasOwnProperty('Update')) {
            console.log('update')
            console.log(this.inPayload)
            this.row = this.inPayload.Location;
            this.project = this.inPayload.ProjectId
            this.updateExisting();
          } else {
            console.log('new')
            console.log(this.inPayload)
            this.project = this.inPayload.ProjectId
            this.row = this.inPayload.Location;
            this.newLocation();
          }
        }
      }

      this.inPayload.IsFirstRun = false;
    }
  }

  updateLayers() {
    console.log("Inside this.map.js, Changing Layer: " + this.selectedBasemap);

    try {
      //console.log("Loading layer: " + datasetActivitiesBasemapConfig[this.selectedBasemap].ServiceURL);

      this.map.removeAll();

      let new_layer = undefined;
      var bing_layers_map = {
        BingMapsRoad: new BingMapsLayer({style: 'road', key: BING_KEY}),
        BingMapsAerial: new BingMapsLayer({style: 'aerial', key: BING_KEY}),
        BingMapsHybrid: new BingMapsLayer({style: 'hybrid', key: BING_KEY}),
      };

      console.dir(bing_layers_map);
      //add the selected basemap
      if (datasetActivitiesBasemapConfig[this.selectedBasemap].library == 'CTUIR')
        new_layer = new TileLayer({url: datasetActivitiesBasemapConfig[this.selectedBasemap].ServiceURL});
      else if (datasetActivitiesBasemapConfig[this.selectedBasemap].library == 'Esri') {
        //new_layer = new esri.layers.TileLayer(datasetActivitiesBasemapConfig[this.selectedBasemap].ServiceURL);
        //new_layer = new esri.layers.TileLayer("http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer");
        // new_layer = new TileLayer(datasetActivitiesBasemapConfig[this.selectedBasemap].type);
        new_layer = new TileLayer({url: 'http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer'});
        console.log("Created new_layer using ESRI...");
      } else if (datasetActivitiesBasemapConfig[this.selectedBasemap].library == 'Bing') {
        new_layer = new BingMapsLayer({
          key: BING_KEY,
          style: bing_layers_map[datasetActivitiesBasemapConfig[this.selectedBasemap].type]
        });
        console.log("Created new_layer using Bing...");
      }

      this.map.layers.add(new_layer);
      // this.map.currentBasemapLayer = new_layer;

      // Somehow, this.url gets set to the production feature layer service url.
      // Therefore, we must set it to the correct one for our environment.
      if (this.url) {
        if (ENVIRONMENT === "prod")
        {
          this.url = CDMS_POINTS_PROD_URL;
        }
        else
        {
          this.url = CDMS_POINTS_TEST_URL;
        }
        console.log("habitat-map-component:  this.url = " + this.url);

        this.locationLayer = new FeatureLayer({url: this.url});
        this.map.layers.add(this.locationLayer);
        this.pointsLayer = new GraphicsLayer();
        this.map.layers.add(this.pointsLayer);
      }


      console.log("done!");
    } catch (e) {
      console.dir(e);
    }
  }

  createMap() {

    // setup our map options based on the attributes and scope
    this.map = new Map();


    if (PUBLIC_OR_PRIVATE === "private") {
      // declare our map
      // loadModules(["esri/core/urlUtils"]).then(([urlUtils]) => { // formerly esri/urlUtils
      addProxyRule({
        urlPrefix: "restdata.ctuir.org",
        proxyUrl: proxyUrl
      });

      //esriConfig.defaults.io.proxyUrl = proxyUrl; // From the config.js file.
      //esriConfig.defaults.io.alwaysUseProxy = false;

      // });
    }


    this.selectedBasemap = defaultLayer;

    this.basemaps = [];
    for (var property in datasetActivitiesBasemapConfig) {
      if (datasetActivitiesBasemapConfig.hasOwnProperty(property)) {
        this.basemaps.push({label: datasetActivitiesBasemapConfig[property].Display, name: property});
      }
    }

    let center;
    if (this.center) {
      center = this.center.split(",").map(c => parseFloat(c));
    }

    this.mapView = new MapView({
      // center: ($attrs.center) ? $attrs.center.split(",") : this.center,
      // zoom: ($attrs.zoom) ? $attrs.zoom : this.zoom,
      container: this.habitatViewElement.nativeElement,
      zoom: this.zoom,
      center,
      //basemap: 'streets', //($attrs.basemap) ? $attrs.basemap : this.basemap,
      map: this.map,
      spatialReference: {
        wkid: 102100 //mercator
        //wkid:26911 //nad_1983
        //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'

      }
    });

    this.mapView.on("click", (e) => {
      this.clickedMap(e);
    });

    // this.map.on("load", function (e) { todo: ask george about createBasemapDropdown
    //   //createBasemapDropdown(map);
    // });

    //this.map.resize();
    this.updateLayers();
    watchUtils.whenTrue(this.mapView.popup, 'visible', () => {
      watchUtils.whenFalseOnce(this.mapView.popup, 'visible', () => {
        this.pointsLayer.removeAll();
        this.mapView.graphics.removeAll();
        this.sde.emit();
      });
    });
    //console.log("Map is complete and in scope.");
    return this.mapView;
    // });
  }


  newLocation() {
  console.log('creating a new habitat subproject location....')
    //nad83 zone 11...  might have to have this as a list somehwere...
    var inSR = new SpatialReference({wkt: NAD83_SPATIAL_REFERENCE}); //esri/SpatialReference
    var outSR = new SpatialReference({wkid: 102100});
    // var geometryService = new GeometryService({url: GEOMETRY_SERVICE_URL}); //esri/tasks/GeometryService
    this.newPoint = new Point({x: this.row.GPSEasting, y: this.row.GPSNorthing, spatialReference: inSR}); // esri/geometry/Point
    //convert spatial reference
    //var PrjParams = new tasks.ProjectParameters();
    let PrjParams = new ProjectParameters(); //esri/tasks/ProjectParameters

    PrjParams.geometries = [this.newPoint];
    // PrjParams.outSR is not set yet, so we must set it also.
    PrjParams.outSpatialReference = outSR;
    PrjParams.toJSON();
    //do the projection (conversion)
    // PrjParams has Easting and Northing; outputpoint has x, y values.
    // The x, y values are what get sent/stored in the points table in sdevector.
    geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((outputpoint) => {

      this.newPoint = new Point(outputpoint[0]);
      //this.newGraphic = new Graphic(this.newPoint, new symbol.SimpleMarkerSymbol());
      this.newGraphic = new Graphic({geometry: this.newPoint, symbol: new SimpleMarkerSymbol()});
      this.mapView.graphics.add(this.newGraphic);

      //add the graphic to the map and get SDE_ObjectId
      this.locationLayer.applyEdits({addFeatures: [this.newGraphic]}).then((results) => {

        if (results.addFeatureResults[0]) {
          this.row.SdeObjectId = this.sde = results.addFeatureResults[0].objectId;

          this.updateLocation.emit({new: [this.project, this.row]});
          // this.setSdeObjectId(this.SdeObjectId);
          // console.log("Created a new point! " + newLocation.SdeObjectId);
          // this.NewPoint = true;
          //
          // var promise = CommonService.saveNewProjectLocation(this.project.Id, newLocation);
          // promise.$promise.then(function (result) {
          // 	console.log("done and success!");
          // 	console.log("result is next...");
          // 	console.dir(result);
          // 	angular.forEach(result, function (item, key) {
          // 		//console.log("key = " + key + ", item is next...");
          // 		//console.dir(item);
          // 		if (key === "Id") {
          // 			//this.locationId = promise.LocationId;
          // 			//this.subproject_row.LocationId = item;
          // 			this.row.LocationId = item;
          // 			//console.log("this.subproject_row.LocationId = " + this.subproject_row.LocationId);
          // 			console.log("this.row.LocationId = " + this.row.LocationId);
          //
          // 			//ok - new location is saved and we are prepped to save the subproject so handoff to next step:
          // 			this.saveFilesAndParent();
          // 		}
          // 	});
          //
          // 	//reload the project -- this will cause the locations and locationlayer to be reloaded!  wow!  go AngularJS!  :)
          // 	//this.refreshProjectLocations();
          // 	//$uibModalInstance.dismiss();  // This is from the ActivitiesConroller, ModalAddLocationCtrl.  We have this down below, so we do not need it here; it causes an error.
          // });

        }
      }); //applyedits
    }); //geometryservice.project
  }

  updateExisting() {
    console.log('Updating an existing habitat subproject location....')
    //nad83 zone 11...  might have to have this as a list somehwere...
    var inSR = new SpatialReference({wkt: NAD83_SPATIAL_REFERENCE}); //esri/SpatialReference
    var outSR = new SpatialReference({wkid: 102100});
    // var geometryService = new GeometryService({url: GEOMETRY_SERVICE_URL}); //esri/tasks/GeometryService
    this.newPoint = new Point({x: this.row.GPSEasting, y: this.row.GPSNorthing, spatialReference: inSR}); // esri/geometry/Point
    this.OldSdeObjectId = this.row.OldSdeObjectId;
    //convert spatial reference
    //var PrjParams = new tasks.ProjectParameters();
    let PrjParams = new ProjectParameters(); //esri/tasks/ProjectParameters

    PrjParams.geometries = [this.newPoint];
    // PrjParams.outSR is not set yet, so we must set it also.
    PrjParams.outSpatialReference = outSR;
    PrjParams.toJSON();
    //do the projection (conversion)
    // PrjParams has Easting and Northing; outputpoint has x, y values.
    // The x, y values are what get sent/stored in the points table in sdevector.
    geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((outputpoint) => {

      this.newPoint = new Point(outputpoint[0]);
      this.newGraphic = new Graphic({geometry: this.newPoint, symbol: new SimpleMarkerSymbol()});
      this.mapView.graphics.add(this.newGraphic);

      //add the graphic to the map and get SDE_ObjectId
      this.locationLayer.applyEdits({addFeatures: [this.newGraphic]}).then((addResults) => {

        if (addResults.addFeatureResults[0]) {
          //newLocation.SdeObjectId = this.SdeObjectId = addResults[0].objectId;
          this.row.SdeObjectId = this.sde = addResults.addFeatureResults[0].objectId;
          // this.setSdeObjectId(this.SdeObjectId);
          //console.log("Added a new point! " + newLocation.SdeObjectId);
          console.log("Added a new point! " + this.row.SdeObjectId);

          //this.NewPoint = true;
          var attributes = {OBJECTID: this.OldSdeObjectId}
          var deleteGraphic = new Graphic({geometry: this.newPoint, attributes: attributes});
          this.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}).then(deleteResults => {

            console.log("deleteResults is next...");

            console.dir(deleteResults.deleteFeatureResults[0]);

            if (!deleteResults.deleteFeatureResults[0]) {
              this.SaveMessage = "The location did not exist in SDE.";
              console.log("The location did not exist in SDE.");
              console.dir(deleteResults.deleteFeatureResults[0]);
              this.updateLocation.emit({update: {nonexistent: [this.project, this.row.LocationId, this.row.SdeObjectId, this.row]}});

              // CommonService.updateLocationAction(this.project.Id, this.row.LocationId, this.row.SdeObjectId);
            } else if (deleteResults.deleteFeatureResults[0]) {
              console.log("Deleted old point in sdevector! " + this.OldSdeObjectId);
              this.updateLocation.emit({update: {deleted: [this.project, this.row.LocationId, this.row.SdeObjectId]}})
              // CommonService.updateLocationAction(this.project.Id, this.row.LocationId, this.row.SdeObjectId);
            } else {
              this.SaveMessage = "There was a problem deleting that location.";
              console.dir(deleteResults.deleteFeatureResults[0]);
            }
          }, function (err) {
            console.log("Apply Edits - Delete - failed:  " + err.message);
          });

          //throw "Stopping right here...";

          //ok - new location is saved and we are prepped to save the subproject so handoff to next step:
          // this.saveFilesAndParent();
        } else {
          this.SaveMessage = "There was a problem adding the new location.";
          console.dir(addResults.addFeatureResults[0]);
        }

      }); //applyedits
    }); //geometryservice.project
  }


}




