import * as angular from 'angular';

var admin_edit_dataset_permissions = ['$scope', '$uibModal', '$timeout', '$routeParams', 'DatasetService', 'CommonService','ProjectService','AdminService',
    function ($scope, $uibModal, $timeout, $routeParams, DatasetService, CommonService, ProjectService, AdminService) {

        $scope.OnTab = "Permissions";
        $scope.SaveMessage = null;
        $scope.dataset = DatasetService.getDataset($routeParams.Id);
        $scope.groups = CommonService.getGroups();

        $scope.dataset.$promise.then(function () {
            if(typeof $scope.dataset.Config.Permissions === 'undefined'){
                $scope.dataset.Config.Permissions = {};
            }
        });


        $scope.saveConfig = function () {
            $scope.SaveMessage = "Saving...";
            $scope.ErrorMessage = "";

            if($scope.dataset.Config.Permissions.Edit == ""){
                delete $scope.dataset.Config.Permissions.Edit;
            }

            if($scope.dataset.Config.Permissions.View == ""){
                delete $scope.dataset.Config.Permissions.View;
            }

            console.dir($scope.dataset.Config);

            $scope.dataset.Config = angular.toJson($scope.dataset.Config);

            var promise = DatasetService.saveDataset($scope.dataset );

            promise.$promise.then(function () {
                $scope.dataset.Config = angular.fromJson(promise.Config);
                $scope.SaveMessage = "Save successful.";
            }, function(error) {
                console.log("something went wrong:");
                console.dir(promise);
                $scope.SaveMessage = "";
                $scope.ErrorMessage = "Error. Try again or contact your administrator"
            });
        };

    }

];

export default admin_edit_dataset_permissions;
