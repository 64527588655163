import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {UpgradeModule} from '@angular/upgrade/static';
import {HttpClientModule} from '@angular/common/http';
import {AppjsModule} from './appjs/appjs.module';
import {LoginModule} from "./login/login.module";
import {EsriMapComponent} from './map/esri-map/esri-map.component';
import {PermitMapComponent} from './map/permit-map/permit-map.component';
import {AppraisalMapComponent} from './map/appraisal-map/appraisal-map.component';
import {LeasingMapComponent} from './map/leasing-map/leasing-map.component';
import {FormsModule} from "@angular/forms";
import {ConfirmModule} from "./confirm/confirm.module";
import { HabitatMapComponent } from './map/habitat-map/habitat-map.component';


@NgModule({
  declarations: [
    AppComponent,
    EsriMapComponent,
    PermitMapComponent,
    AppraisalMapComponent,
    LeasingMapComponent,
    HabitatMapComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    UpgradeModule,
    FormsModule,
    LoginModule, // login must come before all other custom modules
    ConfirmModule,
    AppjsModule,// must come last because it contains catch-all routing
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
//   constructor(public upgrade: UpgradeModule, private http: HttpClient) {
//   }
//
//   ngDoBootstrap() {
//     var root = location.pathname.replace(new RegExp(/\/[^\/]+$/), '');
//     root = (root == '/' ? "" : root); //when visiting website with just the domain, the root has a hanging /
//
//     // require({
//     //   async: true,
//     //   packages: [
//     //     {name: 'app', location: root + '/app'},
//     //     {name: 'core', location: root + '/app/core'},
//     //     {name: 'private', location: root + '/app/private'},
//     //   ]
//     // });
//
//     // require loads each of the javascript files referenced below, which can in turn load other files.
// //     require([
// //       'angular',
// //       'dojo/parser',
// //       'dijit/Menu',
// //       'dijit/MenuItem',
// //       'dijit/form/DropDownButton',
// // //      'app/app',                                                    //main.js
// //       'core/all-modules',
// //       'core/common/common-module',
// //       'core/projects/projects-module',
// //       'core/datasets/datasets-module',
// //       'core/admin/admin-module',
// //       'core/user/user-module',
// //       'private/all-modules',
// //       'private/crpp/crpp-module',
// //       'private/habitat/habitat-module',
// //       'private/appraisals/appraisals-module',
// //       'private/leasing/leasing-module',
// //       'private/permits/permit-module',
// //       'private/olc/olc-module',
// //       'private/covid/covid-module',
// //       'private/bot/bot-module'
// //
// //     ], (angular, parser) => {
// //
// //       angular.element(document).ready(function () {           //punch it, Chewy!
//
//     //check our authentication and setup our user profile first of all!
//     //http://nadeemkhedr.wordpress.com/2013/11/25/how-to-do-authorization-and-role-based-permissions-in-angularjs/
//
//     this.http.get(WHOAMI_URL).pipe(
//       tap(data => profile = data),
//       catchError(e => window.location = LOGIN_URL),
//       finalize(() => {
//                 console.log("Booting dojo...");
//         //parser.parse();
//
//         // require(['dojo/domReady!'], function () {
//         console.log("Booting angular...");
//         // setTimeout(() => {
//         this.upgrade.bootstrap(document.body, ['app'], {strictDi: true});
//         console.log("Inside init.js, all systems GO!")
//       })).subscribe();
//   }
}
