import {
  SharingLevel, SHARINGLEVEL_GROUPREAD,
  SHARINGLEVEL_PRIVATE,
  SHARINGLEVEL_PUBLICREAD,
  SHARINGLEVEL_REFERENCE_LIBRARY
} from "../../../../config";
import {isValidURL} from "../../../common/common-functions";

var modal_edit_file = ['$scope', '$uibModalInstance', 'ProjectService',
    function ($scope, $uibModalInstance, ProjectService){

		$scope.header_message = "Edit file";

        //options from config.js
        $scope.SHARINGLEVEL_PRIVATE = SHARINGLEVEL_PRIVATE;
		$scope.SHARINGLEVEL_PUBLICREAD = SHARINGLEVEL_PUBLICREAD;
		$scope.SHARINGLEVEL_GROUPREAD = SHARINGLEVEL_GROUPREAD;
		$scope.SHARINGLEVEL_REFERENCE_LIBRARY = SHARINGLEVEL_REFERENCE_LIBRARY;
        $scope.SharingLevel = SharingLevel;

		$scope.LinkError = false;

		$scope.save = function(){
			if (($scope.row.FileType) && ($scope.row.FileType.Name == 'Link')){
				$scope.validateLink();
				if ($scope.LinkError)
					return;

				$scope.row.Name = $scope.row.Link;
			}

			var promise = ProjectService.updateFile($scope.project.Id, $scope.row);

			promise.$promise.then(function(){
				$scope.callback(promise);
				$uibModalInstance.dismiss();
			});
		};

		$scope.cancel = function(){
			$uibModalInstance.dismiss();
		};

		//validate link URL
        $scope.validateLink = function(){

            $scope.LinkError = false;

            if(!isValidURL($scope.row.Link))
                $scope.LinkError = "Please enter a full valid URL (http:// or https:// required)";

        }
	}
];

export default modal_edit_file;
