//modal to edit dataset field
var modal_admin_new_datastore = ['$scope', '$uibModal','$uibModalInstance','AdminService',

    function ($scope, $uibModal, $uibModalInstance, AdminService) {

        $scope.datastore = { TableType: 'Header+Detail'};
        $scope.to_generate = "";

        $scope.save = function () {

            //make sure the name is unique
            var nameExists = false;
            $scope.datastores.forEach(function(datastore){
                if(datastore.Name == $scope.datastore.Name){
                    nameExists = true;
                }
            })

            if(nameExists){
                alert("Name exists. Please change the Name and try again.");
                return;
            }

            //make sure the tableprefix is unique
            var prefixExists = false;
            $scope.datastores.forEach(function(datastore){
                if(datastore.TablePrefix == $scope.datastore.TablePrefix){
                    prefixExists = true;
                }
            })

            if(prefixExists){
                alert("TablePrefix exists. Please change the TablePrefix and try again.");
                return;
            }

			var saved_datastore = AdminService.saveNewDatastore($scope.datastore);
            saved_datastore.$promise.then(function () {
                $uibModalInstance.close(saved_datastore);
            }, function (error) {
                $scope.SaveMessage = "Error: " + error.data.ExceptionMessage;
            });

        };

        $scope.checkTablePrefix = function(){
            $scope.datastore.TablePrefix = $scope.datastore.TablePrefix.replace(" ","");
            $scope.datastore.TablePrefix = $scope.datastore.TablePrefix.charAt(0).toUpperCase() + $scope.datastore.TablePrefix.slice(1)
            if($scope.datastore.TableType == 'Header+Detail') {
                $scope.to_generate = $scope.datastore.TablePrefix + "_Header + "+$scope.datastore.TablePrefix + "_Detail";
            } else {
                $scope.to_generate = $scope.datastore.TablePrefix;
            }

        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

    }
];

export default modal_admin_new_datastore;
