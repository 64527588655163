var inspection_violation_modal = ['$scope', '$uibModalInstance', 'LeasingService',
    function ($scope, $uibModalInstance, LeasingService) {

        $scope.header_message = "Update Violation Action";

        $scope.save = function () {

            console.dir($scope.violation_modal);
            var save_result = LeasingService.saveInspectionViolation($scope.violation_modal);

            save_result.$promise.then(function () {
                $scope.saveInspectionCallback(save_result);
                $uibModalInstance.dismiss();
            });
        };


        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

    }
];

export default inspection_violation_modal;
