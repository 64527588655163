import {Component, Injectable, OnInit} from '@angular/core';
import {LoginService} from "../login.service";
import {loginUrl} from "../../../../appjsLegacy/config";
import {Router} from "@angular/router";
@Injectable(
  {providedIn: 'root'}
)
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {
  }

  public logout(){

    var logout = this.loginService.logout();

	if(logout)
	{
		logout.toPromise().then(() => {
      // this.router.navigateByUrl('login');
      //@ts-ignore
      window.location = loginUrl;
		});
	}
  }

}

