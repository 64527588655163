import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import {PermitMapComponent} from "../permit-map/permit-map.component";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
// import {addProxyRule} from "@arcgis/core/core/urlUtils";
// import {defaultLayer, parcelLayerConfig, proxyUrl, servicesLayerConfig} from "../../../appjsLegacy/config";
// import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
// import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
// import TileLayer from "@arcgis/core/layers/TileLayer";
// import Basemap from "@arcgis/core/Basemap";
// import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

@Component({
  selector: 'appraisal-map',
  templateUrl: './appraisal-map.component.html',
  styleUrls: ['./appraisal-map.component.scss']
})
export class AppraisalMapComponent extends PermitMapComponent implements OnInit, OnChanges {
  @ViewChild('appraisalMapViewNode', {static: true}) private appraisalMapViewElement: ElementRef;
  @Input() center: string;
  @Input() zoom: number;
  @Input() scale: number;
  @Input() gridSelection: any;
  @Output() parcel: EventEmitter<any> = new EventEmitter<any>();

  set locationObjectIds(val: any[]) {
    this.showLocationsById(val);
  }

  map: Map;
  mapView: MapView
  serviceLayers: any[];
  selectedServiceLayers: string[];
  parcelLayer: GraphicsLayer;
  locationLayer: any;

  constructor() {
    super();
  };

  ngOnInit() {
    console.log('starting up appraisal map component')
    this.initializeMap(["parcels_outline"], this.appraisalMapViewElement.nativeElement);
  }

  ngOnChanges() {
    // this is getting the input from appraisal-map
    if (this.mapView) {
      this.clearAll();
      this.findOnMap(this.gridSelection);
    }
  }

  addLayer(layer, filter?) {
    // this.locationLayer = this.map.layers.add(layer);

//          console.log("Added layer to map");
//          console.log("layer_"+layer.id);

    //setup our layer locationid function so we can all it again sometime
    layer.showLocationsById = function (locationObjectIds) {
      try {
        this.clearSelection();
        var definitionExpression = "OBJECTID IN (" + locationObjectIds + ")";
        console.log("Definition expression: " + definitionExpression);
        this.setDefinitionExpression(definitionExpression);
        this.refresh();
      } catch (e) {
        console.dir(e);
      }
    };

    if (filter && filter == "location") {
      if (typeof this.locationObjectIds == "undefined") {
        // $scope.$watch('locationObjectIds', function () {

        //skip the first run
        if (typeof this.locationObjectIds == "undefined")
          return;

        layer.showLocationsById(this.locationObjectIds); // now call it

        layer.show();

        // });
      }
    }


    return this.map.layers.add(layer);
  };

  findOnMap(in_allotment) {
    this.showingParcel = false;
    this.mapSearchResults = {'Message': ""};

    this.queryMatchParcel(in_allotment, (features) => {
      if (features.length == 0) {
        console.log("allotment not found: " + in_allotment);
        this.mapSearchResults.Message = "Not found on map.";
      } else {
        this.querySelectParcel(null, features[0].attributes.OBJECTID, (geo_features) => {
          this.addParcelToMap(geo_features[0]);
          this.centerAndZoomToGraphic(this.selectedGraphic, 2);

          this.mapView.popup.content = this.getContentForPopup(features[0]);
          this.mapView.popup.open({location: this.selectedGraphic.geometry.extent.center});
        });
        this.showingParcel = features[0].attributes.OBJECTID;
      }
    });
  }

  // initializeMap(serviceLayerArr: string[], container: any) {
  //   this.map = new Map();
  //
  //   // declare our map
  //   console.log("trying to make the map");
  //
  //
  //   addProxyRule({
  //     urlPrefix: "restdata.ctuir.org",
  //     proxyUrl: proxyUrl
  //   });
  //
  //
  //   //our first layer from up above...
  //   //console.log("//restdata.umatilla.nsn.us/arcgis/rest/services/BasemapParcelViewerCTUIR/MapServer?token=" + security_token);
  //   this.selectedServiceLayers = serviceLayerArr;
  //   //setup basemaps
  //   this.selectedBasemap = defaultLayer; //"imageryLayer"
  //   // this.selectedServiceLayers = this.serviceLayers;
  //
  //   this.basemaps = [];
  //   for (let property in parcelLayerConfig) {
  //     if (parcelLayerConfig.hasOwnProperty(property)) {
  //       this.basemaps.push({label: parcelLayerConfig[property].Display, name: property});
  //     }
  //   }
  //
  //
  //   this.serviceLayers = [];
  //   for (let property in servicesLayerConfig) {
  //     if (servicesLayerConfig.hasOwnProperty(property)) {
  //       this.serviceLayers.push({label: servicesLayerConfig[property].Display, name: property});
  //     }
  //   }
  //
  //
  //   //might want to do this: https://developers.arcgis.com/javascript/3/jssamples/query_hover.html
  //   // did try but had errors. might not be possible without using featureservice?: https://community.esri.com/thread/191330-popup-on-mouse-over-on-dynamic-service
  //
  //   const center = this.center.split(",").map(c => parseInt(c));
  //   this.mapView = new MapView({
  //     container: container,
  //     center,
  //     scale: this.scale,
  //     map: this.map,
  //     spatialReference: {
  //       wkid: 102100 //mercator
  //       // wkid:26911 //nad_1983
  //       //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //
  //     }
  //   });
  //   this.updateLayers();
  //
  //
  //   // start exposing an API by setting properties on "this" which is our controller
  //   // lets expose the "addLayer" method so child directives can add themselves to the map
  //
  //
  //   // // listen for click events and expost them as broadcasts on the scope and suing the scopes click handler
  //   // this.map.on("click", function (e) {
  //   //   // emit a message that bubbles up scopes, listen for it on your scope
  //   //   $scope.$emit("this.map.click", e);
  //   //
  //   //   // use the scopes click fuction to handle the event
  //   //   $scope.$apply(function ($scope) {
  //   //     $scope.click.call($scope, e);
  //   //   });
  //   // });
  //
  //   this.mapView.on("click", (e) => {
  //     this.clickedMap(e);
  //   });
  //
  //   return this.mapView;
  //
  // }
  //  updateLayers() {
  //
  //   console.log("Changing Layer: " + this.selectedBasemap);
  //
  //   try {
  //     console.log("Loading layer: " + parcelLayerConfig[this.selectedBasemap].ServiceURL);
  //
  //     this.map.removeAll();
  //
  //     //add the selected basemap
  //     var new_layer1 = new MapImageLayer({url: parcelLayerConfig[this.selectedBasemap].ServiceURL});
  //     // let new_layer = new TileLayer({url: 'http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer'});
  //     this.map.layers.add(new_layer1);
  //     // this.map.layers.add(new_layer);
  //     // this.map.basemap = new Basemap(new_layer);
  //
  //
  //     //now add any selected service layers
  //     for (var i = this.selectedServiceLayers.length - 1; i >= 0; i--) {
  //       var service_layer;
  //       console.log(i);
  //       console.log(this.selectedServiceLayers[i]);
  //       if (servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL.includes("FeatureServer") || this.selectedServiceLayers[i] == 'parcels') {
  //         service_layer = new FeatureLayer({url: servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL});
  //       } else //then it is a MapServer
  //       {
  //         service_layer = new MapImageLayer({url: servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL});
  //       }
  //
  //       console.log("adding " + servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL);
  //       this.map.layers.add(service_layer);
  //     }
  //
  //
  //     this.parcelLayer = new GraphicsLayer();
  //     this.map.layers.add(this.parcelLayer);
  //
  //     console.log("done!");
  //     // this.map.reposition();
  //   } catch (e) {
  //     console.dir(e);
  //   }
  // };
  // updateLayers() {
  //
  //     console.log("Changing Layer: " + this.selectedBasemap);
  //
  //     try {
  //       console.log("Loading layer: " + parcelLayerConfig[this.selectedBasemap].ServiceURL);
  //
  //       this.map.removeAll();
  //
  //       //add the selected basemap
  //       var new_layer = new TileLayer({url: parcelLayerConfig[this.selectedBasemap].ServiceURL});
  //
  //       this.map.layers.add(new_layer);
  //       this.map.basemap = new Basemap(new_layer);
  //
  //       //now add any selected service layers
  //       for (var i = this.selectedServiceLayers.length - 1; i >= 0; i--) {
  //         var service_layer = new MapImageLayer(servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL);
  //         this.map.layers.add(service_layer);
  //       }
  //
  //       this.parcelLayer = new GraphicsLayer();
  //       this.map.layers.add(this.parcelLayer);
  //
  //       console.log("done!");
  //     } catch (e) {
  //       console.dir(e);
  //     }
  //   };


  // appraisalMap() {
  //
  //
  //   esriConfig.request.proxyUrl = proxyUrl; // From the config.js file.
  //
  //   this.map = new Map();
  //   const center = this.center.split(",").map(c => parseInt(c));
  //   this.mapView = new MapView({
  //     center: center,
  //     zoom: this.zoom,
  //     map: this.map,
  //     spatialReference: {
  //       wkid: 102100 //mercator
  //       //wkid:26911 //nad_1983
  //       //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //
  //     }
  //   });
  //   //our first layer from up above...
  //   //console.log("//restdata.umatilla.nsn.us/arcgis/rest/services/BasemapParcelViewerCTUIR/MapServer?token=" + security_token);
  //
  //   //setup basemaps
  //   this.selectedBasemap = defaultLayer; //imageryLayer
  //   this.selectedServiceLayers = [];
  //
  //   this.basemaps = [];
  //   for (let property in parcelLayerConfig) {
  //     if (parcelLayerConfig.hasOwnProperty(property)) {
  //       this.basemaps.push({label: parcelLayerConfig[property].Display, name: property});
  //     }
  //   }
  //
  //   this.serviceLayers = [];
  //   for (var property in servicesLayerConfig) {
  //     if (servicesLayerConfig.hasOwnProperty(property)) {
  //       this.serviceLayers.push({label: servicesLayerConfig[property].Display, name: property});
  //     }
  //   }
  //
  //
  //   //var layer = new esri.layers.ArcGISTiledMapServiceLayer("//restdata.umatilla.nsn.us/arcgis/rest/services/BasemapParcelViewerCTUIR/MapServer?token=" + security_token);
  //
  //   //var layer = new esri.layers.ArcGISTiledMapServiceLayer(parcelLayerConfig[this.map.selectedBasemap].ServiceURL);
  //   //this.map.addLayer(layer);
  //   //this.map.currentBasemapLayer = layer;
  //
  //   //this.map.parcelLayer = new esri.layers.GraphicsLayer();
  //   //this.map.addLayer(this.map.parcelLayer);
  //   this.updateLayers();
  //
  //
  //   // start exposing an API by setting properties on "this" which is our controller
  //   // lets expose the "addLayer" method so child directives can add themselves to the map
  //   //use this for doing a search by parcelid or address
  //
  //
  //   //use this for selecting a specific parcel/allotment by id (no geometry)
  //   // ALREADY EXISTS IN PERMIT MAP DIRECTIVE
  //   // this.map.queryMatchParcel = function (searchParam, callback) {
  //   //   var queryTask = new QueryTask(parcelLayerConfig[this.map.selectedBasemap].QueryURL);
  //   //   var query = new Query();
  //   //   query.where = dojo.string.substitute(parcelLayerConfig[this.map.selectedBasemap].LocateParcelQuery, [searchParam]);
  //   //   query.returnGeometry = false;
  //   //   query.outSpatialReference = this.spatialReference;
  //   //   query.outFields = ["*"];
  //   //
  //   //   queryTask.execute(query, function (result) {
  //   //     callback(result.features); //give back the parcel features we found...
  //   //   }, function (err) {
  //   //     console.log("Failure executing query!");
  //   //     console.dir(err);
  //   //     console.dir(query);
  //   //   });
  //   // };
  //
  //
  //   //use this to select a particular parcel either by objectid (like after a search) or x,y mapPoint
  //   // EXISTS IN PERMIT MAP COMPONENT
  //   // this.map.querySelectParcel = function (mapPoint, objectId, callback) {
  //   //   console.log("Inside Appraisalthis.map.js...");
  //   //   console.log("Running query on: " + parcelLayerConfig[this.map.selectedBasemap].QueryURL);
  //   //
  //   //   var queryTask = new QueryTask(parcelLayerConfig[this.map.selectedBasemap].QueryURL);
  //   //   var query = new Query();
  //   //
  //   //   query.outSpatialReference = this.spatialReference;
  //   //   query.returnGeometry = true;
  //   //   query.outFields = ["*"];
  //   //   if (mapPoint) {
  //   //     query.geometry = mapPoint;
  //   //   } else {
  //   //     query.objectIds = [objectId];
  //   //   }
  //   //
  //   //   query.spatialRelationship = Query.SPATIAL_REL_INTERSECTS;
  //   //   queryTask.execute(query, function (result) {
  //   //     console.dir(result);
  //   //     callback(result.features); //give back the parcel features we found...
  //   //   }, function (err) {
  //   //     console.log("Failure executing query!");
  //   //     console.dir(err);
  //   //     console.dir(query);
  //   //   });
  //   //
  //   //
  //   // };
  //
  //   // THIS IS IN PERMIT MAP COMPONENT
  //   // this.map.clearGraphics = function () {
  //   //   this.parcelLayer.clear();
  //   // }
  //   //
  //   // THIS IS IN PERMIT MAP DIRECTIVE
  //   // this.map.addParcelToMap = function (feature, color, alpha) {
  //   //
  //   //   var graphic;
  //   //   if (!color)
  //   //     color = "#FF6600";
  //   //
  //   //   if (!alpha)
  //   //     alpha = .25;
  //   //
  //   //
  //   //   var lineColor = new dojo.Color();
  //   //   lineColor.setColor(color);
  //   //
  //   //   var fillColor = new dojo.Color();
  //   //   fillColor.setColor(color);
  //   //   fillColor.a = alpha;
  //   //
  //   //   var symbol = new SimpleFillSymbol(SimpleFillSymbol.STYLE_SOLID,
  //   //     new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, lineColor, 3), fillColor);
  //   //
  //   //   graphic = new Graphic(feature.geometry, symbol, feature.attributes);
  //   //
  //   //   this.parcelLayer.clear();
  //   //   this.parcelLayer.add(graphic);
  //   //   this.selectedFeature = feature;
  //   //   this.selectedGraphic = graphic;
  //   //
  //   //   $scope.$emit("this.map.selectedFeature", feature); //notify
  //   // }
  //   //
  //   // THIS IS IN PERMIT MAP DIRECTIVE
  //   // this.map.centerAndZoomToGraphic = function (graphic) {
  //   //   var centerPoint = graphic.geometry.getExtent().getCenter();
  //   //   return this.map.centerAndZoom(centerPoint, 15);
  //   // };
  //
  //
  //   // lets expose a version of centerAt that takes an array of [lng,lat]
  //   //THIS IS IN ESRI MAP COMPONENT
  //   // this.centerAt = function (center) {
  //   //   var point = new Point({
  //   //     x: center[0],
  //   //     y: center[1],
  //   //     spatialReference: {
  //   //       wkid: 102100 //mercator
  //   //       //wkid:26911 //nad_1983
  //   //       //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //   //     }
  //   //   });
  //   //
  //   //   this.map.centerAt(point);
  //   // };
  //
  //   // listen for click events and expost them as broadcasts on the scope and suing the scopes click handler
  //   // this.map.on("click", function (e) {
  //   //   // emit a message that bubbles up scopes, listen for it on your scope
  //   //   $scope.$emit("this.map.click", e);
  //   //
  //   //   // use the scopes click fuction to handle the event
  //   //   $scope.$apply(function ($scope) {
  //   //     $scope.click.call($scope, e);
  //   //   });
  //   // });
  //
  //   return this.mapView;
  // }


  // this exists already in the permit Map component
  // querySearchParcel(searchParam, callback) {
  //     var queryTask = new QueryTask(parcelLayerConfig[this.map.selectedBasemap].QueryURL);
  //     var query = new Query();
  //     query.where = dojo.string.substitute(parcelLayerConfig[this.map.selectedBasemap].ParcelQuery, [searchParam]);
  //     console.log("query.where is next...");
  //     console.dir(query.where);
  //     query.returnGeometry = false;
  //     query.outSpatialReference = this.spatialReference;
  //     query.outFields = ["*"];
  //
  //     queryTask.execute(query, function (result) {
  //       callback(result.features); //give back the parcel features we found...
  //     }, function (err) {
  //       console.log("Failure executing query!");
  //       console.dir(err);
  //       console.dir(query);
  //     });
  //   };
  // })
}
