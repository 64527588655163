import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var modal_select_grid = ['$scope', '$uibModalInstance','CommonService',
    function ($scope, $uibModalInstance, CommonService){



	$scope.data = CommonService.getSelectGridData($scope.grid_field.cdmsField.FieldId);
	$scope.data.$promise.then(function(){

		//$scope.data = $scope.grid_field.PossibleValues;
		//console.dir($scope.grid_field.PossibleValues);

		var columnDefs = [];

		//console.dir($scope.data);

		Object.keys($scope.data[0]).forEach(function(key){
			var aField = {
				field: key,
				headerName: key,
				menuTabs: ['filterMenuTab'],
				filter: 'text'
			};
			if(key == 'Label'){
			  // @ts-ignore
				aField.sort = 'asc';
			}
			columnDefs.push(aField);
		})

		$scope.selectGrid = {
			columnDefs: columnDefs,
			rowData: $scope.data,
			rowSelection: 'single',
			onSelectionChanged: function (params) {
				$scope.selectGrid.selectedItem = $scope.selectGrid.api.getSelectedRows()[0];
			},
			onRowDoubleClicked: function(params) {
				$scope.selectItemGrid();
			},
			selectedItem: null,
			defaultColDef: {
				editable: false,
				sortable: true,
				resizable: true,
			},
			onFirstDataRendered: function(){
				let allColumnIds = [];
				$scope.selectGrid.columnApi.getAllColumns().forEach(function (columnDef) {
					allColumnIds.push(columnDef.colId);
				});
				$scope.selectGrid.columnApi.autoSizeColumns(allColumnIds);
			}

		}

		$scope.bindGrid();

	});

	$scope.bindGrid = function(){
		$scope.ag_grid_div = document.querySelector('#select-grid');    //get the container id...
		$scope.ag_grid = new Grid($scope.ag_grid_div, $scope.selectGrid); //bind the grid to it.

		//if an item is already selected, select the node.
		if($scope.row[$scope.grid_field.DbColumnName])
		{
			var theId = $scope.row[$scope.grid_field.DbColumnName]
			$scope.selectGrid.api.forEachNode(function(item){
				if(item.data.Id == theId){
					item.setSelected(true);
					setTimeout(function(){
						$scope.selectGrid.api.ensureNodeVisible(item);
					},500)
				}
			})
		}
	}

	$scope.selectItemGrid = function(){
		$uibModalInstance.close($scope.selectGrid.selectedItem.Id);
    };

    $scope.cancelGrid = function(){
        $uibModalInstance.dismiss();
    };
  }
];

export default modal_select_grid;
