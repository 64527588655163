import * as angular from 'angular';
import {initEdit} from "../../../../core/common/common-functions";

var modal_create_crpp_subproject = ['$scope', '$rootScope', '$uibModalInstance', 'DatasetService', 'SubprojectService', 'ServiceUtilities',
    '$timeout', '$location', '$anchorScroll', '$document',
    function ($scope, $rootScope, $uibModalInstance, DatasetService, SubprojectService, ServiceUtilities,
        $timeout, $location, $anchorScroll, $document) {
        console.log("Inside ModalCreateSubprojectCtrl...");

        initEdit();

        $scope.header_message = "Create new CRPP project";
        $rootScope.crppProjectName = $scope.crppProjectName = "";
        $rootScope.projectId = $scope.project.Id;

        $scope.subproject_row = {
            StatusId: 0,
            //OwningDepartmentId: 1,
        };


        $scope.showOtherAgency = false;
        $scope.showOtherProjectProponent = false;
        $scope.showOtherCounty = false;
        $scope.showCountyOptions = false;
        $scope.showAddDocument = true;
		$scope.countyPresent = false;

		console.log("$scope is next...");
		console.dir($scope);

        if ($scope.viewSubproject) {
            $scope.header_message = "Edit CRPP project: " + $scope.viewSubproject.ProjectName;

			console.log("$scope.viewSubproject is next...");
			console.dir($scope.viewSubproject);

            $scope.subproject_row = angular.copy($scope.viewSubproject);
            console.log("$scope.subproject_row is next...");
            console.dir($scope.subproject_row);

            $scope.showAddDocument = false;

            console.log("$scope.subproject_row.Agency = " + $scope.subproject_row.Agency);
            var keepGoing = true;
            var foundIt = false;
            //var responseTypeIndex = 0;

            /*
            *	Need to redo the Agency, Project Proponent, and County
            *
            */
            // Check the Agency
            /*angular.forEach($scope.agencyList, function(option){
            //console.log("option.Label = x" + option.Label + "x, $scope.subproject_row.Agency = x" + $scope.subproject_row.Agency + "x.");
                if ((keepGoing) && (option.Label === $scope.subproject_row.Agency))
                {
                    //console.log("option.Label = " + option.Label);
                    //console.log("Found the Agency...");
                    foundIt = true;
                    keepGoing = false;
                }
                //responseTypeIndex++;
            });

            if (!foundIt)
            {
                console.log("Value of Agency is not in the list...");
                $scope.subproject_row.OtherAgency = $scope.subproject_row.Agency;
                $scope.subproject_row.Agency = "Other";
                $scope.showOtherAgency = true;
            }
            */
            if ((typeof $scope.subproject_row.OtherAgency !== 'undefined') && ($scope.subproject_row.OtherAgency !== null))
                $scope.showOtherAgency = true;

            /*
            keepGoing = true;
            foundIt = false;
            // Check the Project Proponent  Note:  We use the same list as for the Agency.
            console.log("$scope.subproject_row.ProjectProponent = " + $scope.subproject_row.ProjectProponent);
            angular.forEach($scope.agencyList, function(option){
            //console.log("option.Label = x" + option.Label + "x, $scope.subproject_row.ProjectProponent = x" + $scope.subproject_row.ProjectProponent + "x.");
                if ((keepGoing) && (option.Label === $scope.subproject_row.ProjectProponent))
                {
                    //console.log("option.Label = " + option.Label);
                    //console.log("Found the ProjectProponent...");
                    foundIt = true;
                    keepGoing = false;
                }
                //responseTypeIndex++;
            });

            if (!foundIt)
            {
                console.log("Value of ProjectProponent is not in the list...");
                $scope.subproject_row.OtherProjectProponent = $scope.subproject_row.ProjectProponent;
                $scope.subproject_row.ProjectProponent = "Other";
                $scope.showOtherProjectProponent = true;
            }
            */
            if ((typeof $scope.subproject_row.OtherProjectProponent !== 'undefined') && ($scope.subproject_row.OtherProjectProponent !== null))
                $scope.showOtherProjectProponent = true;

            /*
            keepGoing = true;
            foundIt = false;
            // Check the County
            console.log("$scope.subproject_row.County = " + $scope.subproject_row.County);

            // Copy the array into a string.
            var strCounty = "";
            angular.forEach($scope.subproject_row.County, function(item){
                strCounty += item + ",";
            });
            console.log("strCounty = " + strCounty);

            // Remove the trailing comma.
            strCounty = strCounty.substring(0, strCounty.length - 1);
            console.log("strCounty = " + strCounty);
            */

			console.log("$scope.subproject_row.strCounties = " + $scope.subproject_row.strCounties);
			console.log("$scope.subproject_row.County is next...");
			console.dir($scope.subproject_row.County);

            // Now, strip off the "[]".
            if ((typeof $scope.subproject_row.County !== 'undefined') && ($scope.subproject_row.County !== null)) {
                var strCounty = $scope.subproject_row.County;
                strCounty = strCounty.replace(/["\[\]]+/g, '');
                console.log("strCounty = " + strCounty);
                $scope.subproject_row.County = strCounty;
                console.log("$scope.subproject_row.County = " + $scope.subproject_row.County);

                $scope.subproject_row.strCounties = strCounty;
            }

            if ((typeof $scope.subproject_row.OtherCounty !== 'undefined') && ($scope.subproject_row.OtherCounty !== null))
                $scope.showOtherCounty = true;


            // Now convert our string to an array, to compare with the countyList.
            /*var aryCounties = $scope.subproject_row.County.split(",");
            console.log("aryCounties is next...");
            console.dir(aryCounties);

            angular.forEach(aryCounties, function(county){
            //console.log("option.Label = x" + option.Label + "x, $scope.subproject_row.County = x" + $scope.subproject_row.County + "x.");
                angular.forEach($scope.countyList, function(option){
                    if ((keepGoing) && (option.Label === county))
                    {
                        //console.log("option.Label = " + option.Label);
                        console.log("Found county:  " + county);
                        foundIt = true;
                        keepGoing = false;
                    }
                });
                if (!foundIt)
                {

                }
            });
            */

            /*angular.forEach($scope.countyList, function(option){
            //console.log("option.Label = x" + option.Label + "x, $scope.subproject_row.County = x" + $scope.subproject_row.County + "x.");
                if ((keepGoing) && (option.Label === $scope.subproject_row.County))
                {
                    //console.log("option.Label = " + option.Label);
                    //console.log("Found the County...");
                    foundIt = true;
                    keepGoing = false;
                }
            });


            if (!foundIt)
            {
                console.log("Value of County is not in the list...");
                $scope.subproject_row.OtherCounty = $scope.subproject_row.County;
                $scope.subproject_row.County = "Other";
                $scope.showOtherCounty = true;
            }
            */



            // First convert our county string into an array.
            /*var strCounty = $scope.subproject_row.County;
            console.log("strCounty = " + strCounty);

            strCounty = strCounty.substring(1, strCounty.length -1);
            console.log("strCounty = " + strCounty);

            strCounty = strCounty.replace(/["]+/g, '');
            console.log("strCounty = " + strCounty);

            var aryCounty = strCounty.split(",");
            console.log("aryCounty is next...");
            console.dir(aryCounty);

            //var result = document.getElementsByTagName("select");
            //var result = document.getElementById("County");
            //console.dir(result);
            var c = 0;
            angular.forEach(result, function(item){
                console.log(item[c].innerHTML);
                c++;
            });

            var wrappedResult = angular.element(result);
            console.dir(wrappedResult);
            angular.forEach(element.find('select'), function(node)
            {
                if (node.Id === 'County')
                    console.log("Found County select...");

            });

            var counties = angular.element("County").options;
            console.log("counties is next...");
            console.dir(counties);
            angular.forEach(aryCounty, function (county){
                for (var i = 0, max = counties.length; i < max; i++)
                {
                    if (counties[i].innerHTML === county)
                        counties[i].selected = true;
                }

            });
            $scope.subproject_row.County = aryCounty;
            */
            /*scope.subproject_row.County = 'undefined';
            $scope.subproject_row.County = [];
            angular.forEach(aryCounty, function(county){
                $scope.subproject_row.County.push(county);
            });
            */
            console.log("$scope.subproject_row.County is next...");
            console.dir($scope.subproject_row.County);

            /*angular.forEach($scope.countyList, function (option) {
                //console.log("option.Label = x" + option.Label + "x, $scope.subproject_row.County = x" + $scope.subproject_row.County + "x.");
                if ((keepGoing) && (option.Label === $scope.subproject_row.County)) {
                    //console.log("option.Label = " + option.Label);
                    //console.log("Found the County...");
                    foundIt = true;
                    keepGoing = false;
                }
            });
            angular.forEach($scope.subproject_row.County, function (county) {
                if (county === "Other")
                    foundIt = true;

            });

            if (!foundIt) {
                console.log("Value of County is not in the list...");
                $scope.subproject_row.OtherCounty = $scope.subproject_row.County;
                $scope.subproject_row.County = "Other";
                $scope.showOtherCounty = true;
            }
			*/

			if ((typeof $scope.subproject_row.strCounties !== 'undefined') && ($scope.subproject_row.strCounties !== null))
			{
				//console.log("$scope.subproject_row.Collaborators is next...");
				//console.dir($scope.subproject_row.Collaborators);

				var strCounties = $scope.subproject_row.strCounties;
				//strCounties = strCounties.replace(/(\r\n|\r|\n)/gm, ""); // Remove any newlines
				strCounties = strCounties.replace(/["\[\]]+/g, ''); // Remove any brackets []
				strCounties = strCounties.trim();
				strCounties += "\n";
				console.log("strCounties = " + strCounties);

				//$scope.subproject_row.strCounties = null; // dump the previous contents.
				$scope.subproject_row.strCounties = strCounties; // reset its value
				//console.log("$scope.subproject_row.strCounties = " + $scope.subproject_row.strCounties);
				if ($scope.subproject_row.strCounties.indexOf("Other") > -1)
					$scope.showOtherCollaborators = true;

				$scope.subproject_row.strCounties = strCounties;

			}
        }

        console.log("$scope inside ModalCreateSubprojectCtrl, after initializing, is next...");
        //console.dir($scope);

        $scope.selectAgency = function () {
            console.log("Inside selectAgency...");
            //console.dir($scope);
            console.log("$scope.subproject_row is next...");
            console.dir($scope.subproject_row);

            $scope.showCountyOptions = false;

            if ($scope.subproject_row.Agency === "Other") {
                $scope.showOtherAgency = true;
                $scope.subproject_row.OtherAgency = "";
            }
            else {
                $scope.showOtherAgency = false;
                $scope.subproject_row.OtherAgency = 'undefined';
            }

            console.log("$scope.showOtherAgency = " + $scope.showOtherAgency);
        };

        /*$scope.agencyChanged = function () {
            console.log("Inside agencyChanged...");
            console.log("$scope.subproject_row is next...");
            console.dir($scope.subproject_row);
            if ($scope.subproject_row.Agency === "Other")
            {
                $scope.showOtherAgency = true;
                $scope.subproject_row.OtherAgency = "";
            }
            else
                $scope.showOtherAgency = false;
                $scope.subproject_row.OtherAgency = 'undefined';

            console.log("$scope.showOtherAgency = " + $scope.showOtherAgency);
        };
        */

        $scope.selectProjectProponent = function () {
            console.log("Inside selectProjectProponent...");
            console.log("$scope.subproject_row is next...");
            console.dir($scope.subproject_row);

            $scope.showCountyOptions = false;

            if ($scope.subproject_row.ProjectProponent === "Other") {
                $scope.showOtherProjectProponent = true;
                $scope.subproject_row.OtherProjectProponent = "";
            }
            else {
                $scope.showOtherProjectProponent = false;
                $scope.subproject_row.OtherProjectProponent = 'undefined';
            }

            console.log("$scope.showOtherProjectProponent = " + $scope.showOtherProjectProponent);
        };

        /*$scope.selectCounty = function () {
            console.log("Inside selectCounty...");
            console.log("$scope.subproject_row is next...");
            console.dir($scope.subproject_row);

            var strCounty = $scope.subproject_row.County.toString();
            if (strCounty.indexOf("Other") > -1)
            {
                $scope.showOtherCounty = true;
            }
            else
            {
                $scope.showOtherCounty = false;
            }
            */
		/*if ($scope.subproject_row.County === "Other")
		{
			$scope.showOtherCounty = true;
			$scope.subproject_row.OtherCounty = "";
		}
		else
		{
			$scope.showOtherCounty = false;
			$scope.subproject_row.OtherCounty = 'undefined';
		}
		*/
		/*
		console.log("$scope.showOtherCounty = " + $scope.showOtherCounty);
	};
	*/

        /*$scope.projectProponentChanged = function () {
            console.log("Inside projectProponentChanged...");
            console.log("$scope.viewSubproject is next...");
            console.dir($scope.subproject_row);
            if ($scope.subproject_row.ProjectProponent === "Other")
                $scope.showOtherProjectProponent = true;
            else
                $scope.showOtherProjectProponent = false;

            console.log("$scope.showOtherProjectProponent = " + $scope.showOtherProjectProponent);
        };
        */

		$scope.selectCounty = function () {
			console.log("Inside selectCounty...");
			//console.dir($scope);
			console.log("$scope.subproject_row is next...");
			console.dir($scope.subproject_row);

			if ($scope.subproject_row.County === "Other")
			{
				$scope.showOtherCounty = true;
				$scope.subproject_row.OtherCounty = "";
			}
			else
			{
				$scope.showOtherCounty = false;
				$scope.subproject_row.OtherCounty = 'undefined';
			}

			console.log("$scope.subproject_row.OtherCounty = " + $scope.subproject_row.OtherCounty);
		};

        $scope.enteredSelectedCounties = function () {
            $scope.showCountyOptions = true;
        };

        $scope.enteredSomethingElse = function () {
            $scope.showCountyOptions = false;
        };

        $scope.countyChanged = function () {
            console.log("Inside countyChanged...");
            console.log("$scope.subproject_row is next...");
            console.dir($scope.subproject_row);

            $scope.subproject_row.strCounties = $scope.subproject_row.County.toString();
            if ($scope.subproject_row.strCounties.indexOf("Other") > -1) {
                $scope.showOtherCounty = true;
            }
            else {
                $scope.showOtherCounty = false;
                $scope.subproject_row.OtherCounty = null;
            }

            /*if ($scope.subproject_row.County === "Other")
                $scope.showOtherCounty = true;
            else
                $scope.showOtherCounty = false;
            */

            //$scope.showOtherCounty = false;
            //var foundOther = false;
            //angular.forEach($scope.subproject_row.County, function(county){
            // There is only one entry for "Other"
			/*if(county === "Other")
			{
				foundOther = true;
				$scope.showOtherCounty = true
				$scope.subproject_row.OtherCounty = [];
			}
			*/
            //});

            //if (!foundOther)
            //	$scope.subproject_row.OtherCounty = 'undefined';


            console.log("$scope.showOtherCounty = " + $scope.showOtherCounty);
        };

        /*$scope.checkKeyPress = function(event){
            if (event.keyCode === 8) {
                console.log("Backspace pressed...");
            }
        };
        */

	$scope.addCounty = function() {
		console.log("+C clicked...");
		console.log("$scope.subproject_row.strCounties = " + $scope.subproject_row.strCounties);

		if (typeof $scope.subproject_row.strCounties === 'undefined')
			$scope.subproject_row.strCounties = "";

		// We will add a new line at the end, so that the string presents well on the page.
		if ($scope.subproject_row.County === "Other")
		{
			//scope.subproject_row.strCounties += $scope.subproject_row.OtherCounty + ";\n";
			$scope.subproject_row.strCounties += "Other;\n";
		}
		else
		{
			$scope.subproject_row.strCounties += $scope.subproject_row.County + ";\n";
		}

		console.log("$scope.subproject_row.strCounties = " + $scope.subproject_row.strCounties);
	};

	$scope.removeCounty = function() {
		console.log("-C clicked...");
		console.log("$scope.subproject_row.strCounties before stripping = " + $scope.subproject_row.strCounties);

		// First, strip out the new line characters.
		$scope.subproject_row.strCounties = $scope.subproject_row.strCounties.replace(/(\r\n|\r|\n)/gm, "");
		console.log("$scope.subproject_row.strCounties after stripping = " + $scope.subproject_row.strCounties);

		// Note, we still have the trailing semicolon.
		// Convert the string to an array, so that we can easily remove the applicable funding agency from the string.
		var aryCounties = $scope.subproject_row.strCounties.split(";");

		// Next, get rid of that trailing semicolon.
		aryCounties.splice(-1, 1);
		console.dir(aryCounties);

		// Now we can continue with the delete action.
		var aryCountiesLength = aryCounties.length;

		// First check if the user entered an "other" funder.
		if (($scope.subproject_row.County === "Other") && ($scope.subproject_row.OtherCounty))
		{
			for (var i = 0; i < aryCountiesLength; i++)
			{
				console.log("aryCounties[i] = " + aryCounties[i]);
				if (aryCounties[i].indexOf($scope.subproject_row.OtherCounty) > -1)
				{
					console.log("Found the item...");
					aryCounties.splice(i,1);
					console.log("Removed the item.");

					$scope.subproject_row.strCounties = "";
					console.log("Wiped $scope.subproject_row.strCounties...");

					// Rebuild the string now, adding the semicolon and newline after every line.
					angular.forEach(aryCounties, function(item){
						$scope.subproject_row.strCounties += item + ";\n";
						console.log("Added item...");
					});

					// Since we found the item, skip to then end to exit.
					i = aryCountiesLength;
				}
			}
		}
		else
		{
			for (var i = 0; i < aryCountiesLength; i++)
			{
				console.log("aryCounties[i] = " + aryCounties[i]);
				if (aryCounties[i].indexOf($scope.subproject_row.County) > -1)
				{
					console.log("Found the item...");
					aryCounties.splice(i,1);
					console.log("Removed the item.");

					$scope.subproject_row.strCounties = "";
					console.log("Wiped $scope.subproject_row.strCounties...");

					// Rebuild the string now, adding the semicolon and newline after every line.
					angular.forEach(aryCounties, function(item){
						$scope.subproject_row.strCounties += item + ";\n";
						console.log("Added item...");
					});

					// Since we found the item, skip to then end to exit.
					i = aryCountiesLength;
				}
			}
		}
		console.log("Finished.");
	};

        $scope.save = function () {
            console.log("Inside ModalCreateSubprojectCtrl, save...");
			//console.log("$scope.subproject_row is next...");
			//console.dir($scope.subproject_row);

            $scope.subprojectSave = undefined;
            $scope.subprojectSave = [];
            $scope.createNewSubproject = false;
			$scope.errorMessage = "";

            if ((typeof $scope.subproject_row.ProjectName === 'undefined') ||
				($scope.subproject_row.ProjectName === null) ||
				($scope.subproject_row.ProjectName.length < 1)
				)
			{
				console.log("Project name is empty...");
				$scope.errorMessage += "Project Name cannot be blank!  ";
				$scope.subprojectSave.error = true;
			}


			if ((typeof $scope.subproject_row.ProjectLead === 'undefined') || ($scope.subproject_row.ProjectLead === null))
			{
				console.log("Project Lead is empty...");
				$scope.errorMessage += "Project Lead cannot be blank!  ";
				$scope.subprojectSave.error = true;
			}

            //console.dir($scope);

            if (!$scope.subprojectSave.error) {
                // Capture the AddDocument flag, before discarding it.
                console.log("$scope.subproject_row, full is next...");
                console.dir($scope.subproject_row);

                var addDocument = $scope.subproject_row.AddDocument;
                $scope.subproject_row.AddDocument = null;
                console.log("addDocument = " + addDocument);
                console.log("$scope.subproject_row, after del is next...");
                console.dir($scope.subproject_row);

                var saveRow = angular.copy($scope.subproject_row);
                console.log("saveRow is next..");
                console.dir(saveRow);
                /* On the form, $scope.subproject_row.Agency is an object, like this: (Id: theId Name: theName)
                * The technique used to grab the Agency works on the first click (an improvement).
                * Therefore, I (gc) kept the technique, and chose to extract/reset $scope.subproject_row.Agency here in the controller, as just the name.
                */
                //console.log("typeof saveRow.Agency = " + saveRow.Agency);
                //saveRow.Agency = 'undefined';
                //saveRow.Agency = $scope.subproject_row.Agency.Name;
                //console.log("saveRow.Agency = " + saveRow.Agency);

                // Agency Name:  If the user selected Other, we must use the name they supplied in OtherAgency.
                // 20160721:  Colette said that we need the OtherAgency, OtherProjectProponent, and OtherCounty to have their own columns in the database,
                // so that she can easily filter out and determine what "other" agencies, Project Proponents, or Counties that CRPP has interacted with.
                /*if ((typeof saveRow.OtherAgency !== 'undefined') && (saveRow.OtherAgency !== null) && (saveRow.OtherAgency !== 'undefined'))
                {
                    saveRow.Agency = saveRow.OtherAgency;
                    saveRow.OtherAgency = null; // Throw this away, because we do not want to save it; no database field or it.
                }
                */

                // Project Proponent Name:  If the user selected Other, we must use the name they supplied in OtherProjectProponent.
                /*if ((typeof saveRow.OtherProjectProponent !== 'undefined') && (saveRow.OtherProjectProponent !== null) && (saveRow.OtherProjectProponent !== 'undefined'))
                {
                    saveRow.ProjectProponent = saveRow.OtherProjectProponent;
                    saveRow.OtherProjectProponent = null; // Throw this away, because we do not want to save it; no database field or it.
                }
                */

                // County Name:  If the user selected Other, we must use the name they supplied in OtherCounty.
                //if (saveRow.OtherCounty)
                /*if ((typeof saveRow.OtherCounty !== 'undefined') && (saveRow.OtherCounty !== null) && (saveRow.OtherCounty !== 'undefined'))
                {
                    console.log("OtherCounty has a value...");
                    saveRow.County = saveRow.OtherCounty; // For single select
                    //saveRow.County.push(saveRow.OtherCounty); // For multiSelect

                    saveRow.OtherCounty = null; // Throw this away, because we do not want to save it; no database field or it.
                }*/

                // Convert the multiselect (array) values into a json array string.
                //saveRow.County = angular.toJson(saveRow.County).toString();
                //var strCounty = "[";
                //saveRow.County = saveRow.strCounties;
                //angular.forEach(saveRow.County, function(county){
                //	strCounty += '"' + county + '",'; // Use single-quotes and double-quotes, so that JavaScript does not get confused.
                //});

                //console.log("strCounty = " + strCounty);
                // Trim the trailing ","
                //if (strCounty.length > 1)
                //	strCounty = strCounty.substring(0, strCounty.length -1);

                //strCounty += "]";
                //saveRow.County = strCounty;


                saveRow.YearDate = ServiceUtilities.dateTimeNowToStrYYYYMMDD_HHmmSS();
                console.log("saveRow.TrackingNumber = " + saveRow.TrackingNumber);
                if (saveRow.TrackingNumber) {
                    // The tracking number exists, but let's verify that is it not just spaces.
                    var tmpTrackingNumber = saveRow.TrackingNumber;
                    if ((tmpTrackingNumber !== null) && (tmpTrackingNumber.length > 0)) {
                        // The tracking number contains something.  Replace all the spaces and see what is left.
                        tmpTrackingNumber = tmpTrackingNumber.replace(" ", "");
                    }

                    if (tmpTrackingNumber.length === 0) {
                        saveRow.TrackingNumber = saveRow.YearDate
                    }
                }
                else {
                    // The user does not want the TrackingNumber to be set, if they leave it blank.
                    //saveRow.TrackingNumber = saveRow.YearDate
                }
                console.log("saveRow.TrackingNumber = " + saveRow.TrackingNumber);

				// Counties
				console.log("saveRow.strCounties = " + saveRow.strCounties);
				console.log("type of saveRow.strCounties = " + typeof saveRow.strCounties);

				if ((typeof saveRow.strCounties !== 'undefined') &&
					(saveRow.strCounties !== null) &&
					(saveRow.strCounties.length > 0)
					)
				{
					$rootScope.countyPresent = $scope.countyPresent = true;
					var strCounties = saveRow.strCounties.replace(/(\r\n|\r|\n)/gm, "");  // Remove all newlines (used for presentation).
					console.log("strCounties = " + strCounties);
					var aryCounties = strCounties.split(";");  //
					//strCounties.splice(-1, 1);

					// $scope.saveRow.County is probably a string right now, so re-define it as an array for the save.
					saveRow.CountyAry = [];

					angular.forEach(aryCounties, function(item) {
						//After the split on ";", one of the lines is a newline.  We need to watch for and omit that line.
						console.log("item = X" + item + "X");
						//item = item.replace(/(\r\n|\r|\n)/gm, "");
						item = item.replace(/\n/g, "");
						console.log("item = X" + item + "X");

						if (item.length > 0)
						{
							var countyOption = new Object();
							// @ts-ignore
							countyOption.Id = 0;
							// @ts-ignore
							countyOption.Name = "";
							// @ts-ignore
							countyOption.Name = item.trim();
							// @ts-ignore
							console.log("countyOption.Name = " + countyOption.Name);

							//countyOption.ProjectId = $scope.project.Id;

							saveRow.CountyAry.push(countyOption);
						}
					});
					// Convert the array of County objects, to string.
					//saveRow.County = angular.toJson(saveRow.County);

					saveRow.strCounties = undefined;
					saveRow.County = undefined;
					console.log("saveRow.CountyAry is next...");
					console.dir(saveRow.CountyAry);
				}

                //if(!saveRow.CompleteDate)
                //	saveRow.CompleteDate = null;
                saveRow.CorrespondenceEvents = undefined;
                console.log("saveRow is next...");
                console.dir(saveRow);
				//throw "Stopping right here...";

                $scope.saveResults = {};
                //console.log("$scope is next...");
                //console.dir($scope);
                //var promise = SubprojectService.saveSubproject($scope.project.Id, saveRow, $scope.saveResults);
                var promise = SubprojectService.saveCrppSubproject($scope.project.Id, saveRow, $scope.saveResults);
				//$scope.finishAndClose(promise, saveRow);
			//}
		//};

		//$scope.finishAndClose = function (promise, saveRow) {

				if (typeof promise !== 'undefined') {
					promise.$promise.then(function () {
						//window.location.reload();
						console.log("promise is next...");
						console.dir(promise);
						$scope.subprojectId = $rootScope.subprojectId = promise.Id;
						console.log("$scope.subprojectId = " + $scope.subprojectId);

						//promise.County = saveRow.County;
						/*
						console.log("and here is our final new edited subproject_edited:");
						$scope.subproject_edited = promise;
						console.dir($scope.subproject_edited);

						console.log("and if we do the extends thing:")
						var extended = angular.extend({}, saveRow, promise); //empty + saveRow + promise -- in that order
						console.dir(extended);

						$scope.postSaveSubprojectUpdateGrid($scope.subproject_edited);
						*/

						$scope.subproject_row = 'undefined';
						$scope.crppProjectName = saveRow.ProjectName;

						//$scope.reloadSubprojects();
						$scope.postSaveSubprojectUpdateGrid(promise);

						if (addDocument === "Yes") {
							console.log("addDocument = Yes...");

							// If the user wishes to add a Correspondence Event right away, we must wait to get the ID of the new subproject, before we can continue.
							//$scope.reloadSubproject(promise.Id);
							//var promise2 = $scope.reloadSubproject(promise.Id);
							//console.log("Inside reloadSubproject...");
							//SubprojectService.clearSubproject();
							//$scope.reloadSubproject($scope.subprojectId);
							$uibModalInstance.dismiss();
							$scope.openCorrespondenceEventForm(promise, {});
							//$scope.subproject = SubprojectService.getSubproject(id);
						}
						else {
							console.log("addDocument != Yes");

							// If the user just wants to create the Subproject, we can continue without waiting.
							//$scope.reloadSubproject($scope.subprojectId);
							$uibModalInstance.dismiss();
						}
					});
				}
			}
        };

        $scope.cancel = function () {
            // If the user clicks on Cancel, we need to grab the contents of the Other... boxes and put it back into the main box.

            // Agency Name:  If the user selected Other, we must use the name they supplied in OtherAgency.
            if ($scope.subproject_row.OtherAgency) {
                $scope.subproject_row.Agency = $scope.subproject_row.OtherAgency;
                $scope.subproject_row.OtherAgency = null; // Throw this away, because we do not want to save it; no database field or it.
            }

            // Project Proponent Name:  If the user selected Other, we must use the name they supplied in OtherProjectProponent.
            if ($scope.subproject_row.OtherProjectProponent) {
                $scope.subproject_row.ProjectProponent = $scope.subproject_row.OtherProjectProponent;
                $scope.subproject_row.OtherProjectProponent = null; // Throw this away, because we do not want to save it; no database field or it.
            }

            // County Name:  If the user selected Other, we must use the name they supplied in OtherCounty.
            if ($scope.subproject_row.OtherCounty) {
                $scope.subproject_row.County = $scope.subproject_row.OtherCounty;
                $scope.subproject_row.OtherCounty = null; // Throw this away, because we do not want to save it; no database field or it.
            }
            $scope.subproject_row = 'undefined';
            //$scope.reloadSubprojects();
            $uibModalInstance.dismiss();
        };
        /*
        $scope.gotoBottom = function (){
            // set the location.hash to the id of
            // the element you wish to scroll to.
            $location.hash('bottom');

            // call $anchorScroll()
            $anchorScroll();
        };

        $scope.gotoSubprojectsTop = function (){
            // set the location.hash to the id of
            // the element you wish to scroll to.
            console.log("Inside gotoSubprojectsTop...");
            //$location.hash('top');
            $location.hash('spTop');

            // call $anchorScroll()
            $anchorScroll();
        };

        $scope.gotoCategory = function (category) {
            $location.hash(category);
            $anchorScroll();
        };
        */
    }
];

export default modal_create_crpp_subproject;
