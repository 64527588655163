import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {PermitMapComponent} from "../permit-map/permit-map.component";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import QueryTask from "@arcgis/core/tasks/QueryTask";
import Graphic from "@arcgis/core/Graphic";
import Query from "@arcgis/core/rest/support/Query";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import Color from "@arcgis/core/Color";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer = __esri.FeatureLayer;
import FeatureLayerView = __esri.FeatureLayerView;

@Component({
  selector: 'leasing-map',
  templateUrl: './leasing-map.component.html',
  styleUrls: ['./leasing-map.component.scss']
})
export class LeasingMapComponent extends PermitMapComponent implements OnInit, OnChanges {
  @ViewChild('leasingMapViewNode', {static: true}) private leasingMapViewElement: ElementRef;
  @Input() clickSelect: string;
  @Input() gridSelection: any;
  @Output() leaseparcel: EventEmitter<any> = new EventEmitter<any>();
  searchTerm: any;
  map: Map;
  mapView: MapView;

  constructor() {
    super();
  }

  ngOnInit() {
    this.initializeMap([
      "farms",
      "parcels_outline",
    ], this.leasingMapViewElement.nativeElement);
    this.mapSearchResults = {Message: ""};
    this.spatialReference = new SpatialReference({wkid: 102100});
    this.clickSelect = 'allotments';
    // this.layerVisSwitch(this.clickSelect);
    // this.mapView.on('pointer-move', (e) => {
    //   this.map.layers.forEach(featlayer => {
    //     console.log(featlayer);
    //     if (featlayer.title == 'FarmTracts RAF - Farm Tracts' && featlayer.type == "feature") {
    //       //@ts-ignore
    //       let query = featlayer.createQuery();
    //       query.geometry = e.MapPoint;
    //       query.spatialRelationship = "intersects";
    //       query.returnGeometry = true;
    //       query.outFields = ["*"];
    //
    //      // @ts-ignore
    //       featlayer.queryFeatures(query).then(res => {
    //         console.log(res);
    //       })
    //
    //
    //
    //
    //     }
    //   });
    //
    // });


  }

  ngOnChanges(changes: SimpleChanges) {
    // super.ngOnChanges(changes);
    if (this.mapView) {
      this.clearAll();
      // this.layerVisSwitch(this.clickSelect);
      if (changes.hasOwnProperty('gridSelection')) {
      // console.log(this.gridSelection);
      this.findOnMap(this.gridSelection);
      }
    }
  }

  addLayer(layer, filter?) {
    // this.locationLayer = this.map.layers.add(layer);

//          console.log("Added layer to map");
//          console.log("layer_"+layer.id);

    //setup our layer locationid function so we can all it again sometime
    layer.showLocationsById = function (locationObjectIds) {
      try {
        this.clearSelection();
        var definitionExpression = "OBJECTID IN (" + locationObjectIds + ")";
        console.log("Definition expression: " + definitionExpression);
        this.setDefinitionExpression(definitionExpression);
        this.refresh();
      } catch (e) {
        console.dir(e);
      }
    };

    if (filter && filter == "location") {
      if (typeof this.locationObjectIds == "undefined") {
        // $scope.$watch('locationObjectIds', function () {

        //skip the first run
        if (typeof this.locationObjectIds == "undefined")
          return;

        layer.showLocationsById(this.locationObjectIds); // now call it

        layer.show();

        // });
      }
    }


    return this.map.layers.add(layer);
  };

  // setupHoverQuery(mapPoint) {
  //   console.log("Starting up the hover");
  //   var queryTask = new QueryTask({url: "https://arcserv4.ctuir.org/server/rest/services/FarmTracts_RAF/MapServer/0"});
  //   //build query filter
  //   let query = new Query();
  //   query.returnGeometry = true;
  //   query.outFields = ["*"]; //"Acres", "Allotment", "Land_Use"];
  //   query.outSpatialReference = new SpatialReference({wkid: 102100});
  //
  //
  //   if (mapPoint) {
  //     query.geometry = mapPoint;
  //   }
  //   //query.where = "1=1";
  //
  //   var infoTemplate = new PopupTemplate({
  //     // title: `${Allotment}`,
  //     // content: `<b>Allotment: </b>${Allotment}<br/>` +
  //     //   `<b>Land Use: </b>${Land_Use}<br/>` +
  //     //   `<b>Acres: </b>${Acres}<br/>`,
  //   });
  //
  //   // map.infoWindow.resize(245, 125); infoWindow not in v4 API
  //
  //   console.log("2");
  //
  //   //Can listen for complete event to process results
  //   //or can use the callback option in the queryTask.execute method.
  //   queryTask.execute(query).then((result) => {
  //     console.log("query back");
  //
  //     // this.mapView.graphics.removeAll();
  //     // var highlightSymbol = {
  //     //   symbol: "simple-fill",
  //     //   style: "solid",
  //     //   color: new Color([125, 125, 125, 0.35]),
  //     //   outline: {
  //     //     color: new Color([255, 0, 0]),
  //     //     style: "solid"
  //     //   }
  //     // }
  //     //
  //     // var symbol = {
  //     //   symbol: "simple-fill",
  //     //   style: "solid",
  //     //   color: new Color([125, 125, 125, 0.35]),
  //     //   outline: {
  //     //     color: new Color([255, 255, 255, 0.35]),
  //     //     style: "solid",
  //     //     width: 1,
  //     //   }
  //     // }
  //
  //
  //     var features = result.features;
  //     console.log(features);
  //     // console.dir(event);
  //     //
  //     // var countiesGraphicsLayer = new GraphicsLayer();
  //     // //QueryTask returns a featureSet.
  //     // //Loop through features in the featureSet and add them to the map.
  //     // var featureCount = (features) ? features.length : 0;
  //     // for (var i = 0; i < featureCount; i++) {
  //     //   //Get the current feature from the featureSet.
  //     //   var graphic = features[i]; //Feature is a graphic
  //     //   graphic.set('symbol', symbol);
  //     //   graphic.popupTemplate = infoTemplate;
  //     //
  //     //   countiesGraphicsLayer.add(graphic);
  //     // }
  //     // this.map.layers.add(countiesGraphicsLayer);
  //     // // this.mapView.graphics.enableMouseEvents();
  //     // //listen for when the mouse-over event fires on the countiesGraphicsLayer
  //     // //when fired, create a new graphic with the geometry from event.graphic
  //     // //and add it to the maps graphics layer
  //     // // this.mapView.graphics.on("pointer-move", (event) => {
  //     // //   console.log("over");
  //     // //   this.mapView.graphics.removeAll();  //use the maps graphics layer as the highlight layer
  //     // //   var graphic = event.graphic;
  //     // //   this.mapView.popup.content = graphic.getContent();
  //     // //   this.mapView.popup.title = graphic.getTitle();
  //     // //   var highlightGraphic = new Graphic({geometry: graphic.geometry, symbol: highlightSymbol});
  //     // //   this.mapView.graphics.add(highlightGraphic);
  //     // //   this.mapView.popup.open();
  //     // //   // this.mapView.popup.show(event.screenPoint,
  //     // //   //   this.mapView.getInfoWindowAnchor(event.screenPoint));
  //     // // });
  //     // //
  //     // // //listen for when map.graphics mouse-out event is fired
  //     // // //and then clear the highlight graphic
  //     // // //and hide the info window
  //     // // this.mapView.graphics.on("mouse-out", () => {
  //     // //   console.log("out");
  //     // //   this.mapView.graphics.removeAll();
  //     // //   this.mapView.popup.close();
  //     // // });
  //   });
  //   console.log("3");
  //   queryTask.execute(query);
  //
  //
  // };

  querySelectField(mapPoint, objectId, callback) {

    var queryurl = "https://arcserv4.ctuir.org/server/rest/services/FarmTracts_RAF/MapServer/0";

    console.log("Running query on: " + queryurl);

    let queryTask = new QueryTask({url: queryurl});
    var query = new Query();

    query.outSpatialReference = this.spatialReference;
    query.returnGeometry = true;
    query.outFields = ["*"];
    if (mapPoint) {
      query.geometry = mapPoint;
    } else {
      query.objectIds = [objectId];
    }

    query.spatialRelationship = "intersects";
    queryTask.execute(query).then(result => {
      // console.dir(result);
      callback(result.features); //give back the parcel features we found...
    }, function (err) {
      console.log("Failure executing query!");
      console.dir(err);
      console.dir(query);
    });
  }

  clickedMap(e) {
    if (this.clickSelect == 'fields') {
      // todo: get info about fields layer
      this.querySelectField(e.mapPoint, null, (features) => {
        if (features.length == 0) {
          alert('No ' + this.clickSelect + ' found at that location.');
          return;
        }

        this.addParcelToMap(features[0]);
        this.centerAndZoomToGraphic(features[0]);
        console.dir(this.selectedFeature.attributes);
        this.mapView.popup.open({content: this.getContentForPopup(features[0])});
        var objectid = this.selectedFeature.attributes.OBJECTID; //fieldid or cadasterobjectid
        var allotment = this.selectedFeature.attributes.Allotment ? this.selectedFeature.attributes.Allotment : this.selectedFeature.attributes.PARCELID; //parcel = allotment / allotment = field
        this.leaseparcel.emit(this.selectedFeature.attributes);
      });
    } else if (this.clickSelect == 'allotments') {
      this.querySelectParcel(e.mapPoint, null, (features) => {
        if (features.length == 0) {
          alert('No ' + this.clickSelect + ' found at that location.');
          return;
        }
        this.addParcelToMap(features[0]);
        this.centerAndZoomToGraphic(features[0]);
        this.mapView.popup.open({content: this.getContentForPopup(features[0])});
        console.dir(this.selectedFeature.attributes);
        this.leaseparcel.emit(this.selectedFeature.attributes);
        // var objectid = this.selectedFeature.attributes.OBJECTID; //fieldid or cadasterobjectid
        // var allotment = (this.selectedFeature.attributes.Allotment) ? this.selectedFeature.attributes.Allotment : this.selectedFeature.attributes.PARCELID; //parcel = allotment / allotment = field

      });


    }
  }

  // this can be implemented for the fields data
  // layerVisSwitch(selected) {
  //
  //   if (selected == 'allotments') { // this should be the parcel layer outlines
  //
  //     this.map.layers.forEach(layer => {
  //       if (layer.title != null) {
  //         if (layer.title.includes('Farm')) {
  //           console.log('allotments farms OFF');
  //           // console.log(layer);
  //           layer.visible = false;
  //         } else if (layer.title.includes('Parcels')) {
  //           layer.visible = true;
  //         }
  //       }
  //     });
  //   } else if (selected == 'fields') {
  //     this.map.layers.forEach(layer => {
  //       if (layer.title != null) {
  //         if (layer.title.includes('Parcels')) {
  //           layer.visible = false;
  //           console.log('fields parcels OFF');
  //           // console.log(layer.title);
  //         } else if (layer.title.includes('Farm') && layer.type != "graphics") {
  //           layer.visible = true;
  //         }
  //       }
  //     })
  //   }
  // }

  getContentForPopup(feature) {

    let att = feature.attributes;
    let html = "";
    if (att.hasOwnProperty('PARCELID')) {
      this.mapView.popup.title = `${att.Alias} ID: ${att.PARCELID}`;


      if (att.Address && att.Address.trim() != "") {
        html += "<b>Address: </b>" + att.Address + "<br/>";
      }

      if (att.OWNERSHIPS) {
        html += "<b>Ownership: </b>" + att.OWNERSHIPS + "<br/>";
      }

      if (att.ACRES_GIS) {
        html += "<b>Acres (GIS): </b>" + att.ACRES_GIS;
      }
    }

    if (att.hasOwnProperty('Allotment')) {
      this.mapView.popup.title = `Allotment: ${att.Allotment}`;

      html += `<b>Allotment: </b>${att.Allotment}<br/>`;
      html += `<b>Land Use: </b>${att.Land_Use}<br/>`;
      html += `<b>Acres: </b>${att.Acres}<br/>`;
    }


    return html;
  }

  findOnMap(in_allotment) {

    console.log("finding on map " + in_allotment);

    console.log("first search for related parcels")
    // $scope.findRelatedPermits(in_allotment);

    this.queryMatchParcel(in_allotment, (features) => {
      if (features.length == 0) {
        console.log("parcel not found: " + in_allotment);
      } else {
        //found the parcel but it doesn't include geometry so we need to get it
        this.querySelectParcel(null, features[0].attributes.OBJECTID, (geo_features) => {
          this.addParcelToMap(geo_features[0]);
          this.centerAndZoomToGraphic(geo_features[0]);
          // $scope.findRelatedPermits(features[0].attributes.PARCELID);
        });

      }
    });

  }

  // leasingMap() {
  //
  //
  //
  //       // setup our map options based on the attributes and scope
  //       // var mapOptions = {
  //       //   center: ($attrs.center) ? $attrs.center.split(",") : $scope.center,
  //       //   zoom: ($attrs.zoom) ? $attrs.zoom : $scope.zoom,
  //       //   spatialReference: {
  //       //     wkid: 102100 //mercator
  //       //     // wkid:26911 //nad_1983
  //       //     //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //       //
  //       //   }
  //       // };
  //       //
  //       // // declare our map
  //       // console.log("trying to make the map");
  //       //
  //       // loadModules(["esri/config"], {version: '3.37'}).then(([esriConfig]) => {
  //       //
  //       //   esriConfig.defaults.io.proxyUrl = proxyUrl; // From the config.js file.
  //       //   esriConfig.defaults.io.alwaysUseProxy = true;
  //       //
  //       // });
  //       //
  //       // var map = new Map($attrs.id, mapOptions);
  //       // //our first layer from up above...
  //       // //console.log("//restdata.umatilla.nsn.us/arcgis/rest/services/BasemapParcelViewerCTUIR/MapServer?token=" + security_token);
  //       //
  //       // //setup basemaps
  //       // map.selectedBasemap = defaultLayer; //"imageryLayer"
  //       // map.selectedServiceLayers = [
  //       //   "parcels",
  //       //   "parcels_outline",
  //       //   "farms"
  //       // ];
  //       //
  //       // map.basemaps = [];
  //       // for (var property in parcelLayerConfig) {
  //       //   if (parcelLayerConfig.hasOwnProperty(property)) {
  //       //     map.basemaps.push({label: parcelLayerConfig[property].Display, name: property});
  //       //   }
  //       // }
  //       // ;
  //       //
  //       // map.serviceLayers = [];
  //       // for (var property in servicesLayerConfig) {
  //       //   if (servicesLayerConfig.hasOwnProperty(property)) {
  //       //     map.serviceLayers.push({label: servicesLayerConfig[property].Display, name: property});
  //       //   }
  //       // }
  //       // ;
  //
  //
  //       //might want to do this: https://developers.arcgis.com/javascript/3/jssamples/query_hover.html
  //       // did try but had errors. might not be possible without using featureservice?: https://community.esri.com/thread/191330-popup-on-mouse-over-on-dynamic-service
  //
  //       // map.updateLayers = function () {
  //       //
  //       //   console.log("Changing Layer: " + map.selectedBasemap);
  //       //
  //       //   try {
  //       //     console.log("Loading layer: " + parcelLayerConfig[map.selectedBasemap].ServiceURL);
  //       //
  //       //     map.removeAllLayers();
  //       //
  //       //     //add the selected basemap
  //       //     var new_layer = new ArcGISTiledMapServiceLayer(parcelLayerConfig[map.selectedBasemap].ServiceURL);
  //       //     map.addLayer(new_layer);
  //       //     map.currentBasemapLayer = new_layer;
  //       //
  //       //     //now add any selected service layers
  //       //     for (var i = map.selectedServiceLayers.length - 1; i >= 0; i--) {
  //       //       var service_layer;
  //       //       if (servicesLayerConfig[map.selectedServiceLayers[i]].ServiceURL.includes("FeatureServer")) {
  //       //         service_layer = new FeatureLayer(servicesLayerConfig[map.selectedServiceLayers[i]].ServiceURL);
  //       //       } else //then it is a MapServer
  //       //       {
  //       //         service_layer = new MapImageLayer(servicesLayerConfig[map.selectedServiceLayers[i]].ServiceURL);
  //       //       }
  //       //
  //       //       console.log("adding " + servicesLayerConfig[map.selectedServiceLayers[i]].ServiceURL);
  //       //       map.addLayer(service_layer);
  //       //     }
  //       //     ;
  //       //
  //       //     map.parcelLayer = new GraphicsLayer();
  //       //     map.addLayer(map.parcelLayer);
  //       //
  //       //     console.log("done!");
  //       //     map.reposition();
  //       //   } catch (e) {
  //       //     console.dir(e);
  //       //   }
  //       // };
  //
  //       // this.updateLayers();
  //
  //
  //       // start exposing an API by setting properties on "this" which is our controller
  //       // lets expose the "addLayer" method so child directives can add themselves to the map
  //       // this.addLayer = function (layer, filter?) {
  //       //   map.locationLayer = map.addLayer(layer);
  //       //
  //       //   //          console.log("Added layer to map");
  //       //   //          console.log("layer_"+layer.id);
  //       //
  //       //   //setup our layer locationid function so we can all it again sometime
  //       //   layer.showLocationsById = function (locationObjectIds) {
  //       //     try {
  //       //       this.clearSelection();
  //       //       var definitionExpression = "OBJECTID IN (" + locationObjectIds + ")";
  //       //       console.log("Definition expression: " + definitionExpression);
  //       //       this.setDefinitionExpression(definitionExpression);
  //       //       this.refresh();
  //       //     } catch (e) {
  //       //       console.dir(e);
  //       //     }
  //       //   };
  //       //
  //       //   if (filter && filter == "location") {
  //       //     if (typeof $scope.locationObjectIds == "undefined") {
  //       //       $scope.$watch('locationObjectIds', function () {
  //       //
  //       //         //skip the first run
  //       //         if (typeof $scope.locationObjectIds == "undefined")
  //       //           return;
  //       //
  //       //         layer.showLocationsById($scope.locationObjectIds); // now call it
  //       //
  //       //         layer.show();
  //       //
  //       //       });
  //       //     }
  //       //   }
  //       //
  //       //   return map.locationLayer;
  //       // };
  //
  //       //the hover isn't working yet... WIP
  //       //map.on("load", setupHoverQuery);
  //
  //
  //
  //       // IN PERMIT MAP COMPONENT
  //       // //use this for doing a search by parcelid or address
  //       // map.querySearchParcel = function (searchParam, callback) {
  //       //   var queryTask = new QueryTask(parcelLayerConfig[map.selectedBasemap].QueryURL);
  //       //   var query = new Query();
  //       //   query.where = dojo.string.substitute(parcelLayerConfig[map.selectedBasemap].ParcelQuery, [searchParam]);
  //       //   console.log("query.where is next...");
  //       //   console.dir(query.where);
  //       //   query.returnGeometry = false;
  //       //   query.outSpatialReference = this.spatialReference;
  //       //   query.outFields = ["*"];
  //       //
  //       //   queryTask.execute(query, function (result) {
  //       //     callback(result.features); //give back the parcel features we found...
  //       //   }, function (err) {
  //       //     console.log("Failure executing query!");
  //       //     console.dir(err);
  //       //     console.dir(query);
  //       //   });
  //       // };
  //
  //   // IN PERMIT MAP COMPONENT
  //       //use this for selecting a specific parcel/allotment by id (no geometry)
  //       // map.queryMatchParcel = function (searchParam, callback) {
  //       //   var queryTask = new QueryTask(parcelLayerConfig[map.selectedBasemap].QueryURL);
  //       //   var query = new Query();
  //       //   query.where = dojo.string.substitute(parcelLayerConfig[map.selectedBasemap].LocateParcelQuery, [searchParam]);
  //       //   query.returnGeometry = false;
  //       //   query.outSpatialReference = this.spatialReference;
  //       //   query.outFields = ["*"];
  //       //
  //       //   queryTask.execute(query, function (result) {
  //       //     callback(result.features); //give back the parcel features we found...
  //       //   }, function (err) {
  //       //     console.log("Failure executing query!");
  //       //     console.dir(err);
  //       //     console.dir(query);
  //       //   });
  //       // };
  //
  //
  //   // IN PERMIT MAP COMPONENT
  //       //use this to select a particular parcel either by objectid (like after a search) or x,y mapPoint
  //       // map.querySelectParcel = function (mapPoint, objectId, callback) {
  //       //   console.log("Inside leasingMap.js...");
  //       //   console.log("Running query on: " + parcelLayerConfig[map.selectedBasemap].QueryURL);
  //       //
  //       //   var queryTask = new QueryTask(parcelLayerConfig[map.selectedBasemap].QueryURL);
  //       //   var query = new Query();
  //       //
  //       //   query.outSpatialReference = this.spatialReference;
  //       //   query.returnGeometry = true;
  //       //   query.outFields = ["*"];
  //       //   if (mapPoint) {
  //       //     query.geometry = mapPoint;
  //       //   } else {
  //       //     query.objectIds = [objectId];
  //       //   }
  //       //
  //       //   query.spatialRelationship = Query.SPATIAL_REL_INTERSECTS;
  //       //   queryTask.execute(query, function (result) {
  //       //     console.dir(result);
  //       //     callback(result.features); //give back the parcel features we found...
  //       //   }, function (err) {
  //       //     console.log("Failure executing query!");
  //       //     console.dir(err);
  //       //     console.dir(query);
  //       //   });
  //
  //
  //       };


  //use this to select a particular parcel either by objectid (like after a search) or x,y mapPoint


  // IN PARCEL MAP COMPONENT
  // map.clearGraphics = function () {
  //   this.parcelLayer.clear();
  // }

  // IN PARCEL MAP COMPONENT
  // map.addParcelToMap = function (feature, color, alpha) {
  //
  //   var graphic;
  //   if (!color)
  //     color = "#FF6600";
  //
  //   if (!alpha)
  //     alpha = .25;
  //
  //
  //   var lineColor = new dojo.Color();
  //   lineColor.setColor(color);
  //
  //   var fillColor = new dojo.Color();
  //   fillColor.setColor(color);
  //   fillColor.a = alpha;
  //
  //   var symbol = new SimpleFillSymbol(SimpleFillSymbol.STYLE_SOLID,
  //     new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, lineColor, 3), fillColor);
  //
  //   graphic = new Graphic(feature.geometry, symbol, feature.attributes);
  //
  //   this.parcelLayer.clear();
  //   this.parcelLayer.add(graphic);
  //   this.selectedFeature = feature;
  //   this.selectedGraphic = graphic;
  //
  //   $scope.$emit("map.selectedFeature", feature); //notify
  // }

  // IN PERMIT MAP COMPONENT
  // map.centerAndZoomToGraphic = function (graphic, levelOrFactor) {
  //   var the_level = (levelOrFactor) ? levelOrFactor : 2;
  //
  //   var centerPoint = graphic.geometry.getExtent().getCenter();
  //   return map.centerAndZoom(centerPoint, the_level);
  // };

  // IN ESRI MAP COMPONENT
  // lets expose a version of centerAt that takes an array of [lng,lat]
  // this.centerAt = function (center) {
  //   var point = new Point({
  //     x: center[0],
  //     y: center[1],
  //     spatialReference: {
  //       wkid: 102100 //mercator
  //       //wkid:26911 //nad_1983
  //       //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //     }
  //   });
  //
  //   map.centerAt(point);
  // };

  // listen for click events and expost them as broadcasts on the scope and suing the scopes click handler
  // map.on("click", function (e) {
  //   // emit a message that bubbles up scopes, listen for it on your scope
  //   $scope.$emit("map.click", e);
  //
  //   // use the scopes click fuction to handle the event
  //   $scope.$apply(function ($scope) {
  //     $scope.click.call($scope, e);
  //   });
  // });


  // });
  // }]
  // };
// };


}


