// defines the habitat module
import * as angular from 'angular';
// require([
//controllers
import modal_add_habitat from "./components/habitat-sites/modal-add-habitat-item";
import page_sites from "./components/habitat-sites/page-sites";
import modal_create_habitat_subproject from "./components/habitat-sites/modal-create-habitat-subproject";
import {downgradeComponent} from "@angular/upgrade/static";
import permit_module from "../permits/permit-module";
import {HabitatMapComponent} from "../../../app/map/habitat-map/habitat-map.component";

// ], function () {

const habitat_module = angular.module('HabitatModule', ['ui.bootstrap', 'ngResource']);
habitat_module.controller('ModalAddHabitatItemCtrl', modal_add_habitat);
habitat_module.controller('ModalCreateHabSubprojectCtrl', modal_create_habitat_subproject);
habitat_module.controller('HabitatSitesCtrl', page_sites);
permit_module.directive('habitatMap', downgradeComponent({component: HabitatMapComponent}));

export default habitat_module;



