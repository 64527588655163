import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfirmComponent} from "./confirm-component/confirm.component";
import {ConfirmService} from "./confirm.service";
import { ConfirmReviewComponent } from './confirm-review/confirm-review.component';
import {FormsModule} from "@angular/forms";
import {ConfirmRoutingModule} from "./confirm-routing/confirm-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";



@NgModule({
   imports: [
     BrowserModule,
    UpgradeModule,
    CommonModule,
    FormsModule,
    ConfirmRoutingModule
  ],
  declarations: [
    ConfirmComponent,
    ConfirmReviewComponent
  ],
  providers: [
    ConfirmService
  ]
})
export class ConfirmModule { }
