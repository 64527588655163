// defines the admin module and loads the components, controllers and services
import * as angular from 'angular';
//load the components for this module
/*require([*/
import admin_view from "./components/admin-page/admin-view";
import add_project_dataset from "./components/admin-page/admin-add-project-dataset";
import admin_edit_dataset_fields from "./components/admin-page/admin-edit-dataset-fields";
import admin_edit_dataset_config from "./components/admin-page/admin-edit-dataset-config";
import admin_edit_dataset_workflow from "./components/admin-page/admin-edit-dataset-workflow";
import admin_edit_dataset_permissions from "./components/admin-page/admin-edit-dataset-permissions";
import admin_edit_master from "./components/admin-page/admin-edit-master";
import admin_new_dataset from "./components/admin-page/admin-new-dataset";
import admin_metafields from "./components/admin-page/admin-metafields";
import modal_admin_edit_dataset_field from "./components/admin-page/modal-edit-dataset-field";
import modal_admin_edit_master_field from "./components/admin-page/modal-edit-master-field";
import modal_admin_choose_duplicate_fields from "./components/admin-page/modal-choose-duplicate-fields";
import modal_admin_choose_activitylist_fields from "./components/admin-page/modal-choose-activitylist-fields";
import modal_admin_edit_metadataproperty from "./components/admin-page/modal-edit-metadataproperty";
import admin_users from "./components/admin-page/admin-users";
import admin_groups from "./components/admin-page/admin-groups";
import modal_manage_user from "./components/admin-page/admin-manage-users-modal";
import modal_admin_new_datastore from "./components/admin-page/modal-datastore";
import modal_admin_item from "./components/admin-page/modal-admin-item";
import modal_admin_group_members from "./components/admin-page/modal-admin-group-members";
import admin_org from "./components/admin-page/admin-organizations";

    //service
import * as admin_service from './admin-service';
import {serviceUrl} from "../../config";
import admin_services from "./admin-service";

/*], function () {*/
    //add the controllers and services to the module once the files are loaded!
const admin_module = angular.module('AdminModule', ['ui.bootstrap', 'ngResource']);
    //controllers
admin_module.controller('AdminCtrl', admin_view);
admin_module.controller('ModalAddProjectDatasetCtrl', add_project_dataset);
admin_module.controller('AdminEditDatasetFieldsCtrl', admin_edit_dataset_fields);
admin_module.controller('AdminEditDatasetConfigCtrl', admin_edit_dataset_config);
admin_module.controller('AdminEditDatasetWorkflowCtrl', admin_edit_dataset_workflow);
admin_module.controller('AdminEditDatasetPermissionsCtrl', admin_edit_dataset_permissions);
admin_module.controller('AdminEditMasterCtrl', admin_edit_master);
admin_module.controller('AdminNewDatasetCtrl', admin_new_dataset);
admin_module.controller('AdminMetafieldsCtrl', admin_metafields);
admin_module.controller('ModalEditDatasetFieldCtrl', modal_admin_edit_dataset_field);
admin_module.controller('ModalEditMasterFieldCtrl', modal_admin_edit_master_field);
admin_module.controller('ModalChooseDuplicateFieldsCtrl', modal_admin_choose_duplicate_fields);
admin_module.controller('ModalChooseActivityListFieldsCtrl', modal_admin_choose_activitylist_fields);
admin_module.controller('ModalEditMetadataPropertyCtrl', modal_admin_edit_metadataproperty);
admin_module.controller('AdminUsersCtrl', admin_users);
admin_module.controller('AdminGroupsCtrl', admin_groups);
admin_module.controller('ModalManageUserCtrl', modal_manage_user);
admin_module.controller('ModalDatastore',modal_admin_new_datastore);
admin_module.controller('ModalAdminItemCtrl', modal_admin_item);
admin_module.controller('ModalAdminGroupMembersCtrl',modal_admin_group_members);
admin_module.controller('AdminOrgCtrl',admin_org);
admin_module.service('AdminService', admin_services);
    //load services

    //define routes
// });

admin_module.factory('SaveDatasetField', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/dataset/savedatasetfield');
}]);

admin_module.factory('SaveDataset', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/dataset/dataset', {}, {
        save: { method: 'PUT' }
    });
}]);

admin_module.factory('SaveMasterField', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/savemasterfield');
}]);

admin_module.factory('RemoveMasterField', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/removemasterfield');
}]);

admin_module.factory('DeleteDatasetField', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/dataset/deletedatasetfield');
}]);

admin_module.factory('GetAllFields', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/GetDatastoreFields');
}]);

admin_module.factory('AddMasterFieldToDataset', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/addmasterfieldtodataset');
}]);

admin_module.factory('GetAllDatastoreFields', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/getdatastorefields');
}]);

admin_module.factory('SaveNewDatastore', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/saveNewDatastore');
}]);

admin_module.factory('SaveGroup', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/group/saveGroup');
}]);

admin_module.factory('SaveDepartment', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/department/saveDepartment');
}]);

admin_module.factory('SaveOrganization', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/organization/saveOrganization');
}]);

admin_module.factory('SaveOrgAdmin', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/organization/saveOrgAdmin');
}]);

admin_module.factory('RemoveOrgAdmin', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/organization/removeOrgAdmin');
}]);

admin_module.factory('SaveGroupMember', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/group/saveGroupMember');
}]);

admin_module.factory('RemoveGroupMember', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/group/removeGroupMember');
}]);

admin_module.factory('RemoveGroup', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/group/removeGroup');
}]);

admin_module.factory('RemoveOrganization', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/organization/removeOrganization');
}]);

admin_module.factory('RemoveUser', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/user/removeUser');
}]);

admin_module.factory('RemoveDepartment', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/department/removeDepartment');
}]);

admin_module.factory('RemoveDatastore', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/removeDatastore');
}]);

admin_module.factory('RegenerateViews', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/regenerateViews', {}, {
        save: { method: 'POST', isArray: true }
    });
}]);


export default admin_module;




