// defines cross-module functions, components and services that we can use in anywhere
import * as angular from 'angular';
import modal_quick_add_accuracycheck from "./components/modals/modal-quick-add-accuracycheck";
import modal_create_instrument from "./components/modals/modal-create-instrument";
import modal_bulk_rowqa_change from "./components/modals/modal-bulk-rowqa-change";
import modal_save_success from "./components/modals/modal-save-success";
import modal_create_fisherman from "./components/modals/modal-create-fisherman";
import modal_exportfile from "./components/file/modal-exportfile";
import modal_link_field from "./components/modals/modal-link-field";
import modal_verify_action from "./components/modals/modal-verify-action";
import modal_invalid_operation from "./components/modals/modal-invalid-operation";
import modal_feedback from "./components/feedback/feedback";
import modal_select_grid from "./components/modals/modal-select-grid";
import modal_files from "./components/file/modal-files";
import modal_waypoint_files from "./components/file/modal-waypoint-file";
import adultweir_chartservice from "./components/chart/adultweir-chartservice";
import artificialproduction_chartservice from "./components/chart/artificialproduction-chartservice";
import bsample_chartservice from "./components/chart/bsample-chartservice";
import creelsurvey_chartservice from "./components/chart/creelsurvey-chartservice";
import electrofishing_chartservice from "./components/chart/electrofishing-chartservice";
import snorkelfish_chartservice from "./components/chart/snorkelfish-chartservice";
import waterquality_chartservice from "./components/chart/waterquality-chartservice";
import watertemp_chartservice from "./components/chart/watertemp-chartservice";
import chart_services from "./components/chart/chart-services";
import {
  BING_KEY,
  datasetActivitiesBasemapConfig,
  defaultLayer,
  //MapPointsLayer,
  proxyUrl,
  PUBLIC_OR_PRIVATE,
  ENVIRONMENT,
  CDMS_POINTS_PROD_URL,
  CDMS_POINTS_TEST_URL,
  serviceUrl
} from "../../config";
import common_service from "./services/common-service";
import service_utilities from "./services/service-utilities";
import logger_service from "./services/logger";
import dataset_service from "../datasets/services/dataset-service";
import datasets_module from "../datasets/datasets-module";
import dataset_activities_list from "../datasets/components/dataset-activities-list/dataset-activities-list";
import {getOrderedObjectList, makeObjectsFromValues} from "./common-functions";
import * as moment from "moment";
// import {loadModules} from "esri-loader";
import {
  convertToNumberDirective,
  ctuirAccuracyCheckSelectFieldDirective,
  ctuirActivityDateFieldDirective, ctuirActivityTextFieldDirective,
  ctuirCheckboxFieldDirective,
  ctuirCurrencyFieldDirective,
  ctuirDateFieldDirective,
  ctuirEastingFieldDirective, ctuirFileFieldDirective,
  ctuirFishermanSelectFieldDirective,
  ctuirHiddenFieldDirective,
  ctuirInstrumentSelectFieldDirective, ctuirLinkFieldDirective, ctuirLocationSelectFieldDirective,ctuirPermitLocationSelectFieldDirective,
  ctuirMultiselectCheckboxFieldDirective, ctuirMultiselectFieldDirective, ctuirNorthingFieldDirective,
  ctuirNumberFieldDirective,
  ctuirPhoneNumberFieldDirective,
  ctuirPostAccuracyCheckSelectFieldDirective,
  ctuirQaStatusCommentFieldDirective, ctuirQaStatusSelectFieldDirective,
  ctuirRadioFieldDirective,
  ctuirSelectFancyFieldDirective, ctuirSelectFieldDirective, ctuirSelectGridFieldDirective,
  ctuirSelectGroupFieldFunctionDirective,
  ctuirSelectNumberFieldDirective,
  ctuirSelectWithBetterFilterFieldDirective,
  ctuirTempWaypointFileFieldDirective,
  ctuirTextareaFieldDirective,
  ctuirTextFieldDirective,
  ctuirTimeFieldDirective,
  ctuirTimezoneSelectFieldDirective,
  multiselectDirective,
  uiSelectWrapperDirective
} from "./directives/field-definitions";
import checklist_model_directive from "./directives/checklists";
import uiCurrencyDirective from "./directives/currency";
import {projectRoleDirective} from "./directives/roles";
import {
  integerDirective,
  integerfourDirective,
  sevenfloat,
  sixfloat,
  smartfloat,
  textRequired
} from "./directives/validation";
import MapView from "@arcgis/core/views/MapView";

// import {
//   convertToNumberDirective,
//   ctuirAccuracyCheckSelectFieldDirective,
//   ctuirActivityDateFieldDirective,
//   ctuirActivityTextFieldDirective,
//   ctuirCheckboxFieldDirective,
//   ctuirCurrencyFieldDirective,
//   ctuirDateFieldDirective,
//   ctuirEastingFieldDirective, ctuirFileFieldDirective,
//   ctuirFishermanSelectFieldDirective,
//   ctuirHiddenFieldDirective,
//   ctuirInstrumentSelectFieldDirective, ctuirLinkFieldDirective,
//   ctuirLocationSelectFieldDirective, ctuirMultiselectCheckboxFieldDirective,
//   ctuirMultiselectFieldDirective,
//   ctuirNorthingFieldDirective,
//   ctuirNumberFieldDirective,
//   ctuirPhoneNumberFieldDirective,
//   ctuirPostAccuracyCheckSelectFieldDirective,
//   ctuirQaStatusCommentFieldDirective,
//   ctuirQaStatusSelectFieldDirective, ctuirRadioFieldDirective,
//   ctuirSelectFancyFieldDirective,
//   ctuirSelectFieldDirective,
//   ctuirSelectGridFieldDirective, ctuirSelectGroupFieldFunctionDirective,
//   ctuirSelectNumberFieldDirective, ctuirSelectWithBetterFilterFieldDirective, ctuirTempWaypointFileFieldDirective,
//   ctuirTextareaFieldDirective,
//   // ctuirTextFieldDirective,
//   ctuirTimeFieldDirective,
//   ctuirTimezoneSelectFieldDirective, multiselectDirective, testtttt, uiSelectWrapperDirective
// } from "./directives/field-definitions";
//I wish you could just specify a directory and it would find the files and load them, but
// requirejs doesn't work that way so we have to reference each one by hand. -kb
/*
require([
*/
//
// //loads a variety of common functions
// 'core/common/common-functions',
//
// //loads services used by other modules
// 'core/common/services/common-service',
// 'core/common/services/logger',
// 'core/common/services/service-utilities',
// 'core/common/services/wish',
//
// //loads the common controllers
// 'core/common/components/modals/modal-quick-add-accuracycheck',
// 'core/common/components/modals/modal-quick-add-characteristic',
// 'core/common/components/modals/modal-create-instrument',
// 'core/common/components/modals/modal-bulk-rowqa-change',
// 'core/common/components/modals/modal-save-success',
// 'core/common/components/modals/modal-create-fisherman',
// 'core/common/components/modals/modal-link-field',
// 'core/common/components/modals/modal-verify-action',
// 'core/common/components/modals/modal-invalid-operation',
// 'core/common/components/modals/modal-select-grid',
// 'core/common/components/feedback/feedback',
//
// //'core/common/components/file/modal-file-add',
// //'core/common/components/file/modal-file-delete',
// 'core/common/components/file/modal-files',
// 'core/common/components/file/modal-exportfile',
// 'core/common/components/file/modal-waypoint-file',
// 'core/common/components/grid/cell-editors',
// 'core/common/components/grid/cell-validation',
// 'core/common/components/grid/cell-control-types',
// 'core/common/components/grid/grid-service',
//
// //load other common directives
// 'core/common/directives/checklists',
// 'core/common/directives/feature-layer',
// 'core/common/directives/map',
// 'core/common/directives/field-definitions',
// 'core/common/directives/roles',
// 'core/common/directives/validation',
// 'core/common/directives/currency', // ui-currency attribute for the magic
//


// ], function () {
//add the controllers and services to the module once the files are loaded!
const common_module = angular.module('CommonModule', ['ui.bootstrap', 'ngResource']);
//controllers
common_module.controller('ModalQuickAddAccuracyCheckCtrl', modal_quick_add_accuracycheck);
//common_module.controller('ModalQuickAddCharacteristicCtrl', modal_quick_add_characteristic); //kb 11/1 - this is not used anywhere...
common_module.controller('ModalCreateInstrumentCtrl', modal_create_instrument);
common_module.controller('ModalBulkRowQAChangeCtrl', modal_bulk_rowqa_change);
common_module.controller('ModalSaveSuccess', modal_save_success);
common_module.controller('ModalCreateFishermanCtrl', modal_create_fisherman);
common_module.controller('ModalExportController', modal_exportfile);
common_module.controller('LinkModalCtrl', modal_link_field);
common_module.controller('ModalVerifyActionCtrl', modal_verify_action);
common_module.controller('ModalInvalidOperation', modal_invalid_operation);
common_module.controller('FeedbackController', modal_feedback);
common_module.controller('ModalSelectGrid', modal_select_grid);

//common_module.controller('FileAddModalCtrl', modal_file_add);
//common_module.controller('FileDeleteModalCtrl', modal_file_delete);
common_module.controller('FileModalCtrl', modal_files);
common_module.controller('WaypointFileModalCtrl', modal_waypoint_files);

// field definition directives
common_module.directive('ctuirTextField', ctuirTextFieldDirective);
common_module.directive('ctuirCurrencyField', ctuirCurrencyFieldDirective);
common_module.directive('ctuirPhoneNumberField', ctuirPhoneNumberFieldDirective);
common_module.directive('ctuirHiddenField', ctuirHiddenFieldDirective);
common_module.directive('ctuirSelectGridField', ctuirSelectGridFieldDirective);
common_module.directive('ctuirTextareaField', ctuirTextareaFieldDirective);
common_module.directive('ctuirDateField', ctuirDateFieldDirective);
common_module.directive('ctuirActivityTextField', ctuirActivityTextFieldDirective);
common_module.directive('ctuirActivityDateField', ctuirActivityDateFieldDirective);
common_module.directive('ctuirLocationSelectField', ctuirLocationSelectFieldDirective);
common_module.directive('ctuirPermitLocationSelectField', ctuirPermitLocationSelectFieldDirective);
common_module.directive('ctuirTimezoneSelectField', ctuirTimezoneSelectFieldDirective);
common_module.directive('ctuirInstrumentSelectField', ctuirInstrumentSelectFieldDirective);
common_module.directive('ctuirFishermanSelectField', ctuirFishermanSelectFieldDirective);
common_module.directive('ctuirAccuracyCheckSelectField', ctuirAccuracyCheckSelectFieldDirective);
common_module.directive('ctuirPostAccuracyCheckSelectField', ctuirPostAccuracyCheckSelectFieldDirective);
common_module.directive('ctuirQaStatusSelectField', ctuirQaStatusSelectFieldDirective);
common_module.directive('ctuirQaStatusCommentField', ctuirQaStatusCommentFieldDirective);
common_module.directive('ctuirTimeField', ctuirTimeFieldDirective);
common_module.directive('ctuirEastingField', ctuirEastingFieldDirective);
common_module.directive('ctuirNorthingField', ctuirNorthingFieldDirective);
common_module.directive('ctuirNumberField', ctuirNumberFieldDirective);
common_module.directive('ctuirSelectField', ctuirSelectFieldDirective);
common_module.directive('ctuirSelectWithBetterFilterField', ctuirSelectWithBetterFilterFieldDirective);
common_module.directive('ctuirSelectFancyField', ctuirSelectFancyFieldDirective);
common_module.directive('ctuirSelectGroupField', ctuirSelectGroupFieldFunctionDirective);
common_module.directive('ctuirSelectNumberField', ctuirSelectNumberFieldDirective);
common_module.directive('ctuirMultiselectField', ctuirMultiselectFieldDirective);
common_module.directive('ctuirMultiselectCheckboxField', ctuirMultiselectCheckboxFieldDirective);
common_module.directive('ctuirFileField', ctuirFileFieldDirective);
common_module.directive('ctuirTempWaypointFileField', ctuirTempWaypointFileFieldDirective);
common_module.directive('ctuirLinkField', ctuirLinkFieldDirective);
common_module.directive('ctuirRadioField', ctuirRadioFieldDirective);
common_module.directive('ctuirCheckboxField', ctuirCheckboxFieldDirective);
common_module.directive('uiSelectWrapper', uiSelectWrapperDirective);
common_module.directive('multiselect', multiselectDirective);
common_module.directive('convertToNumber', convertToNumberDirective);

// checklists directive
common_module.directive('checklistModel', checklist_model_directive);

// currency directive
common_module.directive('uiCurrency', uiCurrencyDirective);

// map and featureLayer directives
// common_module.directive('esriFeatureLayer', featureLayerDirective);
// common_module.directive('esriMap', mapDirective);
//
// roles directives
common_module.directive('projectRole', projectRoleDirective);

//validation directives
common_module.directive('integer', integerDirective);
common_module.directive('integerfour', integerfourDirective);
common_module.directive('smartfloat', smartfloat)
common_module.directive('sixfloat', sixfloat);
common_module.directive('sevenfloat', sevenfloat)
common_module.directive('textrequired', textRequired);


common_module.service('CommonService', common_service);
common_module.service('ServiceUtilities', service_utilities);
common_module.service('Logger', logger_service);
// common_module.service('ChartService', chart_services);



// });

//
// //We load these asych with the others
// require([
//     //loads chart services
//     'core/common/components/chart/adultweir-chartservice',
//     'core/common/components/chart/artificialproduction-chartservice',
//     'core/common/components/chart/bsample-chartservice',
//     'core/common/components/chart/creelsurvey-chartservice',
//     'core/common/components/chart/electrofishing-chartservice',
//     'core/common/components/chart/snorkelfish-chartservice',
//     'core/common/components/chart/waterquality-chartservice',
//     'core/common/components/chart/watertemp-chartservice',
//
// ], function () {

//there is a chartservice for each dataset.
// NOTE: If you are creating a new dataset, you'll want to make a chartservice for it.
common_module.service('AdultWeir_ChartService', adultweir_chartservice);
common_module.service('ArtificialProduction_ChartService', artificialproduction_chartservice);
common_module.service('BSample_ChartService', bsample_chartservice);
common_module.service('CreelSurvey_ChartService', creelsurvey_chartservice);
common_module.service('ElectroFishing_ChartService', electrofishing_chartservice);
common_module.service('SnorkelFish_ChartService', snorkelfish_chartservice);
common_module.service('WaterQuality_ChartService', waterquality_chartservice);
common_module.service('WaterTemp_ChartService', watertemp_chartservice);

//and then we only load this one after the others are done...
// require([
//     'core/common/components/chart/chart-services',                    //the wrapper for them all...
// ], function () {
//the master chartservice that exposes all of the other dataset-specific chart services
common_module.service('ChartService', chart_services);

var TheFeatureServiceUrl = "";
if (ENVIRONMENT === "prod")
  TheFeatureServiceUrl = CDMS_POINTS_PROD_URL;
else
  TheFeatureServiceUrl = CDMS_POINTS_TEST_URL;

// });
//
// loadModules([
//   // 'app',
//   'esri/map',
//   'esri/views/MapView',
//   'esri/geometry/Point',
//   'esri/dijit/InfoWindow',
//   'esri/InfoTemplate',
//   'esri/dijit/BasemapLayer',
//   'esri/dijit/BasemapGallery',
//   'esri/dijit/Basemap',
//   'esri/virtualearth/VETiledLayer'
// ]).then(([Map, MapView]) => {


  /*
    <esri-map class="claro" id="map" center="-118.45,45.56" zoom="10" basemap="streets" onclick="click">
                      <esri-feature-layer url="{{TheFeatureServiceUrl}}" filter="location"></esri-feature-layer>
                </esri-map>
   */

  // common_module.getLocationMapLayer = function () {
  //   const map = new Map()
  //   var mapOptions = {
  //     center: [-118.45, 45.56],
  //     zoom: (10),
  //     map: map,
  //     spatialReference: {
  //       wkid: 102100 //mercator
  //       //wkid:26911 //nad_1983
  //       //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //     }
  //   };
  //   let mapView = new MapView({
  //     center: [-118.45, 45.56],
  //     zoom: (10),
  //     map: map,
  //     spatialReference: {
  //       wkid: 102100 //mercator
  //       //wkid:26911 //nad_1983
  //       //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'
  //     }
  //   };);
  //   // declare our map
  //
  //   if (ENVIRONMENT == "prod")
  //        map.locationLayer = map.layers.add(CDMS_POINTS_PROD_URL);
  //   else
  //        map.locationLayer = map.layers.add(CDMS_POINTS_TEST_URL);
  //
  //   return mapView;
  // };


// });

common_module.factory('GetMetadataPropertiesForEntity', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/GetMetadataPropertiesForEntity');
}]);

common_module.factory('GetMetadataEntities', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/GetMetadataEntities');
}]);

common_module.factory('GetMetadataProperty', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/GetMetadataProperty');
}]);

common_module.factory('GetLookupItems', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/lookuptable/getitems', {}, {
    query: {method: 'GET', isArray: true}
  });
}]);

common_module.factory('GetSelectGridData', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/GetSelectGridData', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

common_module.factory('SaveLookupTableItem', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/lookuptable/saveitem');
}]);

common_module.factory('SaveMetadataProperty', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/SaveMetadataProperty');
}]);

common_module.factory('DeleteMetadataProperty', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/DeleteMetadataProperty');
}]);

common_module.factory('SaveDatasetMetadata', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/setdatasetmetadata');
}]);

common_module.factory('GetMetadataFor', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/metadata/getmetadatafor', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

common_module.factory('GetWaterBodies', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getwaterbodies');
}]);

common_module.factory('GetSources', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getsources');
}]);

common_module.factory('GetTimeZones', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/gettimezones');
}]);

common_module.factory('GetDepartments', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/department/getdepartments');
}]);

common_module.factory('GetOrgDepartments', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/department/getorgdepartments');
}]);

common_module.factory('GetOrganizations', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/organization/getorganizations');
}]);

common_module.factory('GetGroups', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/group/getgroups');
}]);

common_module.factory('GetOrgGroups', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/group/getorggroups');
}]);

common_module.factory('GetOrgAdministrators', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/organization/getorgadministrators');
}]);

common_module.factory('Users', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getusers', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

common_module.factory('GetOrgUsers', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getorgusers', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

common_module.factory('GetAllUsers', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getallusers', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

common_module.factory('SaveUser', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/saveuser');
}]);

common_module.factory('SaveProjectLocation', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/location/saveprojectlocation');
}]);

common_module.factory('UpdateLocationAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/location/updatelocation');
}]);

common_module.factory('DeleteLocationAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/location/deletelocation');
}]);

common_module.factory('DeleteLocations', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/location/deletelocations', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

common_module.factory('GetAllPossibleDatastoreLocations', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/datastore/getdatastorelocations');
}]);

common_module.factory('SaveFeedback', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/SaveFeedback');
}]);

common_module.factory('DeleteLookupItem', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/lookuptable/DeleteLookupItem');
}]);
//


// common_module.directive('ctuirTextField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-text.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirCurrencyField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-currency.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirPhoneNumberField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-phone-number.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirHiddenField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-hidden.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
//
// common_module.directive('ctuirSelectGridField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-select-grid.html',
//             controller:['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
//                 $scope.selectOptions = getOrderedObjectList(makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues));
//             }],
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirTextareaField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-textarea.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirDateField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-date.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirActivityTextField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-activity-text.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
//
// common_module.directive('ctuirActivityDateField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-activity-date.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirLocationSelectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-location-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirTimezoneSelectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-timezone-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirInstrumentSelectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-instrument-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirFishermanSelectField',
//     function () {
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-fisherman-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirAccuracyCheckSelectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-accuracy-check-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirPostAccuracyCheckSelectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-post-accuracy-check-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirQaStatusSelectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-qa-status-select.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
//
// common_module.directive('ctuirQaStatusCommentField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-qa-status-comment.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
//
// common_module.directive('ctuirTimeField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-time.html',
//             restrict: 'E',
//             controller: function($scope, $element, $attrs) {
//                 //console.dir("have a time!");
//                 //console.dir($scope.field);
//                 //console.dir($scope.row);
//                 if (typeof $scope.row[$scope.field.DbColumnName] === 'string') {
//                     try {
//                         var time_only = moment($scope.row[$scope.field.DbColumnName]).format('HH:mm');
//                         $scope.row[$scope.field.DbColumnName] = time_only;
//                     }catch(e){
//                         console.log(" Could not convert "+ $scope.field.DbColumnName + " values " + $scope.row[$scope.field.DbColumnName] + " to a date.");
//                     }
//                 }
//             }
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirEastingField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-easting.html',
//             restrict: 'E',
//         };
//
//         return result;
//     });
//
// common_module.directive('ctuirNorthingField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-northing.html',
//             restrict: 'E',
//         };
//
//         return result;
//     });
//
// common_module.directive('ctuirNumberField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-number.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirSelectField',
//     function () {
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-select.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
//                 $scope.selectOptions = getOrderedObjectList(makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues));
//             }]
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirSelectWithBetterFilterField',
//     function () {
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-select-with-better-filter.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
//                 $scope.selectOptions = getOrderedObjectList(makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues));
//             }]
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirSelectFancyField',
//     function () {
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-select-fancy.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
//                 $scope.selectOptions = getOrderedObjectList(makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues));
//             }]
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirSelectGroupField',
//     function () {
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-select-group.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
//                 $scope.selectOptions = makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues);
//             }]
//         };
//
//         return result;
//
//     });
//
//
//
// common_module.directive('ctuirSelectNumberField',
//     function () {
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-select-number.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
//                 $scope.selectOptions = getOrderedObjectList(makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues));
//             }]
//         };
//
//         return result;
//
//     });
//
//
// common_module.directive('ctuirMultiselectField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-multiselect.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
//                $scope.selectOptions = makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues);
//
//                //console.log("--- in ctuirMultiselectfield")
//                //console.dir($scope.selectOptions)
//                //console.dir($scope.row)
//                //console.dir($scope.field)
//                //if the values of the multiselect hasn't been converted to an array, try to convert it otherwise any selected values will not highlight as selected
//                if (typeof $scope.row[$scope.field.DbColumnName] === 'string') {
//                     try {
//                         $scope.row[$scope.field.DbColumnName] = angular.fromJson($scope.row[$scope.field.DbColumnName]);
//                     }catch(e){
//                         console.log(" Could not convert "+ $scope.field.DbColumnName + " values " + $scope.row[$scope.field.DbColumnName] + " to an array.");
//                     }
//                 }
//             }]
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirMultiselectCheckboxField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-multiselect-checkbox.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
//                $scope.selectOptions = makeObjectsFromValues($scope.field.DatastoreId+$scope.field.DbColumnName, $scope.field.PossibleValues);
//                 /*
//                 console.dir("have a multiselect-checkbox!");
//                 console.dir($scope.selectOptions);
//                 console.dir($scope.field);
//                 console.dir($scope.row);
//                 if (typeof $scope.row[$scope.field.DbColumnName] === 'string') {
//                     try {
//                         $scope.row[$scope.field.DbColumnName] = angular.fromJson($scope.row[$scope.field.DbColumnName]);
//                     }catch(e){
//                         console.log(" Could not convert "+ $scope.field.DbColumnName + " values " + $scope.row[$scope.field.DbColumnName] + " to an array.");
//                     }
//                 }*/
//             }]
//         };
//
//         return result;
//
//     });
//
//
// common_module.directive('ctuirFileField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-file.html',
//             restrict: 'E',
//         };
//
//         return result;
//     });
//
// common_module.directive('ctuirTempWaypointFileField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-waypoint-file.html',
//             restrict: 'E',
//         };
//
//         return result;
//     });
//
// common_module.directive('ctuirLinkField',
//     function(){
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-link.html',
//             restrict: 'E',
//             controller: ['$scope', '$element', '$attrs', '$uibModal', function($scope, $element, $attrs, $uibModal)
//             {
//                 //add a function that will enable file modal capability for all fields with controlType = link
//                 $scope.openLinkModal = function(row, field)
//                 {
//                     //console.dir(row);
//                     //console.dir(field);
//                     $scope.link_row = row;
//                     $scope.link_field = field;
//
//                     var modalInstance = $uibModal.open({
//                         templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-link-field.html',
//                         controller: 'LinkModalCtrl',
//                         scope: $scope, //scope to make a child of
//                     });
//                 };
//             }]
//         };
//
//         return result;
//     });
//
// common_module.directive('ctuirRadioField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-radio.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('ctuirCheckboxField',
//     function(){
//
//         var result = {
//             templateUrl: 'appjsLegacy/core/common/templates/form-fields/field-checkbox.html',
//             restrict: 'E',
//         };
//
//         return result;
//
//     });
//
// common_module.directive('uiSelectWrapper',
//     function(){
//
// 		return {
// 			link: function(scope, element, attrs) {
// 				var uiSelectController = element.children().controller('uiSelect');
// 				console.log("uiSelectController is next...");
// 				console.dir(uiSelectController);
// 			}
// 		}
//
//     });
//
// common_module.directive('multiselect', function () {
//
//         return {
//
//             scope: true,
//             link: function (scope, element, attrs) {
//
//                 element.multiselect({
//
//                     // Replicate the native functionality on the elements so
//                     // that angular can handle the changes for us.
//                     onChange: function (optionElement, checked) {
//
//                         optionElement.removeAttr('selected');
//
//                         if (checked) {
//                             optionElement.attr('selected', 'selected');
//                         }
//
//                         element.change();
//                     }
//                 });
//
//                 // Watch for any changes to the length of our select element
//                 scope.$watch(function () {
//                     return element[0].length;
//                 }, function () {
//                     element.multiselect('rebuild');
//                 });
//
//                 // Watch for any changes from outside the directive and refresh
//                 scope.$watch(attrs.ngModel, function () {
//                     element.multiselect('refresh');
//                 });
//
//             }
//
//         };
// });
//
// common_module.directive('convertToNumber', function () {
//     return {
//         require: 'ngModel',
//         link: function (scope, element, attrs, ngModel) {
//             ngModel.$parsers.push(function (val) {
//                 return parseInt(val, 10);
//             });
//             ngModel.$formatters.push(function (val) {
//                 return '' + val;
//             });
//         }
//     };
// });
//
export default common_module;
