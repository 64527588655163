import * as angular from 'angular';

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var permit_map = ['$scope', '$route', '$routeParams', '$uibModal', '$location', '$window', '$rootScope', 'PermitService',
  function ($scope, $route, $routeParams, $uibModal, $location, $window, $rootScope, PermitService) {

    $rootScope.inModule = "permits";


    $scope.setOut = function () {
       $scope.outAllotment;
      console.log("allotment change on grid click");
    }

    if (!$scope.Profile.hasRole("Permits"))
      angular.rootScope.go("/unauthorized");

    $scope.permits = PermitService.getAllPermits();
    $scope.CadasterParcels = PermitService.getAllParcels();


    $scope.searchResults = [];
    $scope.searchComplete = false;

    $scope.parcelMatches = [];
    $scope.Selected = {Parcel: []};
    $scope.outAllotment = "";
    // $scope.parcel = "";
    console.log($rootScope.parcelMapFromList)

    $scope.permits.$promise.then(function () {

      if (!$scope.searchGridDiv) {
        $scope.searchGridDiv = document.querySelector("#search-permits-grid");
        new Grid($scope.searchGridDiv, $scope.searchGrid);
      }

      if ($routeParams.ParcelId != null) {
        $scope.searchTerm = $routeParams.ParcelId;
        $scope.searchButton();
      }
      if($rootScope.parcelMapFromList) {
        $scope.searchByString($rootScope.parcelMapFromList);
        $rootScope.parcelMapFromList = undefined;
      }
    });

    var SearchLinksTemplate = function (param) {

      var div = document.createElement('div');

      var editBtn = document.createElement('a');
      editBtn.href = '#';
      editBtn.innerHTML = 'Open';
      editBtn.addEventListener('click', function (event) {
        event.preventDefault();
        $scope.viewPermit(param.data.Id, param.data.IsViolation);
      });
      div.appendChild(editBtn);

      return div;
    };

    var searchColumnDefs = [
      {colId: 'ViewLink', width: 60, cellRenderer: SearchLinksTemplate, menuTabs: []},
      {headerName: "Permit", field: "PermitNumber", width: 130, filter: 'text', sort: 'asc'},
      {
        headerName: "Status", field: "PermitStatus", width: 200, filter: true
      },

    ];

    $scope.searchGrid = {
      suppressPropertyNamesCheck: true,
      columnDefs: searchColumnDefs,
      rowData: [],
      rowSelection: 'single',
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['filterMenuTab']
      },
      onRowClicked: function (params) {
        $scope.selectedPermit = params.data;
        $scope.showRelatedParcels(params.data.Id);
        // $scope.searchTerm = params.data.Id;
      }
    }

    $scope.searchButton = function () {
      $scope.searchByString($scope.searchTerm);
    }


    $scope.searchByString = function (in_string) {
      $scope.searchParcel = "";
      console.log("search " + in_string);
      $scope.searchComplete = false;

      $scope.searchResults.length = 0; //permit matches
      $scope.Selected.Parcel.length = 0;
      $scope.parcelMatches = [];

      $scope.searchTerm = in_string.toUpperCase();
      in_string = $scope.searchTerm;
      var exact_match = false;

      //step 1 - search for matching permits by permit number
      $scope.permits.forEach(function (permit) {

        if (permit.PermitNumber && permit.PermitNumber.toUpperCase().indexOf(in_string) !== -1) {
          $scope.searchResults.push(permit);
        }
        // if (permit.PermitNumber === in_string) {
        //   $scope.searchResults.push(permit);
        // }

      });

      $scope.searchDescription = "Permits matching " + in_string;
      if ($scope.searchGrid != undefined) {
        $scope.searchGrid.api.setRowData($scope.searchResults);
      }
      console.log($scope.searchResults);

      // step 2 - search for matching parcels
      //
      $scope.CadasterParcels.forEach(function (parcel) {
        if (parcel.ParcelId == null || parcel.ParcelId == "")
          return;

        var regex = RegExp(in_string, 'g');
        if (regex.test(parcel.ParcelId)) {
          //$scope.Selected.Parcel.push(angular.toJson(parcel)); //this is the trick
          $scope.parcelMatches.push(parcel);
          if (parcel.ParcelId == in_string)
            exact_match = true;
          $scope.searchParcel = parcel.ParcelId;
        }
      });

      if ($scope.searchResults.length == 0) {
        $scope.findRelatedPermits($scope.searchParcel);
      }

      // $scope.PermitParcels = [];
      //
      // var related_permits = PermitService.getPermitsByRelatedParcels(in_string);
      // console.log("searching for permits related to " + in_string);
      // related_permits.$promise.then(function () {
      //   console.log("found some! " + related_permits.length);
      //   $scope.parcel.length = 0;
      //   related_permits.forEach(function (permit) {
      //     $scope.searchResults.push(permit);
      //   });
      // })

      /*

                  //step 3 - if we have no parcel match, search our own list of parcels to see if any "outdated" ones (not in Cadaster) match
                  if(!exact_match){
                      var oldpermitidmatches = PermitService.getPermitsByRelatedParcels(in_string);
                      oldpermitidmatches.$promise.then(function(){
                          oldpermitidmatches.forEach(function(permit){
                              $scope.parcelMatches.push(permit);
                          })
                      });
                  }
      */

      //          if(exact_match){
      $scope.outAllotment = in_string;
      // $scope.findOnMap(in_string);
      //          }

      $scope.searchComplete = true;

    }

    $scope.selectParcel = function () {
      var parcel = angular.fromJson($scope.Selected.Parcel[0]);
      $scope.outAllotment = parcel.ParcelId;
      // $scope.findRelatedPermits($scope.outAllotment);
      $scope.findRelatedPermits(parcel.ParcelId);
      // $scope.searchTerm = $scope.parcel.ParcelId
      // $scope.findOnMap(parcel.ParcelId);
    }

    $scope.showRelatedParcels = function (id) {
      $scope.PermitParcels = PermitService.getPermitParcels(id);
    };

    //clicked when user clicks id of related parcel
    $scope.clickRelatedParcel = function (id) {
      $scope.parcelMatches.length = 0;
      $scope.outAllotment = id;
      $scope.findRelatedPermits(id);
      // $scope.findOnMap(id);
    }

    $scope.viewPermit = function (Id, IsViolation) {
      if (IsViolation === "y")
        window.open("permits/ehsviolations?Id=" + Id, "_blank");
      else
        window.open("permits/list?Id=" + Id, "_blank");
    };


    // expose a method for handling clicks ON THE MAP
    $scope.mapClick = function (e) {
      $scope.parcel = e;
      console.log("Click!");

      $scope.isPermitSelected = true;
      $scope.hasResults = true;

      // var objectid = $scope.map.selectedFeature.attributes.OBJECTID;
      // var allotment = $scope.map.selectedFeature.attributes.PARCELID;
      console.log($scope.parcel.PARCELID)
      $scope.searchTerm = $scope.parcel.PARCELID
      $scope.searchButton();
      $scope.findRelatedPermits($scope.parcel.PARCELID);
      // $scope.searchByString($scope.parcel.PARCELID);


      // $scope.map.loading = false;
      $scope.$apply(); //bump angular

      // });
    };


    $scope.findRelatedPermits = function (parcel) {

      $scope.searchTerm = parcel;
      $scope.searchDescription = "Related Permits to " + parcel;
      $scope.PermitParcels = [];

      var related_permits = PermitService.getPermitsByRelatedParcels(parcel);
      console.log("searching for permits related to " + parcel);
      related_permits.$promise.then(function () {
        console.log("found some! " + related_permits.length);
        $scope.searchResults.length = 0;
        related_permits.forEach(function (permit) {
          $scope.searchResults.push(permit);
        });

        $scope.searchGrid.api.setRowData($scope.searchResults);
      });
    }
  }];

export default permit_map;
