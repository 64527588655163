import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppjsRoutingModule} from './appjs-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {AppjsComponent} from './appjs.component';
import {UpgradeModule} from '@angular/upgrade/static';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoginModule} from "../login/login.module";
import {ConfirmModule} from "../confirm/confirm.module";


@NgModule({
  declarations: [AppjsComponent],
  imports: [
    CommonModule,
    AppjsRoutingModule,
    HttpClientModule,
    UpgradeModule,
    NgbModule,
    NgbDropdownModule,
    LoginModule
  ],
  providers: []
})
export class AppjsModule {
}
