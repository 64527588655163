//project factories and service
//includes fishermen and instrument services, too, since they are part of projects.
/*
* define the service that can be used by any module in our application to work with projects.
*/
import * as angular from 'angular';

const project_service = ['$q', '$rootScope',
  'ProjectFunders',
  'ProjectCollaborators',
  'ProjectCounties',
  'Projects',
  'Project',
  'ProjectFiles',
  'ProjectDatasets',
  'SaveProjectPermissions',
  'SaveProject',
  'GetAllInstruments',
  'GetAllInstrumentAccuracyChecks',
  'SaveProjectInstrument',
  'SaveProjectFisherman',
  'SaveInstrument',
  'SaveInstrumentAccuracyCheck',
  'SaveFisherman',
  'UpdateFile',
  'DeleteFile',
  'GetDatastoreProjects',
  'GetInstruments',
  'GetInstrumentTypes',
  'RemoveProjectInstrument',
  'GetFishermen',
  'GetProjectFishermen',
  'RemoveProjectFisherman',
  'RemoveInstrumentAccuracyCheck',
  'GetCrppStaff',
  'GetOlcStaff',
  'SaveProjectConfig',
  'GetPrincipals',
  'ChangeOwner',
  'GetVisibilityPermissions',
  'SaveLink',
  'ProjectLocations',
  'SaveLocations',
  'AddLocation',
  'ProjectDatasetLocations',
  function ($q, $rootScope,
            ProjectFunders,
            ProjectCollaborators,
            ProjectCounties,
            Projects,
            Project,
            ProjectFiles,
            ProjectDatasets,
            SaveProjectPermissions,
            SaveProject,
            GetAllInstruments,
            GetAllInstrumentAccuracyChecks,
            SaveProjectInstrument,
            SaveProjectFisherman,
            SaveInstrument,
            SaveInstrumentAccuracyCheck,
            SaveFisherman,
            UpdateFile,
            DeleteFile,
            GetDatastoreProjects,
            GetInstruments,
            GetInstrumentTypes,
            RemoveProjectInstrument,
            GetFishermen,
            GetProjectFishermen,
            RemoveProjectFisherman,
            RemoveInstrumentAccuracyCheck,
            GetCrppStaff,
            GetOlcStaff,
            SaveProjectConfig,
            GetPrincipals,
            ChangeOwner,
            GetVisibilityPermissions,
            SaveLink,
            ProjectLocations,
            SaveLocations,
            AddLocation,
            ProjectDatasetLocations
  ) {

    var service = {
      project: null,

      clearProject: function () {
        service.project = null;
      },

      getPrincipals: function (id) {
        return GetPrincipals.query({id: id});
      },

      getVisibilityPermissions: function (id) {
        return GetVisibilityPermissions.query({id: id});
      },

      //NB: Not used anywhere
      getDatastoreProjects: function (id) {
        return GetDatastoreProjects.query({id: id});
      },

      getProjects: function () {
        return Projects.query();
      },

      getLocations: function (projectId) {
        return ProjectLocations.query({ProjectId: projectId});
      },

      getDatasetLocations: function (projectId, datasetId) {
        return ProjectDatasetLocations.query({ProjectId: projectId, DatasetId: datasetId});
      },

      getProjectDatasets: function (projectId) {
        //this.getProject(projectId); //set our local project to the one selected
        return ProjectDatasets.query({id: projectId});
      },

      getProjectFunders: function (projectId) {
        console.log("Inside getProjectFunders, projectId = " + projectId);
        //this.getProject(projectId); //set our local project to the one selected
        return ProjectFunders.query({id: projectId});
      },

      getProjectCollaborators: function (projectId) {
        console.log("Inside getProjectCollaborators...");
        //this.getProject(projectId); //set our local project to the one selected
        return ProjectCollaborators.query({id: projectId});
      },

      getProjectCounties: function (projectId) {
        console.log("Inside getProjectCounties...");
        //this.getProject(projectId); //set our local project to the one selected
        return ProjectCounties.query({id: projectId});
      },

      saveProject: function (project) {
        return SaveProject.save({Project: project});
      },

      saveLocations: function (datasetId, locationIds) {
        return SaveLocations.save({DatasetId: datasetId, LocationIds: locationIds});
      },

      addLocation: function (datasetId, locationId) {
        return AddLocation.save({DatasetId: datasetId, LocationId: locationId});
      },

      saveLink: function (projectId, link) {
        return SaveLink.save({ProjectId: projectId, Link: link});
      },

      changeProjectOwner: function (projectId, userId) {
        return ChangeOwner.save({ProjectId: projectId, UserId: userId})
      },

      saveProjectConfig: function (project) {
        return SaveProjectConfig.save({Project: project});
      },

      getProjectFiles: function (projectId) {
        //console.log("Inside getProjectFiles...");
        //console.log("projectId = " + projectId);
        return ProjectFiles.query({id: projectId});
      },

      getInstruments: function () {
        return GetInstruments.query();
      },

      getInstrumentTypes: function () {
        return GetInstrumentTypes.query();
      },

      getFishermen: function () {
        return GetFishermen.query();
      },

      getProject: function (id) {
        //console.log("Inside getProject; id = " + id);

        var project = Project.query({id: id});

        project.$promise.then(function () {
          if (!$rootScope.Profile.canViewProject(project)) {
            $rootScope.go('/unauthorized');
          }

          if (project.Config) {
            try {
              project.Config = angular.fromJson(project.Config);
            } catch (e) {
              console.error("config could not be parsed for project: " + project.Config);
              console.dir(e);
            }
          } else {
            project.Config = {};
          }
        })

        return project;
      },

      savePermissions: function (projectId, permissions) {
        return SaveProjectPermissions.save({ProjectId: projectId, Permissions: permissions})
      },

      getAllInstruments: function () {
        return GetAllInstruments.query();
      },

      saveInstrument: function (projectId, instrument) {
        return SaveInstrument.save({ProjectId: projectId, Instrument: instrument}); //will connect to this project if creating instrument
      },

      saveFisherman: function (projectId, fisherman, saveResults) {
        //console.log("Inside saveFisherman...");
        saveResults.saving = true;
        //console.log("saveResults.saving = " + saveResults.saving);

        return SaveFisherman.save({ProjectId: projectId, Fisherman: fisherman});
      },

      saveProjectInstrument: function (projectId, instrument) {
        return SaveProjectInstrument.save({ProjectId: projectId, Instrument: instrument});
      },

      saveProjectFisherman: function (projectId, fisherman) {
        return SaveProjectFisherman.save({ProjectId: projectId, Fisherman: fisherman});
      },

      removeProjectFisherman: function (projectId, fishermanId) {
        return RemoveProjectFisherman.save({ProjectId: projectId, FishermanId: fishermanId});
      },

      removeProjectInstrument: function (projectId, instrumentId) {
        return RemoveProjectInstrument.save({ProjectId: projectId, InstrumentId: instrumentId});
      },

      getProjectFishermen: function (projectId) {
        console.log("Inside getProjectFishermen, projectId = " + projectId);
        return GetProjectFishermen.query({id: projectId});
      },

      getAllInstrumentAccuracyChecks: function (instrumentIdList) {
        return GetAllInstrumentAccuracyChecks.query();
      },

      saveInstrumentAccuracyCheck: function (instrumentId, ac) {
        return SaveInstrumentAccuracyCheck.save({InstrumentId: instrumentId, AccuracyCheck: ac});
      },

      removeInstrumentAccuracyCheck: function (instrumentId, ac) {
        return RemoveInstrumentAccuracyCheck.save({InstrumentId: instrumentId, AccuracyCheck: ac});
      },

      updateFile: function (projectId, file) {
        return UpdateFile.save({ProjectId: projectId, File: file});
      },

      deleteFile: function (projectId, file) {
        //console.log("ProjectId = " + projectId + ", attempting to delete file...");
        //console.dir(file);
        return DeleteFile.save({ProjectId: projectId, File: file});
      },

      getCrppStaff: function () {
        //console.log("Inside getCrppStaff...");
        return GetCrppStaff.query();
      },

      getOlcStaff: function () {
        //console.log("Inside getOlcStaff...");
        return GetOlcStaff.query();
      },
    };

    return service;
  }
];

export default project_service;
