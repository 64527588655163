// defines the crpp module
import * as angular from 'angular';
import modal_add_correspondence_event from './components/correspondence/modal-add-correspondence-event';
import modal_create_crpp_subproject from './components/correspondence/modal-create-crpp-subproject';
import page_correspondence from './components/correspondence/correspondence';

// ], function () {
const crpp_module = angular.module('CrppModule', ['ui.bootstrap', 'ngResource']);
//crpp_module.controller('CrppContractsCtrl', crpp_contracts);
crpp_module.controller('ModalAddCorrespondenceEventCtrl', modal_add_correspondence_event);
crpp_module.controller('ModalCreateSubprojectCtrl', modal_create_crpp_subproject);
crpp_module.controller('CRPPCorrespondenceCtrl', page_correspondence);
// });

export default crpp_module;
