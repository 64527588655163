var modal_invalid_operation =  ['$scope', '$uibModalInstance',
    function ($scope, $uibModalInstance) {

        $scope.header_title = $scope.invalidOperationTitle;
        $scope.header_message = $scope.invalidOperationMessage;

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
];

export default modal_invalid_operation;
