import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {DISPLAY_NAME, 
  PUBLIC_OR_PRIVATE, 
  REPORTSERVER_URL,
  CDMS_DOCUMENTATION_URL,
  CTUIR_AGOL_URL,
  CTUIR_GIS_URL,
  GIS_TAX_PARCELS_URL,
  TPO_REPORTS_URL
} from 'src/appjsLegacy/config';
import {AppjsLoaderService} from './appjs-loader.service';
import {ProfileService} from '../login/profile.service';
import {Observable} from "rxjs";
import {LoginService} from "../login/components/login.service";
import {LogoutComponent} from "../login/components/logout/logout.component";


@Component({
  selector: 'app-appjs',
  templateUrl: './appjs.component.html',
  styleUrls: ['./appjs.component.css']
})
export class AppjsComponent implements OnInit, OnDestroy {
  public DISPLAY_NAME;
  public REPORTSERVER_URL;
  public PUBLIC_OR_PRIVATE;
  public CDMS_DOCUMENTATION_URL;
  public CTUIR_AGOL_URL;
  public CTUIR_GIS_URL;
  public GIS_TAX_PARCELS_URL;
  public TPO_REPORTS_URL;
  // public Profile;


  constructor(
    private lazyLoader: AppjsLoaderService,
    private elRef: ElementRef,
    public profileService: ProfileService,
    public logoutComponent: LogoutComponent,
  ) {}

  ngOnInit() {
    this.DISPLAY_NAME = DISPLAY_NAME;
    this.REPORTSERVER_URL = REPORTSERVER_URL;
    this.PUBLIC_OR_PRIVATE = PUBLIC_OR_PRIVATE;
    this.CDMS_DOCUMENTATION_URL = CDMS_DOCUMENTATION_URL;
    this.CTUIR_AGOL_URL = CTUIR_AGOL_URL;
    this.CTUIR_GIS_URL = CTUIR_GIS_URL;
    this.GIS_TAX_PARCELS_URL = GIS_TAX_PARCELS_URL;
    this.TPO_REPORTS_URL = TPO_REPORTS_URL;
    this.lazyLoader.load(this.elRef.nativeElement);
  }


  ngOnDestroy() {
    this.lazyLoader.destroy();
  }
}
