//Violations api - loaded by permits since they are both TPO projects

// NOTE: that means the resources have the same namespace and cannot share names across services...


import permit_module from "./permit-module";
import {EHS_PROJECTID, serviceUrl} from "../../config";



const violation_services = ['$q',
'GetViolations',
'SaveViolation',
'GetViolationContacts',
'SaveViolationContact',
'GetViolationParcels',
'GetViolationRelatedParcels',
'SaveViolationParcel',
'DeleteViolationFile',
'GetViolationFiles',
'GetViolationEvents',
'SaveViolationEvent',
'GetViolationCodes',
'SaveViolationCode',
'RemoveViolationContact',
'RemoveViolationParcel',
'SendNotification',
'GetNotifications',
'RemoveViolationActivity',

    function ($q,
        GetViolations,
        SaveViolation,
        GetViolationContacts,
        SaveViolationContact,
        GetViolationParcels,
        GetViolationRelatedParcels,
        SaveViolationParcel,
        DeleteViolationFile,
        GetViolationFiles,
        GetViolationEvents,
        SaveViolationEvent,
        GetViolationCodes,
        SaveViolationCode,
        RemoveViolationContact,
        RemoveViolationParcel,
        SendNotification,
        GetNotifications,
        RemoveViolationActivity

    ) {
        var service = {


            getAllViolations: function () {
                return GetViolations.query();
            },

            getViolationContacts: function (Id) {
                return GetViolationContacts.query({ Id: Id });
            },

            saveViolation: function(violation) {
                return SaveViolation.save({Violation: violation})
            },

            saveViolationContact: function (contact) {
                return SaveViolationContact.save({ ViolationContact: contact });
            },

            getViolationParcels:  function (Id) {
                return GetViolationParcels.query({ Id: Id });
            },

            getViolationsByRelatedParcels: function (ParcelId) {
                return GetViolationRelatedParcels.query({ ParcelId: ParcelId });
            },

            saveViolationParcel: function (violationparcel) {
                return SaveViolationParcel.save({ ViolationParcel: violationparcel });
            },

            deleteViolationFile: function (projectId, subprojectId, itemId, file) {
                return DeleteViolationFile.save({ ProjectId: projectId, SubprojectId: subprojectId, ItemId: itemId, File: file });
            },

            getViolationFiles: function (ViolationId) {
                return GetViolationFiles.query({ ProjectId: EHS_PROJECTID, ViolationId: ViolationId });
            },

            saveViolationEvent: function (violationevent) {
                return SaveViolationEvent.save({ ViolationEvent: violationevent });
            },

            getViolationEvents:  function (Id) {
                return GetViolationEvents.query({ Id: Id });
            },

            saveViolationCode: function (violationcode) {
                return SaveViolationCode.save({ ViolationCode: violationcode });
            },

            getViolationCodes:  function (Id) {
                return GetViolationCodes.query({ Id: Id });
            },

            removeViolationParcel: function (violationparcel) {
                return RemoveViolationParcel.save({ ViolationParcel: violationparcel });
            },

            sendNotifications: function (notification) {
                return SendNotification.save({ EHSViolationId: notification.EHSViolationId, NotifyRoutes: notification.NotifyRoutes });
            },

            removeViolationContact: function (violationcontact) {
                return RemoveViolationContact.save({ ViolationContact: violationcontact });
            },

            removeViolationActivity: function (activityId) {
                return RemoveViolationActivity.save({ ViolationActivityId: activityId });
            },

            getNotifications: function () {
                return GetNotifications.query({ Module: 'EHSInspectionViolation' });
            }

        };

        return service;

    }
];

export default violation_services;
