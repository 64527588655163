/*
*   This page loads the project permissions
*/
import * as angular from 'angular';
import * as $ from 'jquery';
import {orderByAlphaName, orderUserByAlpha} from "../../../common/common-functions";
import {DEFAULT_PROJECT_PERMISSION} from "../../../../config";

var project_permissions = ['$scope', '$routeParams','SubprojectService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
    '$rootScope', '$uibModal',
    function (scope, routeParams, SubprojectService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal) {


        scope.OnTab = "Permissions";

        scope.PrincipalTypeSelection = "User";
        scope.saveResults = { success: false, failure: false};
        scope.select2Options = { allowClear:true };
        scope.selectedItem = null;
        scope.currentUserId = $rootScope.Profile.Id;
        //remove existing permissions from the dropdowns.
        scope.filterLists = function()
        {
            scope.permissions.forEach(function(perm){
                //console.dir(perm);
                var objlist = scope.getPrincipalList(perm.PrincipalType);
                for(var a = 0; a < objlist.length; a++){
                    if(objlist[a].Id == perm.PrincipalId){
                        //console.log("splicing")
                        //console.dir(perm);
                        objlist.splice(a,1);
                        break;
                    }
                }
            })

            scope.users = scope.users.sort(orderUserByAlpha);
            scope.departments = scope.departments.sort(orderByAlphaName)
            scope.groups = scope.groups.sort(orderByAlphaName)
        }


        scope.addPermission = function(){
          console.log(scope.PrincipalTypeSelection);
          console.log(scope.selectedItem);
            var objlist = scope.getPrincipalList(scope.PrincipalTypeSelection);

            for (var i = 0; i < objlist.length; i++) {
                var obj = objlist[i];

                if(obj.Id == scope.selectedItem)
                {
                    scope.pushPermission(scope.PrincipalTypeSelection, obj);
                    objlist.splice(i,1);
                    break;
                }
            };

            scope.selectedItem = null;
            //$("select2Options").val('').change()

        };

        scope.getPrincipalList = function(type){
            var objlist = null;

            if(type == "User"){
                objlist = scope.users;
            } else if (type == "Group"){
                objlist = scope.groups;
            } else if (type == "Department"){
                objlist = scope.departments;
            }

            return objlist;

        }

        scope.pushPermission = function(principalType, principal) {
            scope.permissions.push({
                Level: "View",
                Principal: principal,
                PrincipalType: principalType,
                PrincipalId: principal.Id,
                TargetId: scope.project.Id,
                TargetType: "Project"
            })
        }

        scope.removePermission = function(index)
        {
            //remove it from the permissions list
            var obj = scope.permissions.splice(index,1)[0];

            //add it to the right list and re-sort
            var objlist = scope.getPrincipalList(obj.PrincipalType);
            objlist.push(obj.Principal);

            if(obj.PrincipalType == "User")
                objlist = objlist.sort(orderUserByAlpha);
            else
                objlist = objlist.sort(orderByAlphaName);

        };

        scope.savePermissions = function()
        {
            var allpermissions = angular.copy(scope.permissions);
            allpermissions.push(scope.OrganizationUser);
            allpermissions.push(scope.Public);

            var saved = ProjectService.savePermissions(scope.project.Id, allpermissions);

            saved.$promise.then(function(){
                scope.saveResults.success = true;
                scope.saveResults.failure = false;
            }, function(){
                scope.saveResults.success = false;
                scope.saveResults.failure = true;
            })
        };


        scope.reload = function()
        {
            scope.saveResults.success = false;
            scope.saveResults.failure = false;

            scope.project = ProjectService.getProject(routeParams.Id);

            scope.project.$promise.then(function () {

                //set project visibility defaults
                scope.OrganizationUser = { Level: DEFAULT_PROJECT_PERMISSION.Permission.OrgUser, PrincipalId: scope.project.OrganizationId, TargetId: scope.project.Id, TargetType: 'Project', PrincipalType: 'OrgUser' };
                scope.Public = { Level: DEFAULT_PROJECT_PERMISSION.Permission.Public, TargetId: scope.project.Id, PrincipalId: scope.project.OrganizationId, TargetType: 'Project', PrincipalType: 'Public' };

                //load them and replace if they exist
                scope.visibility = ProjectService.getVisibilityPermissions(scope.project.Id);
                scope.visibility.$promise.then(function(){
                    scope.visibility.forEach(function(perm){
                        if(perm.PrincipalType == 'OrgUser')
                            scope.OrganizationUser = perm;
                        if(perm.PrincipalType == 'Public')
                            scope.Public = perm;
                    })
                })

                //load our project permissions for users/groups/departments
                scope.permissions = ProjectService.getPrincipals(scope.project.Id);
                scope.permissions.$promise.then(function(){
                    scope.users = CommonService.getUsers();
                    scope.users.$promise.then(function(){
                        scope.departments = CommonService.getDepartments();
                        scope.departments.$promise.then(function(){
                            scope.groups = CommonService.getGroups();
                            scope.groups.$promise.then(function(){
                                scope.filterLists();
                            });
                        });
                    });
                });

            });

        };

        scope.changeOwner = function (a_selection) {

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-permission-change-owner.html',
                controller: 'ModalChangeOwnerCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_project) {
                scope.reload();
            });
        };

        scope.canEdit = function (project) {
            return $rootScope.Profile.canEdit(project);
        };

        //handle favorite toggle
        scope.isFavorite = $rootScope.Profile.isProjectFavorite(routeParams.Id);
        scope.toggleFavorite = function () {
            UserService.toggleFavoriteProject(scope, $rootScope);
        }

        scope.reload();


    }

];

export default project_permissions;




