
var add_project_dataset = ['$scope','$uibModalInstance', 'ProjectService',
	function($scope,  $uibModalInstance, ProjectService){

		$scope.row = {};

        $scope.projects = ProjectService.getProjects(); //.sort(orderByAlpha);


		$scope.save = function(){

			$uibModalInstance.dismiss();

		};

		$scope.cancel = function(){
			$uibModalInstance.dismiss();
		};

	}
];
export default add_project_dataset;
