import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BING_KEY, defaultLayer, parcelLayerConfig, proxyUrl, servicesLayerConfig} from "../../../appjsLegacy/config";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import esriConfig from "@arcgis/core/config";
import {EsriMapComponent} from "../esri-map/esri-map.component";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import QueryTask from "@arcgis/core/tasks/QueryTask";
import TileLayer from "@arcgis/core/layers/TileLayer";
import Query from "@arcgis/core/rest/support/Query";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as intl from "@arcgis/core/intl";
import Color from "@arcgis/core/Color";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import {addProxyRule} from "@arcgis/core/core/urlUtils";
import Basemap from "@arcgis/core/Basemap";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import ImageryTileLayer from "@arcgis/core/layers/ImageryTileLayer";
import * as watchUtils from "@arcgis/core/core/watchUtils"
import PopupTemplate from "@arcgis/core/PopupTemplate";
import BingMapsLayer from "@arcgis/core/layers/BingMapsLayer";

@Component({
  selector: 'permit-map',
  templateUrl: './permit-map.component.html',
  styleUrls: ['./permit-map.component.scss']
})
export class PermitMapComponent extends EsriMapComponent implements OnInit, OnChanges {
  @ViewChild('permitMapViewNode', {static: true}) private permitMapViewElement: ElementRef;
  @Input() center: string;
  @Input() zoom: number;
  @Input() scale: number;
  @Input() gridSelection: any;
  @Input() clearIt: boolean;
  @Output() parcel: EventEmitter<any> = new EventEmitter<any>();
  map: Map;
  mapView: MapView;
  selectedBasemap: string;
  selectedServiceLayers: string[];
  serviceLayers: any[];
  parcelLayer: GraphicsLayer;
  showingParcel: boolean;

  constructor() {
    super();
  }

  spatialReference = new SpatialReference({wkid: 102100});
  selectedFeature: any;
  selectedGraphic: any;
  locationLayer: any;
  // mapSearchResults: any;
  mapSearchResults = {Message: ""};
  serviceLayerArr = [
      "parcels_outline",
      // "parcels"
    ];


  ngOnInit() {
    this.initializeMap(this.serviceLayerArr, this.permitMapViewElement.nativeElement);

    this.spatialReference = new SpatialReference({wkid: 102100});
  }

  ngOnChanges(changes: SimpleChanges) {
    // this is getting the input from appraisal-map
    // this.updatedGrid();
    if (this.mapView) {
      this.clearAll();
      this.findOnMap(this.gridSelection);
    }
  }

  initializeMap(serviceLayerArr: string[], container: any) {
    this.map = new Map({
      basemap: "hybrid"
    });
    //let new_layer = new BingMapsLayer({style: "hybrid", key: BING_KEY});
    // this.map = new Map({
    //   basemap: {
    //     baseLayers: [
    //       new_layer
    //     ]
    //   }
    // });

    // declare our map
    console.log("trying to make the map");


    addProxyRule({
      urlPrefix: "restdata.ctuir.org",
      proxyUrl: proxyUrl
    });


    //our first layer from up above...
    //console.log("//restdata.umatilla.nsn.us/arcgis/rest/services/BasemapParcelViewerCTUIR/MapServer?token=" + security_token);
    this.selectedServiceLayers = serviceLayerArr;
    //setup basemaps
    this.selectedBasemap = defaultLayer; //"imageryLayer"
    // this.selectedServiceLayers = this.serviceLayers;

    this.basemaps = [];
    for (let property in parcelLayerConfig) {
      if (parcelLayerConfig.hasOwnProperty(property)) {
        this.basemaps.push({label: parcelLayerConfig[property].Display, name: property});
      }
    }


    this.serviceLayers = [];
    for (let property in servicesLayerConfig) {
      if (servicesLayerConfig.hasOwnProperty(property)) {
        this.serviceLayers.push({label: servicesLayerConfig[property].Display, name: property});
      }
    }


    //might want to do this: https://developers.arcgis.com/javascript/3/jssamples/query_hover.html
    // did try but had errors. might not be possible without using featureservice?: https://community.esri.com/thread/191330-popup-on-mouse-over-on-dynamic-service

let new_layer = new TileLayer({url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer'});
      // this.map.basemap = new Basemap({
      //   baseLayers: [
      //     new_layer
      //   ],
      //   title: 'esri topo basemap',
      //   id: 'basemap'
      // });
    let center;
    if (this.center) {
      center = this.center.split(",").map(c => parseFloat(c));
    }
    this.mapView = new MapView({
      container: container,
      center,
      scale: this.scale,
      map: this.map,
      spatialReference: {
        wkid: 102100 //mercator
        // wkid:26911 //nad_1983
        //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'

      }
    });

    this.updateLayers();


    // start exposing an API by setting properties on "this" which is our controller
    // lets expose the "addLayer" method so child directives can add themselves to the map


    // // listen for click events and expost them as broadcasts on the scope and suing the scopes click handler
    // this.map.on("click", function (e) {
    //   // emit a message that bubbles up scopes, listen for it on your scope
    //   $scope.$emit("this.map.click", e);
    //
    //   // use the scopes click fuction to handle the event
    //   $scope.$apply(function ($scope) {
    //     $scope.click.call($scope, e);
    //   });
    // });

    this.mapView.on("click", (e) => {
      this.clickedMap(e);
    });

    watchUtils.whenTrue(this.mapView.popup, 'visible', () => {
      watchUtils.whenFalseOnce(this.mapView.popup, 'visible', () => {
        this.parcelLayer.removeAll();
      });
    });

    return this.mapView;

  }

  // updatedGrid() {
  //   console.log(`grid selection: ${this.gridSelection}`);
  // }
  updateLayers() {

    console.log("Changing Layer: " + this.selectedBasemap);

    try {
      console.log("Loading layer: " + parcelLayerConfig[this.selectedBasemap].ServiceURL);

      this.map.removeAll();

      //add the selected basemap
      // var new_layer = new MapImageLayer({url: "https://restdata.ctuir.org/arcgis/rest/services/BasemapImagery/MapServer"});
      // let new_layer = new TileLayer({url: 'http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer'});
      // let new_layer = new TileLayer({url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer'});

      // this.map.layers.add(new_layer);
      // this.map.layers.add(new_layer);
      // this.map.basemap = basemap;


      //now add any selected service layers
      for (var i = this.selectedServiceLayers.length - 1; i >= 0; i--) {
        var service_layer;
        if (servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL.includes("FeatureServer") || this.selectedServiceLayers[i] == 'parcels' || this.selectedServiceLayers[i] == 'farms') {
          service_layer = new FeatureLayer({url: servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL});
        } else //then it is a MapServer
        {
          service_layer = new MapImageLayer({url: servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL});
        }

        console.log("adding " + servicesLayerConfig[this.selectedServiceLayers[i]].ServiceURL);
        this.map.add(service_layer);
      }


      this.parcelLayer = new GraphicsLayer();
      this.map.add(this.parcelLayer);

      console.log("done!");
      // this.map.reposition();
    } catch (e) {
      console.dir(e);
    }
  };

  addLayer(layer) {
    return this.map.layers.add(layer)
  };

  querySearchParcel(searchParam, callback) {
    var queryTask = new QueryTask({url: parcelLayerConfig[this.selectedBasemap].QueryURL});
    var query = new Query();
    query.where = intl.substitute(parcelLayerConfig[this.selectedBasemap].ParcelQuery, [searchParam]);
    console.log("query.where is next...");
    console.dir(query.where);
    query.returnGeometry = false;
    query.outSpatialReference = this.spatialReference;
    query.outFields = ["*"];

    queryTask.execute(query).then(result => {
      callback(result.features); //give back the parcel features we found...
    }, function (err) {
      // console.log("Failure executing query!");
      console.dir(err);
      console.dir(query);
    });
  }

  queryMatchParcel(searchParam, callback) {
    var queryTask = new QueryTask({url: parcelLayerConfig[this.selectedBasemap].QueryURL});
    var query = new Query();
    query.where = intl.substitute(parcelLayerConfig[this.selectedBasemap].LocateParcelQuery, [searchParam]);
    query.returnGeometry = false;
    query.outSpatialReference = this.spatialReference;
    query.outFields = ["*"];

    queryTask.execute(query).then(result => {
      callback(result.features); //give back the parcel features we found...
    }, function (err) {
      console.log("Failure executing query!");
      console.dir(err);
      console.dir(query);
    });
  };

  querySelectParcel(mapPoint, objectId, callback) {
    console.log("Inside leasing or appraisal Map.js...");
    console.log("Running query on: " + parcelLayerConfig[this.selectedBasemap].QueryURL);

    var queryTask = new QueryTask({url: parcelLayerConfig[this.selectedBasemap].QueryURL});
    var query = new Query();

    query.outSpatialReference = this.spatialReference;
    query.returnGeometry = true;
    query.outFields = ["*"];
    if (mapPoint) {
      query.geometry = mapPoint;
    } else {
      query.objectIds = [objectId];
    }

    query.spatialRelationship = "intersects";
    queryTask.execute(query).then(result => {
      console.dir(result);
      callback(result.features); //give back the parcel features we found...
    }, function (err) {
      console.log("Failure executing query!");
      console.dir(err);
      console.dir(query);
    });
  }

  clearGraphics() {
    if (this.parcelLayer != undefined) {
      return this.parcelLayer.removeAll();
    }
  }

  addParcelToMap(feature, color?, alpha?) {
    console.log(feature)
    let graphic;
    if (!color)
      color = "#FF6600";

    if (!alpha)
      alpha = .25;


    let lineColor = new Color(color);
    // lineColor.setColor(color);

    let fillColor = new Color(color);
    fillColor.a = alpha;

    var symbol = new SimpleFillSymbol({
      style: "solid",
      outline: {
        style: "solid",
        color: lineColor,
        width: 3,
      },
      color: fillColor
    });

    graphic = new Graphic({
      geometry: feature.geometry,
      symbol: symbol,
      attributes: feature.attributes
    });

    this.parcelLayer.removeAll();
    this.parcelLayer.add(graphic);
    this.selectedFeature = feature;
    this.selectedGraphic = graphic;

    // return this.featureEmit.emit("this.map.selectedFeature", feature); //notify

  }

  centerAndZoomToGraphic(graphic, levelOrFactor?) {
    var the_level = (levelOrFactor) ? levelOrFactor : 20000;

    var centerPoint = graphic.geometry.extent.center;

    this.mapView.popup.open({content: this.getContentForPopup(graphic), location: centerPoint});
    return this.mapView.goTo({center: centerPoint, scale: the_level});
  };

  findOnMap(in_allotment) {

    console.log("finding on map " + in_allotment);

    console.log("first search for related parcels")
    // $scope.findRelatedPermits(in_allotment);

    this.queryMatchParcel(in_allotment, (features) => {
      if (features.length == 0) {
        console.log("parcel not found: " + in_allotment);
      } else {
        //found the parcel but it doesn't include geometry so we need to get it
        this.querySelectParcel(null, features[0].attributes.OBJECTID, (geo_features) => {
          this.addParcelToMap(geo_features[0]);
          // this.centerAndZoomToGraphic(features[0]);
          this.centerAndZoomToGraphic(geo_features[0]);
          //$scope.findRelatedPermits(features[0].attributes.PARCELID);
        });

      }
    });
  }


  getContentForPopup(feature) {
    let att = feature.attributes;

    this.mapView.popup.title = `${att.Alias} ID: ${att.PARCELID}`;

    let html = "";

    if (att.Address && att.Address.trim() != "") {
      html += "<b>Address: </b>" + att.Address + "<br/>";
    }

    if (att.OWNERSHIPS) {
      html += "<b>Ownership: </b>" + att.OWNERSHIPS + "<br/>";
    }

    if (att.ACRES_GIS) {
      html += "<b>Acres (GIS): </b>" + att.ACRES_GIS;
    }

    return html;
  }


  clearAll() {
    this.clearGraphics();
    this.mapView.popup.close();
    this.parcelLayer.removeAll();
    this.selectedFeature = null;
    this.showingParcel = false;
    this.mapSearchResults.Message = "";
  }

  clickedMap(e) {
    this.querySelectParcel(e.mapPoint, null, (features) => {
      if (features.length == 0) {
        this.clearAll();
        alert('No parcel found at that location.');
        return;
      } else {
        this.selectedFeature = features[0];
        this.addParcelToMap(features[0]);

        // this.getContentForPopup(features[0]);
        this.mapView.popup.open({content: this.getContentForPopup(features[0])});
        // console.log(this.selectedFeature.attributes);
        // console.dir(this.selectedFeature.attributes);
        // let objectid = this.selectedFeature.attributes.OBJECTID;
        // let allotment = this.selectedFeature.attributes.PARCELID;
        this.parcel.emit(this.selectedFeature.attributes);
      }
    });
  }

}

