var my_preferences = ['$scope', 'UserService', 'ConvertStatus', '$location',
    function ($scope, UserService, ConvertStatus, $location) {

        $scope.savePreferences = function () {

            $scope.User = {
                Id: $scope.Profile.Id,
                Username: $scope.Profile.Username,
                Description: $scope.Profile.Description,
                DepartmentId: $scope.Profile.DepartmentId,
                Fullname: $scope.Profile.Fullname,
                Email: $scope.Profile.Email,
                Cell: $scope.Profile.Cell
            }
//            console.log("$scope.preferencesUpdate is next...");
//            console.dir($scope.preferencesUpdate);

            $scope.savePreferencesResults = [];
//            console.log("$scope.savePreferencesResults = " + $scope.savePreferencesResults);

            var userresult = UserService.saveUserInfo($scope.User, $scope);

            userresult.$promise.then(function () {
                var prefresult = UserService.saveUserPreference("LandingPage", $scope.Profile.LandingPage);
                prefresult.$promise.then(function () {
                    $scope.Message = "Success";
                }, function (data) {
                    console.dir(data);
                    $scope.Message = "Failure";
                });
            }, function (data) {
                console.dir(data);
                $scope.Message = "Failed to save user.";
            });

        };

        $scope.cancel = function () {
          $location.url("dashboard");
        };

    }
];

export default my_preferences;
