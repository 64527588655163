/*
This was for the old-style tabs which we don't use anymore?
common_module.directive('showtab',
        function () {
            return {
                link: function (scope, element, attrs) {
                    element.click(function (e) {
                        e.preventDefault();
                        $(element).tab('show');
                    });
                }
            };
        });
*/

/*
No longer necessary with the angular update
common_module.directive('ngBlur', function () {
        return function( scope, elem, attrs ) {
            elem.bind('blur', function() {
                scope.$apply(attrs.ngBlur);
            });
        };
    });
*/

//Links to permissions for user defined in main.js
//  available project roles = admin, owner (same as admin; legacy), editor, viewer
// import common_module from "../common-module";
declare let Exception: any;

//NOTE: to use this directive, your controller must have the $rootScope defined.
export const projectRoleDirective = function(){
    return {
        link: function(scope,element, attrs)
        {
            if(!attrs.projectRole)
            {
                throw new Exception("Configuration error: project-role attribute must specify a target role name: admin, editor, viewer.");
            }

            var role = attrs.projectRole.trim();

            if(role != 'owner' && role != 'editor' && role != 'viewer' && role != 'admin')
                throw new Exception("Configuration error: project-role attribute must be: admin, editor, viewer.");


            function applyRoleToElement()
            {
                //this causes to default to true before the project loads...
                //if(!scope.project || !scope.project.$resolved)
                //    return; //do nothing until the project arrives

                var show = false; //default to NOT show.
                //console.log("** Checking role " + role);

                if( (role == 'owner' || role == 'admin') && scope.Profile.canAdminProject(scope.project))
                {
                    //console.log(" --> SETTING can admin")
                    show = true;
                }

                if(role == 'editor' && scope.Profile.canEditProject(scope.project) )
                {
                    //console.log(" --> SETTING can edit")
                    show = true;
                }

                if (role == 'viewer' && scope.Profile.canViewProject(scope.project))
                {
                    //console.log(" --> SETTING can view")
                    show = true;
                }

                //console.log("** final check for " + role + " is " + show)

                if(show){
                    element.show();
                    //console.log("should be showing for " + role)
                }
                else{
                    element.hide();
                    //console.log("should be hiding "+ role)
                }
            }

            if(!scope.project || !scope.project.$resolved)
            {
                //console.log("setting watch")
                var projectWatch = scope.$watch('project',function(){
                    //console.log("watch fired")
                    if(scope.project != null)
                    {
                        //console.log("unsetting watch")
                        projectWatch(); //clear watch
                        scope.project.$promise.then(function(){
                            //console.log("applying again")
                            applyRoleToElement();
                        });
                    }
                });
            }
            //console.log("applying ")
            applyRoleToElement();
        }
    };
};


/*
this isn't used anywhere

//Hides or Shows any element with: has-role="someRole"
//  where "someRole" is in the current user's roles list
common_module.directive('hasRole', function($rootScope){
    return{
        link: function(scope, element, attrs) {
            //console.log("checking permission");

            if(!attrs.hasRole)
                return;

            var value = attrs.hasRole.trim();

            var notPermissionFlag = value[0] === '!';

            if(notPermissionFlag)
                value.slice(1).trim();

            var hasPermission = $rootScope.Profile.isInGroup(value);

            //console.log(value + " ? " + hasPermission);

            if(hasPermission || (notPermissionFlag && !hasPermission) )
            {
                console.log("hasPermission("+value+") = true");
                element.show();
            }
            else
            {
                console.log("hasPermission("+value+") = false");
                element.hide();
            }
        }
    };
});
*/
