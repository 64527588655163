//modal to edit location

import { EsriMapComponent } from "src/app/map/esri-map/esri-map.component";


var edit_location_modal = ['$scope', '$uibModal', '$uibModalInstance', 'GridService', 'CommonService', 'Upload', 'ProjectService', '$rootScope', 'PermitService',

  function ($scope, $uibModal, $uibModalInstance, GridService, CommonService, $upload, ProjectService, $rootScope, PermitService) {

    console.log("Inside edit-location-modal.ts...");
    
    $scope.mode = "edit";

    $scope.row = $scope.ProjectLocation_modal;

    if ($scope.mode === 'edit') {
      console.log("Location Id = " + $scope.$parent.row.Id);

      $scope.permitLocationsGrid.columnDefs.forEach(function (field) {
        $scope.onHeaderEditingStopped(field);
      });
    }

    $scope.onHeaderEditingStopped = function (field) {
      //build event to send for validation
      console.log("onHeaderEditingStopped: " + field.DbColumnName);
      var event = {
        colDef: field,
        node: {data: $scope.row},
        value: $scope.row[field.DbColumnName],
        type: 'onHeaderEditingStopped'
      };

      if (GridService.validateCell(event)) {
        GridService.fireRule("OnChange", event); //only fires when valid change is made
      }

      //update our collection of header errors if any were returned
      $scope.headerFieldErrors = [];
      if ($scope.row.rowHasError) {
        $scope.row.validationErrors.forEach(function (error) {
          if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
            $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
          } else {
            $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
          }
        });
      }

    };

    $scope.updateParentItem = function(permitId) {

      $scope.PermitLocations = PermitService.getPermitLocations(permitId);
      $scope.PermitLocations.$promise.then(function() {
        console.log("$scope.PermitLocations in selectPermit is next...");
        console.dir($scope.PermitLocations);
        $scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);
        $scope.permitLocationsGrid.selectedItem = null;
        $scope.$apply();
      });
    }

    $scope.save = function (inId) {
      $uibModalInstance.close(
      // on close this information is sent to the esri-map component
      {
        'Update': true,
        'ProjectId': $scope.project.Id,
        'Location': $scope.row,
      });

      console.log("$rootScope is next...");
      console.dir($rootScope);
      if ($scope.ProjectLocation_modal)
      {
        var esriMapComponent = new EsriMapComponent();
        var anObj = {
          'Update': true,
          'ProjectId': $scope.project.Id,
          'Location': $scope.row,
        };
        esriMapComponent.changeFromChild(anObj);
      }

      //CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId);
      $scope.saveResult = CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.row);

      $scope.saveResult.$promise.then(function () {
        $scope.updateParentItem($scope.permitsGrid.selectedItem.Id)
      });
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

  }

];


export default edit_location_modal;
