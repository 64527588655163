//map locations for multiple activity import
var modal_map_locations = ['$scope', '$uibModal','$uibModalInstance',

    function ($scope, $uibModal, $uibModalInstance) {

        //this is a workaround for angularjs' either too loose matching or too strict...
        $scope.locequals = function (actual, expected) { return actual == expected; };

        $scope.save = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
];

export default modal_map_locations;
