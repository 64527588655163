import {EHS_PROJECTID} from "../../../../config";

var modal_violation_edit_filetype = ['$scope', '$uibModalInstance', 'ProjectService',
    function ($scope, $uibModalInstance, ProjectService){

		$scope.header_message = "Edit File Type";

		$scope.save = function(){
			var saved_file = ProjectService.updateFile(EHS_PROJECTID, $scope.file_modal);
			saved_file.$promise.then(function(){
				$uibModalInstance.close($scope.file_modal);
			});
		};

		$scope.cancel = function(){
			$uibModalInstance.dismiss();
		};
	}
];

export default modal_violation_edit_filetype;
