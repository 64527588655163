import * as angular from 'angular';

var admin_edit_dataset_workflow = ['$scope', '$uibModal', '$timeout', '$routeParams', 'DatasetService', 'CommonService','ProjectService','AdminService',
    function ($scope, $uibModal, $timeout, $routeParams, DatasetService, CommonService, ProjectService, AdminService) {

        $scope.OnTab = "Workflow";
        $scope.SaveMessage = null;
        $scope.dataset = DatasetService.getDataset($routeParams.Id);

        $scope.dataset.$promise.then(function () {
            if(typeof $scope.dataset.Config.Notification === 'undefined'){
                $scope.dataset.Config.Notification = {};
            }
        });


        $scope.saveConfig = function () {
            $scope.SaveMessage = "Saving...";
            $scope.ErrorMessage = "";

            //validation
            if($scope.dataset.Config.Notification.ChkEmailOnFieldChange && !$scope.dataset.Config.Notification.EmailOnFieldChange){
                $scope.ErrorMessage = "Choose a field to watch for changes or uncheck Email on Field Change";
                $scope.SaveMessage = "";
                return;
            }

            if($scope.dataset.Config.Notification.ChkEmailToAddress && !$scope.dataset.Config.Notification.EmailToAddress){
                $scope.ErrorMessage = "Enter an email address to notify or uncheck Email to Address";
                $scope.SaveMessage = "";
                return;
            }

            if($scope.dataset.Config.Notification.ChkEmailToField && !$scope.dataset.Config.Notification.EmailToField){
                $scope.ErrorMessage = "Choose a field with an email address to notify or uncheck Field to Email";
                $scope.SaveMessage = "";
                return;
            }

            console.dir($scope.dataset.Config);

            $scope.dataset.Config = angular.toJson($scope.dataset.Config);

            var promise = DatasetService.saveDataset($scope.dataset );

            promise.$promise.then(function () {
                $scope.dataset.Config = angular.fromJson(promise.Config);
                $scope.SaveMessage = "Save successful.";
            }, function(error) {
                console.log("something went wrong:");
                console.dir(promise);
                $scope.SaveMessage = "";
                $scope.ErrorMessage = "Error. Try again or contact your administrator"
            });
        };

    }

];

export default admin_edit_dataset_workflow;
