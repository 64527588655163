//modal to add/edit permit contact
import * as angular from 'angular';


var modal_edit_permitcontact = ['$scope', '$uibModal','$uibModalInstance','PermitService','ViolationService',

    function ($scope, $uibModal, $uibModalInstance, PermitService, ViolationService) {

        $scope.mode = "edit";

        if (!$scope.contact_modal.PersonId) {
            $scope.mode = "new";
            $scope.contact_modal.ContactType = "Applicant"; //set default
        }

        $scope.save = function () {

            var new_contact = "";

            var save_contact = angular.copy($scope.contact_modal);
            delete save_contact.Person;

            if(!$scope.contact_modal.PersonId){
                alert("Please select a contact for this permit");
                return;
            }

            if($scope.violations != null)
            {
                new_contact = ViolationService.saveViolationContact(save_contact);
            }
            else{
                new_contact = PermitService.savePermitContact(save_contact);
            }


            // @ts-ignore
            new_contact.$promise.then(function () {
                console.log("done and success!");
                $uibModalInstance.close(new_contact);
            });

        };

        $scope.personEntry = "";
        $scope.personMatches = [];

        $scope.Selected = {Person : []};

        $scope.personEntryUpdate = function () {
            console.log("searching... " + $scope.Persons.length + " records for " + $scope.personEntry);
            $scope.personMatches = [];
            $scope.Selected.Person.length = 0;
            $scope.person_modal = {};
            var regex = RegExp($scope.personEntry, "gi"); //global + case insensitive
            var entryLength = $scope.personEntry.length;
            if(entryLength < 2)
                return;

            $scope.Persons.forEach(function (person) {
                if (person.Label == null || person.Label == "") {
                    return;
                }

                if (regex.test(person.Label)) {
                    $scope.personMatches.push(person);
                }
            });
            console.log("found " + $scope.personMatches.length + " matches");
        };

        $scope.selectPerson = function(){
            if(!$scope.Selected)
                return;
            var person = angular.fromJson($scope.Selected.Person[0]); //this is the trick
            $scope.contact_modal.PersonId = person.Id;
            $scope.contact_modal.Person = person;
        }


        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };


        $scope.addPersonModal = function(){
            //$scope.person_modal = $scope.contact_modal;
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/contacts/templates/add-person-modal.html',
                controller: 'AddPersonModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_person) {

                saved_person.Label = (saved_person.Organization) ? saved_person.Organization : saved_person.FullName;
                if (saved_person.Label == null || saved_person.Label == "")
                    saved_person.Label = saved_person.FirstName + " " + saved_person.LastName;

                $scope.Persons.push(saved_person);
                $scope.contact_modal.PersonId = saved_person.Id;
                //$(function () { $("#persons-select").select2(); });
            });
        }


    }
];

export default modal_edit_permitcontact;
