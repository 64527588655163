//modal to add/edit permit event
import * as angular from 'angular';
import * as moment from 'moment';
import {PERMIT_PROJECTID} from "../../../../config";
import {getNextBusinessDay} from "../../../../core/common/common-functions";
import {modalFiles_setupControllerForFileChooserModal} from "../../../../core/common/components/file/modal-files";

var modal_edit_permitevent = ['$rootScope','$scope', '$uibModal','$uibModalInstance','GridService','Upload','PermitService',

    function ($rootScope, $scope, $uibModal, $uibModalInstance, GridService, $upload, PermitService) {


        console.log("Inside add-permit-activity-modal.ts");
        $scope.permit = $scope.row;
            // Create a LOCAL scope variable of ROW that will go away when this scope goes away...
        $scope.row = $scope.activity_modal;
            // modes we send notifications for
        $scope.modes_notifications = ['new_inspection','new_route','edit_route'];
            // Review Item Types that do not have any Contacts
        $scope.reviewTypes_noNotifications = ['WRP','County','TSRC','LPPC Hearing','BOT Resolution'];

        $scope.permitEventsGrid.isReadyRefresh = true;

        $scope.Results = {
            SuccessMessage: null,
            FailureMessage: null,
            DoneSaving: false,
        };

            //intent can be set from the caller... otherwise the mode is based on the incoming activity_modal id
        if ($scope.intent) {
            $scope.mode = $scope.intent;
        } else {
            $scope.mode = ($scope.row.Id) ? "edit" : "new";
        }

        if (!$scope.row.Id) {
                //today
            $scope.row.EventDate = moment().format('L');
                //next business day - note: we always do this now
            $scope.row.RequestDate = getNextBusinessDay('L');
        }
        else {
            if(!$scope.row.ResponseDate)
                $scope.row.ResponseDate = moment().format('L');

            if(!$scope.row.Reviewer)
                $scope.row.Reviewer = $scope.Profile.Fullname;
        }

            //this is ugly but required - change labels for the forms
        if ($scope.mode == 'new_inspection') {
            $scope.permitEventsGrid.columnDefs.forEach(function (coldef) {
                if (coldef.DbColumnName == 'Reference')
                    coldef.Label = "Inspection Description";

                if (coldef.DbColumnName == 'RequestDate')
                    coldef.Label = "Date Inspection Desired";
            });
        } else {
            $scope.permitEventsGrid.columnDefs.forEach(function (coldef) {
                if (coldef.DbColumnName == 'Reference')
                    coldef.Label = "Reference";

                if (coldef.DbColumnName == 'RequestDate')
                    coldef.Label = "Date Requested";
            });

        }

        if (!$scope.row.Files)
            $scope.row.Files = [];

            //reload the item type dropdown based on the EventType value ("Inspection", "Review", etc.)
        $scope.refreshItemTypeLookup = function(){
            $scope.permitEventsGrid.isReadyRefresh = false;

                // Leaving this in, so that we can see what the cols are, if necessary.
            //var cols = $scope.permitEventsGrid.columnApi.getAllColumns();
            var col = $scope.permitEventsGrid.columnApi.getColumn('ItemType');

            col.colDef.PossibleValues.length = 0;
            var masterPV = angular.fromJson(col.colDef.cdmsField.Field.PossibleValues);

                //spin through the select-group (master) possible values and select only the ones from the group that matches EventType choice
            masterPV.forEach(function(item){
                //if($scope.activity_modal.EventType == item.Group){
                if (($scope.activity_modal.EventType) && ($scope.activity_modal.EventType == item.Group)){
                    col.colDef.PossibleValues.push(item);
                }
            })

                //have to use settimeout here in order for the ng-if to be triggered on the directive
            setTimeout(function(){
                $scope.permitEventsGrid.isReadyRefresh = true;
                $scope.$apply();
            },50)

        }

            //initially set our ItemType based on our EventType
        $scope.refreshItemTypeLookup();

            //set up for attaching files
        //modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.PermitFiles);
        $scope.typeOfFilesToCheck = "PermitFiles";
        modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.typeOfFilesToCheck);

        $scope.save = function () {
            console.log("Inside $scope.save...");

                //$scope.row.ReviewerContact is getting set somehow, but I want to use ReviewersContact, even if
                // there is only one Reviewer (or none), therefore set this to undefined.
            if($scope.row.ReviewerContact) {
                $scope.row.ReviewerContact = undefined;
            }

            if($scope.row.Result == "Cancelled"){
                if (!confirm("Are you sure you want to cancel this request? \nA notification will be sent to the recipient.")){
                    return;
                }
            }

            // if($scope.mode == 'new_inspection' && !$scope.row.PreferredTime){
            //     alert("Error: Preferred Time is required; please select a Preferred Time");
            //     return;
            //
            // }

            $scope.Results.DoneSaving = false;
            $scope.Results.IsSaving = true;

            $scope.row.ByUser = $scope.Profile.Id;

            var target = '/api/v1/permit/uploadfile';

            var data = {
                    //ProjectId: PERMIT_PROJECTID,
                ProjectId: $scope.project.Id,
                SubprojectId: $scope.permit.Id,
                ItemId: $scope.row.Id
            };

            if (Array.isArray($scope.row.Files)) {
                if ($scope.row.Files.length == 0)
                    delete $scope.row.Files;
                else
                    $scope.row.Files = angular.toJson($scope.row.Files);
            }

            if($scope.PermitRoutes){
                    // We are going to use the same structure for selecting Contacts for both
                    // Reviews and Inspections, therefore we don't need to check which is which
                    // for the Contacts anymore.

                //if($scope.activity_modal.EventType == "Review"){
                        //checkboxes to select reviewers
                        //drop any recipients that are 'false' since they were unchecked
                    if($scope.row.ReviewersContact) {
                        Object.keys($scope.row.ReviewersContact).forEach(function (key) {
                            if ($scope.row.ReviewersContact[key] === false)
                                delete $scope.row.ReviewersContact[key];
                        });
                    }
                //} //else {
                        //select dropdown for inspectors/others - set the assignee userid
                    //$scope.PermitRoutes.forEach(function(route){
                        //if(route.Email == $scope.row.ReviewerContact){
                            //$scope.row.AssigneeUserId = route.AssigneeUserId;
                        //}
                    //})
                //}
            }

            if($scope.modes_notifications.contains($scope.mode) 
                && !$scope.needsNotification()
                && !$scope.reviewTypes_noNotifications.contains($scope.row.ItemType)) {
                if(!confirm("You did not select any Contacts to notify.\n\n"
                            + "To continue without sending a notification, click 'OK'.\n"
                            + "To go back and select a Contact to notify, click 'Cancel'."))
                {

                    $scope.Results.FailureMessage = "Select one or more Contacts, and then click 'Save'";
                    $scope.Results.DoneSaving = false;
                    $scope.Results.IsSaving = false;
                    return;
                } else {
                    $scope.Results.FailureMessage = null;
                    $scope.Results.DoneSaving = false;
                    $scope.Results.IsSaving = true;
                }
            } else {
                $scope.Results.FailureMessage = null;
                $scope.Results.DoneSaving = false;
                $scope.Results.IsSaving = true;
            }

            var to_save = angular.copy($scope.row);

            if($scope.row.FilesToInclude){
                var new_files = [];
                to_save.SharedFiles = [];
                $scope.row.FilesToInclude.forEach(function(file){
                    //console.dir(file);
                    file = angular.fromJson(file);
                    delete file.User;
                    new_files.push(file);
                    to_save.SharedFiles.push({"Name":file.Name, "Link":file.Link});
                })
                to_save.FilesToInclude = new_files;
                to_save.SharedFiles = $scope.row.SharedFiles = angular.toJson(to_save.SharedFiles);
            }else{
                to_save.FilesToInclude = [];
            }

                //if this is a new event, save it first to get the ID
            if (!$scope.row.Id) {

                var new_event = PermitService.savePermitEvent(to_save);

                new_event.$promise.then(function () {
                    console.log("done and success saving event!");
                    $scope.row.Id = data.ItemId = new_event.Id;

                        //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
                    $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope, $scope);
                });

            } else {
                    //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
                $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope, $scope);
            }
        };

            //callback that is called from modalFile to do the actual file removal (varies by module)
        $scope.modalFile_doRemoveFile = function (file_to_remove, saveRow) {
            //console.dir(file_to_remove);
            return PermitService.deletePermitFile(PERMIT_PROJECTID, $scope.permit.Id, saveRow.Id, file_to_remove);
        };

            //call back from save above once the files are done processing and we're ready to save the item
        $scope.modalFile_saveParentItem = function (saveRow) {

                //save again to update with the files we uploaded
            $scope.saved_event = PermitService.savePermitEvent(saveRow);

            $scope.saved_event.$promise.then(function () {

                $scope.Results.DoneSaving = true;

                console.log("done and success updating the files");

                if($scope.modes_notifications.contains($scope.mode) && !$scope.saved_event.ResponseDate && $scope.needsNotification())//$scope.row.ReviewersContact)
                    $scope.Results.SuccessMessage = "Saved and notifications sent.";
                else
                    $scope.Results.SuccessMessage = "Saved.";

                if($scope.saved_event.EventType=='Review'){
                    console.log(" -- updating route indicator: " + ["Route_" + $scope.saved_event.ItemType]);
                    //now update the permit routing indicator
                    var save_permit = angular.copy($scope.permit);
                    save_permit.Zoning = angular.toJson(save_permit.Zoning);
                    console.dir($scope.saved_event);
                    var route_indicator = ($scope.saved_event.ResponseDate) ? "*" : "+";
                    console.log(" indicator: "+route_indicator);
                    save_permit["Route_" + $scope.saved_event.ItemType] = route_indicator;
                    save_permit.ReviewsRequired = angular.toJson(save_permit.ReviewsRequired);

                    var permit_promise = PermitService.savePermit(save_permit);
                }

                // $scope.close();

                // if($scope.Results.SuccessMessage.includes("notifications sent")) {
                //     window.open("permits/notifications", "_blank");
                // }

            }, function (data) {
                console.error("failure!");
                console.dir(data);
                $scope.Results.FailureMessage = "There was a problem saving or sending notifications.";
                $scope.Results.DoneSaving = false;
            });

        };

        $scope.close = function () {
            $uibModalInstance.close($scope.saved_event);
        };


            // Remove "EventType" field from Reviews, since the Event Type for
            // Reviews will always be "Review". -CD 03/28/2024

        //var NEW_REVIEW_FIELDS = ["EventDate", "EventType", "ItemType", "Comments"];
        //var EDIT_REVIEW_FIELDS = ["EventDate", "EventType", "ItemType", "ResponseDate","Result","Reference","Files","Comments"];
        var NEW_REVIEW_FIELDS = ["EventDate", "ItemType", "Comments"];
        var EDIT_REVIEW_FIELDS = ["EventDate", "ItemType", "ResponseDate","Result","Reference","Files","Comments"];
        var NEW_INSPECTION_FIELDS = ["Reference","ItemType","RequestDate","Comments"];

            //a filter to determine which fields to show
        $scope.doShowField = function (field) {

          // @ts-ignore
            if ($scope.mode == "new_route" && NEW_REVIEW_FIELDS.contains(field.DbColumnName))
                return true;

            // @ts-ignore
            if ($scope.mode == "edit_route" && EDIT_REVIEW_FIELDS.contains(field.DbColumnName))
                return true

            if ($scope.mode == "edit_route" || $scope.mode == "new_route")
                return false;

            // @ts-ignore
            if ($scope.mode == "new_inspection" && NEW_INSPECTION_FIELDS.contains(field.DbColumnName))
                return true;

            if ($scope.mode == "new_inspection")
                return false;

            return field.hasOwnProperty('DbColumnName');
        }

            //fire validation for all columns when we load (if we are editing)
        if ($scope.mode === 'edit') {
            $scope.permitEventsGrid.columnDefs.forEach(function (field) {
                $scope.onHeaderEditingStopped(field);
            });
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $scope.loadRecipientsFromRoute = function () {
            if ( ($scope.activity_modal.EventType == 'Inspection' || $scope.activity_modal.EventType == 'Review')
                && ($scope.mode == 'new' || $scope.mode == 'new_route' || $scope.mode == 'new_inspection') )  {

                    //note: we use ReviewerContact for single, ReviewersContact for multiple
                    // 03/25/2024 - Updated to use ReviewersContact for zero, one, or multiple recipients

                //if($scope.activity_modal.EventType == 'Review'){
                    $scope.row.ReviewersContact = {};
                //} // else {
                //     $scope.row.ReviewerContact;
                // }

                //console.log("getting routes for: " + $scope.activity_modal.ItemType);

                $scope.PermitRoutes = PermitService.getPermitRoutesByItemType($scope.activity_modal.EventType, $scope.activity_modal.ItemType);
                $scope.PermitRoutes.$promise.then(function () {

                        //select the first one
                        // 03/25/2024 - Updated to use Checkboxes for all types (Review and Inspection)

                    $scope.PermitRoutes.forEach(function (route) {

                        //if($scope.activity_modal.EventType == 'Review'){
                            //select the first one (checkbox version)
                            if (route.Rank == 0)
                                $scope.row.ReviewersContact[route.Email] = true;
                        //} //else {
                        //     //select the first one (select version)
                        //     if (route.Rank == 0)
                        //         $scope.row.ReviewerContact = route.Email;
                        // }
                    });
                });
            }
        }

        if (typeof $scope.row.ItemType === 'string') {
            $scope.loadRecipientsFromRoute();
        }

        $scope.getFileLabel = function(file){
            if(file)
                return file.Name + ((file.Description) ? " ("+file.Description+")" : "");
            else return "";
        }


            //Setup an event listener that fires from list-permits.js every time a header field is changed.
            //We listen for ItemType changing.
        $rootScope.$on('headerEditingStopped', function (event, field) {
            
            if (field.DbColumnName == 'ItemType') {
                $scope.loadRecipientsFromRoute();
            }

                //update ItemType possible values/controlType based on EventType
            if (field.DbColumnName == 'EventType'){
                $scope.refreshItemTypeLookup();

                if(($scope.mode == "new" || $scope.mode == "edit") && ($scope.row.EventType == "Review" || $scope.row.EventType == "Inspection")) {                    
                    $scope.mode = $scope.mode + "_" + $scope.row.EventType.toLowerCase().replace("review", "route");
                }
            }
        });

            // Check if Activity needs a Notification sent. Activities requiring notifications are:
            //  --Inspection Requests
            //      **All Item Types
            //  --Review Requests
            //      **CRPP
            //      **Bldg Code
            //      **Env. Health
            //      **Pub. Works
            //      **TERO
            //      **Fire Dept.
            //      **Testing
            //      **Multiple-Personnel
        $scope.needsNotification = function() {
            let returnValue = false;

                // Check for key/value pairs where key = "email@address" and
                // value = true. If at least one is found, the request will need
                // a notification sent.
            $scope.PermitRoutes.forEach(function(route){
                 if($scope.row.hasOwnProperty("ReviewersContact")) {
                     if($scope.row["ReviewersContact"][route.Email]){
                         returnValue = true;
                     }
                 }
             })

             return returnValue;
         };
}];

export default modal_edit_permitevent;
