// defines the permit module
import * as angular from 'angular';

// require([
//     //controllers
import list_permits from "./components/list/list-permits";
import modal_violation_edit_filetype from "./components/list/modal-violation-edit-file";
import list_violations from "./components/ehs-violations/list-violations";
import modal_edit_violationcode from "./components/list/add-violation-code-modal";
import modal_edit_permitcontact from "./components/list/add-contact-modal";
import permit_dashboard from "./components/dashboard/dashboard";
import modal_violation_new_file from "./components/list/modal-violation-new-file";
import modal_permit_edit_filetype from "./components/list/modal-permit-edit-file";
import request_inspection from "./components/list/request-inspection";
import requests_list from "./components/requests/list";
import requests_modal from "./components/requests/request-modal";
import permit_contacts from "./components/contacts/manage-contacts";
import modal_edit_permitevent from "./components/list/add-permit-activity-modal";
import modal_edit_permitparcel from "./components/list/add-parcel-modal";
import modal_edit_violationevent from "./components/list/add-violation-activity-modal";
import modal_permit_new_file from "./components/list/modal-permit-new-file";
import modal_add_permitperson from "./components/contacts/add-person-modal";
//import modal_edit_location from "../../core/projects/components/project-detail/modal-edit-location";
import modal_edit_location from "./components/list/add-location-modal"; // For the initial location picker.
import edit_location_modal from "./components/list/edit-location-modal"; // For editing the properties of a location.
//
//     //modals

//
//     //services


//
//     //map directive
import permit_map from "./components/map/permit-map";
import modal_add_fee from "./components/taskboard/add-fee-modal";
import permit_routes from "./components/taskboard/routes";
import permit_notifications from "./components/notifications/notifications";
import {serviceUrl} from "../../config";
import permit_services from "./permit-service";
import project_service from "../../core/projects/services/project-service";
import violation_services from "./violation-service";
import {downgradeComponent} from "@angular/upgrade/static";
import {PermitMapComponent} from "../../../app/map/permit-map/permit-map.component";
import {EsriMapComponent} from "../../../app/map/esri-map/esri-map.component";
import { map } from 'jquery';

// ], function () {
const permit_module = angular.module('PermitModule', ['ui.bootstrap', 'ngResource']);
permit_module.controller('PermitListController', list_permits);
permit_module.controller('PermitActivityModalController', modal_edit_permitevent);
permit_module.controller('ViolationActivityModalController', modal_edit_violationevent);
permit_module.controller('ContactModalController', modal_edit_permitcontact);
permit_module.controller('LocationModalController', modal_edit_location);
permit_module.controller('EditLocationModalController', edit_location_modal);
permit_module.controller('ParcelModalController', modal_edit_permitparcel);
permit_module.controller('PermitFileModalController', modal_permit_new_file);
permit_module.controller('ViolationFileModalController', modal_violation_new_file);
permit_module.controller('AddPersonModalController', modal_add_permitperson);
permit_module.controller('PermitRoutesController', permit_routes);
permit_module.controller('AddFeeModalController', modal_add_fee);
permit_module.controller('PermitDashboardController', permit_dashboard);
permit_module.controller('RequestInspectionController', request_inspection);
permit_module.controller('PermitNotificationsController', permit_notifications);
permit_module.controller('PermitMapController', permit_map);
permit_module.controller('PermitManageContactsController', permit_contacts);
permit_module.controller('EditPermitFileTypeModalController', modal_permit_edit_filetype);
permit_module.controller('EditViolationFileTypeModalController', modal_violation_edit_filetype);
permit_module.controller('EhsViolationsController', list_violations);
permit_module.controller('ViolationCodesModalController', modal_edit_violationcode);
permit_module.controller('PermitRequestsController', requests_list);
permit_module.controller('PermitRequestsModalController', requests_modal);
permit_module.service('PermitService', permit_services);
permit_module.service('ProjectService', project_service);
permit_module.service('ViolationService', violation_services);
permit_module.directive('permitMap', downgradeComponent({component: PermitMapComponent}));
permit_module.directive('esriMap', downgradeComponent({component: EsriMapComponent}));
permit_module.filter('personOrgName', function () {
  return function (person) {
    if (!person)
      return "";

    return (person.Organization) ? person.Organization : person.FullName;
  }
});

permit_module.filter('sce', ['$sce', function ($sce) {
  return function (html) {
    return $sce.trustAsHtml(html);
  };
}]);

permit_module.factory('AllPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/allpermits', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

permit_module.factory('GetGroupPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetGroupPermits', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('RoutingPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/routingpermits', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

permit_module.factory('InspectionPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/inspectionpermits', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

permit_module.factory('AllParcels', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/allparcels', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

permit_module.factory('GetPermitContacts', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitContacts', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('GetPermitParcels', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitParcels', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('GetPermitEvents', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitEvents', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('GetPermitEventsForMe', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitEventsForMe', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

permit_module.factory('GetPermitEventsByItemType', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitEventsByItemType', {}, {
        save: { method: 'POST', isArray: true }
    });
}]);

permit_module.factory('GetRelatedParcels', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetRelatedParcels', {}, {
        query: { method: 'GET', params: { ParcelId: 'ParcelId'}, isArray: true }
    });
}]);

permit_module.factory('GetPermitFiles', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitFiles', {}, {
        query: { method: 'GET', params: { ProjectId: 'ProjectId', PermitId: 'PermitId'}, isArray: true }
    });
}]);

permit_module.factory('GetAllPersons', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/person/GetAllPersons', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('SavePermit', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/savepermit');
}]);

permit_module.factory('SavePerson', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/person/saveperson');
}]);

permit_module.factory('SavePermitContact', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/savepermitcontact');
}]);

permit_module.factory('SavePermitLocation', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/savepermitlocation');
}]);

permit_module.factory('RemovePermitContact', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/RemovePermitContact');
}]);

permit_module.factory('RemovePermitLocation', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/RemovePermitLocation');
}]);

permit_module.factory('SavePermitParcel', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/savepermitparcel');
}]);

permit_module.factory('RemovePermitParcel', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/RemovePermitParcel');
}]);

permit_module.factory('SavePermitEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/SavePermitEvent');
}]);

permit_module.factory('DeletePermitFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/DeletePermitFile');
}]);

permit_module.factory('DeletePerson', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/person/DeletePerson');
}]);

permit_module.factory('GetOutstandingRequests', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetOutstandingRequests', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetExpiringPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetExpiringPermits', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetPermitStatistics', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitStatistics', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetMyPermitStatistics', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetMyPermitStatistics', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetPermitByPermitNumber', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitByPermitNumber', {}, {
        query: { method: 'GET', params: { }, isArray: false }
    });
}]);

permit_module.factory('GetPublicHearingPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPublicHearingPermits', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetNotifications', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/syslog/GetNotificationsByModule', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetPermitRoutes', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitRoutes', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetPermitTypes', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitTypes', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('DownloadInspectionTemplate', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/DownloadInspectionDocument');
}]);

permit_module.factory('GetViolations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/allviolations', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('SaveViolation', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/saveviolation');
}]);

permit_module.factory('GetViolationContacts', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/GetViolationContacts', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('SaveViolationContact', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/saveviolationcontact');
}]);


permit_module.factory('GetViolationParcels', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/GetViolationParcels', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('GetViolationRelatedParcels', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/GetRelatedParcels', {}, {
        query: { method: 'GET', params: { ParcelId: 'ParcelId'}, isArray: true }
    });
}]);

permit_module.factory('SaveViolationParcel', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/saveviolationparcel');
}]);

permit_module.factory('DeleteViolationFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/DeleteViolationFile');
}]);

permit_module.factory('GetViolationFiles', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/GetViolationFiles', {}, {
        query: { method: 'GET', params: { ProjectId: 'ProjectId', ViolationId: 'ViolationId'}, isArray: true }
    });
}]);

permit_module.factory('GetViolationEvents', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/GetViolationEvents', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('SaveViolationEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/SaveViolationEvent');
}]);

permit_module.factory('GetViolationCodes', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/GetViolationCodes', {}, {
        query: { method: 'GET', params: { Id: 'Id'}, isArray: true }
    });
}]);

permit_module.factory('SaveViolationCode', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/SaveViolationCode');
}]);

permit_module.factory('RemoveViolationContact', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/RemoveViolationContact');
}]);

permit_module.factory('RemoveViolationParcel', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/RemoveViolationParcel');
}]);

permit_module.factory('RemoveViolationActivity', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/RemoveViolationActivity');
}]);

permit_module.factory('SendNotification', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/violation/SendNotification');
}]);

permit_module.factory('GetNotifications', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/syslog/GetNotificationsByModule', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('GetPermitLocations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitLocations', {}, {
        query: { method: 'GET', params: { Id: 'Id' }, isArray: true }
    });
}]);

permit_module.factory('GetPermitDatasetLocations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetPermitDatasetLocations', {}, {
        query: { method: 'GET', params: { Id: 'Id' }, isArray: true }
    });
}]);

permit_module.factory('GetAllPermitLocations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/permit/GetAllPermitLocations', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

permit_module.factory('SearchPermits', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/search/searchpermits', {}, {
      save: {method: 'POST', isArray: true}
    });
  }]);

//
// });

export default permit_module;

