import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {getByName} from "../../appjsLegacy/core/common/common-functions";
import * as angular from 'angular';
import {DEFAULT_PROJECT_PERMISSION, WHOAMI_URL} from "../../appjsLegacy/config";
import {catchError, filter, finalize, map, share, skip, skipWhile, switchMap, take, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import { Location } from '@angular/common';
// import {nextActivity} from "../../appjsLegacy/core/datasets/datasets-functions";

interface Department {
  Description: string;
  Id: number;
  Name: string;
  Organization: Organization;
  OrganizationId: number;
}

interface Group {
  Description: string;
  Id: number;
  Name: string;
  Organization: Organization;
  OrganizationId: number;
}

interface Organization {
  Id: number;
  Name: string;
  Description: string;
}

interface UserPreferences {
  Id: number;
  Name: string;
  UserId: number;
  Value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public ready: ReplaySubject<any> = new ReplaySubject<any>();
  // add loading flag that is set to true -> whoami gets called
  public favoriteDatasets: string[];
  public Department: Department;
  public Description: string;
  public Fullname: string;
  public GUID: number;
  public Groups: Group[];
  public Id: number;
  public Inactive: number;
  public IsOrganizationAdmin: boolean;
  public IsSuperAdmin: boolean;
  public LastLogin: string;
  public Organization: Organization;
  public OrganizationId: number;
  public ProfileImageUrl: string;
  public Roles: string;
  public UserPreferences: UserPreferences[];
  public Username: string;
  public favoriteProjects: string[];
  public permission: boolean | string;
  private loadingProfile: Observable<boolean>;
  // public LandingPage: angular.rootScope.$location.url();
  private _permissions: any = {};
  public LandingPage: string;


  constructor(private http: HttpClient, private router: Router, private location: Location) {
  }

  public initialize(): Observable<boolean> {
    if (!this.loadingProfile) {

      this.loadingProfile = this.loadProfile().pipe(take(1));
      // this.loadingProfile = this.ready;
    }

    return this.loadingProfile;
  };

  private loadProfile() {
    return this.http.get(WHOAMI_URL).pipe(
      tap((profile: any) => {
        this.loadUserPreferences(profile);
      }),
      catchError(e => this.router.navigate(['login'])),
      finalize(() => this.ready.next(true)),
      map(() => true)
    )
  }

  public loadUserPreferences(profile: any) {
    Object.assign(this, profile);
        const favoriteDatasets = getByName(profile.UserPreferences, "Datasets");

        if (favoriteDatasets) {
          this.favoriteDatasets = favoriteDatasets.Value.split(",")
        } else {
          this.favoriteDatasets = [];
        }

        var favoriteProjects = getByName(profile.UserPreferences, "Projects");
        if (favoriteProjects) {
          this.favoriteProjects = favoriteProjects.Value.split(",")
        } else {
          this.favoriteProjects = [];
        }

        let landing = getByName(profile.UserPreferences, "LandingPage");
        if (landing) {
          this.LandingPage = getByName(profile.UserPreferences, "LandingPage").Value;
        }
        return profile;
  }
  public hasRole(role: string): boolean {
    return this.isInGroup(role);

    // var hasRoleInGroup = this.isInGroup(role);
    // console.log("hasRoleInGroup = " + hasRoleInGroup);
    // return hasRoleInGroup;
  };

  public canEdit(project) {
    if (!project) {
      return null;
    }

    return this.canEditProject(project);
  };

  public isProjectEditor(project: string): boolean {
    return this.canEditProject(project);
  };

  public isAdmin() {
    if (this.IsOrganizationAdmin || this.IsSuperAdmin) {
      //console.log(this.IsOrganizationAdmin);
      return true;
    }
    // return (this.IsOrganizationAdmin || this.IsSuperAdmin);
  };

  public canViewProject(project) {

    if (!project || !project.$resolved)
      return false;

    if (this.IsSuperAdmin) {
      // console.log("Super Admin can do anything!")
      return true;
    }

    if (project.OrganizationId == this.OrganizationId && this.IsOrganizationAdmin == true) {
      //console.log("Organization Admin can do anything in this Org!")
      return true;
    }

    var permission = this.getProjectPermission(project);

    //console.log(" Can the user VIEW "+project.Id+": " + permission);

    return (permission)  //if they have any existing permission for this project then they can view

  };

  public canEditProject(project) {
    // console.log(`checking canEditProject for:`)
    // console.log(project);
    // if (project) {
    //   console.log('project is here!');
    // }
    if (!project || !project.$resolved) {
      console.log("canEditProject - no project given - returning false");
      return false;
    }

    if (this.IsSuperAdmin) {
      // console.log("Super Admin can do anything!")
      return true;
    }

    if (project.OrganizationId == this.OrganizationId && this.IsOrganizationAdmin == true) {
      //console.log("Organization Admin can do anything in this Org!")
      return true;
    }

    var permission = this.getProjectPermission(project);

    console.log(" Can the user EDIT "+project.Id+": " + permission);

    return (permission && (permission == "Edit" || permission == "Admin"))

  };

  public canAdminProject(project) {

    if (!project || !project.$resolved) {
      //console.log("Project not loaded for canAdminProject - returning false");
      return false;
    }

    if (this.IsSuperAdmin) {
      //console.log("Super Admin can do anything!")
      return true;
    }

    if (project.OrganizationId == this.OrganizationId && this.IsOrganizationAdmin == true) {
      //console.log("Organization Admin can do anything in this Org!")
      return true;
    }

    var permission = this.getProjectPermission(project);

    //console.log(" Can the user ADMIN "+project.Id+": " + permission);
    return (permission && permission == "Admin");

  };

  public isProjectOwner(project): boolean {

    if (!project || !project.$resolved)
      return false;

    if (project && project.OwnerId == this.Id)
      return true;

    //if(profile.canAdminProject(project))
    //	return true;

    //console.log(profile.Id + " is not owner: " + project.OwnerId);
    return false;
  };

  public getProjectPermission(project) {
    console.log(project)
    if (!project || !project.$resolved) {
      //console.log("project is unresolved or empty")
      return false;
    }

    var key = 'p' + project.Id;
    this.permission = false;

    if (this._permissions.hasOwnProperty(key)) {
      this.permission = this._permissions[key];
      //console.log(" >> cache hit on permission key " + key + ": " + permission);
    } else {
      //console.log(" >> cache MISS on permission key " + key + ". checking permission...");
    }

    //if it was cached then return. our work is finished here!
    if (this.permission) {
      return this.permission;
    }

    //console.log('checking permission');

    //in priority order of permission application:

    //console.log("user auth?")

    //is owner of project?
    if (project.OwnerId == this.Id) {
      console.log(" User is project owner = Admin")

      this.permission = 'Admin';

      //cache and return
      this._permissions[key] = this.permission;
      return this.permission;
    }

    //user auth
    project.Permissions.forEach((perm) => {
      if (perm.PrincipalType == "User") {
        if (this.Id == perm.PrincipalId) {
          this.permission = perm.Level;
          console.log("User auth = " + this.permission);
        }
      }
    })

    //cache and return
    if (this.permission) {
      this._permissions[key] = this.permission;
      return this.permission;
    }

    //console.log("dept auth?")

    //dept auth
    project.Permissions.forEach(function (perm) {
      if (perm.PrincipalType == "Department") {
        if (this.DepartmentId == perm.PrincipalId) {
          this.permission = perm.Level;
          console.log("Department auth = " + this.permission);
        }
      }
    });

    //cache and return
    if (this.permission) {
      this._permissions[key] = this.permission;
      return this.permission;
    }


    //console.log("group auth?")

    //group auth
    project.Permissions.forEach((perm) => {
      if (perm.PrincipalType == "Group") {
        //is the user in this group?
        this.Groups.forEach((group) => {
          if (group.Id == perm.PrincipalId) {
            //check to make sure we get the highest group permission of any group
            if (!this.permission) {
              this.permission = perm.Level;
            } else if (perm.Level == "Admin") {
              this.permission = perm.Level;

            } else if (perm.Level == "Edit" && this.permission == "View") {
              this.permission = perm.Level;
            }
            console.log("group auth (" + group.Name + ") = " + this.permission);
          }
        })
      }
    })

    //cache and return
    if (this.permission) {
      this._permissions[key] = this.permission;
      return this.permission;
    }

    //console.log("dept orguser?")
    var hasOrgUserPermissionSet = false;

    //global project - orguser
    project.Permissions.forEach((perm) => {
      if (perm.PrincipalType == "OrgUser") {
        if (this.OrganizationId == project.OrganizationId && perm.Level == "View") {
          this.permission = perm.Level;
          console.log("orgUser auth = " + this.permission);
        }

        hasOrgUserPermissionSet = true; //
      }
    })

    //cache and return
    if (this.permission) {
      this._permissions[key] = this.permission;
      return this.permission;
    }

    //console.log("public auth?")

    //global project - public
    project.Permissions.forEach((perm) => {
      if (perm.PrincipalType == "Public" && perm.Level == "View") {
        this.permission = perm.Level;
        console.log("Public auth = " + this.permission);
      }
    })

    //cache and return
    if (this.permission) {
      this._permissions[key] = this.permission;
      return this.permission;
    }

    //if we're here, the last chance is if thee was no OrgUser permission set for the project and yet the system-wide default is to allow view

    if (!hasOrgUserPermissionSet && DEFAULT_PROJECT_PERMISSION.Permission.OrgUser == "View") {

      this.permission = DEFAULT_PROJECT_PERMISSION.Permission.OrgUser;
      console.log("System-wide view enabled and no orguser parameter set")
    }

    //cache and return
    if (this.permission) {
      this._permissions[key] = this.permission;
    }

    console.log(" final permission: " + this.permission)

    return this.permission;
  };

  public setLandingPage() {
    // console.log(" our location: " + window.location.pathname);
      this.LandingPage = window.location.pathname;

      this.location.replaceState("/mypreferences");
  };

  public isDatasetFavorite(datasetId): boolean {
    return (this.favoriteDatasets.indexOf(datasetId + "") != -1);
  };

  public isInGroup(in_group: string): boolean {
    //console.log("Inside isInGroup...");
    if (this.IsSuperAdmin)
      return true;

    var inGroup = false;
    this.Groups.forEach(function (group) {
      if (group.Name === in_group) {
        inGroup = true;
      }
    });

    //console.log("inGroup = " + inGroup);
    return inGroup;
  }

  public isProjectFavorite(projectId): boolean {
    return (this.favoriteProjects.indexOf(projectId + "") != -1);
  };

  public toggleDatasetFavorite(dataset) {
    var dsid = dataset.Id + "";
    var index = this.favoriteDatasets.indexOf(dsid);
    if (index == -1) { //doesn't exist
      this.favoriteDatasets.push(dsid);
    } else {
      this.favoriteDatasets.splice(index, 1);
    }
  };

  public toggleProjectFavorite(project) {
    var dsid = project.Id + "";
    var index = this.favoriteProjects.indexOf(dsid);
    if (index == -1) { //doesn't exist
      this.favoriteProjects.push(dsid);
    } else {
      this.favoriteProjects.splice(index, 1);
    }
  };

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.initialize();
  }

  canActivate(route: ActivatedRouteSnapshot, State: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.initialize();
  }

}


// move configureProfile into here
