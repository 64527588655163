

// import leasing_module from "../../leasing-module";
import {
  METADATA_PROPERTY_LEASING_ANIMALTYPES,
  METADATA_PROPERTY_LEASING_COMPLIANCE_INSP_TYPES,
  METADATA_PROPERTY_LEASING_CROPOPTIONS,
  METADATA_PROPERTY_LEASING_LEASETYPES,
  METADATA_PROPERTY_LEASING_OPCITIES,
  METADATA_PROPERTY_LEASING_OPSTATES,
  METADATA_PROPERTY_LEASING_PRODUCTIONDELIVERY,
  METADATA_PROPERTY_LEASING_PRODUCTIONTYPES,
  METADATA_PROPERTY_LEASING_RESIDUETYPES,
  METADATA_PROPERTY_LEASING_SUBPRACTICES,
  METADATA_PROPERTY_LEASING_VIOLATION_TYPES,
  METADATA_PROPERTY_LEASING_WEEDS
} from "../../../../config";

export class LeaseModalScope {
// LeaseStatus = [];
// LeaseStatus[1] = "Active";
// LeaseStatus[2] = "Inactive";
// LeaseStatus[3] = "Pending";
// LeaseStatus[4] = "Withdrawn";
// LeaseStatus[5] = "Expired";
// LeaseStatus[6] = "Cancelled";
//
// let LeaseLevels = [];
// LeaseLevels[1] = "Level 1";
// LeaseLevels[2] = "Level 2";
// LeaseLevels[3] = "Level 3";
// LeaseLevels[4] = "Level 4";
  static LeaseStatus = ["", "Active", "Inactive", "Pending", "Withdrawn", "Expired", "Cancelled"];
  static LeaseLevels = ["", "Level 1", "Level 2", "Level 3", "Level 4"];
  static LeaseTypeLeaseNumber = {
  "Farming": "1",
  "Grazing": "4",
  "Residential": "2",
  "Storage": "TL-",
};



  static prepareLeaseModalScope($scope, LeasingService) {
    //console.log("Inside prepareLeaseModalScope");

    $scope.leaseLevels = this.LeaseLevels;
    $scope.leaseStatuses = this.LeaseStatus;

    $scope.operators = LeasingService.getOperators();

    $scope.operators.$promise.then(function () {
      $scope.operators.forEach(function (oper) {
        oper.SortName = (oper.Organization) ? oper.Organization : oper.FirstName + " " + oper.LastName;
      });
    });
    //console.log("===Setting leaseTypes from LeasingService===");
    $scope.leaseTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_LEASETYPES);
    
    // $scope.leaseTypes.$promise.then(function(){
    //  console.log("scope.leaseTypes is next");
    //  console.dir($scope.leaseTypes);
    // });

    $scope.productionDeliveryUnits = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_PRODUCTIONDELIVERY);

    $scope.cropOptions = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_CROPOPTIONS);

    $scope.weeds = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_WEEDS);

    $scope.residueTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_RESIDUETYPES);

    $scope.animalTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_ANIMALTYPES);

    $scope.subPractices = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_SUBPRACTICES);

    $scope.productionTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_PRODUCTIONTYPES);

    $scope.operatorStates = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_OPSTATES);

    $scope.operatorCities = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_OPCITIES);

    $scope.complianceInspectionTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_COMPLIANCE_INSP_TYPES);

    $scope.violationTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_VIOLATION_TYPES);

  };


}

/*let LeaseStatus = [];
LeaseStatus[1] = "Active";
LeaseStatus[2] = "Inactive";
LeaseStatus[3] = "Pending";
LeaseStatus[4] = "Withdrawn";
LeaseStatus[5] = "Expired";
LeaseStatus[6] = "Cancelled";

let LeaseLevels = [];
LeaseLevels[1] = "Level 1";
LeaseLevels[2] = "Level 2";
LeaseLevels[3] = "Level 3";
LeaseLevels[4] = "Level 4";


export default function prepareLeaseModalScope($scope, LeasingService) {

  $scope.leaseLevels = LeaseLevels;
  $scope.leaseStatuses = LeaseStatus;

  $scope.operators = LeasingService.getOperators();

  $scope.operators.$promise.then(function () {
    $scope.operators.forEach(function (oper) {
      oper.SortName = (oper.Organization) ? oper.Organization : oper.FirstName + " " + oper.LastName;
    });
  });

  // @ts-ignore
  $scope.leaseTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_LEASETYPES);
  // @ts-ignore
  $scope.productionDeliveryUnits = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_PRODUCTIONDELIVERY);
  // @ts-ignore
  $scope.cropOptions = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_CROPOPTIONS);
  // @ts-ignore
  $scope.weeds = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_WEEDS);
  // @ts-ignore
  $scope.residueTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_RESIDUETYPES);
  // @ts-ignore
  $scope.animalTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_ANIMALTYPES);
  // @ts-ignore
  $scope.subPractices = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_SUBPRACTICES);
  // @ts-ignore
  $scope.productionTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_PRODUCTIONTYPES);
  // @ts-ignore
  $scope.operatorStates = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_OPSTATES);
  // @ts-ignore
  $scope.operatorCities = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_OPCITIES);
  // @ts-ignore
  $scope.complianceInspectionTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_COMPLIANCE_INSP_TYPES);
  // @ts-ignore
  $scope.violationTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_VIOLATION_TYPES);

};*/
