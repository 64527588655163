import {
  SharingLevel, SHARINGLEVEL_GROUPREAD,
  SHARINGLEVEL_PRIVATE,
  SHARINGLEVEL_PUBLICREAD,
  SHARINGLEVEL_REFERENCE_LIBRARY
} from "../../../../config";
import {isValidURL} from "../../../common/common-functions";

var modal_new_link = ['$scope','$uibModalInstance', 'ProjectService', 'Upload',
	function($scope,  $uibModalInstance, ProjectService, $upload){

        $scope.header_message = "Add a new link " ;

        //options from config.js
        $scope.SHARINGLEVEL_PRIVATE = SHARINGLEVEL_PRIVATE;
        $scope.SHARINGLEVEL_PUBLICREAD = SHARINGLEVEL_PUBLICREAD;
		$scope.SHARINGLEVEL_GROUPREAD = SHARINGLEVEL_GROUPREAD;
		$scope.SHARINGLEVEL_REFERENCE_LIBRARY = SHARINGLEVEL_REFERENCE_LIBRARY;

        $scope.SharingLevel = SharingLevel;

        $scope.LinkError = false;

		$scope.save = function(){
            console.dir($scope.row);
            $scope.ErrorMessage = "";

            $scope.validateLink();
            if ($scope.LinkError)
                return;

            $scope.saving = ProjectService.saveLink($scope.project.Id, $scope.row);

            $scope.saving.$promise.then(function(){
                $scope.SaveMessage = "Success"
                $uibModalInstance.close($scope.saving);
            }, function(data){
                $scope.ErrorMessage = "There was a problem saving the link.";
            })

		};

		$scope.cancel = function(){
			$uibModalInstance.dismiss();
		};

        //validate link URL
        $scope.validateLink = function(){

            $scope.LinkError = false;

            if(!isValidURL($scope.row.Link))
                $scope.LinkError = "Please enter a full valid URL (http:// or https:// required)";

        }
	}
];

export default modal_new_link;
