//list all datasets.
import * as angular from 'angular';
import {serverUrl} from "../../../../config";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var datasets_list = ['$scope', 'DatasetService', 'ProjectService','CommonService','$uibModal', '$window',
    function (scope, DatasetService, ProjectService, CommonService, $uibModal, $window){
        console.log('dataset list controller called');
        scope.datasets = DatasetService.getDatasetsList();

        scope.datasets.$promise.then(function () {

            angular.forEach(scope.datasets, function (dataset, key) {
                //need to bump this to get the route
                DatasetService.configureDataset(dataset, scope);
            });

            var visibleDatasets = [];

            scope.datasets.forEach(function(dataset){
                //need to bump this to get the route
                DatasetService.configureDataset(dataset, scope);

                //hide if the flag is flipped
                if(!dataset.Config.Hide)
                    visibleDatasets.push(dataset);
            });

            scope.datasets = visibleDatasets;

            var agCellRendererName = function (params) {
                return '<div>' +
                    '<a title="' + params.node.data.Description
                    + '" href="'+ params.node.data.activitiesRoute +'/' + params.node.data.Id + '">'
                    + params.node.data.Name + '</a>' +
                    '</div>';
            };

            var agColumnDefs = [
                { field: 'Name', headerName: 'Dataset Name', sort: 'asc', cellRenderer: agCellRendererName, width: 300, menuTabs: ['filterMenuTab'], filter: 'text'},
                { field: 'ProjectName', headerName: 'Project', width: 300, menuTabs: ['filterMenuTab'], filter: true},
                { field: 'DatastoreName', headerName: 'Type', width: 300, menuTabs: ['filterMenuTab'], filter: true},
            ];

            scope.agGridOptions = {
                columnHoverHighlight: false,
                animateRows: true,
                suppressPropertyNamesCheck: true,
                // showToolPanel: false,
                columnDefs: agColumnDefs,
                rowData: undefined,
                debug: false,
                onGridReady: function (params) {
                    params.api.sizeColumnsToFit();
                },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
            };

            var ag_grid_div = document.querySelector('#datasets-list-grid') as HTMLElement;    //get the container id...
            scope.ag_grid = new Grid(ag_grid_div, scope.agGridOptions); //bind the grid to it.

            scope.agGridOptions.api.setRowData(scope.datasets);
        });
  }
];

export default datasets_list;
