//Leasing api

//import {serviceUrl} from "../../config";
// import leasing_module from "./leasing-module";




import {METADATA_ENTITY_LEASING, METADATA_ENTITY_LEASING_SYSTEM} from "../../config";

const lease_service = ['$q',
    'ActiveLeases',
    'GetLease',
    'PendingLeases',
    'AvailableFields',
    'GetOperators',
    'SaveLease',
    'SaveCropPlan',
    'SaveInspection',
    'SaveProduction',
    'GetLeaseRevisions',
    'GetInspectionViolations',
    'SaveInspectionViolation',
    'AllLeases',
    'SaveOperator',
    'GetLookups',
    'PutMetadataProperty',
    'GetMetadataProperty',
    'GetCropPlanRevisions',
    'ExpireLeases',
    'AvailableAllotments',
    'GetLeasesByField',
    'DeleteOperator',
    'RemoveProduction',
    'RemoveInspection',
    function ($q,
        ActiveLeases,
        GetLease,
        PendingLeases,
        AvailableFields,
        GetOperators,
        SaveLease,
        SaveCropPlan,
        SaveInspection,
        SaveProduction,
        GetLeaseRevisions,
        GetInspectionViolations,
        SaveInspectionViolation,
        AllLeases,
        SaveOperator,
        GetLookups,
        PutMetadataProperty,
        GetMetadataProperty,
        GetCropPlanRevisions,
        ExpireLeases,
        AvailableAllotments,
        GetLeasesByField,
        DeleteOperator,
        RemoveProduction,
        RemoveInspection
    ) {
        var service = {

            getAllLeases: function () {
                return AllLeases.query();
            },

            getActiveLeases: function () {
                return ActiveLeases.query();
            },

            getLease: function (id) {
                return GetLease.query({ id: id });
            },

            getLeasesByField: function (id) {
                return GetLeasesByField.query({ id: id });
            },

            getPendingLeases: function () {
                return PendingLeases.query();
            },

            getAvailableFields: function () {
                return AvailableFields.query();
            },

            getAvailableAllotments: function () {
                return AvailableAllotments.query();
            },

            getOperators: function () {
                return GetOperators.query();
            },

            saveLease: function (lease, cropshareremove) {
                var cropshares = lease.LeaseCropShares;
                delete lease.LeaseCropShares;
                return SaveLease.save({ Lease: lease, LeaseCropShares: cropshares, CropShareRemove: cropshareremove });
            },

            saveCropPlan: function (lease, plan) {
                return SaveCropPlan.save({ Lease: lease, CropPlan: plan });
            },

            saveInspection: function (inspection) {
                return SaveInspection.save({ Inspection: inspection });
            },

            expireLeases: function () {
                return ExpireLeases.query();
            },

            saveProduction: function (production) {
                return SaveProduction.save({ Production: production});
            },

            removeProduction: function(productionId) {
                return RemoveProduction.save({ ProductionId: productionId});
            },

            removeInspection: function(id){
                return RemoveInspection.save({InspectionId: id});
            },

            getLeaseRevisions: function (id) {
                return GetLeaseRevisions.query({ id: id });
            },

            getLeaseCropPlanRevisions: function (id) {
                return GetCropPlanRevisions.query({ id: id });
            },

            getInspectionViolations: function (params) {
                return GetInspectionViolations.query({ QueryParams: params });
            },

            saveInspectionViolation: function (inspectionviolation) {
                return SaveInspectionViolation.save({ Inspection: inspectionviolation });
            },

            saveOperator: function (operator) {
                return SaveOperator.save({ LeaseOperator: operator });
            },

            deleteOperator: function (operator) {
                console.dir(operator);
                return DeleteOperator.query({ Id: operator.Id });
            },

            getLookupLists: function () {

                var lists = GetLookups.query({ Id: METADATA_ENTITY_LEASING });
                lists.$promise.then(function () {
                    lists.forEach(function (lookup_list) {
                        lookup_list.ListValues = JSON.parse(lookup_list.PossibleValues);
                    });
                })
                return lists;
            },

            getLeasingSystemValues: function () {

                return GetLookups.query({ Id: METADATA_ENTITY_LEASING_SYSTEM });
            },

            getLookupValues: function(property_id){
                var property = GetMetadataProperty.query({ id: property_id });
                property.$promise.then(function () {
                    property.ListValues = JSON.parse(property.PossibleValues);
                });
                return property;
            },

            saveLookupList: function (metadata) {
                return PutMetadataProperty.update({ Id: metadata.Id }, metadata);
            }


        };

        return service;

    }
];

export default lease_service;
