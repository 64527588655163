import * as angular from 'angular';
import * as moment from 'moment';
import {
    getAllMatchingFromArray,
    getByField,
    getById,
    getDataGrade,
    getFilesArrayAsList,
    getMatchingByField,
    getParsedMetadataValues,
    initEdit, makeObjects
  } from "../../../common/common-functions";
import {AdminUserIds, ROWSTATUS_DELETED, SystemTimezones} from "../../../../config";

//Show this modal to prevent user from navigating, or doing anything else,
// while the save action is in progress.
var modal_save_status = ['$scope', '$uibModal','$uibModalInstance', 'Upload', '$rootScope', '$location', 'DatasetService',

    function ($scope, $uibModal, $uibModalInstance, Upload, $rootScope, $location, DatasetService,) {
        console.log("Inside modal-save-status.ts, at the top...");

        //$scope.showSuccess = true;
        $scope.showFish = true; // Start out with the fish spinning, and the Close button disabled.
        //$scope.AllActivitySaveTasksComplete = false;

        if ($scope.CallingPage === "DatasetEditForm")
        {

            //This is a workaround for angularjs' either too loose matching or too strict...
            $scope.locequals = function (actual, expected) { return actual == expected; };

            //Handle saving the files.
            var data = {
                ProjectId: $scope.project.Id,
                DatasetId: $scope.dataset.Id,
            };

            var target = '/api/v1/file/uploaddatasetfile';

            //console.log("$scope.row is next...");
            //console.dir($scope.row);
            var saveRow = $scope.row;

            // The flow...
            // Call $scope.handleFilesToUploadRemove
            // $scope.handleFilesToUploadRemove does work and then calls dataset-edit-form.ts, $scope.modalFile_saveParentItem
            // dataset-edit-form.ts, $scope.modalFile_saveParentItem sets $scope.showFish to false in modal-save.status.ts
            // The user clicks Close on modal-save-status.html
            //      - If the user was on dataset Creel Harvest, the page remains where it is
            //      - If the user was on some other dataset, the page then navigates to the Dataset Activities page.
            $scope.handleFilesToUploadRemove(saveRow, data, target, Upload, $rootScope, $scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFile_saveParentItem below.
            
            //$uibModalInstance.close();
        }
        else if ($scope.CallingPage === "ImportMultipleActivities")
        {
            var intActivityCount = 0;
            var intCompletedActivities = 0;

            $scope.ActivitiesToSave.forEach(function (activity) {

                intActivityCount++;

                //compose our payload
                var payload = {
                    'Activity': null,
                    'DatasetId': $scope.dataset.Id,
                    'ProjectId': $scope.project.Id,
                    'UserId': $rootScope.Profile.Id,
                    'deletedRowIds': [],
                    'editedRowIds': [],
                    'header': null,
                    'details': [],
                };

                $scope.dataAgGridOptions.api.forEachNode(function (node, index) {
                    var the_date = moment(node.data.Activity.ActivityDate).format('YYYY-MM-DDTHH:mm');

                    if (activity.ActivityDate == the_date && node.data.Activity.LocationId == activity.LocationId) {
                        //console.dir(node);
                        if (payload.header == null) {
                            payload.Activity = node.data.Activity;
                            payload.header = {};

                            $scope.dataAgColumnDefs.HeaderFields.forEach(function (header_field) {
                                payload.header[header_field.DbColumnName] = node.data[header_field.DbColumnName];
                            })
                            //add the ActivityQAStatus back in with values from the activity
                            payload.Activity.ActivityQAStatus = {
                                'Comments': node.data.Activity.QAComments,
                                'QAStatusId': node.data.Activity.QAStatusId,
                            };
                            delete payload.header['QAStatusId'];
                            delete payload.header['QAComments'];
                            delete payload.header['LocationId'];
                            delete payload.header['ActivityDate'];

                        }
                        //console.dir(node);
                        if (node.data.data_row_hasdata) {

                            var the_detail = { 'QAStatusId': node.data['QAStatusId'] };

                            $scope.dataAgColumnDefs.DetailFields.forEach(function (detail_field) {
                                if (detail_field.ControlType == "multiselect" && Array.isArray(node.data[detail_field.DbColumnName]))
                                    the_detail[detail_field.DbColumnName] = angular.toJson(node.data[detail_field.DbColumnName]);
                                else
                                    the_detail[detail_field.DbColumnName] = node.data[detail_field.DbColumnName];
                            });
                            payload.details.push(the_detail);
                            //console.dir(the_detail);
                        } else {
                            console.log("skipping data for this row because it was empty!");
                        }

                    }
                });

                activity.numRecords = payload.details.length;

                //console.dir(payload);
                activity.result = DatasetService.saveActivities(payload);

                activity.result.$promise.then(
                function () {
                    activity.result.success = "Save successful.";
                    console.log("Success!");

                    intCompletedActivities++;

                    if (intActivityCount === intCompletedActivities)
                    {
                        $scope.system.loading = false;
                        $scope.showFish = false;
                    }

                },
                function (data) {
                    console.log("Failure!");

                    intCompletedActivities++;

                    if (intActivityCount === intCompletedActivities)
                    {
                        $scope.system.loading = false;
                        $scope.showFish = false;
                    }

                    //console.dir(data);
                    let theErrorText: any;
                    if (typeof data.data !== 'undefined') {
                        if (typeof data.data.ExceptionMessage !== 'undefined') {
                            theErrorText = data.data.ExceptionMessage;
                            console.log("Save error:  theErrorText = " + theErrorText);
                        }

                    }
                    activity.result.error = "Error: " + theErrorText ;

                });

            });
        }


        $scope.close = function () {
            if ($scope.background_save) // Creel Survey, Add Section button clicked.
            {
                // Close the modal, but stay on the page.
                $uibModalInstance.dismiss();

                // Set $scope.background_save to false here, as it will get reset to true by the dataset-edit-form
                // when doing a new Creel section.
                $scope.background_save = false;
                $scope.$parent.background_save = false;
            }
            else
            {
                // Close the modal, AND redirect to the Dataset Activities page.
                $location.path("/" + $scope.dataset.activitiesRoute + "/" + $scope.dataset.Id);
                $uibModalInstance.dismiss();
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
];

export default modal_save_status;
