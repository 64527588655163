import * as angular from 'angular';
import * as moment from 'moment';
import {PERMITCONTACTS_DATASETID} from "../../../../config";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var permit_contacts = ['$scope', '$route', '$routeParams', '$uibModal', '$location', '$window', '$rootScope', 'PermitService','GridService','DatasetService',
    function ($scope, $route, $routeParams, $uibModal, $location, $window, $rootScope,PermitService, GridService,DatasetService) {

        $rootScope.inModule = "permits";

        $scope.contactsdataset = DatasetService.getDataset(PERMITCONTACTS_DATASETID);

        $scope.contactsdataset.$promise.then(function () {

            //first, check permissions - we expect a Permission group to be set on dataset.Config.Permissions
            if(!$scope.contactsdataset.Config.Permissions || !$scope.contactsdataset.Config.Permissions.View || !$scope.contactsdataset.Config.Permissions.Edit){
                console.error("Configuration error - the administrator needs to configure the permissions for this dataset on Admin->Dataset->Config->Permissions");
                throw ("Failed to load");
            }

            if (!$scope.Profile.hasRole($scope.contactsdataset.Config.Permissions.View) && !$scope.Profile.hasRole($scope.contactsdataset.Config.Permissions.Edit)){
                angular.rootScope.go("/unauthorized");
            }

            $scope.permitsCanEdit = $scope.Profile.hasRole($scope.contactsdataset.Config.Permissions.Edit);
            //end permissions

            $scope.contacts = PermitService.getAllPersons();

            $scope.contacts.$promise.then(function () {
                $scope.contactsGrid.api.setRowData($scope.contacts);
            });

            $scope.ContactsDatasetColumnDefs = GridService.getAgColumnDefs($scope.contactsdataset);

            $scope.contactsGrid.columnDefs = angular.copy($scope.ContactsDatasetColumnDefs.HeaderFields);

            $scope.contactsGridDiv = document.querySelector('#contacts-grid');
            new Grid($scope.contactsGridDiv, $scope.contactsGrid);

        });

        $scope.deleteContact = function () {

            if (confirm("Are you sure you want to delete this person?")) {
                var deleting = PermitService.deletePerson($scope.contactsGrid.selectedItems[0].Id);

                deleting.$promise.then(function () {
                    $scope.saveContactCallback(); //refresh the contacts...
                }, function(data){
                    alert("Error: " + data.data.InnerException.ExceptionMessage)
                });
            }
        };

        $scope.openAddContact = function (params) {

            delete $scope.person_modal;

            if (params) {
                $scope.person_modal = params;
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/contacts/templates/add-person-modal.html',
                controller: 'AddPersonModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function(saved){
                $scope.saveContactCallback();
            });
        }

        $scope.contactsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: [],
            rowData: null,
            rowSelection: 'single',
            onSelectionChanged: function (params) {
                $scope.contactsGrid.selectedItems = $scope.contactsGrid.api.getSelectedRows();
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            selectedItems: [],
            onRowDoubleClicked: function (params) {
                if ($scope.permitsCanEdit) {
                    $scope.openAddContact(params.data);
                }
            },
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
        }

        $scope.saveContactCallback = function () {
            $scope.contacts = PermitService.getAllPersons();

            $scope.contacts.$promise.then(function () {
                if ($scope.contactsGrid.api)
                    $scope.contactsGrid.api.setRowData($scope.contacts);
            });

        }

        $scope.currentUser = $rootScope.Profile.Fullname;
        $scope.currentDay = moment().format();
}];

export default permit_contacts;
