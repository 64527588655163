
import * as angular from 'angular';
export function buildRandomString10()
{
	var strNum = "";

	var intRandom = -1;
	for (var i = 0; i < 10; i++)
	{
		intRandom = Math.floor(Math.random() * 8) + 1; // We want a number between 1 and 9, inclusively.

		strNum += intRandom;
	}
	return strNum;
}

export function writeResults(originalTextList)
{
	for (let item of originalTextList){
		console.log(item.strText + ", " + item.intNumber);
		console.log("***********");
		console.log("***********");
	}
}

export function buildFinalResults(originalTextList, strFirstNumber, strSecondNumber)
{
	//console.log("originalTextList is next...");
	//console.dir(originalTextList);
	//console.log("strFirstNumber = " + strFirstNumber);
	//console.log("strSecondNumber = " + strSecondNumber);

	var strFinalResult = "";
	var strLastDigit = "";

	for(let item of originalTextList)
  {
		strFinalResult += item.intNumber.toString().length +  item.intNumber.toString();
	}

	//console.log("strFinalResult (before concat)= " + strFinalResult);

	// Note:  The string contained in strFinalResult has a good probability of being too long
	// for either int32 or int64.
	// Int32 -- (-2,147,483,648 to +2,147,483,647)
	// Int64 -- (-9,223,372,036,854,775,808 to +9,223,372,036,854,775,807)
	//
	// Therefore, we need to handle the number in a string instead.

	strFinalResult += strFirstNumber + strSecondNumber;
	//console.log("strFinalResult (after concat) = " + strFinalResult);

	var n = -1;
	//for (int i = (strSecondNumber.length - 1); i > -1; i--)
	for (var i = (strFirstNumber.length - 1); i > -1; i--)
	{
		//n = parseInt(strSecondNumber.Substring(i, 1));
		n = parseInt(strFirstNumber.substr(i, 1));
		if ( n > 2)
		{
			//console.log("Last hash # = " + n);
			strFinalResult = multiply(strFinalResult, n.toString());
			strLastDigit = n.toString();
			i = -1;
		}
    // copy string
		strFinalResult = angular.copy(strFinalResult) + strLastDigit;
	}
	console.log("strFinalResult (after final hash)= " + strFinalResult);

	//$scope.strFinalResult = intFinalResult.toString();

	//console.log("strFinalResult (final)= " + strFinalResult);
	//console.log("strFinalResult.length = " + strFinalResult.length);
	//console.log("strOriginalText.length = " + strOriginalText.length);
	//console.log("strFirstNumber = " + strFirstNumber);
	//console.log("strSecondNumber = " + strClientNumber);
	return strFinalResult;
}

export function multiply(strA, strB)
{
	//console.log("Inside multiply...");
	//console.log("strA = " + strA + ", strB = " + strB);
	// This method expects two numbers, each in a separate string.
	// This version accepts a multidigit numbr (strA), and a single-digit second numbr (strB)
	var intADigit = 0;
	var intBDigit = 0;

	var intRemainder = 0;
	var intCarryOver = 0;

	var intResult = 0;
	var strResult = "";

	strA.trim();
	strB.trim();

	var intALength = strA.length;
	//console.log("intALength = " + intALength);

	var intBLength = strB.length;
	//console.log("intBLength = " + intBLength);

	// Example of what we are doing...
	//  123
	// *  2
	//  ---
	//  246

	intBDigit = parseInt(strB);
	for (var i = intALength - 1; i > -1; i--)
	{
		intADigit = parseInt(strA.substr(i, 1));
		//intBDigit = parseInt(strB.substr(i, 1));
		//console.log("intADigit = " + intADigit);
		//console.log("intBDigit = " + intBDigit);

		intResult = intADigit * intBDigit;
		//console.log("intResult = " + intResult);

		// The first time through, intCarryOver is 0;
		intRemainder = (intResult % 10) + intCarryOver;
		//console.log("intRemainder = " + intRemainder);

		intCarryOver = Math.floor((intResult / 10)); // Round down to nearest integer; 1.7 -> 1.
		//console.log("intCarryOver = " + intCarryOver);

		while (intRemainder > 9)
		{
			intCarryOver += Math.floor((intRemainder / 10)); // Round down to nearest integer; 1.7 -> 1.
			intRemainder = intRemainder % 10;
		}
		//console.log("intCarryOver = " + intCarryOver + ", intRemainder = " + intRemainder);

		strResult = intRemainder.toString() + strResult;
		//console.log("strResult = " + strResult);
	}
	if (intCarryOver > 0)
	{
		//strResult = intRemainder.toString() + intCarryOver.toString() + strResult;
		strResult = intCarryOver.toString() + strResult;
	}

	return strResult;
}

export function getNumberFromPlace(aNumber, strSecondNumber)
{
	//console.log("Inside $scope.getNumberFromPlace...");
	//console.log("aNumber = " + aNumber);
	var intDigitFromSecond = parseInt(strSecondNumber.substr(aNumber - 1, 1));

	return intDigitFromSecond;
}
