// defines the dataset module and all dataset controllers.
import * as angular from 'angular';
//load the components for this module
// require([

// //loads the dataset functions
import * as datasets_functions from './datasets-functions';
//
// //loads the dataset filters
// import * as datasets_filters from './datasets-filters';
import dataset_service from "./services/dataset-service";
import grid_service from "../common/components/grid/grid-service";
import dataset_activities_list from "./components/dataset-activities-list/dataset-activities-list";
import dataset_query from "./components/dataset-query/dataset-query";
import datasets_list from "./components/datasets-list/datasets-list";
import dataset_query_view_all_records from "./components/dataset-query/dataset-query-view-all-records";
import dataset_import from "./components/dataset-import/dataset-import";
import table_editor from "./components/dataset-table/table";
import modal_dataset_duplicates from "./components/dataset-import/modal-dataset-duplicates";
import modal_qa_update from "./components/dataset-view/modal-qa-update";
import modal_create_season from "./components/dataset-seasons-list/modal-create-season";
import modal_map_locations from "./components/dataset-import/modal-map-locations";
import modal_activities_grid from "./components/dataset-import/modal-activities-grid";
import dataset_detail from "./components/dataset-detail/dataset-detail";
import modal_data_entry from "./components/dataset-view/modal-data-entry";
import dataset_edit_form from "./components/dataset-editor/dataset-edit-form";
import modal_save_status from "./components/dataset-editor/modal-save-status";
import table_data_modal from "./components/dataset-table/table-data-modal";
import big_bucket_query from "./components/dataset-query/big-bucket-query";
import dataset_seasons_list from "./components/dataset-seasons-list/dataset-seasons-list";
import {cdmsShareUrl, serviceUrl} from "../../config";
import {array_count, orderByAlpha} from "../common/common-functions";
import activity_parser from "./services/activity-parser";
import convert_status from "./services/convert-status";
//
// //load all components for the dataset module
// 'core/datasets/components/dataset-activities-list/dataset-activities-list',
// 'core/datasets/components/dataset-detail/dataset-detail',
// 'core/datasets/components/dataset-editor/dataset-edit-form',
// 'core/datasets/components/datasets-list/datasets-list',
// 'core/datasets/components/dataset-view/modal-qa-update',
// 'core/datasets/components/dataset-view/modal-data-entry',
// 'core/datasets/components/dataset-import/dataset-import',
// 'core/datasets/components/dataset-import/modal-dataset-duplicates',
// 'core/datasets/components/dataset-import/modal-map-locations',
// 'core/datasets/components/dataset-import/modal-activities-grid',
// 'core/datasets/components/dataset-query/dataset-query',
// 'core/datasets/components/dataset-query/dataset-query-view-all-records',
// 'core/datasets/components/dataset-query/big-bucket-query',
// 'core/datasets/components/dataset-seasons-list/dataset-seasons-list',
// 'core/datasets/components/dataset-seasons-list/modal-create-season',
// 'core/datasets/components/dataset-table/table',
// 'core/datasets/components/dataset-table/table-data-modal',
//
// //loads the chart functions
// 'core/datasets/components/dataset-table/charts/zingCharts',
//
// //load the various dataset services
// 'core/datasets/services/dataset-service',
// 'core/datasets/services/activity-parser',
// 'core/datasets/services/convert-status',
//'core/datasets/services/datasheet',

// ], function () {
//add the controllers and services to the module once the files are loaded!
const datasets_module = angular.module('DatasetModule', ['ui.bootstrap', 'ngResource', 'ngFileUpload']);
//controllers
datasets_module.controller('DatasetActivitiesCtrl', dataset_activities_list);
datasets_module.controller('DatasetDetailsCtrl', dataset_detail);
datasets_module.controller('DataEditCtrl', dataset_edit_form);
datasets_module.controller('DatasetsListCtrl', datasets_list);
datasets_module.controller('ModalQaUpdateCtrl', modal_qa_update);
datasets_module.controller('ModalDataEntryCtrl', modal_data_entry);
datasets_module.controller("DatasetImportCtrl", dataset_import);
datasets_module.controller('ModalDuplicatesViewCtrl', modal_dataset_duplicates);
datasets_module.controller('DataQueryCtrl', dataset_query);
datasets_module.controller('DataQueryViewAllRecordsCtrl', dataset_query_view_all_records);
datasets_module.controller('DatastoreQueryCtrl', big_bucket_query);
datasets_module.controller('DatasetSeasonsCtrl', dataset_seasons_list);
datasets_module.controller('ModalCreateSeasonCtrl', modal_create_season);
datasets_module.controller('ModalMapLocationsCtrl', modal_map_locations);
datasets_module.controller('ModalActivitiesGridCtrl', modal_activities_grid);
datasets_module.controller('ModalSaveStatusCtrl', modal_save_status);
datasets_module.controller('TableCtrl', table_editor);
datasets_module.controller('TableDataModal', table_data_modal);
datasets_module.service('DatasetService', dataset_service);
datasets_module.service('GridService', grid_service);
datasets_module.service('ActivityParser', activity_parser);
datasets_module.service('ConvertStatus', convert_status);

datasets_module.factory('DatasetFiles', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/file/getdatasetfiles', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('Activities', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/getdatasetactivities', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('GetSeasons', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/season/getseasons', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('SaveSeason', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/season/saveseason');
}]);

datasets_module.factory('RemoveSeason', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/season/removeseason');
}]);

datasets_module.factory('ActivitiesForView', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/getdatasetactivitiesview', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('CreelSurveyActivitiesForView', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/getcreelsurveydatasetactivitiesview', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('Dataset', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/getdataset', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: false}
  });
}]);

datasets_module.factory('Datasets', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/getdatasets', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

datasets_module.factory('GetDatasetsList', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/getdatasetslist', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

datasets_module.factory('GetAllDatasetsList', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/getalldatasetslist', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

datasets_module.factory('GetTableData', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/gettabledata', {}, {
    query: {method: 'GET', params: {id: 'id'}, isArray: true}
  });
}]);

datasets_module.factory('SaveTableData', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/savetabledata', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('DeleteTableData', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/deletetabledata', {}, {
    save: {method: 'POST', isArray: false}
  });
}]);

datasets_module.factory('GetChildTableData', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/getchildtabledata', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('GetTableFiles', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/gettablefiles', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('DeleteTableFile', ['$resource', function ($resource) {
  // return $resource(serviceUrl + '/api/v1/table/DeleteFile');
  return $resource(serviceUrl + '/api/v1/file/removeselectedmultipanelfile');
}]);

datasets_module.factory('TableNotification', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/table/notify');
}]);


datasets_module.factory('Data', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/getdatasetactivitydata', {}, {
    query: {method: 'GET', params: {id: 'activityId'}, isArray: false}
  });
}]);

// datasets_module.factory('SaveActivitiesAction', ['$resource', function ($resource) {
//   return $resource(serviceUrl + '/api/v1/activity/savedatasetactivities');
// }]);

datasets_module.factory('SaveActivitiesAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/savedatasetactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

// datasets_module.factory('UpdateActivitiesAction', ['$resource', function ($resource) {
//   return $resource(serviceUrl + '/api/v1/activity/updatedatasetactivities');
// }]);

datasets_module.factory('UpdateActivitiesAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/updatedatasetactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('QueryActivitiesAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/query/querydatasetactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('ExportActivitiesAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/export/exportdatasetactivities', {}, {
    save: {method: 'POST', isArray: false}
  });
}]);

datasets_module.factory('DeleteActivitiesAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/deletedatasetactivities2', {}, {
    //save: {method: 'POST', isArray: false}
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('SetQaStatusAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/setqastatus');
}]);

//shouldn't this have an ID parameter? my guess is we don't actually use this anywhere...
datasets_module.factory('GetDatastore', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/datastore/getdatastore', {}, {
    query: {
      method: 'GET',
      params: {},
      isArray: false
    }
  });
}]);

datasets_module.factory('GetAllDatastores', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/datastore/getdatastores');
}]);

datasets_module.factory('GetDatastoreDatasets', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/datastore/getdatastoredatasets');
}]);

datasets_module.factory('GetHeadersDataForDataset', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/getheadersdatafordataset');
}]);

datasets_module.factory('DeleteDatasetFile', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/file/deletedatasetfile');
}]);

datasets_module.factory('DeleteMultiPanelFile', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/file/deletemultipanelfile');
}]);

datasets_module.factory('GetRelationData', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/getrelationdata', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('AddDatasetToProject', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/adddatasettoproject')
}]);

datasets_module.factory('UpdateDataset', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/dataset/updatedataset');
}]);


datasets_module.factory('MigrationYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getmigrationyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('RunYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getrunyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('ReportYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getreportyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('BenthicSampleYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getbenthicsampleyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('DriftSampleYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getdriftsampleyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('SpawningYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getspawningyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('BroodYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getbroodyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('OutmigrationYears', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/list/getoutmigrationyears', {}, {
    query: {method: 'GET', params: {id: 'datasetId'}, isArray: true}
  });
}]);

datasets_module.factory('SpecificActivities', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/queryspecificactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('SpecificActivitiesWithBounds', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/queryspecificactivitieswithbounds', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('SpecificWaterTempActivities', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/queryspecificwatertempactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('SpecificCreelSurveyActivities', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/queryspecificcreelsurveyactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

datasets_module.factory('SpecificScrewTrapActivities', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/queryspecificscrewtrapactivities', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);


datasets_module.factory('HasExistingActivity', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/activity/HasExistingActivity', {}, {
    save: {method: 'POST', isArray: false}
  });
}]);


datasets_module.filter('dataGrade', function () {
  return function (check) {
    if (!check)
      return;

    var grade = "N/A";

    if (check.CheckMethod == 1)
      grade = check.Bath1Grade + "-" + check.Bath2Grade;
    else if (check.CheckMethod == 2)
      grade = check.Bath1Grade;

    return grade;
  }
}).filter('checkmark', function () {
  return function (input) {
    return (input == null) ? '\u2713' : '\u2718';
  };
})
  .filter('units', function () {
    return function (input) {
      return (input == null) ? '' : ' (' + input + ')';
    };
  })
  .filter('locationNameFilter', ['$rootScope', function ($rootScope) {
    return function (input) {
      if ($rootScope.locationOptions[input])
        return $rootScope.locationOptions[input];

      return input;
    };
  }])
  .filter('instrumentFilter', ['$rootScope', function ($rootScope) {
    return function (input) {
      if ($rootScope.instrumentOptions[input]) {
        return $rootScope.instrumentOptions[input];
      }
      return input;
    };
  }])
  .filter('timezoneFilter', ['$rootScope', function ($rootScope) {
    return function (input) {
      if ($rootScope.timezoneOptions[input]) {
        return $rootScope.timezoneOptions[input];
      }
      return input;
    };
  }])
  .filter('QAStatusFilter', ['$rootScope', function ($rootScope) {
    return function (input) {
      if ($rootScope.QAStatusOptions[input])
        return $rootScope.QAStatusOptions[input];

      return input;
    };
  }])
  .filter('fishermanFilter', ['$rootScope', function ($rootScope) {
    return function (input) {
      //console.log("input = " + input);
      if ((typeof input !== 'undefined') && (input !== null)) {
        return $rootScope.fishermenOptions[input];
      } else {
        return null;
      }

      //if($rootScope.FishermanOptions[input])
      if ($rootScope.fishermenOptions[input]) {
        //return $rootScope.FishermanOptions[input];
        return $rootScope.fishermenOptions[input];
      }
      //return input;
    };
  }])
  .filter('DataGradeMethod', ['$rootScope', function ($rootScope) {
    return function (input) {
      return $rootScope.DataGradeMethods[input];
    };
  }])
  .filter('arrayValues', function () {
    return function (input) {
      var result = '';
      if (input) {

        try {
          result = angular.fromJson(input).toString();
        } catch (e) {
          result = input;
        }
        /*
        var vals = angular.fromJson(input);
        angular.forEach(vals, function(item){
            if(result != '')
                result += ',';
            result += item;
        });
        */
      }

      return result;
    };
  })
  .filter('arrayDisplayValue', function () {
    return function (input, field) {
      //console.log(" ----- Field: " + field.DbColumnName);
      //console.dir(input);
      //console.dir(field.PossibleValues);

      var result = '';
      var the_val = '';

      if (input) {

        try {
          the_val = angular.fromJson(input).toString();
          //console.dir(the_val);
          //console.dir(field.PossibleValues);

        } catch (e) {
          //console.dir(e);
          the_val = input;
        }
      }

      //if multiselect and we have more than one value, compose them
      if (Array.isArray(the_val)) {
        var arr_result = [];
        the_val.forEach(function (val) {
          arr_result.push(field.PossibleValues[val]);
        });
        result = arr_result.join(",");
      } else {
        //otherwise single select, return the matched value
        result = field.PossibleValues[the_val];
      }

      if (!result)
        result = the_val;

      return result;
    };
  })
  .filter('RowQAStatusFilter', ['$rootScope', function ($rootScope) {
    return function (input) {
      if ($rootScope.RowQAStatuses[input]) {
        return $rootScope.RowQAStatuses[input];
      } else {
        return 'unknown';
      }
    };
  }])
  .filter('urlsFromString', ['$sce', '$rootScope', function ($sce, $rootScope) {
    return function (input) {
      //console.log("Inside urlsFromString...");
      //console.log("input is next...");
      //console.dir(input);
      let retval: any = [];
      if (input) {
        var urls = angular.fromJson(input);
        angular.forEach(urls, function (aUrl) {
          //console.log("aUrl is next...");
          //console.dir(aUrl);
          var theTarget = "_blank";
          retval.push("<a href='" + aUrl.Link + "' target=\"_blank\">" + aUrl.Name + "</a>");
        });
      }
      if (retval.length == 0) {
        retval = "&nbsp;";
      } else {
        retval = retval.join(",");
      }

      return $sce.trustAsHtml(retval);
    };
  }])
  .filter('fileNamesFromString', ['$sce', function ($sce) { // to use this filter you need to pass in the scope in the filter call: "| fileNamesFromString:this"
    return function (input, $scope) {
      //console.log($scope.project.Id);
      //console.log("Inside fileNamesFromString...");
      //console.log("input is next...");
      //console.dir(input);
      let retval: any = [];
      var fileIsString = false;
      if (input) {
        //var files = angular.fromJson(input); // Original line
        var files = null;
        try {
          files = angular.fromJson(input);
        } catch (err) {
          files = [];
          files.push(input);
          fileIsString = true;
        }

        files.forEach(function (file) {

          var theTarget = "_blank";

          if (file.hasOwnProperty("Link")) {
            retval.push("<a href='" + file.Link + "' target=\"_blank\">" + file.Name + "</a>");
            return;
          }

          // Although datasets-filters handles assembling the file link when viewing,
          // During a save operation, this section is used.
          if (!$scope.viewSubproject) // We are working with a project, dataset, or new subproject file.
          {
            if ($scope.newSubproject) // New subproject, with no viewSubproject yet.
            {
              //console.log("This is a subproject file.");
              if (fileIsString) {
                retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.project.Id + "/S/[TBD]" + "/" + file + "' target=\"_blank\">" + file + "</a>");
              } else
                retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.project.Id + "/S/[TBD]" + "/" + file.Name + "' target=\"_blank\">" + file.Name + "</a>");
            } else if ($scope.dataset && $scope.dataset.Id) // It's a dataset
            {
              //console.log("This is a dataset file.");
              if (fileIsString) {
                retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.dataset.ProjectId + "/D/" + $scope.dataset.Id + "/" + file + "' target=\"_blank\">" + file + "</a>");
              } else
                retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.dataset.ProjectId + "/D/" + $scope.dataset.Id + "/" + file.Name + "' target=\"_blank\">" + file.Name + "</a>");

            }
            // Project files are handled in projects/components/project-detail/project-files.js
            else // It's a project
            {
              console.log("This is a project file.");
              if (fileIsString) {
                retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.project.Id + "/" + file + "' target=\"_blank\">" + file + "</a>");
              } else
                retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.project.Id + "/" + file.Name + "' target=\"_blank\">" + file.Name + "</a>");
            }
          } else // We are working with a subproject file.
          {
            //console.log("This is a subproject file.");
            //console.log("$rootScope.projectId = " + $rootScope.projectId);
            if (fileIsString) {
              retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.project.Id + "/S/" + $scope.subprojectId + "/" + file + "' target=\"_blank\">" + file + "</a>");
            } else
              retval.push("<a href='" + cdmsShareUrl + "P/" + $scope.project.Id + "/S/" + $scope.subprojectId + "/" + file.Name + "' target=\"_blank\">" + file.Name + "</a>");

          }
        });
      }

      if (retval.length == 0) {
        retval = "&nbsp;";
      }else {
        retval = retval.join("<br/>");
      }
      return $sce.trustAsHtml(retval);

    };
  }]).filter('countItems', ['$sce', function ($sce) {
  return function (input) {
    var retval = '-';
    if (input) {
      retval = array_count(input) + "";
    }
    return $sce.trustAsHtml(retval);
  }
}]).filter('orderByObjectValue', function () {

  return function (obj) {
    var array = [];

    Object.keys(obj).forEach(function (key) {
      array.push({Id: key, Label: obj[key]});
    });

    // apply a custom sorting function
    array.sort(orderByAlpha);

    //console.dir(array);
    return array;
  };
}).filter('orderAlpha', function () {
  return function (items) {
    var filtered = [];

    Object.keys(items).forEach(function (key) {
      filtered.push({key: items[key]});
    });

    filtered.sort(function (a, b) {
      var aVal = "";
      var bVal = "";

      for (let key in Object.keys(a)) {
        aVal = a[key];
      }

      for (let key in Object.keys(b)) {
        bVal = b[key];
      }

      /* not supported in IE
      for(let [akey,avalue] of Object.entries(a)){
          aVal = avalue;
          for( let [bkey,bvalue] of Object.entries(b)){
              bVal = bvalue;
          }
      }
      */

      return aVal > bVal ? 1 : -1;
    });
    //console.dir(filtered);
    return filtered;
  };
});

// });

export default datasets_module;
