import * as angular from 'angular';

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var admin_groups = ['$scope', '$uibModal', 'CommonService',
    function (scope, $uibModal, CommonService) {

        if (!scope.Profile.isAdmin())
            angular.rootScope.go("/unauthorized");

        scope.groups = CommonService.getGroups();
        scope.organizations = CommonService.getOrganizations();

        scope.groups.$promise.then(function(){

            var agColumnDefs = [
                { field: 'Organization.Name', headerName: 'Organization', width: 280, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc'},
                { field: 'Name', headerName: 'Group Name', width: 300, menuTabs: ['filterMenuTab'], filter: 'text'},
                { field: 'Description', headerName: 'Description', width: 300, menuTabs: ['filterMenuTab'], filter: 'text'},
                { headerName: "Members", width: 120, cellRenderer: function(params){
                    return params.data.Members.length;
                }}
            ];

            scope.groupGrid = {
                // showToolPanel: false,
                suppressPropertyNamesCheck: true,
                columnDefs: agColumnDefs,
                rowData: scope.groups,
                selectedItem: null,
                rowSelection: 'single',
                onSelectionChanged: function (params) {
                    scope.groupGrid.selectedItem = scope.groupGrid.api.getSelectedRows()[0];
                    scope.$apply();
                },
                onRowDoubleClicked: function(params) {
                    scope.openAdminGroupModal(scope.groupGrid.selectedItem);
                },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
            };

            var ag_grid_div = document.querySelector('#groups-grid')as HTMLElement;    //get the container id...
            scope.ag_grid = new Grid(ag_grid_div, scope.groupGrid); //bind the grid to it.


        });

        scope.openAdminGroupMembersModal = function(){
            scope.SaveMessage = null;

            scope.group = scope.groupGrid.selectedItem;

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-admin-group-members.html',
                controller: 'ModalAdminGroupMembersCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function () {
                scope.groupGrid.api.setRowData(scope.groups);
            });
        }

        scope.openAdminGroupModal = function(in_group){
            scope.SaveMessage = null;

            scope.group = (in_group) ? in_group : {};

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-admin-item.html',
                controller: 'ModalAdminItemCtrl',
                scope: scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_group) {
                if(scope.group.hasOwnProperty('Id')) //edited
                {
                    scope.groups.forEach(function(item, index){
                        if(item.Id == saved_group.Id){
                            angular.extend(item, saved_group);
                            scope.groupGrid.api.setRowData(scope.groups);
                        }
                    });
                } else { //created
                    scope.groups.push(saved_group);
                    scope.groupGrid.api.setRowData(scope.groups);
                }

                scope.SaveMessage = "Success.";
            }, function(error){
                console.dir(error);
                scope.ErrorMessage = "Failed.";
            });
        }


    }

];

export default admin_groups;
