/*
*   This page loads the project locations.
*/
import * as angular from 'angular';
import {
  SYSTEM_LOCATION_DATASET, 
  GEOMETRY_SERVICE_URL, 
  NAD83_SPATIAL_REFERENCE, 
  ENVIRONMENT,
  CDMS_POINTS_TEST_URL, 
  CDMS_POINTS_PROD_URL
} from "../../../../config";
import {modalFiles_setupControllerForFileChooserModal} from "../../../common/components/file/modal-files";
import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';
import { EsriMapComponent } from "src/app/map/esri-map/esri-map.component";
//--------
import Map from "@arcgis/core/Map";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import Graphic from "@arcgis/core/Graphic";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as geometryServ from "@arcgis/core/rest/geometryService";
import Point from "@arcgis/core/geometry/Point";
//import ProjectParameters from "@arcgis/core/tasks/support/ProjectParameters"; // Deprecated
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import Query from "@arcgis/core/rest/support/Query";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

var project_locations = ['$scope', '$routeParams', 'GridService', 'ProjectService', 'DatasetService', 'CommonService', 'UserService',
  '$rootScope', '$uibModal', '$sce', '$window', '$http',
  'ServiceUtilities', '$location', '$anchorScroll', 'Upload', 'SubprojectService',
  function (scope, routeParams, GridService, ProjectService, DatasetService, CommonService, UserService, $rootScope, $uibModal, $sce, $window, $http,
            ServiceUtilities, $location, $anchorScroll, $upload, SubprojectService) {
    console.log("Inside controllers.js, projectDatasetsController...");
    console.log("routeParams.Id = " + routeParams.Id);

    //$rootScope.IsFirstRun = true;

    scope.OnTab = "Locations";
    scope.ag_grid_div = false;
    scope.datasets = ProjectService.getProjectDatasets(routeParams.Id);
    scope.project = ProjectService.getProject(routeParams.Id);
    scope.locationDataset = DatasetService.getDataset(SYSTEM_LOCATION_DATASET); //load the dataset with the fields for location grid
    scope.selectedDataset = null;
    scope.map = new Map();

    scope.ShowMap = {
      Display: false,
      Prod: false,
      Test: false,
      Message: "Show Map",
      MessageToOpen: "Show Map",
      MessageToClose: "Hide Map",
    };

    console.log("ENVIRONMENT = " + ENVIRONMENT);
    if (ENVIRONMENT == "prod")
    {
      scope.ShowMap.Test = false;
      scope.ShowMap.Prod = true;
      scope.map.locationLayer = new FeatureLayer({url:CDMS_POINTS_PROD_URL});
    }
    else
    {
      scope.ShowMap.Test = true;
      scope.ShowMap.Prod = false;
      scope.map.locationLayer = new FeatureLayer({url:CDMS_POINTS_TEST_URL});
    }
    console.log("scope.map.locationLayer is next...");
    console.dir(scope.map.locationLayer);

    scope.toggleMap = function () {
      if (scope.ShowMap.Display) {
        scope.ShowMap.Display = false;
        scope.ShowMap.Message = scope.ShowMap.MessageToOpen;
      } else {
        scope.ShowMap.Display = true;
        scope.ShowMap.Message = scope.ShowMap.MessageToClose;
      }
    };

    modalFiles_setupControllerForFileChooserModal(scope, $uibModal, scope.project.Files);
    //once the datasets load, make sure each is configured with our scope.
    /*
    scope.datasets.$promise.then(function () {
        if ((scope.datasets) && (scope.datasets.length > 0)) {
           for (var i = 0; i < scope.datasets.length; i++) {
        DatasetService.configureDataset(scope.datasets[i], scope);  // We must pass the scope along on this call.
    }
  } else {
    console.warn("This project has no datasets.");
        }
    });
*/
    // todo: why is this promise resolving twice sometimes?
    scope.locationDataset.$promise.then(function () {
      scope.dataGridOptions.columnDefs = GridService.getAgColumnDefs(scope.locationDataset).HeaderFields;
      scope.dataGridOptions.columnDefs.unshift(
        {
          field: 'Assigned',
          headerName: 'Assigned',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          width: 110,
          maxWidth: 110,
          alwaysShowField: true,
          hide: true,
          menuTabs: [],
          comparator: scope.assignedComparator
        }),
        scope.activateDataGrid();
      //console.dir(scope.dataGridOptions.columnDefs);
      console.log("project locations resolved");
    });

    scope.showLocations = function (dataset) {

      //select the locations for this dataset - and float selected to the top
      scope.selectedDataset = dataset;

      scope.dataGridOptions.api.deselectAll();
      scope.dataGridOptions.api.forEachNode(function (node) {

        dataset.Locations.forEach(function (location) {
          if (location.Id == node.data.Id)
            node.setSelected(true);
        })
      });

      scope.dataGridOptions.columnApi.setColumnVisible('Assigned', true);
      scope.dataGridOptions.suppressRowClickSelection = true;

      var sort = [
        {colId: 'Assigned', sort: 'desc'}
      ];

      scope.dataGridOptions.api.setSortModel(sort);

    };

    scope.showProjectLocations = function () {
      scope.selectedDataset = null;
      scope.dataGridOptions.api.deselectAll();

      scope.dataGridOptions.columnApi.setColumnVisible('Assigned', false);
      scope.dataGridOptions.suppressRowClickSelection = false;

      //scope.displayLocationsOnMap();
    };


    scope.displayLocationsOnMap = function () {
      var locationIds = [];
      if (scope.dataGridOptions.api.getSelectedNodes().length != 0) {
        scope.dataGridOptions.api.getSelectedNodes().forEach(node => {
          locationIds.push(node.data.SdeObjectId);
        });

      } else {
        scope.dataGridOptions.api.forEachNodeAfterFilter(function (node) {
          //console.dir(node);
          locationIds.push(node.data.SdeObjectId);
        });
      }


      // if (scope.map && scope.map.locationLayer && scope.map.locationLayer.hasOwnProperty('showLocationsById'))
      scope.locationIds = locationIds;
      // scope.selectedIds = locationIds;

      //console.log("showing these locations");
      //console.dir(locationIds);

    }

    scope.highlightSelectionOnMap = function () {
      var selectedRows = [];
      if (scope.dataGridOptions.api.getSelectedNodes().length != 0) {
        scope.dataGridOptions.api.getSelectedNodes().forEach(node => {
          // locationIds.push(node.data.SdeObjectId);
          selectedRows.push(node.data);
          // node.index = 0;
        });
      }
      // else {
      //   scope.dataGridOptions.api.forEachNodeAfterFilter(function (node) {
      //     console.log()
      //     //console.dir(node);
      //     // selectedRows.push(node.data);
      //     // locationIds.push(node.data.SdeObjectId);
      //   });
      // }

      scope.selectedIds = selectedRows;

      //  let sort = [
      //   {colId: 'Assigned', sort: 'desc'}
      // ];
      //
      // // set selected row to top of grid
      // scope.dataGridOptions.api.setSortModel(sort);

    }

    //datasets tab grid
    scope.dataGridOptions = {
      suppressPropertyNamesCheck: true,
      columnDefs: [],
      rowSelection: 'multiple',
      suppressRowClickSelection: false,
      onSelectionChanged: function (params) {
        //console.dir(params);
        scope.dataGridOptions.selectedItems = scope.dataGridOptions.api.getSelectedRows();
        // scope.displayLocationsOnMap();
        scope.highlightSelectionOnMap();
        scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid

      },
      onGridReady: function (params) {
        console.log("Grid ready!");
      },
      selectedItems: [],
      onRowDoubleClicked: function (params) {
        console.log("Inside onRowDoubleClicked...");
        console.dir(params);
        if (scope.Profile.canEdit(scope.project))
          scope.openEditModal(params.data);
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        width: 180,
        maxWidth: 250
      },
      onFirstDataRendered: function (params) {
        let allColumnIds = [];
        scope.dataGridOptions.columnApi.getAllColumns().forEach(function (columnDef) {
          allColumnIds.push(columnDef.colId);
        });
        scope.dataGridOptions.columnApi.autoSizeColumns(allColumnIds);
        scope.displayLocationsOnMap();
      },

    };

    scope.updateGrid = function (sdeObjectId) {

      scope.dataGridOptions.api.forEachNode(node => {
        if (node.data.SdeObjectId == sdeObjectId) {
          scope.dataGridOptions.api.deselectAll();
          node.setSelected(true);
          node.checkboxSelection = true;
          scope.$apply();
        }
      });
    }


    scope.activateDataGrid = function () {

      if (!scope.ag_grid_div) {
        scope.ag_grid_div = document.querySelector('#locations-grid') as HTMLElement;
        scope.datatab_ag_grid = new Grid(scope.ag_grid_div, scope.dataGridOptions); //bind the grid to it.
        scope.dataGridOptions.api.showLoadingOverlay(); //show loading...
        scope.ag_grid_div = true;
      }

      scope.project.$promise.then(function () {

        scope.loadProjectLocations();

        scope.project.Config = (scope.project.Config) ? angular.fromJson(scope.project.Config) : {};
      console.log("activate data grid called")
        // scope.displayLocationsOnMap();

      });

    };

    scope.openEditModal = function (a_selection) {
      scope.SaveMessage = null;
      scope.row = angular.copy(a_selection);
      scope.outpayload = {info: {}};
      $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-location.html',
        controller: 'ModalEditLocationCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false,
        // resolve: scope.outpayload
      }).result.then(function (saved_location) {
        console.log("resolving promise and saved location")
        console.log(saved_location);
        scope.outpayload = saved_location;
        //replace that location in the grid with the one we got back
        scope.all_locations.forEach(function (existing_location, index) {
          if (existing_location.Id == saved_location.Location.Id) {
            console.dir("found field to replace : " + existing_location.Id);
            scope.all_locations[index] = saved_location.Location;

            // scope.dataGridOptions.api.setRowData(scope.all_locations[index]);
          }
        });

        scope.dataGridOptions.api.setRowData(scope.all_locations);
        scope.SaveMessage = "Success.";

        if (!scope.selectedDataset) {
          scope.showProjectLocations();
        } else {
          scope.showLocations(scope.selectedDataset);
        }

      });
    };


    scope.addLocation = function (a_selection) {
      scope.SaveMessage = null;
      scope.row = GridService.getNewRow(scope.dataGridOptions.columnDefs); //sets the DefaultValue, etc.
      scope.row.Status = 0;

      //console.dir(scope.row);

      var modalInstance = $uibModal.open({
        templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-location.html',
        controller: 'ModalEditLocationCtrl',
        scope: scope, //very important to pass the scope along...
        backdrop: "static",
        keyboard: false
      }).result.then(function (saved_location) {
        scope.outpayload = saved_location;
        // console.log(saved_location);
        // console.log("in project-locations.addLocation()");
        // //reload all the project locations to pickup the new one
        scope.loadProjectLocations();

        //if we were in the context of a dataset, link this new location to it
        if (scope.selectedDataset) {
          var save = ProjectService.addLocation(scope.selectedDataset.Id, saved_location.Id);

          save.$promise.then(function () {
            //reload the project locations
            scope.datasets = ProjectService.getProjectDatasets(routeParams.Id);

            //reload the dataset that was updated
            scope.datasets.$promise.then(function () {
              scope.datasets.forEach(function (dataset) {
                if (dataset.Id == scope.selectedDataset.Id)
                  scope.showLocations(dataset);
              })
            })
          })


        }
      });
    };


    scope.deleteLocations = function () {

      //delete selected locations
      var locationIds = [];
      // these are the object Ids that will be passed to the map component for deletion
      let deleteIds = [];

      
      scope.dataGridOptions.selectedItems.forEach(function (location) {
        deleteIds.push(location.SdeObjectId);
        locationIds.push(location.Id);
      });

      var blnLocationBelongsToHabitatDataset = false;
      var blnGetHabitatsHasFinished = false;
      var ErrMessage = ""; //This location cannot be deleted, until it is removed from the following datasets...\n";
      scope.HabitatSubprojects = SubprojectService.getHabSubprojects();

      scope.HabitatSubprojects.$promise.then(function () {
        scope.HabitatSubprojects.forEach(function (hs) {
          locationIds.forEach(function (aLocId) {
            if (hs.LocationId === aLocId)
            {
              blnLocationBelongsToHabitatDataset = true;
              ErrMessage += "One of the locations selected for deletion is a habitat site.\n" +
                "Habitat locations must be removed using the Habitat pages.";
            }
          });
        });

        if (blnLocationBelongsToHabitatDataset)
        {
          alert(ErrMessage);
          return;
        }

        //scope.deletePoints = deleteIds;

        //var esriMapComponent = new EsriMapComponent();

        //esriMapComponent.deleteLocations(scope.project.Id, scope.row, $rootScope.IsFirstRun, scope.deletePoints, $rootScope);

        // $uibModalInstance.close(
        //   // on close this information is sent to the esri-map component, and enters as the inPayload object.
        //   {
        //     'ProjectId': scope.project.Id,
        //     'Location': scope.row,
        //     'IsFirstRun': true,
        //     'deletePoints': deleteIds
        //   });

          //scope.deletePoints.forEach (function (item) {
          deleteIds.forEach (function (item) {

            var inSR = new SpatialReference({ wkt: NAD83_SPATIAL_REFERENCE });
            var outSR = new SpatialReference({ wkid: 102100 });
            //var GeometryService = new geometryService({URL: GEOMETRY_SERVICE_URL});
  
            var theSdeObjectId = 0;
            var theEasting = 0;
            var theNorthing = 0;
            scope.selectedIds.forEach(function (sId) {
              if (sId.SdeObjectId === item)
              {
                theSdeObjectId = sId.SdeObjectId;
                theEasting = sId.GPSEasting;
                theNorthing = sId.GPSNorthing;
              }
            });

            //var theEasting = scope.selectedIds.GPSEasting;
            //var theNorthing = scope.selectedIds.GPSNorthing;
            //var pointParams = {"x": theEasting, "y": theNorthing};
            //$scope.newPoint = new Point($scope.row.GPSEasting, $scope.row.GPSNorthing, inSR);
            //scope.newPoint = new Point(pointParams);
            // Instead of creating the parameters for a new point,
            // and then creating the point from them, like we did above,
            // just create the point by passing the parameters in directly, in the next line.
            scope.newPoint = new Point({x: theEasting, y: theNorthing, spatialReference: inSR});
  
            //convert spatial reference
            var PrjParams = new ProjectParameters();
            PrjParams.outSpatialReference = outSR;
  
            PrjParams.geometries = [scope.newPoint];
  
            //geometryService.project(PrjParams, function (outputpoint) {
            geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((outputpoint) => {
              let symbol = new SimpleMarkerSymbol({
                style: "circle",
                color: "#00ff00",
                size: "8px",
              });
            
              var attributes = {OBJECTID: theSdeObjectId};
              //var deleteGraphic = new Graphic({geometry: this.newPoint, attributes: attributes});
  
              //scope.newPoint = new Point(outputpoint[0], outSR);
              //var newGraphic = new Graphic({geometry: scope.newPoint, symbol: symbol});
              scope.PointToDelete = new Point(outputpoint[0]);
  
              //throw "Stopping right here...";
  
              var deleteGraphic = new Graphic({geometry: scope.PointToDelete, symbol: symbol, attributes: attributes});
              // var deletes = {
              //   deleteFeatures: [deleteGraphic]
              // };
              //var DeleteFeats = {
              //  deletes: [deleteGraphic]
              //};
  
              // Note:  You cannot just pass the point to delete; they must be in a graphic object,
              //        or the code dies in dojo-land, which is different than what is shown in 
              //        ArcGIS documenation on the service interface.
              // this.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}, null,).then((deleteResults) => { // From esri-map.component.ts
              //scope.map.locationLayer.deleteFeatures({objectIds: theSdeObjectId}).then (function (deleteResults) { // locationLayer.deleteFeatures is not a function
              //$scope.map.locationLayer.applyEdits(null, null, [deleteGraphic], function (addResults, updateResults, deleteResults) { // From masterV2
              //scope.map.locationLayer.applyEdits(null, null, [deleteGraphic]).then (function (deleteResults) { // Cleaned and adjusted for V3.  
                //Error:  Missing params:  addFeatures, updateFeatures, or deleteFeatures is required.
              //scope.map.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}, null).then (function (deleteResults) { // Err:  Unable to complete operation.
                //Error:  No edits (adds, updates, deletes, or attachment edits) were specified.
              //scope.map.locationLayer.applyEdits({deleteFeatures: {adds: null, updates: null, deleted: [deleteGraphic]}}, null).then (function (deleteResults) { // Err
                //Error: deleteFeatures.forEach is not a function.
              //scope.map.locationLayer.applyEdits(deletes).then (function (deleteResults) { // Err:  Unable to complete operation
                //Error:  No Edits (adds, updates, deletes, or attachment edits) were specified.
              //scope.map.locationLayer.applyEdits(null, null, deletes, null).then (function (deleteResults) { // 
                //Error:  Apply Edits - Delete - failed:  'addFeatures', 'updateFeatures', 'deleteFeatures', 'addAttachments', 'updateAttachments' or 'deleteAttachments' parameter is required
              //scope.map.locationLayer.applyEdits(null, null, deleteFeatures).then (function (deleteResults) { // feature-layer:missing-parameters
                //Error:  'addFeatures', 'updateFeatures', 'deleteFeatures', 'addAttachments', 'updateAttachments' or 'deleteAttachments' parameter is required"
              //scope.map.locationLayer.applyEdits({deleteFeatures: DeleteFeats}).then (function (deleteResults) { // deleteFeatures.forEach is not a function
                //Error:  
              //scope.map.locationLayer.applyEdits({deleteFeatures: deletes}).then (function (deleteResults) { // deleteFeatures.forEach is not a function
                //Error: 
              // Keeping in the iterations above, just to remember them and avoid going through it all again to get the next line.
              // The next line is what actually works. 
              scope.map.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}).then (function (deleteResults) { // This works
  
                //var query = new Query();
                //query.objectIds = [deletes[0].objectId];
                //$scope.map.locationLayer.selectFeatures(query, )
                console.log("deleteResults is next...");
                console.dir(deleteResults);
  
                //.then(function (deleteResults) {
                // if (Array.isArray(deleteResults) && deleteResults[0] && deleteResults[0].length === 0)
                // {
                //     scope.SaveMessage = "The location did not exist in SDE.";
                //     console.dir(deleteResults);
                // }
                // else if (Array.isArray(deleteResults) && deleteResults[0] && deleteResults[0].success) {
                //     console.log("Deleted old point! " + scope.row.SdeObjectId);

                //     //CommonService.UpdateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.OldSdeObjectId);
                //     CommonService.updateLocationAction(scope.project.Id, scope.row.Id, scope.row.SdeObjectId);
                // }
                // else {
                //     scope.SaveMessage = "There was a problem deleting that location.";
                //     console.dir(deleteResults);
                // }

                if ((deleteResults) &&
                  (deleteResults.deleteFeatureResults) &&
                  (deleteResults.deleteFeatureResults.length === 0)
                )
                {
                  scope.SaveMessage = "The location did not exist in SDE.";
                  //console.dir(deleteResults);
                }
                else if ((deleteResults) &&
                  (deleteResults.deleteFeatureResults) &&
                  (deleteResults.deleteFeatureResults.length > 0)
                )
                {
                  console.log("Deleted the following item from SDE:  " + deleteResults.deleteFeatureResults[0].objectId);
                }
                else {
                    scope.SaveMessage = "There was a problem deleting that location.";
                    console.dir(deleteResults);
                }
              }, function (err) {
                  console.log("Apply Edits - Delete - failed:  " + err.message);
              });
            
            
              var data = {
                  ProjectId: scope.project.Id,
              };
              
  
              //var target = '/api/v1/file/UploadProjectFile';
              //scope.handleFilesToUploadRemove(scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
              
            });
          });
  
          //throw "Inside project-locations.ts, stopping right here, to leave the point in CDMS.";
          var delete_loc = CommonService.deleteLocations(locationIds); // This just removes the location from CDMS, not SDE.
  
          delete_loc.$promise.then(function (data) {
            console.log("deleted locations");
            scope.dataGridOptions.api.deselectAll()
            scope.loadProjectLocations();
          }, function (error) {
            console.dir(error);
            alert("Could not delete some locations because activities exist. Remove them and then you can delete those locations.");
            scope.loadProjectLocations();
  
            // $uibModalInstance.close(
            //   // on close this information is sent to the esri-map component, and enters as the inPayload object.
            //   {
            //     'ProjectId': $scope.project.Id,
            //     'Location': $scope.row,
            //     'IsFirstRun': true
            //   });
          });
        //}

      });
    };

    scope.click = function () {
    } //don't do anything for clicking for now...

    //handle favorite toggle
    scope.isFavorite = $rootScope.Profile.isProjectFavorite(routeParams.Id);
    scope.toggleFavorite = function () {
      UserService.toggleFavoriteProject(scope, $rootScope);
    }

    scope.assignedComparator = function (one, two, nodeone, nodetwo) {
      if (nodeone.isSelected() && nodetwo.isSelected())
        return 0;

      if (!nodeone.isSelected() && !nodetwo.isSelected())
        return 0;

      if (nodeone.isSelected() && !nodetwo.isSelected())
        return 1;

      if (!nodeone.isSelected() && nodetwo.isSelected())
        return -1;

    }

    scope.resetAssignments = function () {
      scope.showLocations(scope.selectedDataset);
    }

    scope.assignedUnchanged = function () {

      if (!scope.dataGridOptions.selectedItems || !scope.selectedDataset || !scope.selectedDataset.Locations)
        return true;

      //easy - if the lengths don't match then obviously we've changed.
      if (scope.selectedDataset.Locations.length != scope.dataGridOptions.selectedItems.length)
        return false;

      var noChange = true;
      scope.dataGridOptions.selectedItems.forEach(function (item) {
        var isAssignedLocation = false;
        scope.selectedDataset.Locations.forEach(function (loc) {
          if (loc.Id == item.Id)
            isAssignedLocation = true;
        })
        noChange = noChange && isAssignedLocation;
      })

      //noChange only true at this point if all assigned exist in datset locations
      // so if all assigned are in the locations and none are added then we return true, else false.
      return (noChange && scope.selectedDataset.Locations.length == scope.dataGridOptions.selectedItems.length);

    }

    scope.loadProjectLocations = function () {
      scope.all_locations = ProjectService.getLocations(scope.project.Id);

      scope.all_locations.$promise.then(function () {
        scope.dataGridOptions.api.setRowData(scope.all_locations);

        //autoexpand columns once we have data

        let allColumnIds = [];
        scope.dataGridOptions.columnApi.getAllColumns().forEach(function (columnDef) {

          allColumnIds.push(columnDef.colId);
        });

        scope.dataGridOptions.columnApi.autoSizeColumns(allColumnIds);
      })
    }

    scope.saveLocations = function () {

      var locationIds = [];
      scope.dataGridOptions.selectedItems.forEach(function (item) {
        locationIds.push(item.Id);
      })

      var save = ProjectService.saveLocations(scope.selectedDataset.Id, locationIds);

      save.$promise.then(function () {
        //reload the project locations
        scope.datasets = ProjectService.getProjectDatasets(routeParams.Id);
        scope.loadProjectLocations();

        //reload the dataset that was updated
        scope.datasets.$promise.then(function () {
          scope.datasets.forEach(function (dataset) {
            if (dataset.Id == scope.selectedDataset.Id)
              scope.showLocations(dataset);
          })
        })
      })
    }

    scope.saveNewPoint = function (inId) {
      console.log('inside saveNewPoint');
      console.log(inId);
      if (inId.pointId) {
        scope.row.SdeObjectId = inId.pointId
        console.log("Set row.SdeObjectId:  " + scope.row.SdeObjectId);

        var data = {
          ProjectId: scope.project.Id,
        };

        var target = '/api/v1/file/UploadProjectFile';

        scope.handleFilesToUploadRemove(scope.row, data, target, $upload, $rootScope, scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.

      } else {
        scope.SaveMessage = "There was a problem saving that location.";
        console.dir(inId.pointId);
      }
    }

    scope.updateExistingPoint = function (inId) {
      if (inId.hasOwnProperty('Editing'))
        //    $uibModalInstance.close(
        //     // on close this information is sent to the esri-map component
        //  {
        //   'ProjectId': scope.project.Id,
        //   'Location': scope.row,
        // });


        // Capture the old SdeObjectId, so we can delete it later.
        scope.OldSdeObjectId = scope.row.SdeObjectId;


        // scope.map.locationLayer.applyEdits([scope.newGraphic], null, null).then(function (addResults) {
        if (inId.hasOwnProperty('pointId')) {

          //.then(function (deleteResults) {
          if (inId.hasOwnProperty('failed')) {
            scope.SaveMessage = "The location did not exist in SDE.";
            console.dir(inId);
          } else if (inId.hasOwnProperty('pointId')) {
            console.log("In project-locations. Deleted old point in sdevector! " + scope.row.SdeObjectId);

            //CommonService.UpdateLocationAction(scope.project.Id, scope.row.Id, scope.row.SdeObjectId, scope.OldSdeObjectId);
            CommonService.updateLocationAction(scope.project.Id, scope.row.Id, scope.row.SdeObjectId);
          } else {
            scope.SaveMessage = "There was a problem deleting that location.";
            console.dir(inId);
          }
          // }
          // ,

          // function (err) {
          //   console.log("Apply Edits - Delete - failed:  " + err.message);
          // }

          // );

          var data = {
            ProjectId: scope.project.Id,
          };

          var target = '/api/v1/file/UploadProjectFile';
          scope.handleFilesToUploadRemove(scope.row, data, target, $upload, $rootScope, scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
        } else {
          scope.SaveMessage = "There was a problem adding the new location.";
          console.dir(inId);
        }


        // });
        // });

        var target = '/api/v1/file/UploadProjectFile';

        scope.handleFilesToUploadRemove(scope.row, data, target, $upload, $rootScope, scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.

    }

    scope.modalFile_saveParentItem = function (saveRow) {

      saveRow.WaterBody = undefined;

      var new_location = CommonService.saveNewProjectLocation(scope.project.Id, saveRow);
      new_location.$promise.then(function () {
        console.log("done and success!");
        scope.loadProjectLocations();
        return new_location;
        // $uibModalInstance.close(new_location);
      });
    };
  }

];


export default project_locations;



