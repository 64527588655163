
// user preferences factories and service

/*
* define the service that can be used by any module in our application to work with projects.
*/
const user_service = ['$q',
    'SaveUserInfo',
    'GetMyProjectsAction',
    'SaveUserPreferenceAction',
    'GetMyDatasetsAction',
    'GetMyLastUpdatedDatasets',
    'UserLoginsPastMonth',
    'UserRequestsTotalPastMonth',
    'DatasetRequestsTotalPastMonth',
    'LastUpdatedDatasets',
    'GetMyProjectsList',
    'GetMyDatasetsList',
    'SearchProjects',
    'SearchDatasets',
    'SearchFiles',

    function ($q,
        SaveUserInfo,
        GetMyProjectsAction,
        SaveUserPreferenceAction,
        GetMyDatasetsAction,
        GetMyLastUpdatedDatasets,
        UserLoginsPastMonth,
        UserRequestsTotalPastMonth,
        DatasetRequestsTotalPastMonth,
        LastUpdatedDatasets,
        GetMyProjectsList,
        GetMyDatasetsList,
        SearchProjects,
        SearchDatasets,
        SearchFiles
) {

        var service = {

            getUserLoginsPastMonth: function () {
                return UserLoginsPastMonth.query();
            },

            getUserRequestsTotalPastMonth: function () {
                return UserRequestsTotalPastMonth.query();
            },

            getDatasetRequestsTotalPastMonth: function () {
                return DatasetRequestsTotalPastMonth.query();
            },

            getLastUpdatedDatasets: function () {
                return LastUpdatedDatasets.query();
            },

            getMyDatasets: function () {
                return GetMyDatasetsAction.query();
            },

            getMyProjects: function () {
                return GetMyProjectsAction.query();
            },

            getMyDatasetsList: function () {
                return GetMyDatasetsList.query();
            },

            getMyProjectsList: function () {
                return GetMyProjectsList.query();
            },

            saveUserPreference: function (name, value) {
                var payload = { UserPreference: { Name: name, Value: value } };

                return SaveUserPreferenceAction.save(payload);

            },

            saveUserInfo: function (user, scope) {
                console.log("Inside services, DatasetService.saveUserInfo...");
                var payload = { User: user };

                return SaveUserInfo.save(payload);

            },

            getMyLastUpdatedDatasets: function () {
                return GetMyLastUpdatedDatasets.query();
            },

            //the_scope needs the project set; toggles the favorite status.
            toggleFavoriteProject: function(the_scope, root_scope){

                if (!the_scope || !the_scope.project)
                    return;

                the_scope.isFavorite = !the_scope.isFavorite; //make the visible change instantly.

                root_scope.Profile.toggleProjectFavorite(the_scope.project);

                var save_pref = this.saveUserPreference("Projects", root_scope.Profile.favoriteProjects.join());

                save_pref.$promise.then(function () {
                    //success = already done.
                }, function (data) {
                    //if something goes wrong, roll it back.
                    the_scope.isFavorite = !the_scope.isFavorite;
                    root_scope.Profile.toggleProjectFavorite(the_scope.project);
                });
            },

            //the_scope needs the dataset set; toggles the favorite status.
            toggleFavoriteDataset: function(the_scope, root_scope){

                the_scope.isFavorite = !the_scope.isFavorite; //make the visible change instantly.

                root_scope.Profile.toggleDatasetFavorite(the_scope.dataset);

                var save_pref = this.saveUserPreference("Datasets", root_scope.Profile.favoriteDatasets.join());

                save_pref.$promise.then(function () {
                    //success = already done.
                }, function (data) {
                    //if something goes wrong, roll it back.
                    the_scope.isFavorite = !the_scope.isFavorite;
                    root_scope.Profile.toggleDatasetFavorite(the_scope.dataset);
                });
            },

            searchProjects: function(search_term) {
                return SearchProjects.save({Search: search_term});
            },

            searchDatasets: function(search_term) {
                return SearchDatasets.save({Search: search_term});
            },

            searchFiles: function(search_term) {
                return SearchFiles.save({Search: search_term});
            },
        };

        return service;
    }
];

export default user_service;

