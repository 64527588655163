//Permits api

import {PERMIT_PROJECTID, serviceUrl} from "../../config";



const permit_services = ['$q',

    'AllPermits',
    'GetGroupPermits',
    'GetPermitContacts',
    'GetPermitParcels',
    'GetPermitEvents',
    'GetPermitFiles',
    'SavePermit',
    'GetAllPersons',
    'SavePermitContact',
    'SavePerson',
    'RemovePermitContact',
    'RemovePermitLocation',
    'AllParcels',
    'SavePermitParcel',
    'RemovePermitParcel',
    'SavePermitEvent',
    'RoutingPermits',
    'InspectionPermits',
    'DeletePermitFile',
    'GetRelatedParcels',
    'GetExpiringPermits',
    'GetOutstandingRequests',
    'GetPermitStatistics',
    'GetMyPermitStatistics',
    'GetPermitByPermitNumber',
    'GetPublicHearingPermits',
    'GetNotifications',
    'GetPermitRoutes',
    'GetPermitTypes',
    'DeletePerson',
    'GetPermitEventsByItemType',
    'GetPermitEventsForMe',
    'DownloadInspectionTemplate',
    'GetPermitLocations',
    'GetAllPermitLocations',
    'GetPermitDatasetLocations',
    'SavePermitLocation',
    'SearchPermits',

    function ($q,

        AllPermits,
        GetGroupPermits,
        GetPermitContacts,
        GetPermitParcels,
        GetPermitEvents,
        GetPermitFiles,
        SavePermit,
        GetAllPersons,
        SavePermitContact,
        SavePerson,
        RemovePermitContact,
        RemovePermitLocation,
        AllParcels,
        SavePermitParcel,
        RemovePermitParcel,
        SavePermitEvent,
        RoutingPermits,
        InspectionPermits,
        DeletePermitFile,
        GetRelatedParcels,
        GetExpiringPermits,
        GetOutstandingRequests,
        GetPermitStatistics,
        GetMyPermitStatistics,
        GetPermitByPermitNumber,
        GetPublicHearingPermits,
        GetNotifications,
        GetPermitRoutes,
        GetPermitTypes,
        DeletePerson,
        GetPermitEventsByItemType,
        GetPermitEventsForMe,
        DownloadInspectionTemplate,
        GetPermitLocations,
        GetAllPermitLocations,
        GetPermitDatasetLocations,
        SavePermitLocation,
        SearchPermits

    ) {
        var service = {

            getAllPermits: function () {
                return AllPermits.query();
            },

            getGroupPermits: function (Id) {
                return GetGroupPermits.query({Id: Id});
            },

            getInspectionPermits: function () {
                return InspectionPermits.query();
            },

            getRoutingPermits: function () {
                return RoutingPermits.query();
            },

            getAllParcels: function () {
                return AllParcels.query();
            },

            getPermitContacts: function (Id) {
                return GetPermitContacts.query({ Id: Id });
            },

            getPermitParcels:  function (Id) {
                return GetPermitParcels.query({ Id: Id });
            },

            getPermitTypes: function () {
                return GetPermitTypes.query();
            },

            getPermitEvents:  function (Id) {
                return GetPermitEvents.query({ Id: Id });
            },

            getPermitEventsForMe:  function () {
                return GetPermitEventsForMe.query();
            },

            getPermitEventsByItemType:  function (itemType) {
                return GetPermitEventsByItemType.save({ ItemType: itemType });
            },

            getPermitFiles: function (ProjectId, PermitId) {
                //return GetPermitFiles.query({ ProjectId: PERMIT_PROJECTID, PermitId: PermitId });
                return GetPermitFiles.query({ ProjectId: ProjectId, PermitId: PermitId });
            },

            getPermitsByRelatedParcels: function (ParcelId) {
                return GetRelatedParcels.query({ ParcelId: ParcelId });
            },

            savePermit: function (permit) {
                return SavePermit.save({ Permit: permit });
            },

            savePerson: function (person) {
                return SavePerson.save({ Person: person });
            },

            savePermitContact: function (permitcontact) {
                return SavePermitContact.save({ PermitContact: permitcontact });
            },

            savePermitLocation: function (permitId, permitLocationId) {
                return SavePermitLocation.save({ PermitId: permitId, PermitLocationId: permitLocationId });
            },

            getAllPersons:  function (Id) {
                return GetAllPersons.query();
            },

            removeContact: function (permitcontact) {
                return RemovePermitContact.save({ PermitContact: permitcontact });
            },

            removePermitLocation: function (permitId) {
                return RemovePermitLocation.save({ PermitId: permitId });
            },

            savePermitParcel: function (permitparcel) {
                return SavePermitParcel.save({ PermitParcel: permitparcel });
            },

            removePermitParcel: function (permitparcel) {
                return RemovePermitParcel.save({ PermitParcel: permitparcel });
            },

            savePermitEvent: function (permitevent) {
                return SavePermitEvent.save({ PermitEvent: permitevent });
            },

            deletePermitFile: function (projectId, subprojectId, itemId, file) {
                return DeletePermitFile.save({ ProjectId: projectId, SubprojectId: subprojectId, ItemId: itemId, File: file });
            },

            deletePerson: function(Id){
                return DeletePerson.save({Id: Id});
            },

            downloadInspectionDocument: function(permitEventId){
                return DownloadInspectionTemplate.save({PermitEventId: permitEventId});
            },

            getOutstandingRequests: function () {
                return GetOutstandingRequests.query();
            },

            getExpiringPermits: function () {
                return GetExpiringPermits.query();
            },

            getPermitStatistics: function () {
                return GetPermitStatistics.query();
            },

            getMyPermitStatistics: function () {
                return GetMyPermitStatistics.query();
            },

            getPermitByPermitNumber: function (permitnumber) {
                return GetPermitByPermitNumber.query({ PermitNumber: permitnumber });
            },

            getPublicHearingPermits: function () {
                return GetPublicHearingPermits.query();
            },

            getPermitRoutes: function () {
                return GetPermitRoutes.query();
            },

            getPermitRoutesByItemType: function (eventType, itemType) {
                return GetPermitRoutes.query({ EventType: eventType, ItemType: itemType });
            },

            getNotifications: function () {
                return GetNotifications.query({ Module: 'Permits' });
            },

            getPermitLocations: function (Id) {
                return GetPermitLocations.query({Id: Id});
            },

            getAllPermitLocations: function () {
                return GetAllPermitLocations.query();
            },

            getPermitDatasetLocations: function (Id) {
                return GetPermitDatasetLocations.query({Id: Id});
            },

            searchPermits: function(search_term) {
                return SearchPermits.save({Search: search_term});
            }

        };

        return service;

    }
];

export default permit_services;
