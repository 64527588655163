// AdminService
//  factories and service to manage dataset master and dataset fields...
//


const admin_services = ['$q',
    'SaveDatasetField',
    'SaveMasterField',
    'DeleteDatasetField',
    'GetAllFields',
    'AddMasterFieldToDataset',
    'GetAllDatastoreFields',
    'SaveDataset',
    'SaveNewDatastore',
    'RemoveMasterField',
    'SaveGroup',
    'SaveGroupMember',
    'RemoveGroupMember',
    'SaveDepartment',
    'SaveOrganization',
    'SaveOrgAdmin',
    'RemoveOrgAdmin',
    'RemoveGroup',
    'RemoveOrganization',
    'RemoveUser',
    'RemoveDepartment',
    'RemoveDatastore',
    'RegenerateViews',
    function ($q,
        SaveDatasetField,
        SaveMasterField,
        DeleteDatasetField,
        GetAllFields,
        AddMasterFieldToDataset,
        GetAllDatastoreFields,
        SaveDataset,
        SaveNewDatastore,
        RemoveMasterField,
        SaveGroup,
        SaveGroupMember,
        RemoveGroupMember,
        SaveDepartment,
        SaveOrganization,
        SaveOrgAdmin,
        RemoveOrgAdmin,
        RemoveGroup,
        RemoveOrganization,
        RemoveUser,
        RemoveDepartment,
        RemoveDatastore,
        RegenerateViews
        ) {

        var service = {

           addMasterFieldToDataset: function(datasetId, fieldId)
            {
               return AddMasterFieldToDataset.save({ DatasetId: datasetId, FieldId: fieldId });
            },

            saveNewDatastore: function(datastore){
                return SaveNewDatastore.save({ Datastore: datastore });
            },

            saveGroup: function(group){
                return SaveGroup.save({ Group: group });
            },

            saveDepartment: function(department){
                return SaveDepartment.save({ Department: department });
            },

            saveOrganization: function(organization){
                return SaveOrganization.save({ Organization: organization });
            },

            saveGroupMember: function(groupId, userId){
                return SaveGroupMember.save({ GroupId: groupId, UserId: userId });
            },

            removeGroupMember: function(groupId, userId){
                return RemoveGroupMember.save({ GroupId: groupId, UserId: userId });
            },

            removeGroup: function(groupId){
                return RemoveGroup.save({ GroupId: groupId });
            },

            removeOrganization: function(orgId){
                return RemoveOrganization.save({ OrganizationId: orgId });
            },

            removeUser: function(userId){
                return RemoveUser.save({ UserId: userId });
            },

            removeDepartment: function(deptId){
                return RemoveDepartment.save({ DepartmentId: deptId });
            },

            removeDatastore: function(datastoreId){
                return RemoveDatastore.save({ DatastoreId: datastoreId });
            },

            saveOrgAdmin: function(orgId, userId){
                return SaveOrgAdmin.save({ OrganizationId: orgId, UserId: userId });
            },

            removeOrgAdmin: function(orgId, userId){
                return RemoveOrgAdmin.save({ OrganizationId: orgId, UserId: userId });
            },

            removeField: function(datasetId, fieldId)
            {
				console.log("Trying to remove a field... datasetId = " + datasetId + ", fieldId = " + fieldId);
                return DeleteDatasetField.save({ DatasetId: datasetId, FieldId: fieldId });
            },

            removeMasterField: function(datastoreid, fieldId) {
                return RemoveMasterField.save({ DatastoreId: datastoreid, FieldId: fieldId });
            },

            regenerateViews: function(){
                return RegenerateViews.save();
            },

            getFields: function (id) {
                return GetAllDatastoreFields.query({ id: id });
            },

            getMasterFields: function (datastoreId) {
                return GetAllFields.query({ id: datastoreId });
            },

            saveDataset: function (dataset, saveResults) {
                saveResults.saving = true;

                SaveDataset.save({id: dataset.id, dataset: dataset}, function (data) {
                    saveResults.saving = false;
                    saveResults.success = true;
                }, function (data) {
                    saveResults.saving = false;
                    saveResults.failure = true;
                });
            },

            saveDatasetField: function (field) {

                return SaveDatasetField.save(field);

            },
            saveMasterField: function (field) {
                return SaveMasterField.save(field);
            },

        };

        return service;
    }
];

export default admin_services;
