import * as moment from 'moment';
import {valueFormatterBoolean} from "../../../../core/common/common-functions";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var modal_add_spring_inspection = ['$scope', '$uibModalInstance','LeasingService',
    function ($scope, $uibModalInstance, LeasingService) {

        $scope.header_message = "Edit Spring Inspection";

        //if we're not editing then we are creating a new one
        if (!$scope.inspection_modal) {
            $scope.header_message = "Add Spring Inspection";
            $scope.inspection_modal = {
                InspectionDateTime: $scope.currentDay,
                InspectedBy: $scope.currentUser,
                LeaseYear: "" + moment().year(),
                InspectionType: "Spring",
                ViolationType: "Crop",
            };
        }

        console.dir($scope.inspection_modal);

        var cropsColumnDefsModal = [
            { headerName: "Year", field: "LeaseYear", width: 75, sort: 'asc' },
            { headerName: "Crop Req'd", field: "CropRequirement", width: 120 },
            {
                headerName: "AltCrop?", field: "OptionAlternateCrop", width: 95,
                valueFormatter: function (params) {
                    return valueFormatterBoolean(params.node.data.OptionAlternateCrop);
                },

            }
        ];

        $scope.cropsGridModal = {
            columnDefs: cropsColumnDefsModal,
            rowData: $scope.lease.LeaseCropPlans,
            enableSorting: true,
            enableFilter: true,
            rowSelection: 'single'
        }

        //we want $uibModalInstance.rendered, but that isn't in our angularjs yet. :/
        $uibModalInstance.opened.then(function () {
            setTimeout(function () {
                $scope.cropsGridModalDiv = document.querySelector('#modal-crops-grid');
                new Grid($scope.cropsGridModalDiv, $scope.cropsGridModal);
            }, 500);
        })


        $scope.save = function () {
            console.dir($scope.inspection_modal);
            $scope.inspection_modal.LeaseId = $scope.lease.Id;
            var save_result = LeasingService.saveInspection($scope.inspection_modal);

            save_result.$promise.then(function () {
                $scope.saveLeaseCallback(save_result);
                $uibModalInstance.dismiss();
            });

        };


        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

    }
];

export default modal_add_spring_inspection;
