//modal to bulk update RowQAStatus
var modal_bulk_rowqa_change = ['$scope', '$uibModal','$uibModalInstance',

    function ($scope, $uibModal, $uibModalInstance) {

        $scope.newRowQAStatus = {};

        $scope.save = function () {
            $scope.setSelectedBulkQAStatus($scope.newRowQAStatus.Id);
            $uibModalInstance.close('save');
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

    }
];

export default modal_bulk_rowqa_change;
