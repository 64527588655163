// defines the project module and all project controllers.
import * as angular from 'angular';
import modal_permission_change_owner from "./components/project-detail/modal-permission-change-owner";
import modal_new_link from "./components/project-detail/modal-new-link";
import modal_edit_file from "./components/project-detail/modal-edit-file";
import modal_new_file from "./components/project-detail/modal-new-file";
import project_locations from "./components/project-detail/project-locations";
import modal_edit_lookup_item from "./components/project-detail/modal-edit-lookup-item";
import modal_edit_location from "./components/project-detail/modal-edit-location";
import modal_edit_project from "./components/project-detail/modal-edit-project";
import modal_choose_map from "./components/project-detail/modal-choose-map";
import project_files from "./components/project-detail/project-files";
import lookup_instruments from "./components/project-detail/project-lookup-instruments";
import module_add_accuracy_check from "./components/project-detail/modal-add-accuracy-check";
import project_landing from "./components/project-detail/project-landing";
import project_data from "./components/project-detail/project-data";
import project_list from "./components/project-list/project-list";
import modal_choose_summary_images from "./components/project-detail/modal-choose-summary-images";
import modal_projectconfig_choose_lists from "./components/project-detail/modal-choose-project-lists";
import project_permissions from "./components/project-detail/project-permissions";
import project_lookups from "./components/project-detail/project-lookups";
import {serviceUrl} from "../../config";
import subproject_service from "./services/subproject-service";
import project_service from "./services/project-service";
//load the components for this module
// require([
//'core/projects/components/project-detail/project-detail',
// 'core/projects/components/project-detail/project-landing',
//   'core/projects/components/project-detail/modal-new-file',
//   'core/projects/components/project-detail/modal-new-link',
//   'core/projects/components/project-detail/modal-edit-file',
//   'core/projects/components/project-detail/modal-edit-project',
//   'core/projects/components/project-detail/modal-choose-summary-images',
//   'core/projects/components/project-detail/modal-choose-map',
//   'core/projects/components/project-detail/modal-add-accuracy-check',
//   'core/projects/components/project-detail/modal-edit-location',
//   'core/projects/components/project-detail/modal-edit-lookup-item',
//
//   //tabs for the project detail page
//   'core/projects/components/project-detail/project-lookup-instruments',
//   'core/projects/components/project-detail/project-files',
//   'core/projects/components/project-detail/project-data',
//   'core/projects/components/project-detail/project-permissions',
//   'core/projects/components/project-detail/modal-permission-change-owner',
//   'core/projects/components/project-detail/project-lookups',
//   'core/projects/components/project-detail/project-locations',
//   'core/projects/components/project-detail/modal-choose-project-lists',
//   'core/projects/components/project-list/project-list',
//
//   //load project service
//   'core/projects/services/project-service',
//   'core/projects/services/subproject-service'

// ], function () {
//add the controllers and services to the module once the files are loaded!
const projects_module = angular.module('ProjectModule', ['ui.bootstrap', 'ngFileUpload', 'ui.select2', 'ngResource']);
//projects_module.controller('project-detail-ctrl', project_detail);
projects_module.controller('ProjectLandingCtrl', project_landing);
projects_module.controller('ModalEditFileCtrl', modal_edit_file);
projects_module.controller('ModalNewFileCtrl', modal_new_file);
projects_module.controller('ModalLinkCtrl', modal_new_link);
projects_module.controller('ModalProjectEditorCtrl', modal_edit_project);
projects_module.controller('ModalChooseSummaryImagesCtrl', modal_choose_summary_images);
projects_module.controller('ModalChooseMapCtrl', modal_choose_map);
projects_module.controller('ModalEditLocationCtrl', modal_edit_location);
projects_module.controller('ModalEditLookupItemCtrl', modal_edit_lookup_item);

projects_module.controller('LookupInstrumentsCtrl', lookup_instruments);
projects_module.controller('ProjectFilesCtrl', project_files);
projects_module.controller('ProjectDataCtrl', project_data);
projects_module.controller('ProjectPermissionsCtrl', project_permissions);
projects_module.controller('ModalChangeOwnerCtrl', modal_permission_change_owner);
projects_module.controller('ProjectLookupsCtrl', project_lookups);
projects_module.controller('ProjectLocationsCtrl', project_locations);
projects_module.controller('ModalProjectConfigLists', modal_projectconfig_choose_lists);
projects_module.controller('ProjectListCtrl', project_list);

projects_module.controller('ModalAddAccuracyCheckCtrl', module_add_accuracy_check);



// });

projects_module.factory('ProjectFunders', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/getprojectfunders', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('ProjectCollaborators', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/getprojectcollaborators', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('ProjectCounties', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/getprojectcounties', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('ProjectFiles', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/file/getprojectfiles', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('Projects', ['$resource', function (resource) {
    return resource(serviceUrl + '/api/v1/project/getprojects', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

projects_module.factory('ProjectLocations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/getlocations', {}, {
        query: { method: 'GET', params: { ProjectId: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('ProjectDatasetLocations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/getdatasetlocations', {}, {
        query: { method: 'GET', params: { ProjectId: 'projectId', DatasetId: 'datasetId' }, isArray: true }
    });
}]);


projects_module.factory('Project', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/getproject', {}, {
        query: { method: 'GET', params: { id: 'id' }, isArray: false }
    });
}]);

projects_module.factory('ProjectDatasets', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/getprojectdatasets', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('SaveProjectPermissions', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/saveprojectpermissions');
}]);

projects_module.factory('SaveLocations',['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/savelocations');
}]);

projects_module.factory('AddLocation',['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/addlocation');
}]);

projects_module.factory('SaveProject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/saveproject');
}]);

projects_module.factory('SaveLink', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/file/saveprojectlink');
}]);

projects_module.factory('SaveProjectConfig', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/SaveProjectConfig');
}]);

projects_module.factory('GetAllInstruments', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/getinstruments');
}]);

projects_module.factory('GetAllInstrumentAccuracyChecks', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/getallinstrumentaccuracychecks');
}]);

projects_module.factory('SaveProjectInstrument', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/saveprojectinstrument');
}]);

projects_module.factory('SaveProjectFisherman', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/fishermen/saveprojectfisherman');
}]);

projects_module.factory('SaveInstrument', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/saveinstrument');
}]);

projects_module.factory('SaveInstrumentAccuracyCheck', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/saveinstrumentaccuracycheck');
}]);

projects_module.factory('RemoveInstrumentAccuracyCheck', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/removeinstrumentaccuracycheck');
}]);

projects_module.factory('SaveFisherman', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/fishermen/savefisherman');
}]);

projects_module.factory('UpdateFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/file/updatefile');
}]);

projects_module.factory('DeleteFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/file/deletefile');
}]);

projects_module.factory('GetDatastoreProjects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/datastore/getdatastoreprojects');
}]);

projects_module.factory('GetInstruments', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/getinstruments');
}]);

projects_module.factory('GetInstrumentTypes', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/getinstrumenttypes');
}]);

projects_module.factory('RemoveProjectInstrument', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/instrument/removeprojectinstrument');
}]);

projects_module.factory('GetFishermen', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/fishermen/getfishermen');
}]);

projects_module.factory('GetProjectFishermen', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/fishermen/getprojectfishermen');
}]);

projects_module.factory('RemoveProjectFisherman', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/fishermen/removeprojectfisherman');
}]);

projects_module.factory('GetCrppStaff', ['$resource', function($resource){
    return $resource(serviceUrl+'/api/v1/user/GetCrppStaff'); // This line will need adjusting.
}]);

projects_module.factory('GetOlcStaff', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/user/GetOlcStaff'); // This line will need adjusting.
}]);

projects_module.factory('GetPrincipals', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/GetPrincipals'); //
}]);

projects_module.factory('GetVisibilityPermissions', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/GetVisibilityPermissions'); //
}]);

projects_module.factory('ChangeOwner', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/project/changeowner'); //
}]);

projects_module.service('ProjectService', project_service);

projects_module.factory('ProjectSubprojects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/gethabsubprojects', {}, {
        save: { method: 'POST', isArray: true }
    });
}]);

// Although SubprojectFiles calls the backend controller crppsubproject, method getcrppsubprojectfiles,
// getcrppsubprojectfiles passes the projectId and handles files for EITHER CRPP or Habitat.
projects_module.factory('SubprojectFiles', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/getcrppsubprojectfiles', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('OlcSubprojectFiles', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/getolcsubprojectfiles', {}, {
        query: { method: 'GET', params: { id: 'projectId' }, isArray: true }
    });
}]);

projects_module.factory('SaveCorrespondenceEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/savecorrespondenceevent');
}]);

projects_module.factory('SaveOlcEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/saveolcevent');
}]);

projects_module.factory('SaveHabitatItem', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/savehabitatitem');
}]);

projects_module.factory('DeleteCorresEventFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/deletecorreseventfile');
}]);

projects_module.factory('DeleteOlcEventFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/deleteolceventfile');
}]);

projects_module.factory('DeleteHabitatItemFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/deletehabitatitemfile');
}]);

projects_module.factory('DeleteHabSubprojectFile', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/deletehabsubprojectfile');
}]);

projects_module.factory('SaveSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/savecrppsubproject');
}]);

projects_module.factory('SaveCrppSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/savecrppsubproject');
}]);

projects_module.factory('SaveHabSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/savehabsubproject');
}]);

projects_module.factory('SaveOlcSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/saveolcsubproject');
}]);

projects_module.factory('GetSubprojects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/getcrppsubprojects');
}]);

//NB: does this need an ID parameter? -- actually it isn't used anywhere...
projects_module.factory('GetHabSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/gethabsubproject');
}]);

projects_module.factory('GetHabSubprojects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/gethabsubprojects');
}]);

projects_module.factory('RemoveSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/removecrppsubproject', {}, {
        save: { method: 'POST', isArray: false }
    });
}]);

projects_module.factory('RemoveHabSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/removehabsubproject');
}]);

projects_module.factory('RemoveOlcSubproject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/removeolcsubproject');
}]);

projects_module.factory('RemoveCorrespondenceEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/crppsubproject/removecorrespondenceevent');
}]);

projects_module.factory('RemoveOlcEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/removeolcevent');
}]);

projects_module.factory('RemoveHabitatItem', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/removehabitatitem');
}]);

projects_module.factory('GetOlcSubprojects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/getolcsubprojects');
}]);

//projects_module.factory('GetOlcSubprojectsForSearch', ['$resource', function ($resource) {
//    return $resource(serviceUrl + '/api/v1/olcsubproject/getolcsubprojectsforsearch');
//}]);

projects_module.factory('QueryOlcSubprojectsForSearch', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/queryolcsubprojectsforsearch', {}, {
        save: { method: 'POST', isArray: true }
    });
}]);

//datasets_module.factory('QueryActivitiesAction', ['$resource', function ($resource) {
//    return $resource(serviceUrl + '/api/v1/query/querydatasetactivities', {}, {
//        save: { method: 'POST', isArray: true }
//    });
//}]);

projects_module.factory('MigrateOlcEvent', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/olcsubproject/migrateolcevent');
}]);

projects_module.factory('SaveFileItemId', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/habsubproject/savefileitemid');
}]);

projects_module.service('SubprojectService', subproject_service);

export default projects_module;




