import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ConfirmComponent} from "../confirm-component/confirm.component";
import {ConfirmReviewComponent} from "../confirm-review/confirm-review.component";

const routes: Routes = [
  {path: 'inspection/:Id', component: ConfirmComponent},
  {path: 'review/:Id', component: ConfirmReviewComponent}
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ConfirmRoutingModule { }
