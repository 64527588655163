import * as angular from 'angular';
import {PERMIT_PROJECTID} from "../../../../config";
import {getFilesArrayAsList} from "../../../../core/common/common-functions";
import {executeNgPackagrBuilder} from "@angular-devkit/build-angular";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var requests_list = ['$scope', '$route', '$routeParams', '$uibModal', '$location', '$window', '$rootScope', 'PermitService', 'UserService', 'GridService', 'DatasetService','CommonService',
    function ($scope, $route, $routeParams, $uibModal, $location, $window, $rootScope, PermitService, UserService, GridService, DatasetService, CommonService) {

        $scope.ReviewItemType = $routeParams.ItemType;
        $scope.dataset = DatasetService.getDataset($routeParams.DatasetId);

        $scope.currentPage = "Open";
        $scope.project = { Id: PERMIT_PROJECTID };
        $scope.downloadSpinner = false;

        $scope.dataset.$promise.then(function () {
            var EventColumnDefs = GridService.getAgColumnDefs($scope.dataset);
            $scope.permitEventsGrid.columnDefs = EventColumnDefs.HeaderFields;

            //add in the PermitNumber virtual column (returned by the be)
            $scope.permitEventsGrid.columnDefs.unshift(
                {
                    field: 'PermitNumber',
                    headerName: 'Permit Number',
                    alwaysShowField: true,
                    menuTabs: ['filterMenuTab'],
                    filter: 'text',
                    Label: 'Permit Number',
                    DbColumnName: 'PermitNumber',
                    cdmsField: { Label: 'Permit Number', DbColumnName: 'PermitNumber', ControlType: 'text', Disabled: true, Field:{ Description: "hi"} }
                });

            if($scope.ReviewItemType == 'Inspections'){
                $scope.PermitEvents = PermitService.getPermitEventsForMe();
            } else {
                $scope.PermitEvents = PermitService.getPermitEventsByItemType($scope.ReviewItemType);
            }

            $scope.PermitEvents.$promise.then(function () {
                $scope.permitEventsGridDiv = document.querySelector('#permit-events-grid');
                new Grid($scope.permitEventsGridDiv, $scope.permitEventsGrid);
                $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);

                $scope.addFilterOptions();
                $scope.showOpen();

            });

            $scope.isFavorite = $rootScope.Profile.isDatasetFavorite($scope.dataset.Id);
            $scope.permitsCanEdit = $scope.Profile.hasRole($scope.dataset.Config.Permissions.Edit);

        });

        $scope.showOpen = function(){
            $scope.currentPage = "Open";
            $scope.filterRequests('open');
        }

        $scope.showClosed = function(){
            $scope.currentPage = "Closed";
            $scope.filterRequests('closed');
        }

        //this ag-grid filter options is a bit tricky
        // primarily because you MUST have a string as the first element in the array
        // or it simply does not work. :/
        const dateFilterOptions = [
            'empty',
            {
                displayKey: 'closed',
                displayName: 'closed',
                test: function (filterValue, cellValue) {
                    return (cellValue !== null);
                },
                hideFilterInput: true
            },
            {
                displayKey: 'open',
                displayName: 'open',
                test: function (filterValue, cellValue) {
                    return (cellValue === null);
                },
                hideFilterInput: true
            },

            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
        ];

        $scope.addFilterOptions = function(){
            $scope.permitEventsGrid.columnDefs.forEach(function (coldef) {
                if (coldef.DbColumnName == 'ResponseDate'){
                    coldef.filterParams.filterOptions = dateFilterOptions;
                }
            });
        }

        $scope.permitEventsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            selectedItem: null,
            rowSelection: 'single',
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,

            },
            onRowDoubleClicked: function (params) {
                $scope.openActivityModal($scope.permitEventsGrid.selectedItem);
            },
            onSelectionChanged: function (params) {
                $scope.permitEventsGrid.selectedItem = $scope.row = $scope.permitEventsGrid.api.getSelectedRows()[0];
                console.dir($scope.permitEventsGrid.columnDefs);
                $scope.downloadSpinner = false;
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            onFirstDataRendered: function(){
                var allColumnIds = [];
                $scope.permitEventsGrid.columnApi.getAllColumns().forEach(function (columnDef) {
                    allColumnIds.push(columnDef.colId);
                });
                $scope.permitEventsGrid.columnApi.autoSizeColumns(allColumnIds);
            },
            getRowHeight: function (params) {
                var file_height = 25 * (getFilesArrayAsList(params.data.Files).length); //count up the number of file lines we will have.
                var shared_height = 25 * (getFilesArrayAsList(params.data.SharedFiles).length);
                var final = (shared_height > file_height) ? shared_height : file_height;
                return (final > 25) ? final : 25;
           },
        }

        $scope.downloadTemplate = function(){
            $scope.downloadSpinner = true; //disables button

            var template = PermitService.downloadInspectionDocument($scope.permitEventsGrid.selectedItem.Id)

            template.$promise.then(function(){
                console.log('redirecting to ' + template.InspectionFormUrl);

                var a = document.createElement("a");
                a.href=template.InspectionFormUrl;
                a.download="InspectionForm_" + $scope.permitEventsGrid.selectedItem.Id + ".pdf";
                a.click();

                //there is no way to know how long the browser will take to start the download... so we'll just give it a second
                setTimeout(function(){
                    $scope.downloadSpinner = false;
                },4000);

            })
        }

        $scope.filterRequests = function(filterBy){

            var filter_component = $scope.permitEventsGrid.api.getFilterInstance('ResponseDate');
            if ((typeof filter_component !== 'undefined') && (filter_component != null))
            {
                filter_component.setModel(null);
                $scope.permitEventsGrid.api.onFilterChanged();
                filter_component.setModel({ type: filterBy });
                $scope.permitEventsGrid.api.onFilterChanged();
            }

            var allColumnIds = [];
            $scope.permitEventsGrid.columnApi.getAllColumns().forEach(function (columnDef) {
                allColumnIds.push(columnDef.colId);
            });
            $scope.permitEventsGrid.columnApi.autoSizeColumns(allColumnIds);
        }

        $scope.openActivityModal = function (params, intent) {

            //load the related permit's file
            $scope.PermitFiles = PermitService.getPermitFiles($scope.row.PermitId);

            delete $scope.activity_modal;
            $scope.intent = intent;

            //in this UI we only ever edit
            if (params) {
                $scope.activity_modal = angular.copy(params);
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/requests/templates/requests-modal.html',
                controller: 'PermitRequestsModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_activity) {
                if($scope.ReviewItemType == 'Inspections'){
                    $scope.PermitEvents = PermitService.getPermitEventsForMe();
                } else {
                    $scope.PermitEvents = PermitService.getPermitEventsByItemType($scope.ReviewItemType);
                }
                $scope.PermitEvents.$promise.then(function(){
                    $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);
                    if($scope.currentPage == "Open")
                        $scope.showOpen()
                    else
                        $scope.showClosed();
                })

            });

        }

        $scope.onHeaderEditingStopped = function (field) { //fired onChange for header fields (common/templates/form-fields)

            //console.log("onHeaderEditingStopped: " + field.DbColumnName);

            //build event to send for validation
            var event = {
                colDef: field,
                node: { data: $scope.row },
                value: $scope.row[field.DbColumnName],
                type: 'onHeaderEditingStopped',
                scope: $scope
            };

            if (GridService.validateCell(event)) {
                GridService.fireRule("OnChange", event); //only fires when valid change is made
            }

            //update our collection of header errors if any were returned
            $scope.headerFieldErrors = [];
            if ($scope.row.rowHasError) {
                $scope.row.validationErrors.forEach(function (error) {
                    if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
                        $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
                    } else {
                        $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
                    }
                });
            }

            $rootScope.$emit('headerEditingStopped', field); //offer child scopes a chance to do something, i.e. add activity modal...


        };

        //handle favorite toggle
        $scope.toggleFavorite = function () {
            UserService.toggleFavoriteDataset($scope, $rootScope);
        }

    }];

export default requests_list;
