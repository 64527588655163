//modal to edit location
import {modalFiles_setupControllerForFileChooserModal} from "../../../common/components/file/modal-files";
import {loadModules} from "esri-loader";
import {GEOMETRY_SERVICE_URL, 
  NAD83_SPATIAL_REFERENCE
} from '../../../../config';
import { EsriMapComponent } from "src/app/map/esri-map/esri-map.component";
//--------
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import Graphic from "@arcgis/core/Graphic";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as geometryServ from "@arcgis/core/rest/geometryService";
import Point from "@arcgis/core/geometry/Point";
//import ProjectParameters from "@arcgis/core/tasks/support/ProjectParameters";
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import Query from "@arcgis/core/rest/support/Query";

var modal_edit_location = ['$scope', '$uibModal', '$uibModalInstance', 'GridService', 'CommonService', 'Upload', 
  'ProjectService', '$rootScope', 'PermitService',
  function ($scope, $uibModal, $uibModalInstance, GridService, CommonService, $upload, 
    ProjectService, $rootScope, PermitService) {

    console.log("Inside modal-edit-location.ts...");
    //geometryService: geometryService;

    $scope.mode = "edit";
    $scope.OriginalEasting = 0;
    $scope.OriginalNorthing = 0;
    $scope.EastingOrNorthingChanged = false;
    var symbol = null;

    console.log("Inside modal-edit-location.ts...");

    if ($scope.ProjectLocation_modal)
    {
      $scope.row = $scope.ProjectLocation_modal;
      $scope.dataGridOptions = $scope.permitLocationsGrid;
    }

    if (!$scope.row.Id) {
      $scope.mode = "new";
    }
    else
    {
      $scope.OriginalEasting = $scope.row.GPSEasting;
      $scope.OriginalNorthing = $scope.row.GPSNorthing;
    }

    modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.project.Files);

    // $scope.saveNewPoint = function (inId) {
    //   if (inId.pointId) {
    //     $scope.row.SdeObjectId = inId.pointId
    //     console.log("Created a new point! " + $scope.row.SdeObjectId);
    //
    //     var data = {
    //       ProjectId: $scope.project.Id,
    //     };
    //
    //     var target = '/api/v1/file/UploadProjectFile';
    //
    //     $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
    //
    //   } else {
    //     $scope.SaveMessage = "There was a problem saving that location.";
    //     console.dir(inId.pointId);
    //   }
    // }

    $scope.updateParentItem = function() {

      if ($scope.ProjectLocation_modal){
        $scope.PermitLocations = PermitService.getPermitLocations($scope.row.PermitId);
        $scope.PermitLocations.$promise.then(function() {
          console.log("$scope.PermitLocations modal-edit-location.ts is next...");
          console.dir($scope.PermitLocations);
          $scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);
          $scope.permitLocationsGrid.selectedItem = null;
          $scope.$apply();
        });
      }
      else 
      {
        $scope.all_locations = ProjectService.getLocations($scope.project.Id);
        $scope.all_locations.$promise.then(function () {
          $scope.dataGridOptions.api.setRowData($scope.all_locations);
        });
      }

    };

    $scope.save = function (inId) {


      //OK -- if we are saving a NEW location then start off by adding the point to the featurelayer
      if (!$scope.row.Id) {
        console.log("Adding a NEW location...");

        $uibModalInstance.close(
          // on close this information is sent to the esri-map component, and enters as the inPayload object.
          {
            'ProjectId': $scope.project.Id,
            'Location': $scope.row,
            'IsFirstRun': true
          });


      } else //updating an existing...
      {
        //------------------------------------------------------------
        // The current process for updating a point is...
        // - Capture the SdeObjectId for the original point
        // - Add a new one point, with the desired specs, and set the SdeObjectId to be that of the new point
        // - Delete the old point
        //------------------------------------------------------------

        // $uibModalInstance.close(
        //   // on close this information is sent to the esri-map component
        //   {
        //     'Update': true,
        //     'ProjectId': $scope.project.Id,
        //     'Location': $scope.row,
        //     'IsFirstRun': true
        //   });
        var data = {
          ProjectId: $scope.project.Id,
        };
      

        // The ngOnChanges in esri-map.component.ts works with a project-level location,
        // but not from a permits-level (dataset-level) location.
        // ($scope.ProjectLocation_modal exists)
        // if ($scope.ProjectLocation_modal)
        // {
        //   var locationObject = {
        //     'Update': true,
        //     'ProjectId': $scope.project.Id,
        //     'Location': $scope.row,
        //   }
        // }
          //var esriMapComponent = new EsriMapComponent();

          //esriMapComponent.modalLocationEdit(locationObject);
        //}

        if ($scope.EastingOrNorthingChanged)
        {
          // // Capture the old SdeObjectId, so we can delete it later.
          $scope.OldSdeObjectId = $scope.row.SdeObjectId;
        
          // Now setup things for the new point.
          var inSR = new SpatialReference({ wkt: NAD83_SPATIAL_REFERENCE });
          var outSR = new SpatialReference({ wkid: 102100 });

          var theEasting = $scope.row.GPSEasting;
          var theNorthing = $scope.row.GPSNorthing;
          $scope.newPoint = new Point({x: theEasting, y: theNorthing, spatialReference: inSR});

          //convert spatial reference
          var PrjParams = new ProjectParameters();

          PrjParams.geometries = [$scope.newPoint];
          PrjParams.outSpatialReference = outSR;
          //PrjParams.toJSON();

          // The following are nested, because otherwise the async process will call
          // a service and then continue on.
          geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((outputpoint) => {

            $scope.newAddPoint = new Point(outputpoint[0]);

            symbol = new SimpleMarkerSymbol({
              style: "circle",
              color: "#00ff00",
              size: "8px",
            });

            // PrjParams.outSR is not set yet, so we must set it also.
            //PrjParams.outSR = outSR;  // Does not exist in supportProjectParameters

            //var attributes = {OBJECTID: theSdeObjectId};
            var newGraphic = new Graphic({geometry: $scope.newAddPoint, symbol: symbol});

            console.log("$scope.map.locationLayer is next...");
            console.dir($scope.map.locationLayer);
            // $scope.map.locationLayer.applyEdits([$scope.newGraphic], null, null).then(function (addResults) { // Previous technique under masterV2
            $scope.map.locationLayer.applyEdits({addFeatures: [newGraphic]}).then (function (addResults) {
                //if (inId.hasOwnProperty('pointId')) {
              //$scope.row.SdeObjectId = addResults[0].objectId;
              if ((addResults.addFeatureResults) && (addResults.addFeatureResults[0].objectId))
              {            
                $scope.row.SdeObjectId = addResults.addFeatureResults[0].objectId;
                console.log("Added new point! " + $scope.row.SdeObjectId);
              }

          
              //throw "Stopping right here...";

              // Now that we added the new point, we must delete the old point.
              // Reuse the inSR
              // Reuse the outSR

              $scope.deletePoint = new Point({x: $scope.OriginalEasting, y: $scope.OriginalNorthing, spatialReference: inSR});

              //convert spatial reference
              var PrjParams = new ProjectParameters();
              PrjParams.outSpatialReference = outSR;
              PrjParams.geometries = [$scope.deletePoint];

              // Do the projection (conversion)
              geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((delOutputpoint) => {
                // Reuse symbol
                // let symbol = new SimpleMarkerSymbol({
                //   style: "circle",
                //   color: "#00ff00",
                //   size: "8px",
                // });
                $scope.PointToDelete = new Point(delOutputpoint[0]);
                
                var attributes = {OBJECTID: $scope.OldSdeObjectId};

                //throw "Stopping right here...";

                var deleteGraphic = new Graphic({geometry: $scope.PointToDelete, symbol: symbol, attributes: attributes});

                // Note:  You cannot just pass the point to delete; they must be in a graphic object,
                //        or the code dies in dojo-land, which is different than what is shown in 
                //        ArcGIS documenation on the service interface.
                $scope.map.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}).then (function (deleteResults) { // This works

                  console.log("deleteResults is next...");
                  console.dir(deleteResults);

                  if (deleteResults && deleteResults.deleteFeatureResults.length === 0)
                  {
                      $scope.SaveMessage = "The location did not exist in SDE.";
                      console.log($scope.SaveMessage);
                      console.dir(deleteResults);
                      CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.row);
                  }
                  else if (deleteResults && deleteResults.deleteFeatureResults[0] && deleteResults.deleteFeatureResults[0].objectId) 
                  {                                         
                      console.log("Deleted old project point! " + $scope.row.SdeObjectId);

                      //CommonService.UpdateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.OldSdeObjectId);
                      //CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId);
                      CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.row);
                  }
                  else 
                  {
                      $scope.SaveMessage = "There was a problem deleting that location.";
                      console.log($scope.SaveMessage);
                      console.dir(deleteResults);
                  }

                  // var data = {
                  //   ProjectId: $scope.project.Id,
                  // };
                  $rootScope.ProjectId = $scope.project.Id;

                  //var target = '/api/v1/file/UploadProjectFile';
                  //$scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.

                  $scope.modalFile_saveParentItem($scope.row);

                }, function (deleteErr: any) {
                    console.log("Apply Edits - Delete project location - failed:  " + deleteErr.message);
                }); // Delete applyEdits
              }); // Delete GeometryService
            }, function (addErr: any) {
              $scope.SaveMessage = "There was a problem adding the new project location.";
              console.log("Apply Edits - Add - failed:  " + addErr.message + " > " + addErr.innerHTML);
            }); // Add applyEdits
          }); // Add GeometryService
        }
        else
        {
          console.log("Easting or northing did not change.  Only updating dbo.Locations.");
          $scope.modalFile_saveParentItem($scope.row);
        }
      }
    };

    //call back from save above once the files are done processing and we're ready to save the item
    $scope.modalFile_saveParentItem = function (saveRow) {
      console.log("Inside modal-edit-location.ts, modalFile_saveParentItem...");
      
      saveRow.WaterBody = undefined;

      var new_location = null;

      // var new_location = CommonService.saveNewProjectLocation($scope.project.Id, saveRow);

      if (!$scope.project)
      {
        new_location = CommonService.saveNewProjectLocation($scope.$parent.project.Id, saveRow);
      }
      else 
      {
        new_location = CommonService.saveNewProjectLocation($scope.project.Id, saveRow);
      }

      new_location.$promise.then(function () {
        console.log("done and success!");
        //return new_location;
        //$uibModalInstance.close(new_location);
        $uibModalInstance.dismiss();
        //$scope.$apply();
        window.location.reload();
      });

    };

    //callback that is called from modalFile to do the actual file removal (varies by module)
    $scope.modalFile_doRemoveFile = function (file_to_remove, saveRow) {
      console.dir(file_to_remove);
      return ProjectService.deleteFile($scope.project.Id, file_to_remove);
    };

    //used as a filter to exclude the edit link - only show bonafide fields
    $scope.hasDbColumnName = function (field) {
      return field.hasOwnProperty('DbColumnName');
    };

    $scope.onHeaderEditingStopped = function (field) {
      //build event to send for validation
      console.log("onHeaderEditingStopped: " + field.DbColumnName);

      $scope.EastingOrNorthingChanged = false;
      if (field.DbColumnName)
      {
        var tmpDbColName = field.DbColumnName.toLowerCase();
        if ((tmpDbColName.indexOf("easting") > -1) || (tmpDbColName.indexOf("northing") >  -1))
        {
          $scope.EastingOrNorthingChanged = true;
        }
      }
      
      var event = {
        colDef: field,
        node: {data: $scope.row},
        value: $scope.row[field.DbColumnName],
        type: 'onHeaderEditingStopped'
      };

      if (GridService.validateCell(event)) {
        GridService.fireRule("OnChange", event); //only fires when valid change is made
      }

      //update our collection of header errors if any were returned
      $scope.headerFieldErrors = [];
      if ($scope.row.rowHasError) {
        $scope.row.validationErrors.forEach(function (error) {
          if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
            $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
          } else {
            $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
          }
        });
      }

    };

    //fire validation for all columns when we load (if we are editing)
    if ($scope.mode === 'edit') {
      console.log("Location Id = " + $scope.$parent.row.Id);

      $scope.dataGridOptions.columnDefs.forEach(function (field) {
        $scope.onHeaderEditingStopped(field);
      });
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };


  }
];


export default modal_edit_location;