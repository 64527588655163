var modal_save_success = ['$scope','$uibModalInstance',
  function($scope,  $uibModalInstance){

    $scope.header_message = "Save Successful!";

    $scope.cancel = function(){
        $uibModalInstance.dismiss();
    };
  }
];

export default modal_save_success;
