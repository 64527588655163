//bot api
import {serviceUrl} from "../../config";
import bot_module from "./bot-module";



const bot_services = ['$q',

    'BOTProjects',
    'ProjectObjectiveReports',
    'SaveBOTProject',
    'SaveBOTProjectObjective',
    'SaveBOTProjectObjectiveReport',
    'AllBOTProjects',

    function ($q,

        BOTProjects,
        ProjectObjectiveReports,
        SaveBOTProject,
        SaveBOTProjectObjective,
        SaveBOTProjectObjectiveReport,
        AllBOTProjects

    ) {
        var service = {

            getBOTProjects: function (ProjectTypeId) {
                return BOTProjects.query({ Id: ProjectTypeId });
            },

            getProjectObjectiveReports: function (ProjectObjectiveId) {
                return ProjectObjectiveReports.query({ Id: ProjectObjectiveId });
            },

            saveBOTProject: function (project) {
                return SaveBOTProject.save({ Project: project });
            },

            saveBOTProjectObjective: function(objective) {
                return SaveBOTProjectObjective.save({ ProjectObjective: objective });
            },

            saveProjectObjectiveReport: function(report){
                return SaveBOTProjectObjectiveReport.save({ ProjectObjectiveReport: report });
            },

            getAllBOTProjects: function () {
                return AllBOTProjects.query({  });
            },

        };

        return service;

    }
];

export default bot_services;
