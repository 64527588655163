import * as angular from 'angular';
import * as moment from 'moment';
import {modalFiles_setupControllerForFileChooserModal} from "../../../common/components/file/modal-files";

var table_data_modal = ['$scope', '$rootScope','$uibModalInstance', 'DatasetService', 'GridService', 'Upload', '$uibModal',
    function ($scope, $rootScope, $uibModalInstance, DatasetService, GridService, $upload, $uibModal) {

        $scope.DisabledFields = {};

        $scope.header_message = "Edit " + $scope.context_dataset.Name;

        $scope.row = angular.copy($scope.data_modal);

        $rootScope.remainingFiles = [];

        $scope.row.strSelectedItemList = "";
        $scope.lastField = null;
        $scope.MultiSelectAlreadyRan = false;

        $scope.typeOfFilesToCheck = "";

        console.log("Inside table-data.modal.ts");

        //console.dir($scope.row);
        //incoming we have at least:
        /*
                scope.context_dataset - dataset
                scope.context_grid - grid
                scope.context_table - all data rows
                scope.row - row
                scope.data_parentId - "subprojectId" - the Id of the parent
                scope.data_type - type we're saving (parent, child, grandchild)
        */

        //if we're not editing then we are creating a new one
        if (!$scope.row.Id) {
            $scope.header_message = "Add " + $scope.context_dataset.Name;
        } else {
            //copy over the LocationId if it exists so that it works with our existing Location dropdown ng-model
            if($scope.row.LocationId) {
                $scope.row.Activity = { LocationId : $scope.row.LocationId };
            }
        }

        //set internal fields if they exist
        if($scope.row.hasOwnProperty("EffDt"))
            $scope.row.EffDt = moment().format(); //'L');

        if($scope.row.hasOwnProperty("CreateDate") && !$scope.row.CreateDate)
            $scope.row.CreateDate = moment().format(); //'L');

        if($scope.row.hasOwnProperty("ByUserId"))
            $scope.row.ByUserId = $rootScope.Profile.Id;

        if($scope.row.hasOwnProperty("ProjectId") && !$scope.row.ProjectId)
            $scope.row.ProjectId = $scope.dataset.ProjectId;


        $scope.hasFilesToSave = false;

        //if the dataset has a file field then enable the file add/remove modal
        // dataset.file_fields is an array of file field names ["EventFiles"]
        // it exists if there are any file columns in this dataset
        if($scope.context_dataset.hasOwnProperty('file_fields')){

            //if ($scope.row[$scope.context_dataset.file_fields] !== null) //will be always empty at this time... and file_fields is an array
            $scope.hasFilesToSave = true;

            if($scope.data_parentId != 0)
            {
                //$scope.row.PurchaseId = $scope.data_parentId; //do we need this? it is specific to Purchasing?

                $scope.viewSubproject = $scope.subprojectId = $scope.data_parentId; //this sets the context for the form file field filter

                // //fetch the subproject parent files
                // $scope.ParentFiles = DatasetService.getTableFiles($scope.context_dataset.ProjectId, $scope.data_parentId, $scope.context_dataset.Id);
                // $scope.ParentFiles.$promise.then(function(){
                //     //var file_field = $scope.context_dataset.file_fields[0];
                //     //$scope.row._files = getFilesArrayAsList($scope.row[file_field]);
                //     //console.dir($scope.row)
                //     modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.ParentFiles);
                // })
                $scope.typeOfFilesToCheck = "ParentFiles";
                modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.typeOfFilesToCheck);
            }else{
                // $scope.checkFiles = DatasetService.getTableFiles($scope.context_dataset.ProjectId, $scope.data_parentId, $scope.context_dataset.Id);
                // $scope.checkFiles.$promise.then(() => {
                // modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.checkFiles);
                // });
                $scope.typeOfFilesToCheck = "checkFiles";
                modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.typeOfFilesToCheck);
            }

        }

        if ($scope.context_dataset.Config.DataEntryPage)
        {
            if ($scope.context_dataset.Config.DataEntryPage.DisabledFields)
            {
                $scope.context_dataset.Config.DataEntryPage.DisabledFields.forEach(function(item){
                    $scope.DisabledFields[item] = item;

                    if ($scope.DisabledFields[item])
                    {
                        $scope.DisabledFields[item] = {disabled: true};
                        //console.log("$scope.DisabledFields[item].disabled = " + $scope.DisabledFields[item].disabled);
                    }
                });
            }

            if ($scope.context_dataset.Config.DataEntryPage.SelectedItemList)
            {
                $scope.context_dataset.Config.DataEntryPage.SelectedItemList.forEach(function(item){
                    $scope.row.strSelectedItemList = "";
                    if (($scope.row[item]) && ($scope.row[item] !== null))
                    {
                        var tmpItem = $scope.row[item];
                        //var arySelectedItemList = $scope.row[item].split(",");
                        var arySelectedItemList = $scope.row[item].split(";");

                        // Rebuild the string now, adding the semicolon and newline after every line.
                        //angular.forEach(arySelectedItemList, function(item){
                        //    $scope.row.strSelectedItemList += item + ";\n";
                        //});
                        for (var i = 0; i < arySelectedItemList.length; i++)
                        {
                            if (arySelectedItemList[i] !== "")
                                $scope.row.strSelectedItemList += arySelectedItemList[i] + ";\n";
                            else
                                arySelectedItemList.splice(i, 1);
                        }

                        //var strField = item + "SelectedItemList";

                        //$scope.row[item] = {};
                        //$scope.row[item].value = tmpItem;
                        //$scope.row[item].SelectedItemList = strSelectedItemList;
                        $scope.row.SelectedItemList = $scope.row.strSelectedItemList;
                    }
                });
            }
        }

        // Could not get a date to go into the DatePicker, via a rule in SQL,
        // so I chose this way instead.  Still using a rule but a little differently.
        if (($scope.context_dataset) && ($scope.context_dataset.Fields))
        {
            $scope.context_dataset.Fields.forEach(function(aField){
                if ((aField.Rule) && (aField.Rule.DefaultValue))
                {
                    console.log("typeof aField.Rule.DefaultValue = " + typeof aField.Rule.DefaultValue);
                    if ((aField.Rule) &&
                        (aField.Rule.DefaultValue) &&
                        (typeof aField.Rule.DefaultValue === 'string') &&
                        (aField.Rule.DefaultValue.indexOf("dtNow") > -1))
                    {
                        $scope.row[aField.DbColumnName] = moment().format('MM/DD/YYYY');
                    }
                }

                //var OtherBoxName = "Other" + aField.DbColumnName;
                //if (($scope.context_dataset.Fields[OtherBoxName]) && ($scope.context_dataset.Fields[OtherBoxName].ControlType === "hidden"))
                //{
                //    $scope.tmpHiddenFields[aField.DbColumnName] = {"hidden":true};
                //}
            });
        }

        $scope.save = function () {
            console.log("Inside table-data-modal.js, $scope.save...");

            //fire validation on all header fields
            for (let headerField of $scope.context_grid.columnDefs){
                $scope.onHeaderEditingStopped(headerField);
                if(headerField.cdmsField.Field.Rule && headerField.cdmsField.Field.Rule.BeforeSave){
                    $scope.beforeSave(headerField);
                }
            }

            //move the LocationId if it exists
            if($scope.row.Activity && $scope.row.Activity.LocationId){
                $scope.row.LocationId = $scope.row.Activity.LocationId;
                delete $scope.row.Activity;
            }

            if(Object.keys($scope.headerFieldErrors).length > 0){
                alert("There are errors on the page. Please fix them before saving.");
                console.dir($scope.headerFieldErrors);
                return;
            }

            console.log(" ** saving ** ");
            console.dir($scope.row);
            console.dir($scope.context_dataset);

            $scope._row = angular.copy($scope.row); //in case things go bad

            $scope.row.ProjectId = $scope.context_dataset.ProjectId;
            $scope.row.DatasetId = $scope.context_dataset.Id;
            $scope.row.ByUserId = $rootScope.Profile.Id;

            if ((typeof $scope.row.SelectedItemList !== 'undefined') && ($scope.row.SelectedItemList != ""))
            {
                // First, strip out the new line characters.
                $scope.row.SelectedItemList = $scope.row.SelectedItemList.replace(/(\r\n|\r|\n)/gm, "");
                console.log("$scope.row.SelectedItemList after stripping = " + $scope.row.SelectedItemList);

                // Note, we still have the trailing semicolon.
                // Convert the string to an array, so that we can easily remove the applicable funding agency from the string.
                var arySelectedItemList = $scope.row.SelectedItemList.split(";");

                // Next, get rid of that trailing semicolon.
                arySelectedItemList.splice(-1, 1);
                console.dir(arySelectedItemList);

                // Rebuild the string now, adding the ; after each item.
                var intCount = 0;
                $scope.row.SelectedItemList = "";
                angular.forEach(arySelectedItemList, function(item){
                    if (intCount === 0)
                        $scope.row.SelectedItemList += "'" + item;
                    else
                        $scope.row.SelectedItemList += ";" + item;

                    intCount++;
                });
                $scope.row.SelectedItemList += "'";
                $scope.row.County = $scope.row.SelectedItemList;
            }

            // If we are saving a new subproject, there won't be a $scope.$parent.context_parentgrid...
            if (($scope.$parent.context_parentgrid) && ($scope.$parent.context_parentgrid.selectedItem))
                $scope.row.SubprojectId = $scope.$parent.context_parentgrid.selectedItem.Id;

            //var DtEffDt = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
            //$scope.row.EffDt = moment().format("DD/MM/YYYY HH:mm:ss");

            //$scope.row.EffDt = moment(); //already set above

            if ($scope.row.Id === null)
                $scope.row.Id = undefined;



            //if ((typeof $scope.row.TrackingPONumber !== 'undefined') && ($scope.row.TrackingPONumber != null))
            //{
            //    $scope.$parent.context_parentgrid.selectedItem.PurchasePONumber = $scope.row.TrackingPONumber;
            //    $scope.MustUpdateParent = true;
            //}
            //throw "Stopping right here...";

            //save the data first so that we have the subprojectid
            console.log("Calling saveTableData, Dataset/subproject-level...");
            $scope.save_result = DatasetService.saveTableData($scope.context_dataset, $scope.row);

            $scope.save_result.$promise.then(function (resultData) {
                $scope.saveResults[$scope.data_type]['success'] = "Success.";
                console.log("Saved successfully...");

                if (($scope.dataset.Config) && ($scope.dataset.Config.TransferFieldsFromGrandChild))
                {
                    console.log("Need to transfer fields from grandchild...");

                    //var transferFields = [];
                    var transferFields = JSON.parse(JSON.stringify($scope.dataset.Config.TransferFieldsFromGrandChild));
                    //transferFieldsFromJson.forEach(function(item){
                        //var FieldPair = JSON.parse(item);
                        //transferFields.push(FieldPair);
                        //transferFields.push(item);
                    //});
                    //var FieldFrom = $scope.dataset.Config.TransferFromGrandChild.FieldFrom;
                    //var FieldTo = $scope.dataset.Config.TransferFromGrandChild.FieldTo;

                    transferFields.forEach(function(item){
                        if (resultData[0][item.FieldFrom] !== null)
                        {
                            //$scope.$parent.context_parentgrid.selectedItem.PurchasePONumber = resultData[0].TrackingPONumber;

                            if (($scope.$parent.context_parentgrid) && ($scope.$parent.context_parentgrid.selectedItem))
                                $scope.$parent.context_parentgrid.selectedItem[item.FieldTo] = resultData[0][item.FieldFrom];

                        }
                    });

                    //if (($scope.$parent.context_parentgrid) && ($scope.$parent.context_parentgrid.selectedItem))
                    //{
                    //    $scope.saveGrandParentResult = DatasetService.saveTableData($scope.$parent.context_parentdataset, $scope.$parent.context_parentgrid.selectedItem);

                    //    $scope.saveGrandParentResult.$promise.then(function(grandParentData) {
                    //        console.log("grandParentData is next...");
                    //        console.dir(grandParentData);
                    //    });

                    //    //$scope.$apply();
                    //    $scope.MustUpdateParent = true;
                    //}
                }

                if($scope.hasFilesToSave){
                    console.log("Has a file field - check for any files to upload...");
                    $scope.saveResults[$scope.data_type]['success'] = "Saving file...";

                    if($scope.data_parentId == 0){ //then we just saved a new parent row
                        //$scope.data_parentId = $scope.save_result.Id; //set it to our new id (this is the subprojectid)
                        $scope.data_parentId = resultData[0].Id; //set it to our new id (this is the subprojectid)
                    }
                    //now save the files
                    var saveRow = angular.copy($scope.row);

                    var data = {
                        ProjectId: $scope.context_dataset.ProjectId,
                        DatasetId: $scope.context_dataset.Id,
                        SubprojectId: $scope.data_parentId,
                        DatastoreTablePrefix: $scope.context_dataset.Datastore.TablePrefix,
                    };
                    //console.log("data is next...");
                    //console.dir(data);

                    //var target = '/api/v1/table/uploadfile';
                    var target = null;
                    if ($scope.data_type === 'parent'){
                        //target = '/api/v1/file/uploadmultipanelsubprojectfile';
                      // @ts-ignore
                        data.ItemId = resultData[0].Id; //set the itemid
                        target = '/api/v1/file/uploaddatasetfile';
                    }
                    else if ($scope.data_type === 'child'){
                      // @ts-ignore
                        data.ItemId = resultData[0].Id; //set the itemid
                        target = '/api/v1/file/uploadmultipanelsubprojectfile';

                    }
                    else if ($scope.data_type === 'grandchild'){
                      // @ts-ignore
                        data.ItemId = resultData[0].Id; //set the itemid
                        target = '/api/v1/file/uploadmultipanelsubprojectfile';
                    }

                    $scope.handleFilesToUploadRemove(saveRow, data, target, $upload, $rootScope, $scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
                }
                else{
                    console.log("No files to save...");

                    $uibModalInstance.close($scope.save_result);
                    $scope.emailNotifications();

                }

                // Since we are reloading the parent table, not just refreshing it,
                // after the next step, we don't need this step.
                // Leaving it in for the moment now though.
                //$scope.$parent.context_parentgrid.selectedItem.EffDt = moment().format();

                if ($scope.context_parentdataset)
                {
                    // Reload the parent table, to incorporated the fresh changes.
                    $scope.$parent.loadTable($scope.context_parentdataset.Id);

                    $scope.$parent.sortTableGrid(); // Bump parent to resort.
                    //$scope.saveParentItem($scope.$parent.context_parentgrid.selectedItem);
                }
                else
                {
                    $scope.$parent.loadTable($scope.context_dataset.Id);
                    $scope.$parent.sortTableGrid();
                }

            }, function(failure){
                console.dir(failure);
                $scope.row = $scope._row; //fall back
                $scope.saveResults[$scope.data_type]['failure'] = "Failed: " + failure.data.ExceptionMessage;
            });

        }

        $scope.modalFile_saveParentItem = function (saveRow) {
            //console.log("Inside table-data-modal.js, $scope.modalFile_saveParentItem...");
            //console.dir(saveRow);
            //console.dir($scope.save_result);

            //update file fields with link

            if ($scope.context_dataset.file_fields)
            {
              if ($scope.save_result[0]) {
                $scope.context_dataset.file_fields.forEach(function(field){
                    if ((saveRow) && (typeof saveRow[field] !== 'undefined') && (saveRow[field] != null))
                        $scope.save_result[0][field] = saveRow[field];
                });
              }
            }

            console.log("Calling saveTableData, Parent-level...");
            //console.log("$scope.context_dataset and $scope.save_result[0] are next...");
            //console.dir($scope.context_dataset);
            //console.dir($scope.save_result[0]);

            $scope.save_result = DatasetService.saveTableData($scope.context_dataset, $scope.save_result[0]);
            $scope.save_result.$promise.then(function(result){
                $scope.emailNotifications();
                $scope.saveResults[$scope.data_type]['success'] = "Success.";
                $uibModalInstance.close($scope.save_result);
                $scope.$apply();

            }, function(failure){
                console.dir(failure);
                $scope.row = $scope._row; //fall back
                $scope.saveResults[$scope.data_type]['failure'] = "Failed: " + failure.data.ExceptionMessage;
            });
        };

        $scope.modalFile_doRemoveFile = function (file_to_remove, saveRow) {
          return DatasetService.deleteTableFile($scope.data_type, $scope.context_dataset.ProjectId, $scope.context_dataset.Id, file_to_remove, $scope.context_grid.selectedItem);
        };

        //fire any "BeforeSave" rules
        $scope.beforeSave = function(field){
            var event = {
                colDef: field,
                node: { data: $scope.row },
                data: $scope.row,
                scope: $scope,
                value: $scope.row[field.DbColumnName],
                type: 'beforeSave'
            };

            GridService.fireRule("BeforeSave", event);
        }

        //send any notifications configured in workflow
        $scope.emailNotifications = function(){
            //if enabled
            if(!$scope.dataset.Config.Notification)
                return;

            var messages = [];
            var notify = $scope.dataset.Config.Notification;

            //check triggers and add messages
            if(notify.ChkEmailOnNewRecord && !$scope.data_modal.Id){
                console.log("notify: new record")
                messages.push({
                    Subject: "New Record for "+$scope.dataset.Name,
                    Message: "",
                });
            }

            if(notify.ChkEmailOnUpdatedRecord && $scope.data_modal.Id){
                console.log("notify: updated record")
                messages.push({
                    Subject: "Updated Record for "+$scope.dataset.Name,
                    Message: "",
                });
            }

            if(notify.ChkEmailOnFieldChange && $scope.data_modal.Id &&
                $scope.row[notify.EmailOnFieldChange] != $scope.data_modal[notify.EmailOnFieldChange]){
                    console.log("notify: record field change")
                    messages.push({
                        Subject: notify.EmailOnFieldChange + " updated for "+$scope.dataset.Name,
                        Message: notify.EmailOnFieldChange + " changed from " + $scope.data_modal[notify.EmailOnFieldChange] + " to " + $scope.row[notify.EmailOnFieldChange],
                    });
                }

            if(messages.length > 0){
                //notify address
                if(notify.ChkEmailToAddress){
                    DatasetService.tableNotification(notify.EmailToAddress, $scope.composeMessage(messages));
                }

                //notify field email
                if(notify.ChkEmailToField && $scope._row[notify.EmailToField]){
                    DatasetService.tableNotification($scope.row[notify.EmailToField], $scope.composeMessage(messages));
                }

                console.log("sent notifications!");
            } else {
                console.log("no notifications to send.");
            }

        }

        $scope.composeMessage = function(messages){
            var message = ""
            messages.forEach(function(item){
                message += "<h3>" + item.Subject + "</h3>";
                message += item.Message + "<br/>";
            })

            message += "<hr><h4>Current Record</h4>";
            var skip = ['ProjectId','Id','ByUserId','DatasetId']; //,'rowErrorToolTip', 'validationErrors','rowHasError','strSelectedItemlist'];

            Object.keys($scope.row).forEach(function(property){
              // @ts-ignore
                if(!skip.contains(property)){
                    var field;
                    $scope.dataset.Fields.forEach(function(f){ if(f.DbColumnName == property) field = f});
                    if(field){
                        message += "<b>"+field.Label + "</b>: ";

                        if(field.Field.DataType == "date" || field.Field.DataType == "datetime")
                            message += moment($scope.row[field.DbColumnName]).format('MM/DD/YYYY HH:mm') + "<br/>";
                        else
                            message += $scope.row[field.DbColumnName] + "<br/>";
                    }
                }
            });

            return message;

        }

        $scope.onHeaderEditingStopped = function (field, logerrors) {
            //build event to send for validation
            console.log("onHeaderEditingStopped: " + field.DbColumnName);
            var event = {
                colDef: field,
                node: { data: $scope.row },
                data: $scope.row,
                scope: $scope,
                value: $scope.row[field.DbColumnName],
                type: 'onHeaderEditingStopped'
            };

            $scope.MultiSelectAlreadyRan = false;

            $scope.lastField = field;

            //$scope.context_dataset.Config.DataEntryPage.DisabledFields.forEach(function(item){
            //    if ($scope.DisabledFields.indexOf(field.DbColumnName) > -1)
            //        $scope.inactive = true;
            //});

            var OtherBoxName = "Other" + event.colDef.DbColumnName;
            //console.log("typeof event.data[" + OtherBoxName + "] = " + typeof event.data[OtherBoxName]);
            // Case1:  A select box has an option of "Other", and the "Other" is selected, so disable the associated Other... box.
            if ((typeof event.data[OtherBoxName] !== 'undefined') && (event.value == "Other"))
            {
                //var OtherBoxName = "Other" + field.DbColumnName;

                $scope.context_dataset.Fields.forEach(function(aField){
                    if (aField.DbColumnName == OtherBoxName)
                    {
                        //aField.ControlType = "text";
                        //console.log("$scope.DisabledFields[" + OtherBoxName + "].disabled = " + $scope.DisabledFields[OtherBoxName].disabled);
                        //console.log("field.disabled = " + field.disabled);
                        //console.log("field.Disabled = " + field.Disabled);

                        if ($scope.DisabledFields[OtherBoxName].disabled === true)
                        {
                            $scope.DisabledFields[OtherBoxName].disabled = false;
                        }
                    }
                });

            }
            // Case2:  A select box has an option of "Other", and the "Other" option IS NOT selected, so disable the associated Other... box.
            else if ((typeof event.data[OtherBoxName] !== 'undefined') && (event.value !== null) && (event.value !== "Other"))
            {
                if (($scope.DisabledFields[OtherBoxName]) && (event.value != "Other"))// && (event.data[OtherBoxName] === null))// && (event.data[OtherBoxName].length < 1))
                {
                    event.data[OtherBoxName] = null;
                    $scope.DisabledFields[OtherBoxName].disabled = true;
                }
                //aField.ControlType = "hidden";
            }
            // Case3: A different control is selected, and has no "Other..." box, so do nothing.
            else
            {
                // Do nothing
            }

            if (GridService.validateCell(event)) {
                GridService.fireRule("OnChange", event); //only fires when valid change is made
            }

            //update our collection of header errors if any were returned
            $scope.headerFieldErrors = [];
            if ($scope.row.rowHasError) {
                $scope.row.validationErrors.forEach(function (error) {
                    if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
                        $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
                    } else {
                        $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
                    }
                });
            }


        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $scope.addItem = function (selectedItem) {
            console.log("+ button clicked...");

            if (selectedItem === null)
                return;
            else if ($scope.lastField.ControlType !== "select-fancy")
                return;

            //console.log("$scope.row.strSelectedItemList = " + $scope.row.strSelectedItemList);

            var event = {
                colDef: $scope.lastField,
                node: { data: $scope.row },
                data: $scope.row,
                scope: $scope,
                value: selectedItem,
                type: 'addItem'
            };

            if (typeof $scope.row.strSelectedItemList === 'undefined')
                $scope.row.strSelectedItemList = "";

            //var SelectedBoxName = "Selected" + event.colDef.DbColumnName;

            // We will add a new line at the end, so that the string presents well on the page.
            if (event.value === "Other")
            {
                //scope.row.strCounties += $scope.row.OtherCounty + ";\n";
                $scope.row.strSelectedItemList += "Other;\n";

            }
            else
            {
              if (!$scope.row.strSelectedItemList.includes(event.value)) {
                $scope.row.strSelectedItemList += event.value + ";\n";
              }



            }

            //console.log("$scope.row.strSelectedItemList = " + $scope.row.strSelectedItemList);
            console.log("$scope.row.strSelectedItemList = " + $scope.row.strSelectedItemList);
            $scope.row.SelectedItemList = $scope.row.strSelectedItemList;
        }

        $scope.removeItem = function (selectedItem) {
            console.log("- button clicked...");

            if (selectedItem === null)
                return;

            //console.log("$scope.row.SelectedItemList before stripping = " + $scope.row.SelectedItemList);

            var event = {
                colDef: $scope.lastField,
                node: { data: $scope.row },
                data: $scope.row,
                scope: $scope,
                value: selectedItem,
                type: 'addItem'
            };

            // First, strip out the new line characters.
            $scope.row.strSelectedItemList = $scope.row.SelectedItemList.replace(/(\r\n|\r|\n)/gm, "");
            console.log("$scope.row.strSelectedItemList after stripping = " + $scope.row.strSelectedItemList);

            // Note, we still have the trailing semicolon.
            // Convert the string to an array, so that we can easily remove the applicable funding agency from the string.
            var arySelectedItemList = $scope.row.strSelectedItemList.split(";");

            // Next, get rid of that trailing semicolon.
            arySelectedItemList.splice(-1, 1);
            console.dir(arySelectedItemList);

            // Now we can continue with the delete action.
            var arySelectedItemListLength = arySelectedItemList.length;

            // First check if the user entered an "other" item.
            if (selectedItem === "Other")
            {
                var OtherBoxName = "Other" + event.colDef.DbColumnName;

                for (var i = 0; i < arySelectedItemListLength; i++)
                {
                    console.log("arySelectedItemList[i] = " + arySelectedItemList[i]);
                    if (arySelectedItemList[i].indexOf(selectedItem) > -1)
                    {
                        console.log("Found the item...");
                        arySelectedItemList.splice(i,1);
                        console.log("Removed the item.");

                        $scope.row.strSelectedItemList = "";
                        console.log("Wiped $scope.row.strSelectedItemList...");

                        // Rebuild the string now, adding the semicolon and newline after every line.
                        angular.forEach(arySelectedItemList, function(item){
                            $scope.row.strSelectedItemList += item + ";\n";
                            console.log("Added item...");
                        });

                        // Since we found the item, skip to then end to exit.
                        i = arySelectedItemListLength;
                    }
                }
            }
            else
            {
                for (var i = 0; i < arySelectedItemListLength; i++)
                {
                    console.log("arySelectedItemList[i] = " + arySelectedItemList[i]);
                    if (arySelectedItemList[i].indexOf(selectedItem) > -1)
                    {
                        console.log("Found the item...");
                        arySelectedItemList.splice(i,1);
                        console.log("Removed the item.");

                        $scope.row.strSelectedItemList = "";
                        console.log("Wiped $scope.row.strSelectedItemList...");

                        // Rebuild the string now, adding the semicolon and newline after every line.
                        angular.forEach(arySelectedItemList, function(item){
                            $scope.row.strSelectedItemList += item + ";\n";
                            console.log("Added item...");
                        });

                        // Since we found the item, skip to then end to exit.
                        i = arySelectedItemListLength;
                    }
                }
            }
            $scope.row.SelectedItemList = $scope.row.strSelectedItemList;
            console.log("Finished.");
        }

        $scope.AddDefaultItem = function (selectedItem) {
            if (!$scope.MultiSelectAlreadyRan)
            {
                if (($scope.row.County) && ($scope.row.strSelectedItemList === ""))
                {
                    $scope.addItem(selectedItem);
                }

                $scope.MultiSelectAlreadyRan = true;
            }
        }

        //select grid modal has to be available in all scopes
        $scope.openSelectGridModal = function(field){

            $scope.grid_field = field;

            var modal = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-select-grid.html',
                controller: 'ModalSelectGrid',
                scope: $scope,
                backdrop: "static",
                keyboard: false,
                windowClass: 'modal-large'
            }).result.then(function(itemId){
                console.log("Id selected = " + itemId);
                $scope.row[field.DbColumnName] = itemId;

            });
          }
        }
];

export default table_data_modal;
