//modal to add/edit permit contact
import * as angular from 'angular';


var modal_edit_location = ['$scope', '$uibModal','$uibModalInstance','PermitService','ViolationService',

    function ($scope, $uibModal, $uibModalInstance, PermitService, ViolationService) {

        $scope.mode = "edit";

        if (!$scope.location_modal.LocationId) {
            $scope.mode = "new";
            //$scope.contact_modal.ContactType = "Applicant"; //set default
        }

        console.log("Inside add-location-modal.ts...");

        $scope.save = function () {

            console.log("Inside save..., current url:  " + window.location.href);

            var new_location = "";

            //var save_location = angular.copy($scope.location_modal);
            //delete save_location.Location;

            if(!$scope.location_modal.LocationId){
                alert("Please select a location for this permit");
                return;
            }

            // if($scope.violations != null)
            // {
            //     new_location = ViolationService.saveViolationContact(save_location);
            // }
            // else{
            //     new_location = PermitService.savePermitLocation($scope.location_modal.LocationId);
            // }
            new_location = PermitService.savePermitLocation($scope.$parent.row.Id, $scope.location_modal.LocationId);


            // @ts-ignore
            new_location.$promise.then(function () {
                console.log("done and success!");
                $uibModalInstance.close(new_location);
                //location.reload();
                $scope.$parent.row.LocationId = $scope.location_modal.LocationId;
            });

        };

        $scope.locationEntry = "";
        $scope.locationMatches = [];

        $scope.Selected = {Location : []};

        $scope.locationEntryUpdate = function () {
            console.log("searching... " + $scope.datasetLocations.length + " records for " + $scope.locationEntry);
            $scope.locationMatches = [];
            $scope.Selected.Location.length = 0;
            $scope.location_modal = {};
            var regex = RegExp($scope.locationEntry, "gi"); //global + case insensitive
            var entryLength = $scope.locationEntry.length;
            if(entryLength < 2)
                return;

            $scope.datasetLocations.forEach(function (location) {
                if (location.Label == null || location.Label == "") {
                    return;
                }

                if (regex.test(location.Label)) {
                    $scope.locationMatches.push(location);
                }
            });
            console.log("found " + $scope.locationMatches.length + " matches");
        };

        $scope.selectLocation = function(){
            if(!$scope.Selected)
                return;
            var location = angular.fromJson($scope.Selected.Location[0]); //this is the trick
            $scope.location_modal.LocationId = location.Id;
            $scope.location_modal.Location = location;
        }


        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };


        $scope.addLocationModal = function(){
            //$scope.person_modal = $scope.contact_modal;
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/contacts/templates/add-person-modal.html',
                controller: 'AddPersonModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_location) {

                //saved_person.Label = (saved_person.Organization) ? saved_person.Organization : saved_person.FullName;
                // if (saved_person.Label == null || saved_person.Label == "")
                //     saved_person.Label = saved_person.FirstName + " " + saved_person.LastName;

                $scope.Locations.push(saved_location);
                $scope.location_modal.LocationId = saved_location.Id;
                //$(function () { $("#persons-select").select2(); });
            });
        }


    }
];

export default modal_edit_location;
