// defines the leasing module
import * as angular from 'angular';

// require([
//controllers
import inspection_violation_modal from "./components/manage/inspection-violation-modal";
import active_leases from "./components/manage/active-leases";
import view_lease from "./components/manage/view-lease";
import modal_add_spring_inspection from "./components/manage/add-spring-inspection-modal";
import modal_add_fall_inspection from "./components/manage/add-fall-inspection-modal";
import inspection_violations from "./components/manage/inspection-violations";
import lookup_lists from "./components/manage/lookups";
import modal_add_operator from "./components/manage/add-operator-modal";
import modal_add_production from "./components/manage/add-production-modal";
import modal_lease from "./components/manage/lease-modal";
import available_land from "./components/manage/available-land";
import leasing_home from "./components/manage/manage-leases";
import manage_operators from "./components/manage/manage-operators";
import modal_add_compliance_inspection from "./components/manage/add-compliance-inspection-modal";
import modal_modify_crop_plan from "./components/manage/modify-crop-plan-modal";
import pending_leases from "./components/manage/pending-leases";
import modal_add_grazing_inspection from "./components/manage/add-grazing-inspection-modal";
import lease_service from "./leasing-service";
//map directive



//service

import {LeaseModalScope} from "./components/manage/lease-scope";
import {serviceUrl} from "../../config";
import {downgradeComponent} from "@angular/upgrade/static";
import {LeasingMapComponent} from "../../../app/map/leasing-map/leasing-map.component";



// ], function () {
const leasing_module = angular.module('LeasingModule', ['ui.bootstrap', 'ngResource']);
leasing_module.controller('LeasingHomeController', leasing_home);
leasing_module.controller('ActiveLeasesController', active_leases);
leasing_module.controller('PendingLeasesController', pending_leases);
leasing_module.controller('ViewLeaseController', view_lease);
leasing_module.controller('AvailableLandController', available_land);
leasing_module.controller('LeaseModalController', modal_lease);
leasing_module.controller('SpringInspectionModalController', modal_add_spring_inspection);
leasing_module.controller('ProductionModalController', modal_add_production);
leasing_module.controller('FallInspectionModalController', modal_add_fall_inspection);
leasing_module.controller('ComplianceInspectionModalController', modal_add_compliance_inspection);
leasing_module.controller('GrazingInspectionModalController', modal_add_grazing_inspection);
leasing_module.controller('ModifyCropPlanController', modal_modify_crop_plan);
leasing_module.controller('ViolationsController', inspection_violations);
leasing_module.controller('InspectionViolationModalController', inspection_violation_modal);
leasing_module.controller('ManageOperatorsController', manage_operators);
leasing_module.controller('AddOperatorModalController', modal_add_operator);
leasing_module.controller('LookupListsController', lookup_lists);
leasing_module.service('LeasingService', lease_service);
leasing_module.directive('leasingMap', downgradeComponent({component: LeasingMapComponent}));
//Lease Statuses: these must correspond to Lease.cs in BE
// leasing_module.LeaseStatus = [];
// leasing_module.LeaseStatus[1] = "Active";
// leasing_module.LeaseStatus[2] = "Inactive";
// leasing_module.LeaseStatus[3] = "Pending";
// leasing_module.LeaseStatus[4] = "Withdrawn";
// leasing_module.LeaseStatus[5] = "Expired";
// leasing_module.LeaseStatus[6] = "Cancelled";
//
// leasing_module.LeaseLevels = [];
// leasing_module.LeaseLevels[1] = "Level 1";
// leasing_module.LeaseLevels[2] = "Level 2";
// leasing_module.LeaseLevels[3] = "Level 3";
// leasing_module.LeaseLevels[4] = "Level 4";


leasing_module.LeaseStatus = LeaseModalScope.LeaseStatus;
leasing_module.LeaseLevels = LeaseModalScope.LeaseLevels;
//maps lease types to the first number of the leasenumber (when generating lease numbers)
// leasing_module.LeaseTypeLeaseNumber = {
//   "Farming": "1",
//   "Grazing": "4",
//   "Residential": "2",
//   "Storage": "TL-",
// };

leasing_module.LeaseTypeLeaseNumber = LeaseModalScope.LeaseTypeLeaseNumber;

//leasing_module.InspectionViolationResolutions = ["Dismissed","Fee Paid","No Resolution","Other"];

//leasing_module.ProductionDeliveryUnit = ["ACRE", "AUM", "BU", "LB", "TON"];

// -- filter for lease status
leasing_module.filter('leaseStatus', function () {
  return function (input) {
    return leasing_module.LeaseStatus[input];
  }
});

leasing_module.filter('operOrgName', function () {
  return function (oper) {
    if (!oper)
      return "";

    return (oper.Organization) ? oper.Organization : oper.FirstName + " " + oper.LastName;
  }
});

//filter for Yes No fields
leasing_module.filter('boolYesNo', function () {
  return function (in_val) {
    return (in_val) ? "Yes" : "No";
  }
});

leasing_module.filter('arrayList', function () {
  return function (array_values) {
    let result = '';
    if (array_values) {

      try {
        for (let i = 0, len = array_values.length; i < len; i++) {
          result += array_values[i] + "\n";
        }
      } catch (e) {
        result = array_values;
      }
    }

    return result;
  }
});

leasing_module.factory('AllLeases', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/allleases', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);


leasing_module.factory('ActiveLeases', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/activeleases', {}, {
        query: { method: 'GET', params: { }, isArray: true }
    });
}]);

leasing_module.factory('PendingLeases', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/pendingleases', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);


leasing_module.factory('AvailableFields', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/availablefields', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

leasing_module.factory('AvailableAllotments', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/availableallotments', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);


leasing_module.factory('GetLease', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/getlease', {}, {
        query: { method: 'GET', params: { id: 'id'}, isArray: false }
    });
}]);

leasing_module.factory('GetLeasesByField', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/GetLeasesByField', {}, {
        query: { method: 'GET', params: { id: 'id'}, isArray: true }
    });
}]);


leasing_module.factory('GetOperators', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/getoperators', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

leasing_module.factory('DeleteOperator', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/deleteoperator');
}]);


leasing_module.factory('SaveLease', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/savelease');
}]);

leasing_module.factory('SaveCropPlan', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/savecropplan');
}]);

leasing_module.factory('SaveInspection', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/SaveInspection');
}]);

leasing_module.factory('SaveProduction', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/SaveProduction');
}]);

leasing_module.factory('RemoveProduction', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/RemoveProduction');
}]);

leasing_module.factory('GetLeaseRevisions', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/GetLeaseRevisions', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

leasing_module.factory('GetCropPlanRevisions', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/GetCropPlanRevisions', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

leasing_module.factory('GetInspectionViolations', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/GetInspectionViolations', {}, {
        query: { method: 'POST', params: {}, isArray: true }
    });
}]);

leasing_module.factory('SaveInspectionViolation', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/SaveInspectionViolation');
}]);

leasing_module.factory('RemoveInspection', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/RemoveInspection');
}]);

leasing_module.factory('SaveOperator', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/SaveOperator');
}]);

leasing_module.factory('GetLookups', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/metadata/GetPropertiesForEntity', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

leasing_module.factory('PutMetadataProperty', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/metadata/PutMetadataProperty', {}, {
        update: { method: 'PUT' }
    });
}]);

leasing_module.factory('GetMetadataProperty', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/metadata/GetMetadataProperty', {}, {
        query: { method: 'GET', params: {}, isArray: false }
    });
}]);

leasing_module.factory('ExpireLeases', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/lease/ExpireLeases', {}, {
        query: { method: 'GET', params: {}, isArray: false }
    });
}]);

/*
LeaseModalScope.prepareLeaseModalScope($scope, LeasingService);
*/

//can call to prepare a scope to use the leasemodal
// mainly loads the various lists for the dropdowns
/*
leasing_module.prepareLeaseModalScope = function ($scope, LeasingService) {

  $scope.leaseLevels = leasing_module.LeaseLevels;
  $scope.leaseStatuses = leasing_module.LeaseStatus;

  $scope.operators = LeasingService.getOperators();

  $scope.operators.$promise.then(function () {
    $scope.operators.forEach(function (oper) {
      oper.SortName = (oper.Organization) ? oper.Organization : oper.FirstName + " " + oper.LastName;
    });
  });

  // @ts-ignore
  $scope.leaseTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_LEASETYPES);
  // @ts-ignore
  $scope.productionDeliveryUnits = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_PRODUCTIONDELIVERY);
  // @ts-ignore
  $scope.cropOptions = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_CROPOPTIONS);
  // @ts-ignore
  $scope.weeds = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_WEEDS);
  // @ts-ignore
  $scope.residueTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_RESIDUETYPES);
  // @ts-ignore
  $scope.animalTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_ANIMALTYPES);
  // @ts-ignore
  $scope.subPractices = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_SUBPRACTICES);
  // @ts-ignore
  $scope.productionTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_PRODUCTIONTYPES);
  // @ts-ignore
  $scope.operatorStates = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_OPSTATES);
  // @ts-ignore
  $scope.operatorCities = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_OPCITIES);
  // @ts-ignore
  $scope.complianceInspectionTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_COMPLIANCE_INSP_TYPES);
  // @ts-ignore
  $scope.violationTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_VIOLATION_TYPES);

};
*/

// });
export default leasing_module;

