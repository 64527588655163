//Covid api

import {serviceUrl} from "../../config";
import covid_module from "./covid-module";



const covid_services = ['$q',

    'MyEmployees',
    'MyEmployeesWork',
    'SaveEmployees',
    'RemoveEmployee',

    function ($q,

        MyEmployees,
        MyEmployeesWork,
        SaveEmployees,
        RemoveEmployee

    ) {
        var service = {

            getMyEmployees: function () {
                return MyEmployees.query();
            },

            getMyEmployeesWork: function() {
                return MyEmployeesWork.query();
            },

            saveEmployees: function(data){
                return SaveEmployees.save({ Employees: data });
            },

            removeEmployee: function(Id) {
                return RemoveEmployee.save({ Id: Id });
            }



        };

        return service;

    }
];

export default covid_services;
