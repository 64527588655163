import * as angular from 'angular';
import * as moment from 'moment';
import {
  getByField,
  getFilesArrayAsList,
  getParsedMetadataValues,
  instrumentsToPossibleValues, makeObjects, orderByAlpha
} from "../../../common/common-functions";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var dataset_query_view_all_records = ['$scope', '$routeParams', 'DatasetService', '$location', '$uibModal', '$rootScope', 'ChartService',
    'ProjectService', 'CommonService', 'SubprojectService','GridService','$timeout',
    function ($scope, $routeParams, DatasetService, $location, $uibModal, $rootScope, ChartService, ProjectService, CommonService, SubprojectService, GridService, $timeout) {

        $scope.system = { loading: true, messages : [] };

        if (!$rootScope.infGridParams)
        {
            alert("To run this page, you must start with the Dataset Query page first!");

            $location.path("/projects");
            return;
        }

        $scope.Criteria = {};
        $scope.criteriaList = [];

        $scope.selectedRow = {};
        $scope.onField = {};

        $scope.instrumentIdList = [];
        $scope.instrumentAccuracyCheckList = [];

        $scope.prevTop = -1;
        $scope.lastRecord = 0;
        $scope.queryFullResults = [];
        $scope.queryTotalRecords = 0;
        $scope.queryTotalRecordsRun = false;
        $scope.DatasetTotalRecordsRun = false;
        $scope.DatasetTotalRecords = "...";
        $scope.RecordGroupSize = 30;
        $scope.prevStartRow = 0;
        $scope.endRow = $scope.RecordGroupSize;
        $scope.ScrollIndex = 0;
        $scope.scrollCount = 0;
        $scope.lastVisibleRow = 18;
        $scope.endPadding = 5;
        $scope.queryLoaded = false;
        $scope.datasource = null;
        $scope.NumberOfRecordsToPull = -1;
        $scope.NumberOfRecordsToPullSave = $scope.NumberOfRecordsToPull;
        $scope.infGridParams = null;
        $scope.fieldCriteriaRemoved = false;
        $scope.queryResult = {
            Error: false,
            ErrorMessage: null
        }
        $scope.runningQueryWithFieldCriteria = false;
        $scope.runningQueryToLoadAllRecords = false;

        $scope.dataset = DatasetService.getDataset($routeParams.Id);

        $scope.dataset.$promise.then(function () {

            $scope.project = ProjectService.getProject($scope.dataset.ProjectId);

            if ($scope.dataset.Datastore.TablePrefix === "WaterTemp")
                $scope.instrumentAccuracyCheckList = ProjectService.getAllInstrumentAccuracyChecks();

            $scope.project.$promise.then(function () {
                $scope.activateGrid();
            });

            //$scope.queryGetRecordCount();

        });

        $scope.clearValue = function()
        {
            $scope.Criteria.Value = null;
            console.dir($scope.Criteria);
        };

        //ag-grid - header + details --- alrid for multiple activities
        $scope.dataAgGridOptions = {
            rowBuffer: 0,
            animateRows: true,
            columnDefs: null,
            rowData: [],
            rowSelection: 'multiple',
            suppressPropertyNamesCheck: true, // this removes the console warning for custom properties, this is a known problem, see https://github.com/ag-grid/ag-grid/issues/2320
          //@ts-ignore
            gridOptions: any = this.gridOptions,

            onSelectionChanged: function (params) {
                $scope.selectedRow = $scope.dataAgGridOptions.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },

            onCellFocused: function (params) {
                //console.dir(params);
                if (!params.column)
                    return;

                $scope.onField = (params.column.colDef.cdmsField);
                $scope.$apply();
            },

            onBodyScroll: function(params){
                // Leaving this funciton in, in case we need it sometime in the future.
                //console.log(" scrolling ");
                //console.dir(params);
                //console.dir($scope.ag_grid);

                //$scope.scrollCount++;
                //console.log("$scope.scrollCount = " + $scope.scrollCount);
                //console.log("-----");
            },

            onGridReady: function (params) {
                console.log("GRID READY fired. ------------------------------------------>>>");
                $scope.system.loading = false;
                $scope.$apply();
                GridService.autosizeColumns($scope.dataAgGridOptions);
            },

            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
            },

            processCellForClipboard: $scope.processCellDataForOutput,
        };

        $scope.activateGrid = function () {
            console.log("activating grid!");
            //setup grid and coldefs and then go!
            $timeout(function () {

                //need to set some header field possible values manually BEFORE we load our coldefs - so that the named value will display in the grid.
                var instrument_coldef = getByField($scope.dataset.Fields,"InstrumentId", "DbColumnName");
                if (instrument_coldef) {
                    instrument_coldef.Field.PossibleValues = instrumentsToPossibleValues($scope.project.Instruments);
                }

                //var hidden_header_controltypes = ["file", "hidden", "accuracy-check-select", "activity-text", "instrument-select", "post-accuracy-check-select", "qa-status-comment", "timezone-select"];
                var hidden_header_controltypes = ["file", "hidden", "activity-text", "instrument-select", "qa-status-comment"];
                //var hidden_grid_controltypes = ["hidden", "activity-text","accuracy-check-select","timezone-select","post-accuracy-check-select"];
                var hidden_grid_controltypes = ["hidden", "activity-text"];

                $scope.dataAgColumnDefs = GridService.getAgColumnDefs($scope.dataset);

                //setup any possible values that are needed - detail
                //note: the "hide" property hides the column in the results grid; the "hide_header" hides it in the header list in columns multiselect
                angular.forEach($scope.dataAgColumnDefs.DetailFields, function (fieldDef) {
                    if (fieldDef.field == "QAStatusId") { //RowQAStatusId because we're in the details
                        fieldDef.field = fieldDef.DbColumnName = "RowQAStatusId";
                        fieldDef.PossibleValuesList = makeObjects($scope.dataset.RowQAStatuses, 'Id', 'Name');
                        fieldDef.setPossibleValues(fieldDef.PossibleValuesList);
                        fieldDef.hide_header = true;

                    } else {
                        fieldDef.PossibleValuesList = getParsedMetadataValues(fieldDef.PossibleValues);
                    }

                    if (fieldDef.ControlType == 'file' || fieldDef.ControlType == 'hidden')
                        fieldDef.hide_header = true;

                });

                //setup activity fields to point to the right place
                //note: the "hide" property hides the column in the results grid; the "hide_header" hides it in the header list in columns multiselect
                angular.forEach($scope.dataAgColumnDefs.HeaderFields, function (fieldDef) {
                    //console.dir(fieldDef);
                    if (fieldDef.field == "LocationId") {

                        //load the config so that we can check if we are supposed to include the habitat sites for this project
                        try {
                            $scope.project.Config = ($scope.project.Config) ? angular.fromJson($scope.project.Config) : {};
                        } catch (e) {
                            console.error("config could not be parsed for project" + $scope.project.Config);
                            console.dir(e);
                        }

                        var dataset_locations = ProjectService.getDatasetLocations($scope.project.Id, $scope.dataset.Id);

                        dataset_locations.$promise.then(function(){
                            dataset_locations = dataset_locations.sort(orderByAlpha);

                            fieldDef.PossibleValuesList = dataset_locations; //makeObjects(dataset_locations, 'Id', 'Label'); //used in the headers

                            if (fieldDef.hasOwnProperty('setPossibleValues'))
                                fieldDef.setPossibleValues(makeObjects(dataset_locations, 'Id', 'Label')); //used in the grid
                        })


                    } else if (fieldDef.field == "QAStatusId") { //ActivityQAStatusId
                        fieldDef.field = fieldDef.DbColumnName = "ActivityQAStatusId";
                        fieldDef.PossibleValuesList = makeObjects($scope.dataset.QAStatuses, 'Id', 'Name');

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                        fieldDef.hide_header = true; //hide in header
                    }
                    else if (fieldDef.field == "QAComments") { //ActivityQAStatusId
                        fieldDef.field = fieldDef.DbColumnName = "ActivityQAComments";
                    }

                    if (fieldDef.ControlType == "select" || fieldDef.ControlType == "multiselect") {
                        fieldDef.PossibleValuesList = getParsedMetadataValues(fieldDef.PossibleValues);

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                    } else if (fieldDef.ControlType == "instrument-select") {// || fieldDef.ControlType == "accuracy-check-select" || fieldDef.ControlType =="post-accuracy-check-select" || fieldDef.ControlType == "timezone-select") {
                        fieldDef.PossibleValuesList = makeObjects(fieldDef.PossibleValues, 'Id', 'Label');

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                        //fieldDef.AccuracyChecks =

                    } else if (fieldDef.ControlType == "fisherman-select") {
                        fieldDef.PossibleValuesList = fieldDef.PossibleValues;

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                    } else if (fieldDef.ControlType == "number-select") {
                        fieldDef.PossibleValuesList = fieldDef.PossibleValues;

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                    } else if (fieldDef.ControlType == "accuracy-check-select") {
                        fieldDef.PossibleValuesList = fieldDef.PossibleValues;

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                    } else if (fieldDef.ControlType == "post-accuracy-check-select") {
                        fieldDef.PossibleValuesList = fieldDef.PossibleValues;

                        if (fieldDef.hasOwnProperty('setPossibleValues'))
                            fieldDef.setPossibleValues(fieldDef.PossibleValuesList);

                    }

                    //hidden headers
                  // @ts-ignore
                    if (hidden_header_controltypes.contains(fieldDef.ControlType))
                        fieldDef.hide_header = true; //just the headers

                    //hidden in grid
                  // @ts-ignore
                    if (hidden_grid_controltypes.contains(fieldDef.ControlType))
                        fieldDef.hide = true; //just the detail


                });

                $scope.dataAgGridOptions.columnDefs = $scope.dataAgColumnDefs.HeaderFields.concat($scope.dataAgColumnDefs.DetailFields);

                //console.log("Ready to go!");
                //console.dir($scope.dataAgGridOptions.columnDefs);
                //console.dir($scope.imported_rows);


                var ag_grid_div = document.querySelector('#query-grid') as HTMLElement;    //get the container id...
                $scope.ag_grid = new Grid(ag_grid_div, $scope.dataAgGridOptions); //bind the grid to it.
                //$scope.dataAgGridOptions.api.showLoadingOverlay(); //show loading...

                $scope.infGridParams = angular.copy($rootScope.infGridParams);
                $scope.DatasetTotalRecords = $rootScope.infGridParams.NumberOfRecordsToPull;

                $scope.executeQuery($scope.infGridParams);

                //set the detail values into the grid
                //$scope.dataAgGridOptions.api.setRowData($scope.imported_rows);

                //$scope.dataAgGridOptions.api.redrawRows();
                //GridService.autosizeColumns($scope.dataAgGridOptions);

                //$scope.calculateStatistics();
                //-----

            }, 0);
        };

        /*$scope.runQueryWithCriteria = function(params){
            // When the page opens and the query first runs, it pulls the first set of
            // records, with no filters (get everything).
            // When the user clicks Execute Query, they have probably added
            // criteria.  Therefore, we must reset the query,
            // so that we don't append the new results onto the old result set.
            // Also, we want to start at the beginning,
            // rather than offsetting into the data (having a startRow > 0).

            // First dump the last results and reset things.
            $scope.clearGrid();
            $scope.query = undefined;

            $scope.queryTotalRecords = 0;
            $scope.DatasetTotalRecords = "...";
            $scope.queryTotalRecordsRun = false;
            $scope.DatasetTotalRecordsRun = false;
            $scope.resetQueryError();
            $scope.runningQueryWithFieldCriteria = true;
            $scope.NumberOfRecordsToPull = $scope.NumberOfRecordsToPullSave;

            // Now, set the datasource for the grid back to the real thing.
            $scope.dataAgGridOptions.api.setDatasource($scope.dataSource);

            // Update the offset and endRow.
            params.startRow = 0;
            params.endRow = $scope.NumberOfRecordsToPull;

            $scope.dataSource.getRows(params);
        };

        $scope.runQueryWithCriteriaLoadAllItems = function(){

            // Dump the last results and reset things.
            $scope.clearGrid();
            $scope.query = undefined;

            $scope.queryTotalRecords = 0;
            //$scope.queryTotalRecordsRun = false;  // Should be true still.
            //$scope.DatasetTotalRecordsRun = false; // Should be true still.
            $scope.resetQueryError();
            //$scope.runningQueryWithFieldCriteria = true; // More thoughts on this below.

            // Now, set the datasource for the grid back to the real thing.
            //$scope.dataAgGridOptions.api.setDatasource($scope.dataSource);

            if ($scope.fieldCriteriaRemoved)
                $scope.runningQueryWithFieldCriteria = false;

            $scope.runningQueryToLoadAllRecords = true;

            // Set the starting row and ending row, so that we can get all the records.
            $scope.infGridParams.startRow = 0;
            $scope.infGridParams.endRow = $scope.DatasetTotalRecords;
            $scope.infGridParams.NumberOfRecordsToPull = $scope.DatasetTotalRecords;
            $scope.NumberOfRecordsToPullSave = $scope.NumberOfRecordsToPull =

            // $scope.NumberOfRecordsToPull is set initially when the page/dataset loads.
            $scope.NumberOfRecordsToPull = $scope.DatasetTotalRecords;

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-rowqaupdate.html',
                controller: 'ModalBulkRowQAChangeCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });

            $location.path("/datasetquery/" + $scope.dataset.Id);

        };
        */

        $scope.executeQuery = function(params){
            $scope.query = $scope.buildQuery(params);

            $scope.query.loading = true;

            $scope.query.criteria.GetTotalCount = false;

            $scope.query.results = DatasetService.queryActivities($scope.query);

            $scope.query.results.$promise.then(function () {

                    if (!$scope.query.results.$resolved)
                        return;

                    $scope.query.results.forEach(function (item) {
                        if (item.SqlError)
                        {
                            $scope.query.loading = false;
                            console.error("SQL Timeout in the backend...");

                            $scope.queryResult.Error = true;
                            $scope.queryResult.ErrorMessage = "The query results are currently too intense for this system.  Please contact the GIS office for support with this request.";
                            //alert($scope.queryResult.ErrorMessage);
                            return;
                        }

                        $scope.instrumentAccuracyCheckList.forEach(function (accCheck) {
                            if (item.AccuracyCheckId === accCheck.Id) {
                                item.AccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
                            }
                            if (item.PostAccuracyCheckId === accCheck.Id) {
                                item.PostAccuracyCheckId = accCheck.Bath1Grade + "-" + accCheck.Bath2Grade + " " + moment(accCheck.CheckDate).format('MMM DD YYYY');
                            }
                        });

                        if (item.Timezone) {
                            var parsedTimezone = JSON.parse(item.Timezone);
                            item.Timezone = parsedTimezone.Name;
                        }

                        $scope.queryTotalRecords++;
                    });

                    if ($scope.queryResult.Error)
                        return; // Get us out of here.

                    console.log("$scope.queryTotalRecords = " + $scope.queryTotalRecords);

                    // Can only call setRowData when using normal row model (not infinite).
                    $scope.dataAgGridOptions.api.setRowData($scope.query.results);



                    $scope.query.loading = false;

                    // If we have not pulled the record count yet, we need to,
                    // but we only want to run it once, when the page loads,
                    // or when the user supplies some fields to filter on.
                    //if (!$scope.DatasetTotalRecordsRun)
                    //{
                    //    $scope.queryGetRecordCount();
                    //    $scope.DatasetTotalRecordsRun = true;
                    //}


                }
            );
        };

        /*$scope.queryGetRecordCount = function(){

            $scope.query.criteria.GetTotalCount = true;

            $scope.query.TotalRecordCount = DatasetService.queryActivities($scope.query);
            $scope.query.TotalRecordCount.$promise.then(function () {
                // There should only be one...
                $scope.query.TotalRecordCount.forEach(function (item) {
                    $scope.DatasetTotalRecords = item.TotalRecords;
                });
                $scope.query.criteria.GetTotalCount = undefined;

                $scope.infGridParams.startRow = 0;
                $scope.infGridParams.endRow = $scope.DatasetTotalRecords;
                $scope.infGridParams.NumberOfRecordsToPull = $scope.DatasetTotalRecords;
            });
        };
        */

        $scope.buildQuery = function(params){

            /*var queryCriteriaList = angular.copy($scope.criteriaList);
            queryCriteriaList.forEach(function (criteria) {
                try {
                    if(Array.isArray(criteria.Value))
                        criteria.Value = angular.toJson(criteria.Value);
                } catch (e) {
                    //oh well.
                }
            });
            */

            var query = null;
            query =
            {
                criteria: {
                    DatasetId: 	  $scope.dataset.Id,
                    Fields: 	  $rootScope.queryCriteriaList,
                    TablePrefix:  $scope.dataset.Datastore.TablePrefix,
                    startRow: params.startRow,
                    endRow: params.endRow,
                    sortModel: params.sortModel,
                    filterModel: params.filterModel,
                    NumberOfRecordsToPull: params.NumberOfRecordsToPull,
                    PullAllRecords: true
                },
                loading: true,
            };

            $scope.startRow = params.startRow;

            //console.log("query in buildQuery is next...");
            //console.dir(query);

            return query;
        };

        $scope.openActivity = function()
        {
            console.dir($scope.selectedRow);
            $location.path("/dataview/"+$scope.selectedRow.ActivityId);
        };

        //export the data - button click
        $scope.doExport = function () {

            if (!$scope.ExportFilename) {
                alert("Please enter a name for your export file like: 'TucannonJune25Export'.");
                return;
            }

            var params = {
                fileName: $scope.ExportFilename + ".xlsx",
                processCellCallback : $scope.processCellDataForOutput,
            };

            $scope.dataAgGridOptions.api.exportDataAsExcel(params);
        };

        //this is used by both export and copy functions to de-reference our values
        $scope.processCellDataForOutput = function (params) {

            //here we do any special formatting since export does NOT call cell renderers or cell formatters...
            //if ((params.column.colDef.DbColumnName == "LocationId") && (params.column.colDef.PossibleValues !== null)) {
            if ((params.column.colDef.DbColumnName == "LocationId") && ($scope.dataset.Locations !== null)){
                //return params.column.colDef.PossibleValues[params.value];

                // For locations, overwrite the params value with the Label,
                // rather than the Id, before it goes to the file, so that
                // the text name shows in the file, rather than the Id.
                $scope.dataset.Locations.forEach(function (item){
                    if (params.value == item.Id)
                        params.value = item.Label;
                        //return item.Label;
                });

            }

            if ((params.column.colDef.DbColumnName == "InstrumentId") && (params.column.colDef.PossibleValues !== null)) {
                return params.column.colDef.PossibleValues[params.value];
            }

            if ((params.column.colDef.DbColumnName == "FishermanId") && (params.column.colDef.PossibleValues !== null)) {
                return params.column.colDef.PossibleValues[params.value];
            }

            if ((params.column.colDef.DbColumnName == "StreamName") && (params.column.colDef.PossibleValues !== null)) {
                return params.column.colDef.PossibleValues[params.value];
            }

            if ((params.column.colDef.DbColumnName == "ActivityQAStatusId") && (params.column.colDef.PossibleValues !== null)) {
                return params.column.colDef.PossibleValues[params.value];
            }

            if ((params.column.colDef.DbColumnName == "RowQAStatusId") && (params.column.colDef.PossibleValues !== null)) {
                return params.column.colDef.PossibleValues[params.value];
            }

            if (params.column.colDef.ControlType == 'file' || params.column.colDef.ControlType == 'link') {
                var retvalue = [];
                var files = getFilesArrayAsList(params.value);
                files.forEach(function (file) {
                    if (params.column.colDef.ControlType == 'file')
                    {
                        if (file.Name)
                            retvalue.push(file.Name);
                        else
                            retvalue.push(file);
                    }
                    else if (params.column.colDef.ControlType == 'link')
                        retvalue.push(file.Link);
                });
                return retvalue.join();
            }

            if (params.column.colDef.ControlType == "datetime" || params.column.colDef.ControlType == "time") {

                let retval = params.value;
                try {

                    retval = moment(params.value).format("YYYY-MM-DD HH:mm:ss");
                } catch (e) {

                    console.log("problem converting: " + retval + " to datetime text");
                    console.dir(e);
                }

                return retval;
            }

            if (params.column.colDef.ControlType == "date" || params.column.colDef.ControlType == "activity-date") {

                let retval = params.value;
                try {
                    retval = moment(params.value).format("YYYY-MM-DD");
                } catch (e) {
                    console.log("problem converting: " + retval + " to datetime text");
                    console.dir(e);
                }
                return retval;
            }

            //default
            return params.value;

        };

        $scope.resetQueryError = function ()
        {
            $scope.queryResult.Error = false;
            $scope.queryResult.ErrorMessage = null;
        };

    }];

export default dataset_query_view_all_records;
