// defines the bot module
import * as angular from 'angular';
// require([
//controllers
import bot_projects from "./components/dashboard/projects";


//modals

//service
import * as bot_service from './bot-service';
import {serviceUrl} from "../../config";
import bot_services from "./bot-service";


// ], function () {
const bot_module = angular.module('BOTModule', ['ui.bootstrap', 'ngResource'])
bot_module.controller('BOTProjectsController', bot_projects);
bot_module.service('BOTService', bot_services);

bot_module.factory('AllBOTProjects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/bot/allprojects', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

bot_module.factory('BOTProjects', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/bot/projectsbytype', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

bot_module.factory('ProjectObjectiveReports', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/bot/ProjectObjectiveReportsByObjective', {}, {
        query: { method: 'GET', params: {}, isArray: true }
    });
}]);

bot_module.factory('SaveBOTProject', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/bot/saveBotProject');
}]);

bot_module.factory('SaveBOTProjectObjective', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/bot/saveBotProjectObjective');
}]);

bot_module.factory('SaveBOTProjectObjectiveReport', ['$resource', function ($resource) {
    return $resource(serviceUrl + '/api/v1/bot/saveBotProjectObjectiveReport');
}]);

// });

export default bot_module;

