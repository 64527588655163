import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var permit_notifications = ['$scope', 'PermitService','ViolationService',
    function ($scope, PermitService, ViolationService) {

        $scope.PermitRoutes = PermitService.getPermitRoutes();

        $scope.PermitRoutes.$promise.then(function () {
            $scope.routesGridDiv = document.querySelector('#routes-grid');
            new Grid($scope.routesGridDiv, $scope.routesGrid);
            $scope.routesGrid.api.setRowData($scope.PermitRoutes);
        });

        $scope.routesGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            selectedItem: null,
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
        };


        $scope.viewViolationNotifications = function(){
            console.log("violation notifications");
            $scope.Notifications = ViolationService.getNotifications();
        }


        $scope.viewPermitNotifications = function(){
            console.log("permit notifications");
            $scope.Notifications = PermitService.getNotifications();
        }


        $scope.routesGrid.columnDefs = [
            { headerName: "Event Type", field: "EventType", width: 250, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Item Type", field: "ItemType", width: 120, menuTabs: ['filterMenuTab'], filter: 'text' },
            { headerName: "Rank", field: "Rank", width: 150, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Email", field: "Email", width: 250, menuTabs: ['filterMenuTab'], filter: true }
        ];

        $scope.addRow = function () {
            $scope.routesGrid.api.updateRowData({add: [{ItemType:"",Rank:null,Email:""}]});
        };

        $scope.viewPermitNotifications();

    }
];

export default permit_notifications;
