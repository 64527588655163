import {
	//SYSTEM_LOCATION_DATASET, 
	GEOMETRY_SERVICE_URL, 
	NAD83_SPATIAL_REFERENCE, 
	ENVIRONMENT,
	CDMS_POINTS_TEST_URL, 
	CDMS_POINTS_PROD_URL
} from "../../../../config";
import Map from "@arcgis/core/Map";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
//import ProjectParameters from "@arcgis/core/tasks/support/ProjectParameters";
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import Graphic from "@arcgis/core/Graphic";
//import GeometryService from "@arcgis/core/tasks/GeometryService";
import * as GeometryService from "@arcgis/core/rest/geometryService";
import Point from "@arcgis/core/geometry/Point";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

var modal_verify_action = ['$scope', '$rootScope', '$uibModalInstance', 'SubprojectService','CommonService',
    function ($scope, $rootScope, $uibModalInstance, SubprojectService, CommonService){
	console.log("Inside ModalVerifyActionCtrl...");
	//console.log("$scope is next...");
	//console.dir($scope);
	console.log("$scope.verifyAction = " + $scope.verifyAction);
	console.log("$scope.verifyingCaller = " + $scope.verifyingCaller);

    $scope.map = new Map();

    if (ENVIRONMENT == "prod")
    {
      $scope.ShowMap.Test = false;
      $scope.ShowMap.Prod = true;
      $scope.map.locationLayer = new FeatureLayer({url:CDMS_POINTS_PROD_URL});
    }
    else
    {
      $scope.ShowMap.Test = true;
      $scope.ShowMap.Prod = false;
      $scope.map.locationLayer = new FeatureLayer({url:CDMS_POINTS_TEST_URL});
    }

	console.log("Calling program = " + $scope.verifyingCaller);
	if ($scope.verifyingCaller === "CrppSubproject")
	{
		console.log("CrppSubproject is calling...");
		$scope.header_title = $scope.verifyAction + " this CRPP project: " + $scope.viewSubproject.ProjectName;
		$scope.header_message = $scope.verifyAction.toLowerCase() + " this CRPP project: " + $scope.viewSubproject.ProjectName;
	}
	else if ($scope.verifyingCaller === "CorrespondenceEvent")
	{
		console.log("CorrespondenceEvent is calling...");
		var intTLocation = $scope.ce_row.CorrespondenceDate.indexOf("T");
		var strCeDate = $scope.ce_row.CorrespondenceDate.substring(0, intTLocation);
		$scope.header_title = $scope.verifyAction + " this Correspondence Event: " + strCeDate;
		//$scope.header_message = $scope.verifyAction + " this Correspondence Event: " + $scope.ce_row.CorrespondenceDate + ", by this Staff member: " + $scope.ce_row.StaffMember;
		$scope.header_message = $scope.verifyAction + " this Correspondence Event: " + strCeDate + ", by this Staff member: " + $scope.ce_row.StaffMember;
	}
	else if ($scope.verifyingCaller === "HabSubproject")
	{
		console.log("HabSubproject is calling...");
		$scope.header_title = $scope.verifyAction + " this Habitat project: " + $scope.viewSubproject.ProjectName;
		$scope.header_message = $scope.verifyAction.toLowerCase() + " this Habitat project: " + $scope.viewSubproject.ProjectName;
    }
    else if ($scope.verifyingCaller === "OlcSubproject") {
        console.log("OlcSubproject is calling...");
        $scope.header_title = $scope.verifyAction + " this OLC project: " + $scope.viewSubproject.CatalogNumber;
        $scope.header_message = $scope.verifyAction.toLowerCase() + " this OLC project: " + $scope.viewSubproject.CatalogNumber;
    }

    //$scope.header_message = $scope.verifyAction.toLowerCase() + " this CRPP project";


        /* kb - commenting out to see if we can do without now
	var subprojectListwatcher = $scope.$watch('subprojectList.length', function(){
		console.log("Inside ModalVerifyActionCtrl watch, subprojectList");

		if ($scope.subprojectList.length === 0)
		{
			console.log("No subprojects found yet...");
			return;
		}

		console.log("$scope.subprojectList is next..");
		console.dir($scope.subprojectList);

		$scope.subprojectOptions = $rootScope.subprojectOptions = makeObjects($scope.subprojectList, 'Id','ProjectName');

		// Debug output ... wanted to verify the contents of scope.subprojectOptions
		angular.forEach($scope.subprojectOptions, function(subproject){
			console.dir(subproject);
		});

		console.log("$scope.subprojectOptions is next...");
		console.dir($scope.subprojectOptions);
		////console.dir(scope);
		//subprojectListwatcher(); // Turn off this watcher.
		//$uibModalInstance.dismiss();
	});
	*/


    $scope.cancel = function(){
        $uibModalInstance.dismiss();
		$scope.verifyAction = 'undefined';
    };

	$scope.continueAction = function(){
		console.log("Inside continueAction...");
		//console.log("$scope is next...");
		//console.dir($scope);
		//$scope.continueAction = true;
		//$scope.verifyAction = 'undefined';

		console.log("$scope.verifyAction = " + $scope.verifyAction);

		var promise = null;
		if (($scope.verifyAction === "Delete") && ($scope.verifyingCaller === "CrppSubproject"))
		{
			console.log("$scope.project.Id = " + $scope.project.Id + ", $scope.viewSubproject.Id = " + $scope.viewSubproject.Id);
            promise = SubprojectService.removeSubproject($scope.project.Id, $scope.viewSubproject.Id);
            promise.$promise.then(function () {
                $scope.postRemoveSubprojectUpdateGrid();
                $uibModalInstance.dismiss();
            });
		}
		else if (($scope.verifyAction === "Delete") && ($scope.verifyingCaller === "CorrespondenceEvent"))
        {
            //maybe there is no case of this...

			console.log("$scope.project.Id = " + $scope.project.Id + ", $scope.viewSubproject.Id = " + $scope.viewSubproject.Id + ", $scope.ce_RowId = " + $scope.ce_rowId);
			//var promise = SubprojectService.removeCorrespondenceEvent($scope.project.Id, $scope.viewSubproject.Id, $scope.ce_rowId);
            promise = SubprojectService.removeCorrespondenceEvent($scope.project.Id, $scope.viewSubproject.Id, $scope.ce_rowId);
            promise.$promise.then(function () {
                //$scope.postRemoveSubprojectUpdateGrid();
                $uibModalInstance.dismiss();
            });
		}
		else if (($scope.verifyAction === "Delete") && ($scope.verifyingCaller === "HabSubproject"))
		{
			console.log("$scope.projectId = " + $scope.project.Id + ", $scope.viewSubproject.Id = " + $scope.viewSubproject.Id + ", $scope.viewSubproject.locationId = " + $scope.viewSubproject.locationId);
			var theSdeObjectId = 0;
			var keepGoing = true;

			// We will handle deleting the point from SDE at a later time.  For now, just delete the location from CDMS.
			// First, get the SdeObjectId from the location associated to this subproject.
			/*angular.forEach($scope.project.Locations, function(aLocation){
				if ((keepGoing) && (aLocation.Id === $scope.viewSubproject.LocationId))
				{
					console.log("Found the location...");
					theSdeObjectId = aLocation.SdeObjectId;
					keepGoing = false;
				}
			});

			// Next, call the service and delete the point from the SDE.
			var applyEditsParams = {
				"id": 0,
				"adds":[{}],
				"updates":[{}],
				"deletes":[{theSdeObjectId}],
			};
			/*var applyEditsParams = {
				"id": 0,
				"adds": null,
				"updates": null,
				"deletes": theSdeObjectId
			};
			var applyEditsParams = theSdeObjectId;


			$scope.map.locationLayer.applyEdits(null,null,applyEditsParams).then(function(results){
				console.log("Inside $scope.map.locationLayer.applyEdits");
				console.log("results is next...");
				console.dir(results);
				if(results[0].success)
				{
					console.log("Deleted point! "+ theSdeObjectId);
				}
			});
			*/

			// First, delete the point in SDE.  To do that, we must build a graphic for the point, 
			// because ArcGIS has us delete a graphic, not a point.
			var inSR = new SpatialReference({ wkt: NAD83_SPATIAL_REFERENCE }); //esri/SpatialReference
			var outSR = new SpatialReference({ wkid: 102100 });

			$scope.deletePoint = new Point({x: $rootScope.theLocation.GPSEasting, y: $rootScope.theLocation.GPSNorthing, spatialReference: inSR});

			//convert spatial reference
			var PrjParams = new ProjectParameters();
			PrjParams.outSpatialReference = outSR;
			PrjParams.geometries = [$scope.deletePoint];

			// Do the projection (conversion)
			GeometryService.project(GEOMETRY_SERVICE_URL, PrjParams).then((delOutputpoint) => {
				let symbol = new SimpleMarkerSymbol({
					style: "circle",
					color: "#00ff00",
					size: "8px",
					});

			  	$scope.PointToDelete = new Point(delOutputpoint[0]);

			  	//throw "Stopping right here...";

			  	var attributes = {OBJECTID: $rootScope.theLocation.SdeObjectId};

				var deleteGraphic = new Graphic({geometry: $scope.PointToDelete, symbol: symbol, attributes: attributes});




				// Note:  You cannot just pass the point to delete; it must be in a graphic object,
				//        or the code dies in dojo-land, which is different than what is shown in 
				//        ArcGIS documenation on the service interface.
				$scope.map.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}).then (function (deleteResults) { // This works

					// If the point WAS NOT in SDE, we still need to delete the CDMS location and Habitat Subproject anyway.
					if (deleteResults && deleteResults.deleteFeatureResults.length === 0)
					{
						$scope.SaveMessage = "The location did not exist in SDE.";
						console.log($scope.SaveMessage);
						console.dir(deleteResults);

						//throw "Inside project-locations.ts, stopping right here, to leave the point in CDMS.";
						// Delete the location, before deleting the Habitat Subproject.
						var delete_loc = CommonService.deleteLocation($scope.viewSubproject.LocationId); // This just removes the location from CDMS, not SDE.
				
						delete_loc.$promise.then(function (data) {
							console.log("deleted location");
							//$scope.dataGridOptions.api.deselectAll()
							//$scope.loadProjectLocations();

							// Now (finally) we can delete the Habitat Subproject.
							promise = SubprojectService.removeHabSubproject(parseInt($scope.project.Id), $scope.viewSubproject.Id, $scope.viewSubproject.LocationId);
							promise.$promise.then(function () {
								$scope.postRemoveHabitatSubprojectUpdateGrid();
								$uibModalInstance.dismiss();
							});
							
						}, function (error) {
							console.dir(error);
							$scope.SaveMessage = "Could not delete that location.";
							alert($scope.SaveMessage);

							console.log($scope.SaveMessage);
							console.dir(deleteResults);
							//$scope.loadProjectLocations();
				
							// $uibModalInstance.close(
							//   // on close this information is sent to the esri-map component, and enters as the inPayload object.
							//   {
							//     'ProjectId': $scope.project.Id,
							//     'Location': $scope.row,
							//     'IsFirstRun': true
							//   });
						});


					}
					else if (deleteResults && deleteResults.deleteFeatureResults[0] && deleteResults.deleteFeatureResults[0].objectId) 
					{
						console.log("Deleted old habitat point! " + deleteResults.deleteFeatureResults[0].objectId);

						//throw "Inside project-locations.ts, stopping right here, to leave the point in CDMS.";
						// We deleted the point in SDE.  Now to delete the location.
						var delete_loc = CommonService.deleteLocation($scope.viewSubproject.LocationId); // This just removes the location from CDMS, not SDE.
						delete_loc.$promise.then(function(){
							var removeHabSubPromise = SubprojectService.removeHabSubproject(parseInt($scope.project.Id), $scope.viewSubproject.Id, $scope.viewSubproject.LocationId);
							removeHabSubPromise.$promise.then(function () {
								$scope.postRemoveHabitatSubprojectUpdateGrid();
								$uibModalInstance.dismiss();
							});
						});


					}
					else {
						$scope.SaveMessage = "There was a problem deleting that location.";
						console.log($scope.SaveMessage);
						console.dir(deleteResults);
					}

				}, function (deleteErr: any) {
					console.log("Apply Edits - Delete Habitat site location - failed:  " + deleteErr.message + " > " + deleteErr.innerHTML);
				}); // Delete applyEdits

				$rootScope.ProjectId = $scope.project.Id;

			}); // GeometryService

			////promise = SubprojectService.removeHabSubproject(parseInt($scope.projectId), $scope.viewSubproject.Id);
			////promise = SubprojectService.removeHabSubproject(parseInt($scope.projectId), $scope.viewSubproject.Id, theSdeObjectId);
            // promise = SubprojectService.removeHabSubproject(parseInt($scope.project.Id), $scope.viewSubproject.Id, $scope.viewSubproject.LocationId);
            // promise.$promise.then(function () {
            //     $scope.postRemoveHabitatSubprojectUpdateGrid();
            //     $uibModalInstance.dismiss();
            // });
        }
        else if (($scope.verifyAction === "Delete") && ($scope.verifyingCaller === "OlcSubproject")) {
            console.log("$scope.project.Id = " + $scope.project.Id + ", $scope.viewSubproject.Id = " + $scope.viewSubproject.Id);
            promise = SubprojectService.removeOlcSubproject($scope.project.Id, $scope.viewSubproject.Id);
            promise.$promise.then(function () {
                $scope.postRemoveSubprojectUpdateGrid();
                $uibModalInstance.dismiss();
            });
        }

	}
    }];

export default modal_verify_action;
