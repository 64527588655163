import { Injectable } from '@angular/core';
import {serviceUrl} from "../../appjsLegacy/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(private http: HttpClient) { }
  getConfirmationData(eventId){
    return this.http.get(serviceUrl + '/api/v1/permit/getconfirmationdata', {params: {Id: eventId}, responseType: "json"})
  }

  saveConfirmation(confirmation) {
    return this.http.post(serviceUrl + '/api/v1/permit/confirm', {Confirmation: confirmation} )
  }

  downloadInspectionDocument(permitEventId) {
    return this.http.post(serviceUrl + '/api/v1/permit/DownloadInspectionDocument',{PermitEventId: permitEventId})
  }
}
// //confirm URLs for our service
// app.factory('GetConfirmationData',['$resource', function(resource){
//     return resource(serviceUrl +'/api/v1/permit/getconfirmationdata', {}, {
// 			query: {method: 'GET', params: {}, isArray: false}
// 		});
// }]);
//
// app.factory('DownloadInspectionTemplate', ['$resource', function ($resource) {
//     return $resource(serviceUrl + '/api/v1/permit/DownloadInspectionDocument');
// }]);
//
// app.factory('SaveConfirmationForm',['$resource', function(resource){
//         return resource(serviceUrl+'/api/v1/permit/confirm');
// }]);
