import * as angular from 'angular';
import user_module from "./core/user/user-module";
import common_module from "./core/common/common-module";
import habitat_module from "./private/habitat/habitat-module";
import {
  ANALYTICS_CODE,
  CDMS_DOCUMENTATION_URL, CURRENT_VERSION, DataGradeMethods,
  DEFAULT_PROJECT_PERMISSION, DISPLAY_NAME,
  ENVIRONMENT,
  PUBLIC_OR_PRIVATE, REPORTSERVER_URL, serverUrl, SystemTimezones,
  AG_GRID_LICENSE,
} from "./config";
import permit_module from "./private/permits/permit-module";
import {getByName} from "./core/common/common-functions";
import olc_module from "./private/olc/olc-module";
import admin_module from "./core/admin/admin-module";
import bot_module from "./private/bot/bot-module";
import projects_module from "./core/projects/projects-module";
import leasing_module from "./private/leasing/leasing-module";
import covid_module from "./private/covid/covid-module";
import crpp_module from "./private/crpp/crpp-module";
import datasets_module from "./core/datasets/datasets-module";
import appraisals_module from "./private/appraisals/appraisals-module";

import 'angular-ui-select2/src/select2';
// import 'ui-select/dist/select.js';
import 'angular-route';
import 'angular-ui-mask';
import 'angular-input-masks';
import 'angular-ui-bootstrap';
import 'angular-resource';
import 'ng-file-upload';

import 'angular-nvd3';
import 'angular-messages';
import 'angular-material';
import {downgradeComponent, downgradeInjectable} from "@angular/upgrade/static";
import {ProfileService} from "../app/login/profile.service";
import {featureLayerDirective} from "./core/common/directives/feature-layer";
import {EsriMapComponent} from "../app/map/esri-map/esri-map.component";
import {ConfirmService} from "../app/confirm/confirm.service";
import { LicenseManager } from 'ag-grid-enterprise';
//analytics configuration
// (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
// (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
// m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
// })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
// ga('create', ANALYTICS_CODE, 'auto');
// ga('send', 'pageview');

var BUILD_VERSION = "2.0.0"; //increment the last number as desired to show in the footer

// specific urls to ignore for the page not found alert
let urls = ["https://localhost", "http://localhost", "https://localhost/", "http://localhost/",
  'https://paluuttest.ctuir.org/', 'https://paluuttest.ctuir.org', 'http://paluuttest.ctuir.org/', 'http://paluuttest.ctuir.org',
  'https://paluut.ctuir.org/', 'https://paluut.ctuir.org', 'http://paluut.ctuir.org/', 'http://paluut.ctuir.org',
  'https://cdmstest.ctuir.org/', 'https://cdmstest.ctuir.org', 'http://cdmstest.ctuir.org/', 'http://cdmstest.ctuir.org',
  'https://cdms.ctuir.org/', 'https://cdms.ctuir.org', 'http://cdms.ctuir.org/', 'http://cdms.ctuir.org',
  'https://cdmsdevgc.ctuir.org/', 'https://cdmsdevgc.ctuir.org', 'http://cdmsdevgc.ctuir.org/', 'http://cdmsdevgc.ctuir.org'
]
// define([
//   'angular'
// ], function(angular){


// define our app as an angular module - with our dependencies and our routes
var app = angular.module("app",
  [
    'ngRoute',						// assets/js/angular/angular-route.js (referred to in js/controllers/login-controller.js)
    'ui.mask',
    'ui.utils.masks',

    //these are the cdms modules
    common_module.name,
    // 'ProjectModule',
    projects_module.name,
    // 'DatasetModule',
    datasets_module.name,
    // 'AdminModule',
    admin_module.name,
    user_module.name,

    'ngFileUpload',
    'nvd3',

    //these are ctuir specific
    // 'CrppModule',
    crpp_module.name,
    // 'AppraisalsModule',
    appraisals_module.name,
    // 'HabitatModule',
    habitat_module.name,
    // 'LeasingModule',
    leasing_module.name,
    // 'PermitModule'
    permit_module.name,
    // 'OlcModule',
    olc_module.name,
    // 'CovidModule',
    covid_module.name,
    // 'BOTModule',
    bot_module.name,

    'ngMessages',
    'ngMaterial',

  ])
  .config(['$locationProvider', '$routeProvider', function ($locationProvider, $routeProvider) {
    $locationProvider.html5Mode(true);
    $routeProvider.when('/dashboard', {
      templateUrl: 'appjsLegacy/core/user/components/landing-page/templates/landing-page.html',
      controller: 'LandingPage'
    });

    $routeProvider.when('/projects', {
      templateUrl: 'appjsLegacy/core/projects/components/project-list/templates/projects.html',
      controller: 'ProjectListCtrl'
    });
    $routeProvider.when('/datasets', {
      templateUrl: 'appjsLegacy/core/datasets/components/datasets-list/templates/datasets.html',
      controller: 'DatasetsListCtrl'
    });
    $routeProvider.when('/projects/:Id', {
      templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/project-landing.html',
      controller: 'ProjectLandingCtrl'
    });
    $routeProvider.when('/projectFiles/:Id', {
      templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/project-files.html',
      controller: 'ProjectFilesCtrl'
    });
    $routeProvider.when('/projectData/:Id', {
      templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/project-data.html',
      controller: 'ProjectDataCtrl'
    });
    $routeProvider.when('/projectPermissions/:Id', {
      templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/project-permissions.html',
      controller: 'ProjectPermissionsCtrl'
    });
    $routeProvider.when('/projectLookups/:Id', {
      templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/project-lookups.html',
      controller: 'ProjectLookupsCtrl'
    });
    $routeProvider.when('/projectLocations/:Id', {
      templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/project-locations.html',
      controller: 'ProjectLocationsCtrl'
    });

    $routeProvider.when('/analytics', {
      templateUrl: 'appjsLegacy/core/user/components/analytics/templates/user-analytics.html',
      controller: 'UserAnalyticsCtrl'
    });

    $routeProvider.when('/mydata', {
      templateUrl: 'appjsLegacy/core/user/components/dataset-preferences/templates/mydatasets.html',
      controller: 'MyDatasetsCtrl'
    });
    $routeProvider.when('/myprojects', {
      templateUrl: 'appjsLegacy/core/user/components/project-preferences/templates/myprojects.html',
      controller: 'MyProjectsCtrl'
    });
    $routeProvider.when('/mypreferences', {
      templateUrl: 'appjsLegacy/core/user/components/my-preferences/templates/mypreferences.html',
      controller: 'MyPreferencesCtrl'
    });
    $routeProvider.when('/activities/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-activities-list/templates/dataset-activities.html',
      controller: 'DatasetActivitiesCtrl',
      permission: 'Edit'
    });
    $routeProvider.when('/dataentryform/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-editor/templates/dataset-edit-form.html',
      controller: 'DataEditCtrl',
      permission: 'Edit'
    });

    $routeProvider.when('/edit/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-editor/templates/dataset-edit-form.html',
      controller: 'DataEditCtrl',
      permission: 'Edit'
    });
    $routeProvider.when('/dataview/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-view/templates/dataset-view.html',
      controller: 'DataEditCtrl'
    });
    $routeProvider.when('/table/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-table/templates/table.html',
      controller: 'TableCtrl'
    });

    $routeProvider.when('/datasetquery/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-query/templates/dataset-query.html',
      controller: 'DataQueryCtrl'
    });
    $routeProvider.when('/datasetqueryviewallrecords/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-query/templates/dataset-query-view-all-records.html',
      controller: 'DataQueryViewAllRecordsCtrl'
    });
    $routeProvider.when('/dataset-details/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-detail/templates/dataset-details-view.html',
      controller: 'DatasetDetailsCtrl'
    });
    $routeProvider.when('/dataset-edit/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-detail/templates/dataset-details-edit.html',
      controller: 'DatasetDetailsCtrl'
    });
    $routeProvider.when('/datasetimport/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-import/templates/dataset-import.html',
      controller: 'DatasetImportCtrl',
      permission: 'Edit'
    });

    $routeProvider.when('/query/:Id', {
      templateUrl: 'appjsLegacy/core/datasets/components/dataset-query/templates/dataset-query.html',
      controller: 'DatastoreQueryCtrl'
    });
    $routeProvider.when('/admin', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin.html',
      controller: 'AdminCtrl'
    });

    $routeProvider.when('/admin-dataset/:Id', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-dataset-fields.html',
      controller: 'AdminEditDatasetFieldsCtrl'
    });
    $routeProvider.when('/admin-config/:Id', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-dataset-config.html',
      controller: 'AdminEditDatasetConfigCtrl'
    });
    $routeProvider.when('/admin-workflow/:Id', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-dataset-workflow.html',
      controller: 'AdminEditDatasetWorkflowCtrl'
    });
    $routeProvider.when('/admin-permissions/:Id', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-dataset-permissions.html',
      controller: 'AdminEditDatasetPermissionsCtrl'
    });
    $routeProvider.when('/admin-metafields', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-metafields.html',
      controller: 'AdminMetafieldsCtrl'
    });

    //$routeProvider.when('/admin-users', { templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-users.html', controller: 'AdminUsersCtrl' });
    //$routeProvider.when('/admin-groups', { templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-groups.html', controller: 'AdminGroupsCtrl' });
    $routeProvider.when('/admin-orgs', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-organizations.html',
      controller: 'AdminOrgCtrl'
    });

    $routeProvider.when('/admin-master/:Id', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-master.html',
      controller: 'AdminEditMasterCtrl'
    });
    $routeProvider.when('/admin-new-dataset/:Id', {
      templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/admin-new-dataset.html',
      controller: 'AdminNewDatasetCtrl'
    });

    $routeProvider.when('/leasing', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/manage-leases.html',
      controller: 'LeasingHomeController'
    });
    $routeProvider.when('/active-leases', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/active-leases.html',
      controller: 'ActiveLeasesController'
    });
    $routeProvider.when('/pending-leases', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/pending-leases.html',
      controller: 'PendingLeasesController'
    });
    $routeProvider.when('/view-lease/:Id', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/view-lease.html',
      controller: 'ViewLeaseController'
    });
    $routeProvider.when('/available-land', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/available-land.html',
      controller: 'AvailableLandController'
    });
    $routeProvider.when('/violations', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/inspection-violations.html',
      controller: 'ViolationsController'
    });
    $routeProvider.when('/manage-operators', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/manage-operators.html',
      controller: 'ManageOperatorsController'
    });
    $routeProvider.when('/manage-lookups', {
      templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/lookups.html',
      controller: 'LookupListsController'
    });

    $routeProvider.when('/permits/list', {
      templateUrl: 'appjsLegacy/private/permits/components/list/templates/list-permits.html',
      controller: 'PermitListController'
    });
    $routeProvider.when('/permits/list/:DatasetId', {
      templateUrl: 'appjsLegacy/private/permits/components/list/templates/list-permits.html',
      controller: 'PermitListController'
    });
    $routeProvider.when('/permits/routes', {
      templateUrl: 'appjsLegacy/private/permits/components/taskboard/templates/routes.html',
      controller: 'PermitRoutesController'
    });
    $routeProvider.when('/permits/dashboard', {
      templateUrl: 'appjsLegacy/private/permits/components/dashboard/templates/dashboard.html',
      controller: 'PermitDashboardController'
    });
    $routeProvider.when('/permits/inspection', {
      templateUrl: 'appjsLegacy/private/permits/components/list/templates/request-inspection.html',
      controller: 'RequestInspectionController'
    });
    $routeProvider.when('/permits/notifications', {
      templateUrl: 'appjsLegacy/private/permits/components/notifications/templates/notifications.html',
      controller: 'PermitNotificationsController'
    });
    $routeProvider.when('/permits/map', {
      templateUrl: 'appjsLegacy/private/permits/components/map/templates/permit-map.html',
      controller: 'PermitMapController'
    });
    $routeProvider.when('/permits/contacts', {
      templateUrl: 'appjsLegacy/private/permits/components/contacts/templates/manage-contacts.html',
      controller: 'PermitManageContactsController'
    });
    $routeProvider.when('/permits/ehsviolations', {
      templateUrl: 'appjsLegacy/private/permits/components/ehs-violations/templates/list-violations.html',
      controller: 'EhsViolationsController'
    });
    $routeProvider.when('/permits/requests/:ItemType/:DatasetId', {
      templateUrl: 'appjsLegacy/private/permits/components/requests/templates/list.html',
      controller: 'PermitRequestsController'
    });
    $routeProvider.when('/permits/requests/:ItemType', {
      templateUrl: 'appjsLegacy/private/permits/components/requests/templates/list.html',
      controller: 'PermitRequestsController'
    });

    //custom routes for datasets that require custom controller+pages
    //$routeProvider.when('/appraisals/:Id', { templateUrl: 'appjsLegacy/private/appraisals/components/appraisal-activities/templates/appraisal-activities.html', controller: 'AppraisalCtrl'});
    $routeProvider.when('/crppcorrespondence/:Id', {
      templateUrl: 'appjsLegacy/private/crpp/components/correspondence/templates/correspondence.html',
      controller: 'CRPPCorrespondenceCtrl'
    });
    $routeProvider.when('/habitatsites/:Id', {
      templateUrl: 'appjsLegacy/private/habitat/components/habitat-sites/templates/sites.html',
      controller: 'HabitatSitesCtrl'
    });
    $routeProvider.when('/olcevents/:Id', {
      templateUrl: 'appjsLegacy/private/olc/components/events/templates/events.html',
      controller: 'OlcEventsCtrl'
    });
    $routeProvider.when('/olceventssearch/:Id', {
      templateUrl: 'appjsLegacy/private/olc/components/events/templates/events-search.html',
      controller: 'OlcEventsSearchCtrl'
    });

    $routeProvider.when('/unauthorized', {
      templateUrl: 'appjsLegacy/core/common/templates/unauthorized.html',
      controller: 'ErrorCtrl'
    });
    $routeProvider.when('/feedback', {
      templateUrl: 'appjsLegacy/core/common/components/feedback/templates/feedback.html',
      controller: 'FeedbackController'
    });

    $routeProvider.when('/covid', {
      templateUrl: 'appjsLegacy/private/covid/components/employees/templates/list.html',
      controller: 'CovidListController'
    });

    $routeProvider.when('/bot', {
      templateUrl: 'appjsLegacy/private/bot/components/dashboard/templates/projects.html',
      controller: 'BOTProjectsController'
    });

    //when all else fails...
    $routeProvider.otherwise({
      redirectTo:
        function () {
          console.log(window.location.toString());
          if (!window.location.toString().includes('login')) {
            let val = window.location.toString();
            let included = urls.find(str => {
              return str == val;
            });

            //URL is not in list of URL's to ignore for page not found...
            if (!included) {
              //...but is a URL for a file.
              if (!window.location.toString().includes('uploads')) {
                alert('That page or file does not exist, please contact your system administrator.');
              }
            }
          }

          if (window.location.toString().includes('uploads')) {
            //URL was for a file, so return path to file (minus site root).
            return window.location.toString().substring(window.location.toString().indexOf("services"));
          } else {
            //Otherwise return path to dashboard (minus site root).
            return '/dashboard';
          }          
        }
    });
  }])

app.directive('esriFeatureLayer', featureLayerDirective);
app.directive('esriMap', downgradeComponent({component: EsriMapComponent}));

app.service('ProfileService', downgradeInjectable(ProfileService));
app.service('ConfirmService', downgradeInjectable(ConfirmService));
//any functions in here are available to EVERY scope.  use sparingly!
app.run(['$rootScope', '$window', '$location', 'ProfileService', function ($rootScope, $window, $location, ProfileService) {
  $rootScope.config = {
    version: CURRENT_VERSION,
    CDMS_DOCUMENTATION_URL: CDMS_DOCUMENTATION_URL,
    REPORTSERVER_URL: REPORTSERVER_URL,
    build_version: BUILD_VERSION,
    DISPLAY_NAME: DISPLAY_NAME,
    PUBLIC_OR_PRIVATE: PUBLIC_OR_PRIVATE
  };

  $rootScope.serverUrl = serverUrl;

  $rootScope.Cache = {};
  // $rootScope.Profile = configureProfile(profile); // profile defined in init.js
  $rootScope.Profile = ProfileService;
  $rootScope.go = function (path) {
    $location.path(path);
  };

  $rootScope.$location = $location;

  angular.rootScope = $rootScope; // just so we can get to it later. :)

  $rootScope.SystemTimezones = SystemTimezones; //defined in init.js
  $rootScope.DataGradeMethods = DataGradeMethods; //ditto

  if ('serviceWorker' in navigator) {
    console.log("We are good to go with service workers.");
    $rootScope.ServiceWorkers = true;
  } else {
    console.log("browser does not support service workers");
    $rootScope.ServiceWorkers = false;
  }

  //Fire analytics call on location change in URL for SPA.
  $rootScope.$on('$locationChangeSuccess', function (params) {

    //console.log("CHECK PERMISSION");
    //console.dir($location.path())

    //clear out the $rootScope variables that control the file path filter...
    $rootScope.viewSubproject = null;
    $rootScope.datasetId = null;
    $rootScope.newSubproject = null;

    if (ENVIRONMENT != "prod") return;

    console.log("Sending " + $location.url() + " to: " + ANALYTICS_CODE);
    // $window.ga('send', {
    //   'hitType': 'screenview',
    //   'appName': 'CDMS',
    //   'screenName': $location.url()
    // });
  });
}]);

export default app;
