import {makeObjects} from '../../../common/common-functions';
import * as angular from 'angular';

var admin_edit_dataset_config = ['$scope', '$uibModal', '$timeout', '$routeParams', 'DatasetService', 'CommonService','ProjectService','AdminService',
    function ($scope, $uibModal, $timeout, $routeParams, DatasetService, CommonService, ProjectService, AdminService) {

        $scope.OnTab = "Configuration";
        $scope.SaveMessage = null;
        $scope.dataset = DatasetService.getDataset($routeParams.Id);

        $scope.dataset.$promise.then(function () {

            $scope.convertDisabledFields();


            $scope.dataset.childDatasets = DatasetService.getDatasetsList();
            $scope.dataset.childDatasets.$promise.then(function(){

                $scope.dataset.DefaultActivityQAStatusId = "" + $scope.dataset.DefaultActivityQAStatusId;
                $scope.dataset.DefaultRowQAStatusId = "" + $scope.dataset.DefaultRowQAStatusId;

                //load our child dataset if we have one already configured
                if($scope.dataset.ChildDatasetId != null){
                    $scope.selectChildDataset();
                }

                $scope.QAStatusList = makeObjects($scope.dataset.QAStatuses, 'Id', 'Name');
                $scope.RowQAStatuses = makeObjects($scope.dataset.RowQAStatuses, 'Id', 'Name');
                //console.log($scope.dataset.ChildDatasetId)
            })

        });

        $scope.convertDisabledFields = function(){
            if($scope.dataset.Config.DataEntryPage && $scope.dataset.Config.DataEntryPage.DisabledFields){
                try{
                    $scope.dataset.Config.DataEntryPage.DisabledFields = angular.toJson($scope.dataset.Config.DataEntryPage.DisabledFields);
                }catch{
                    console.log("error converting DisabledFields");
                }
            }
        }

        $scope.saveConfig = function () {
            $scope.SaveMessage = "Saving...";

            //console.dir($scope.childDataset)
            //console.dir($scope.dataset.Config);

            if($scope.dataset.Config.DataEntryPage && $scope.dataset.Config.DataEntryPage.DisabledFields){
                try{
                    $scope.dataset.Config.DataEntryPage.DisabledFields = angular.fromJson($scope.dataset.Config.DataEntryPage.DisabledFields);
                }catch{
                    console.log("error converting DisabledFields");
                }
            }

            $scope.dataset.Config = angular.toJson($scope.dataset.Config);

            console.dir($scope.dataset.Config);

            var promise = DatasetService.saveDataset($scope.dataset );

            promise.$promise.then(function () {
                if($scope.childDataset){
                    console.log("also save the child dataset");
                    $scope.childDataset.Config = angular.toJson($scope.childDataset.Config);
                    var childPromise = DatasetService.saveDataset($scope.childDataset);
                    childPromise.$promise.then(function(){
                        $scope.SaveMessage = "Save successful.";
                        $scope.childDataset.Config = angular.fromJson(childPromise.Config);
                    }, function(error){
                        console.dir(error);
                        $scope.SaveMessage = "Child save failed.";
                    })
                }
                $scope.dataset.Config = angular.fromJson(promise.Config);
                $scope.SaveMessage = "Save successful.";
                $scope.convertDisabledFields();
            }, function(error) {
                console.log("something went wrong:");
                console.dir(promise);
                $scope.SaveMessage = "Save failed.";
                $scope.convertDisabledFields();
            });
        };

        $scope.selectChildDataset = function(){
            console.dir($scope.dataset.ChildDatasetId);
            $scope.childDataset = DatasetService.getDataset($scope.dataset.ChildDatasetId);
            $scope.childDataset.$promise.then(function(data){
                if(!$scope.childDataset.ChildDatasetKeyColumn) {
                    $scope.childDataset.ChildDatasetKeyColumn = "ParentId"; //set the default to be ParentId
                    $scope.childDataset.hasDefault = true;
                }
                console.dir($scope.childDataset)
                console.dir($scope.dataset);
                console.log($scope.dataset.ChildDatasetId)
            })
        }

        $scope.openChooseDuplicateFields = function () {

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-choose-duplicate-fields.html',
                controller: 'ModalChooseDuplicateFieldsCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_field) {

            });
        };

        $scope.openChooseActivityListFields = function () {

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/admin/components/admin-page/templates/modal-choose-activitylist-fields.html',
                controller: 'ModalChooseActivityListFieldsCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_field) {

            });
        };




    }

];

export default admin_edit_dataset_config;
