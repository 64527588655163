import {Component, NgModule, OnInit} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';

import { LoginComponent } from './components/login/login.component';
import {LoginService} from "./components/login.service";
import { LogoutComponent } from './components/logout/logout.component';
import {LoginRoutingModule} from "./components/login-routing/login-routing.module";
import {FormsModule} from "@angular/forms";

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
    LoginRoutingModule,
    FormsModule
  ],
  declarations: [
    LoginComponent,
    LogoutComponent
  ],
  providers: [
    LoginService
  ]
})

export class LoginModule{}
