import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  BING_KEY,
  datasetActivitiesBasemapConfig,
  defaultLayer, 
  GEOMETRY_SERVICE_URL, 
  NAD83_SPATIAL_REFERENCE,
  proxyUrl,
  PUBLIC_OR_PRIVATE,
  ENVIRONMENT,
  CDMS_POINTS_TEST_URL,
  CDMS_POINTS_PROD_URL
} from "../../../appjsLegacy/config";
import BingMapsLayer from "@arcgis/core/layers/BingMapsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import Point from "@arcgis/core/geometry/Point";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import {addProxyRule} from '@arcgis/core/core/urlUtils';
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import Graphic from "@arcgis/core/Graphic";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as geometryServ from "@arcgis/core/rest/geometryService";
import Query from "@arcgis/core/rest/support/Query";
import * as execute from "@arcgis/core/rest/query/executeQueryJSON"
import Circle from "@arcgis/core/geometry/Circle";
import Color from "@arcgis/core/Color";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import Multipoint from "@arcgis/core/geometry/Multipoint";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

@Component({
  selector: 'esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.scss'],
})
export class EsriMapComponent implements OnInit, OnChanges {
  @ViewChild('mapViewNode', {static: true}) private mapViewElement: ElementRef;
  // private centerAt: (center) => void;
  @Input() zoom: number;
  @Input() center: string;
  @Input() basemap: string;
  @Input() url: string;
  @Input() row: any;
  @Input() project: any;
  @Input() inPayload: any;
  @Input() deleteIds: any[];

  @Input()
  set gridSelectIds(val: any[]) {
    this.showSelectedLocationsById(val)
  }

  @Input()
  set locationObjectIds(val: any[]) {
    this.showLocationsById(val);
  }

  // @Output() handleUploadRemove: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateLocation: EventEmitter<any> = new EventEmitter<any>();
  @Output() sde: EventEmitter<any> = new EventEmitter<any>();

  // SdeObjectId: number;
  OldSdeObjectId: number;

  newPoint: Point;
  newGraphic: Graphic;

  // SaveMessage: string;
  selectedBasemap: string;
  basemaps: any[];
  map: Map;
  mapView: MapView;
  locationLayer: FeatureLayer;
  pointsLayer: GraphicsLayer;
  spatialReference = new SpatialReference({wkid: 102100});
  selectedFeature: any;
  selectedGraphic: any;


  constructor() {
  }

  ngOnInit() {
    this.createMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log("this.inPaylaod (ngOnChanges) is next...");
    console.log(this.inPayload);

    // Note:  ngOnChanges runs whenever these is a change to the value it has.
    // In this case, it has inPayload.
    // So, when in PayLoad changes, it causes this to run.
    // Therefore, we added IsFirstRun in modal-edit-location.ts, $scope.save,
    // so that this page can keep track of whether it has run already or not.
    // Thereby, we keep this to only run once (add a new location only once).
    if (this.inPayload && this.inPayload.IsFirstRun)
    {
      if (this.inPayload != undefined && this.inPayload.hasOwnProperty('Location')) {
        if (this.inPayload.hasOwnProperty('Update')) {
          this.row = this.inPayload.Location;

        }
        this.modalLocationEdit();
        //var permitLocationObject = null;
        //this.modalLocationEdit(permitLocationObject);
      }

      //if (this.project && this.locationLayer) {
      if (this.row && this.row.ProjectId && this.locationLayer) {
        //this.filterMap(this.project.Id);
        this.filterMap(this.row.ProjectId);
      }

      if (this.deleteIds) {
        this.deletePoints(this.deleteIds);
      }

      this.inPayload.IsFirstRun = false;
    }
  }

  changeFromChild(obj): void {
    if (!this.inPayload)
    {
      this.inPayload = obj;
    }
    console.log("this.inPaylaod (changeFromChild) is next...");
    console.log(this.inPayload);
    if (this.inPayload != undefined && this.inPayload.hasOwnProperty('Location')) {
      if (this.inPayload.hasOwnProperty('Update')) {
        this.row = this.inPayload.Location;

      }
      this.modalLocationEdit();
      //var permitLocationObject = null;
      //this.modalLocationEdit(permitLocationObject);
    }

    //if (this.project && this.locationLayer) {
    if (this.row && this.row.ProjectId && this.locationLayer) {
      //this.filterMap(this.project.Id);
      this.filterMap(this.row.ProjectId);
    }

    if (this.deleteIds) {
      this.deletePoints(this.deleteIds);
    }
  }

  deletePoints(locationIds) {
    this.locationLayer.applyEdits({deleteFeatures: [{objectId: locationIds}]}).then(res => {
      res.deleteFeatureResults.forEach(feat => {
        if (!feat.error) {
          console.log(`Successfully deleted point ${feat.objectId} from map layer.`)
        } else {
          console.log(`error deleting point: ${feat.objectId}`);
        }
      });
    });
  }

  updateLayers() {
    console.log("Inside this.map.js, Changing Layer: " + this.selectedBasemap);

    try {
      //console.log("Loading layer: " + datasetActivitiesBasemapConfig[this.selectedBasemap].ServiceURL);

      this.map.removeAll();

      let new_layer = undefined;
      var bing_layers_map = {
        BingMapsRoad: new BingMapsLayer({style: 'road', key: BING_KEY}),
        BingMapsAerial: new BingMapsLayer({style: 'aerial', key: BING_KEY}),
        BingMapsHybrid: new BingMapsLayer({style: 'hybrid', key: BING_KEY}),
      };

      console.dir(bing_layers_map);
      //add the selected basemap
      if (datasetActivitiesBasemapConfig[this.selectedBasemap].library == 'CTUIR')
        new_layer = new TileLayer({url: datasetActivitiesBasemapConfig[this.selectedBasemap].ServiceURL});
      else if (datasetActivitiesBasemapConfig[this.selectedBasemap].library == 'Esri') {
        //new_layer = new esri.layers.TileLayer(datasetActivitiesBasemapConfig[this.selectedBasemap].ServiceURL);
        //new_layer = new esri.layers.TileLayer("http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer");
        // new_layer = new TileLayer(datasetActivitiesBasemapConfig[this.selectedBasemap].type);
        new_layer = new TileLayer({url: 'http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer'});
        console.log("Created new_layer using ESRI...");
      } else if (datasetActivitiesBasemapConfig[this.selectedBasemap].library == 'Bing') {
        new_layer = new BingMapsLayer({
          key: BING_KEY,
          style: bing_layers_map[datasetActivitiesBasemapConfig[this.selectedBasemap].type]
        });
        console.log("Created new_layer using Bing...");
      }

      this.map.layers.add(new_layer);
      // this.map.currentBasemapLayer = new_layer;

      // Somehow, this.url gets set to the production feature layer service url.
      // Therefore, we must set it to the correct one for our environment.
      if (this.url) {
        if (ENVIRONMENT === "prod")
        {
          this.url = CDMS_POINTS_PROD_URL;
        }
        else
        {
          this.url = CDMS_POINTS_TEST_URL;
        }
        console.log("esri-map-component:  this.url = " + this.url);

        this.locationLayer = new FeatureLayer({url: this.url});
        this.map.layers.add(this.locationLayer);
        this.pointsLayer = new GraphicsLayer();
        this.map.layers.add(this.pointsLayer);
      }


      console.log("done!");
    } catch (e) {
      console.dir(e);
    }
  }

  createMap() {

    // setup our map options based on the attributes and scope
    this.map = new Map();


    // if (PUBLIC_OR_PRIVATE === "private") {
    //   // declare our map
    //   // loadModules(["esri/core/urlUtils"]).then(([urlUtils]) => { // formerly esri/urlUtils
    //   addProxyRule({
    //     urlPrefix: "restdata.ctuir.org",
    //     proxyUrl: proxyUrl
    //   });

    //   //esriConfig.defaults.io.proxyUrl = proxyUrl; // From the config.js file.
    //   //esriConfig.defaults.io.alwaysUseProxy = false;

    //   // });
    // }


    this.selectedBasemap = defaultLayer;

    this.basemaps = [];
    for (var property in datasetActivitiesBasemapConfig) {
      if (datasetActivitiesBasemapConfig.hasOwnProperty(property)) {
        this.basemaps.push({label: datasetActivitiesBasemapConfig[property].Display, name: property});
      }
    }

    let center;
    if (this.center) {
      center = this.center.split(",").map(c => parseFloat(c));
    }

    this.mapView = new MapView({
      // center: ($attrs.center) ? $attrs.center.split(",") : this.center,
      // zoom: ($attrs.zoom) ? $attrs.zoom : this.zoom,
      container: this.mapViewElement.nativeElement,
      zoom: this.zoom,
      center,
      //basemap: 'streets', //($attrs.basemap) ? $attrs.basemap : this.basemap,
      map: this.map,
      spatialReference: {
        wkid: 102100 //mercator
        //wkid:26911 //nad_1983
        //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'

      }
    });

    this.mapView.on("click", (e) => {
      this.clickedMap(e);
    });

    // this.map.on("load", function (e) { todo: ask george about createBasemapDropdown
    //   //createBasemapDropdown(map);
    // });

    //this.map.resize();
    this.updateLayers();
    watchUtils.whenTrue(this.mapView.popup, 'visible', () => {
      watchUtils.whenFalseOnce(this.mapView.popup, 'visible', () => {
        this.pointsLayer.removeAll();
        this.mapView.graphics.removeAll();
        this.sde.emit();
      });
    });
    //console.log("Map is complete and in scope.");
    return this.mapView;
    // });
  }

  centerAt(center) {
    var point = new Point({
      x: center[0],
      y: center[1],
      spatialReference: {
        wkid: 102100 //mercator
        //wkid:26911 //nad_1983
        //"wkt":'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]'

      }
    });

    this.mapView.goTo(point);
  };

  showSelectedLocationsById(selectedIds) {
    let color = "#FF6600";
    let alpha = .25;
    let lineColor = new Color(color);
    // lineColor.setColor(color);

    let fillColor = new Color(color);
    fillColor.a = alpha;

    let symbol = new SimpleMarkerSymbol({
      style: "diamond",
      color: fillColor,
      size: "10px",
      outline: {
        style: "solid",
        color: lineColor,
        width: 3,
      },
    });

    try {
      if (this.locationLayer && selectedIds && this.pointsLayer) {
        this.pointsLayer.removeAll();
        selectedIds.forEach(row => {
          // console.log(row);
          let selectedPoint = new Point({
            x: row.GPSEasting,
            y: row.GPSNorthing,
            spatialReference: new SpatialReference({wkid: 26911}),
          });
          let selectedGraphic = new Graphic({geometry: selectedPoint, symbol: symbol, attributes: row});
          this.pointsLayer.add(selectedGraphic);
        });
      }
    } catch (e) {
      console.log("issue selecting those graphics");
      console.dir(e);
    }
  }

  showLocationsById(locationObjectIds) {
    console.info(" --- showLocationsByid ---");
    try {
      if (this.locationLayer) {
        // this.clearSelection(); todo: note feature selection is not occurring

        var definitionExpression = "";
        // If we have a new user,
        if (locationObjectIds === "") {
          console.log("locationObjectIds is blank; give it a number, but we won't pull anything...");
          definitionExpression = "OBJECTID IN (0)";
        } else {
          console.log("locationObjectIds has something; pull only those points...");
          // console.log(locationObjectIds);
          definitionExpression = "OBJECTID IN (" + locationObjectIds + ")";
        }
        // console.log("Inside this.map.js, Definition expression: " + definitionExpression);
        //console.log("In Map.js, definitionExpression: ...");

        return this.locationLayer.definitionExpression = definitionExpression;
      }


    } catch (e) {
      console.log("died in showLocationsById");
      console.dir(e);
    }
  };


  modalLocationEdit() {
  //modalLocationEdit(permitLocationObject) {
    //OK -- if we are saving a NEW location then start off by adding the point to the featurelayer
    if (!this.row) {
      console.log("Adding a NEW location in map component... ");
      this.row = this.inPayload.Location;
      // $scope.map.reposition(); //this is important or else we end up with our map points off somehow.

      //nad83 zone 11...  might have to have this as alist somehwere...
      let inSR = new SpatialReference({wkt: NAD83_SPATIAL_REFERENCE});
      console.log("Created inSR...");

      let outSR = new SpatialReference({wkid: 102100})
      console.log("Created outSR...");
      // let geometryService = new GeometryService({url: GEOMETRY_SERVICE_URL});

      let newPoint = new Point({
        x: this.inPayload.Location.GPSEasting,
        y: this.inPayload.Location.GPSNorthing,
        spatialReference: inSR
      });

      //convert spatial reference
      let PrjParams = new ProjectParameters();

      PrjParams.geometries = [newPoint];
      // PrjParams.outSR is not set yet, so we must set it also.
      PrjParams.outSpatialReference = outSR;
      PrjParams.toJSON();
      //throw "Stopping right here...";

      //do the projection (conversion)
      geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((outputpoint) => {
        let symbol = new SimpleMarkerSymbol({
          style: "circle",
          color: "#00ff00",
          size: "8px",
        });

        this.newPoint = new Point(outputpoint[0]);
        this.newGraphic = new Graphic({geometry: newPoint, symbol: symbol});
        this.mapView.graphics.add(this.newGraphic);

        //add the graphic to the map and get SDE_ObjectId
        this.locationLayer.applyEdits({addFeatures: [this.newGraphic]}).then(results => {
          if (results.addFeatureResults[0]) {
            console.log(results.addFeatureResults[0])
            this.row.SdeObjectId = results.addFeatureResults[0].objectId;

            results.addFeatureResults[0].SDEObjectID = results.addFeatureResults[0].objectId;
            results.addFeatureResults[0].SdeObjectId = results.addFeatureResults[0].objectId;
            this.newGraphic = results.addFeatureResults[0];
            console.log("Created a new point! " + this.row.SdeObjectId);
            // this.pointsLayer.add(this.newGraphic);
            this.updateLocation.emit({pointId: this.row.SdeObjectId});
            this.inPayload = null;

            this.mapView.goTo({
              center: [this.newPoint.longitude, this.newPoint.latitude]
            });
            this.row = null;
            // this.locationLayer.applyEdits({updateFeatures: results.addFeatureResults[0].OBJECTID }).then((res) => {
            //   console.log(res)
            // });

          } else {
            this.updateLocation.emit({failed: "adding graphic failed"});
            console.log('failed adding new location')
            console.dir(results);
          }

        }).catch((error) => {
          console.log("error = ", error);
        });
      });
    } else //updating an existing...
    {
      console.log("updating existing point in map component...");
      console.log("this is next...");
      console.dir(this);

      let query = new Query();

      query.outSpatialReference = this.spatialReference;
      query.returnGeometry = true;
      // query.where = `objectId = ${this.row.SdeObjectId}`;
      query.where = `objectId = ${this.row.SdeObjectId} OR SDEObjectID =${this.row.SdeObjectId}`;
      query.outFields = ["*"];
      // Capture the old SdeObjectId, so we can delete it later.
      this.OldSdeObjectId = this.row.SdeObjectId;
      // getting the old point
      this.locationLayer.queryFeatures(query).then(res => {
        console.log("update this feature");
        console.log("this.row is next...");
        console.dir(this.row);

        // deleting the old point
        if ((typeof res.features[0] !== 'undefined') && (typeof res.features[0].attributes !== 'undefined'))
        {
          console.log("res.features attibutes exists; delete old point.");
          this.deletePoints(res.features[0].attributes.OBJECTID);
        }
        else
        {
          console.log("res.features attributes is undefined; nothing to delete...");
        }


        var inSR = new SpatialReference({wkt: NAD83_SPATIAL_REFERENCE});
        var outSR = new SpatialReference({wkid: 102100})
        // var geometryService = new GeometryService({url: GEOMETRY_SERVICE_URL});
        this.newPoint = new Point({x: this.row.GPSEasting, y: this.row.GPSNorthing, spatialReference: inSR});

        //convert spatial reference
        var PrjParams = new ProjectParameters();

        PrjParams.geometries = [this.newPoint];
        // PrjParams.outSR is not set yet, so we must set it also.
        PrjParams.outSpatialReference = outSR;


        // throw "Stopping right here...";
        //
        // do the projection(conversion)

        geometryServ.project(GEOMETRY_SERVICE_URL, PrjParams).then((outputpoint) => {
          let symbol = new SimpleMarkerSymbol({
            style: "circle",
            color: "#00ff00",
            size: "8px",
          });

          this.newPoint = new Point(outputpoint[0]);
          this.newGraphic = new Graphic({geometry: this.newPoint, symbol: symbol});
          this.mapView.graphics.add(this.newGraphic);

          var attributes = {OBJECTID: this.row.SdeObjectId};

          this.locationLayer.applyEdits({addFeatures: [this.newGraphic]}).then((addResults) => {
            if (addResults) {
              console.log('apply location edits')
              console.log(addResults)
              this.row.SdeObjectId = addResults.addFeatureResults[0].objectId;
              console.log("Added new point! " + this.row.SdeObjectId);
              this.updateLocation.emit({Editing: true, pointId: this.row.SdeObjectId});
              //throw "Stopping right here...";

              // var attributes = {
              //   OBJECTID: this.OldSdeObjectId
              // }
              // var deleteGraphic = new Graphic({geometry: this.newPoint, attributes: attributes});

              // Note:  You cannot just pass the point to delete; they must be in a graphic object,
              //        or the code dies in dojo-land, which is different than what is shown in
              //        ArcGIS documenation on the service interface.
              //$scope.map.locationLayer.applyEdits(null, null, pointsToDelete).then(function (deleteResults) {
              //$scope.map.locationLayer.applyEdits(null, null, $scope.aryDeletes).then(function (deleteResults) {
              // this.locationLayer.applyEdits({deleteFeatures: [deleteGraphic]}, null,).then((deleteResults) => {

              //var query = new Query();
              //query.objectIds = [deletes[0].objectId];
              //$scope.map.locationLayer.selectFeatures(query, )
              //   console.log("deleteResults is next...");
              //   console.dir(deleteResults);
              //
              //   //.then(function (deleteResults) {
              //   if (Array.isArray(deleteResults.deleteFeatureResults) && deleteResults.deleteFeatureResults[0] && deleteResults.deleteFeatureResults.length === 0) {
              //     this.updateLocation.emit({failed: "The location did not exist in SDE."});
              //     // this.SaveMessage.emit("The location did not exist in SDE.");
              //     console.dir(deleteResults);
              //   } else if (deleteResults.deleteFeatureResults.length) {
              //     console.log("Deleted old point in sdevector! " + this.row.SdeObjectId);
              //
              //     this.updateLocation.emit({Editing: true, pointId: this.row.SdeObjectId});
              //     // OLD CommonService.UpdateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId, $scope.OldSdeObjectId);
              //     // CommonService.updateLocationAction($scope.project.Id, $scope.row.Id, $scope.row.SdeObjectId);
              //   } else {
              //     this.updateLocation.emit({failed: "Problem deleting location."});
              //     // this.SaveMessage = "There was a problem deleting that location.";
              //     console.dir(deleteResults);
              //   }
              // }, function (err) {
              //   console.log("Apply Edits - Delete - failed:  " + err.message);
              // });

              // var data = {
              //   ProjectId: this.project.Id,
              // };
              //
              // // var target = '/api/v1/file/UploadProjectFile';
              // // this.updateLocation.emit(this.row.SdeObjectId);
              // this.inPayload = null;
              // $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.
            } else {
              // this.handleUploadRemove.emit();
              // this.SaveMessage.emit("There was a problem adding the new location.");
              this.updateLocation.emit({failed: "Problem adding location."})
              console.dir(addResults);
            }


          });
        });
      });


      // var target = '/api/v1/file/UploadProjectFile';
      // this.handleUploadRemove.emit(true);
      // $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.

    }

  }

  clickedMap(e) {
    // this will return the nearest point to the map click within a 1000 foot buffer
    if (this.url) {
      let query = new Query();
      query.outSpatialReference = this.spatialReference;
      query.returnGeometry = true;
      query.outFields = ["*"];

      if (e.mapPoint) {
        query.geometry = new Circle({
          center: e.mapPoint,
          radius: 0.25,
          radiusUnit: "miles"
        });
        // query.geometry = pointBuffer;
      } else {
        this.pointsLayer.removeAll();
      }

      query.spatialRelationship = "intersects";
      // executing the query to return all the map points within 1000 feet of the map click
      execute.executeQueryJSON(this.url, query).then(res => {
        // alert the user if no sample was found
        if (res.features.length == 0) {
          alert("No sample found at that location");
          this.pointsLayer.removeAll();
          this.sde.emit();
        } else {

          // generate a graphic and open a popup for the closest sample
          // same colors as parcel layer selection
          let graphic;
          let color = "#FF6600";
          let alpha = .25;
          let lineColor = new Color(color);
          // lineColor.setColor(color);

          let fillColor = new Color(color);
          fillColor.a = alpha;

          let symbol = new SimpleMarkerSymbol({
            style: "diamond",
            color: fillColor,
            size: "10px",
            outline: {
              style: "solid",
              color: lineColor,
              width: 3,
            },
          });

          // this array of graphics will is used for the multipoint feature for the geometry service and the resulting graphic
          let nearPoints = [];

          // adding a graphic for each returned item of the query
          res.features.forEach(point => {
            nearPoints.push(new Graphic({
                geometry: new Point({
                  latitude: point.geometry.latitude,
                  longitude: point.geometry.longitude,
                  x: point.geometry.x,
                  y: point.geometry.y,
                  spatialReference: this.spatialReference,
                }),
                attributes: point.attributes
              })
            );
          });

          // creating a new multipoint geometry that is passed into the geometryEngine
          let multiPoint = new Multipoint({spatialReference: this.spatialReference});
          nearPoints.forEach(point => {
            // adding each point to the multipoint geometry
            multiPoint.addPoint(point.geometry);
          })

          // returning the nearest point from the geometry engine and creating a graphic at that location
          let nearest = geometryEngine.nearestCoordinate(multiPoint, new Point({
            x: e.mapPoint.x,
            y: e.mapPoint.y,
            spatialReference: this.spatialReference
          }));

          let nearestPt = new Point({
            x: nearest.coordinate.x,
            y: nearest.coordinate.y,
            spatialReference: this.spatialReference
          });

          graphic = new Graphic({
            geometry: nearestPt,
            symbol: symbol,
            attributes: nearPoints[nearest.vertexIndex].attributes
          });

          graphic.attributes.SDEObjectID = graphic.attributes.OBJECTID;
          this.sde.emit(graphic.attributes.SDEObjectID);

          this.mapView.goTo({
            center: [nearPoints[nearest.vertexIndex].geometry.longitude, nearPoints[nearest.vertexIndex].geometry.latitude]
          });

          // this.mapView.popup.open({
          //   title: `SDEObjectID: ${graphic.attributes.SDEObjectID}`,
          //   location: graphic.geometry
          // });

          this.pointsLayer.removeAll();
          this.pointsLayer.add(graphic);
          // this.selectedFeature = res.features[nearest.vertexIndex];
          this.selectedGraphic = graphic;
        }
      })
    }
  }

  filterMap(projectId) {
    this.locationLayer.definitionExpression = `ProjectId = ${projectId}`;
  }
}
