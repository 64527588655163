/**
*  Data entry, date edit, data view, import (single activity) all use this controller
*/
import * as angular from 'angular';
import * as moment from 'moment';
import {
  getAllMatchingFromArray,
  getByField,
  getById,
  getDataGrade,
  getFilesArrayAsList,
  getMatchingByField,
  getParsedMetadataValues,
  initEdit, makeObjects
} from "../../../common/common-functions";
import {AdminUserIds, ROWSTATUS_DELETED, SystemTimezones} from "../../../../config";
import {modalFiles_setupControllerForFileChooserModal} from "../../../common/components/file/modal-files";
import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';
import 'select2';

declare const $: any;


var dataset_edit_form = ['$scope', '$q', '$timeout', '$sce', '$routeParams', 'DatasetService', 'SubprojectService', 'ProjectService', 'CommonService', '$uibModal', '$location', '$rootScope',
    'ActivityParser', 'GridService','Upload','ChartService','$compile',
    function ($scope, $q, $timeout, $sce, $routeParams, DatasetService, SubprojectService, ProjectService, CommonService, $uibModal, $location, $rootScope,
        ActivityParser, GridService, Upload, ChartService, $compile) {

        $scope.system = { loading: true, messages: [] };

        $scope.fishermen = ProjectService.getFishermen();
        $scope.fishermen.$promise.then(function () {
            console.log("Fishermen loaded...");
            //console.log("Fishermen loaded and is next...");
            //console.dir($scope.fishermen);
        });

        $scope.Characteristics = null;

        $scope.WaypointIdField = "";

        initEdit(); // stop backspace while editing from sending us back to the browser's previous page.

        $scope.saveResult = { saving: false, error: null, success: null, saveMessage: "Saving..."};
        $scope.background_save = false;

        $scope.fields = { header: [], detail: [] };
        $scope.headerFieldErrors = [];
        $scope.hasDuplicateError = false;

        $scope.userId = $rootScope.Profile.Id;
        $scope.IsFileCell = false;

        $scope.PageErrorCount = 0;
        $scope.NextActivity = $scope.PreviousActivity = 0;

        $scope.dsConfig = null;
        $scope.EventTriggeredBy = "";
        $scope.NumberOfRows = 0;
        $scope.NumberOfCols = 0;

        //returns the number of errors on the page, headers + details
        //TODO this is probably expensive for big grids, maybe not...
        $scope.getPageErrorCount = function () {
            if (!$scope.dataAgGridOptions.hasOwnProperty('api'))
                return 0; //not loaded yet.

            var count = Object.keys($scope.headerFieldErrors).length; //start with number of header errors
            $scope.dataAgGridOptions.api.forEachNode(function (node) {
                if (node.data.rowHasError) {
                    count++;
                }
            });

            return count;
        };

        $scope.pagemode = $location.path().match(/\/(.*)\//)[1]; //edit, dataentryform, dataview - our 3 options from our route... nothing else is possible.

        // Are we editing or not?
        if ($scope.pagemode == 'dataentryform') {
            $scope.dataset_activities = { Header: {}, Details: [] };

            //are we importing?
            if ($rootScope.hasOwnProperty('imported_rows')) {
                $scope.dataset_activities = { Header: $rootScope.imported_header, Details: $rootScope.imported_rows };
                //console.dir($scope.dataset_activities);
            }

            $scope.dataset = DatasetService.getDataset($routeParams.Id);
            $scope.dataset.$promise.then(function () {
                //$scope.row is the Header fields data row
                $scope.row = Object.assign({ 'Activity': { 'ActivityDate': moment().format(), 'ActivityQAStatus': { 'QAStatusId': $scope.dataset.DefaultActivityQAStatusId } } }, $scope.dataset_activities.Header);
                //console.dir($scope.row);
                $scope.afterDatasetLoadedEvent();
            });
        }
        else {  //either edit or data view - both load a particular activity
            $scope.dataset_activities = DatasetService.getActivityData($routeParams.Id);
            $scope.dataset_activities.$promise.then(function () {
                $scope.dataset = $scope.dataset_activities.Dataset;
                $scope.dsConfig = JSON.parse($scope.dataset.Config);
                //console.log("$scope.dsConfig.DataEntryPage.ShowFields.contains('AccuracyCheck') = " + $scope.dsConfig.DataEntryPage.ShowFields.contains('AccuracyCheck'));

                //$scope.row is the Header fields data row
                $scope.row = $scope.dataset_activities.Header;
                $scope.row.FishermanFullName = "";

                angular.forEach($scope.fishermen, function (afisherman) {
                    if (afisherman.Id === $scope.row.FishermanId)
                        $scope.row.FishermanFullName = afisherman.FullName;
                });

                if ($scope.row.FishermanFullName === 2019)
                    console.log("$scope.row.FishermanFullName = " + $scope.row.FishermanFullName);

                $scope.afterDatasetLoadedEvent();
            });

            //setup our next/previous
            if ($rootScope.activities) {
                $rootScope.activities.forEach(function (act_list, index) {
                    if (act_list.Id == $routeParams.Id) {
                        try {
                            console.log("found the index: " + index);
                            console.dir(act_list);
                            if ((index == 0) && ($rootScope.activities.length === 1)) // One and only one record so far.
                            {
                                $scope.NextActivity = $rootScope.activities[index].Id;
                                $scope.PreviousActivity = $rootScope.activities[index].Id;
                            }
                            else if (index == 0) {
                                $scope.NextActivity = $rootScope.activities[index + 1].Id;
                                $scope.PreviousActivity = $rootScope.activities[index].Id;

                            } else if (index == $rootScope.activities.length - 1) {
                                $scope.NextActivity = $rootScope.activities[index].Id;
                                $scope.PreviousActivity = $rootScope.activities[index - 1].Id;

                            } else {
                                $scope.NextActivity = $rootScope.activities[index + 1].Id;
                                $scope.PreviousActivity = $rootScope.activities[index - 1].Id;
                            }
                            console.log("NextActivity = " + $scope.NextActivity);
                            console.log("PreviousActivity = " + $scope.PreviousActivity);
                        } catch (e) {
                            console.error("prev/next index error");
                        }
                    }
                });
            }

        }


        //ag-grid - details
        $scope.dataAgGridOptions = {
            suppressPropertyNamesCheck: true,
            animateRows: true,
            //enableSorting: true,
            //enableFilter: true,
            //enableColResize: true,
            //showToolPanel: false,
            columnDefs: null,
            rowData: [],
            //filterParams: { apply: true }, //enable option: doesn't do the filter unless you click apply
            dataChanged: false, //updated to true if ever any data is changed
            rowSelection: 'multiple',


          // not used
            // tabToNextCell: function (params) {
            //     //console.dir(params);
            //     var previousCell = params.previousCellPosition;
            //     var nextCell = (params.nextCellPosition) ? params.nextCellPosition : params.previousCellPosition;
            //
            //     var result = {
            //         rowIndex: previousCell.rowIndex,
            //         column: nextCell.column,
            //         floating: previousCell.floating
            //     };
            //
            //     if(previousCell.column.colDef.ControlType == "number" || previousCell.column.colDef.ControlType == "text")
            //         nextCell.column.tabbingIn = true;
            //
            //     //$scope.dataAgGridOptions.api.redrawRows();
            //     //console.log("--- tabbing -- ")
            //
            //     return result;
            // },


            onCellFocused: function (params) {

                //console.log("Cell Focused!!  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

                //console.dir(params.column.colDef.ControlType);
                if (!params.column)
                    return;

                params.column.tabbingIn = false;

                $scope.IsFileCell = (params.column.colDef.ControlType == 'file');
                $scope.onField = (params.column.colDef.cdmsField);

                $scope.$apply();

                //var cell = $scope.dataAgGridOptions.api.getFocusedCell();
                //if(cell.column.colId != "RunYear")
                //    debugger
            },

            onSelectionChanged: function (params) {
                //console.log("selection changed fired!  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
                //console.dir(params);

                var rows = params.api.getSelectedRows();
                //if (Array.isArray(rows) && rows[0] != null)
                //{
                    $scope.dataAgGridOptions.selectedItems.length = 0; //truncate, don't replace with [] -- otherwise it is a shadow copy
                    rows.forEach(function (row) {
                        $scope.dataAgGridOptions.selectedItems.push(row);
                    });

                    $scope.$apply(); //bump angular (won't work otherwise!)
                //}
            },
            //onFilterModified: function () {
            //    scope.corrAgGridOptions.api.deselectAll();
            //},
            editedRowIds: [],
            deletedItems: [],
            selectedItems: [],
            //isFullWidthCell: function (rowNode) {
            //    return rowNode.level === 1;
            //},

            /*
            onFirstDataRendered: function(params){
                GridService.autosizeColumns($scope.dataAgGridOptions);
            },
            */
            onGridReady: function (params) {
                //console.log("GRID READY fired. ------------------------------------------>>>");
                $scope.system.loading = false;

                //$scope.$apply();
                //GridService.autosizeColumns($scope.dataAgGridOptions);
                //params.api.sizeColumnsToFit();
                //console.log("resize in ON GRID READY")

            },

            defaultColDef: {
                editable: ($scope.pagemode!=='dataview'),
                sortable: true,
                resizable: true,
            },

            onCellDoubleClicked: function (event) {
                //console.dir(event);
                if (event.colDef.ControlType == "file")
                    $scope.editCellFiles();

            },

            getRowHeight: function (params) {
                //console.log("get row height -------------------");
                //set the rowheight of this row to be the largest of the file count in this row...
                if (!params.node.file_height) {
                    var file_fields = getMatchingByField($scope.dataAgGridOptions.columnDefs, 'file','ControlType');
                    let max_file_field = 1;
                    file_fields.forEach(function (file_field) {
                        var curr_file_count = getFilesArrayAsList(params.node.data[file_field.DbColumnName]).length;
                        if (curr_file_count > max_file_field)
                            max_file_field = curr_file_count;
                    });
                    params.node.file_height = max_file_field;
                    //console.log(" -- MAX number of files in cell file field " + max_file_field);
                }
                var file_height = 25 * (params.node.file_height);
                return (file_height > 25) ? file_height : 25;
            },

            rowClassRules: {
                'row-validation-error': function(params) { return params.node.data.rowHasError; }
            },
            //onCellEditingStarted: function (event) {
                //console.log('cellEditingStarted >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            //},
            onCellEditingStopped: function (event) {
                //save the row we just edited
                //console.log("cell editing stopped >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> "+event.colDef.DbColumnName);
                //console.dir(event);

                if ($rootScope.headerFields)
                    $scope.headerFields = $rootScope.headerFields;

                if ($rootScope.waypoints)
                    $scope.waypoints = $rootScope.waypoints;

                if (GridService.validateCell(event, $scope)) {
                    // Issue:  A Temp-C field updates a Temp-F field, or vice-versa.
                    // This conversion/update/action is OK.
                    // But then the changed Temp-F field updates the Temp-C field, or the opposite,
                    // which triggered the whole sequence, in the first place.
                    // In this situation, we DO NOT want the reverse update to take place.
                    // We only want the initial C -> F, or the F -> C conversion to take place.
                    $scope.EventTriggeredBy += event.colDef.DbColumnName + "-";

                    // Length of 42 chosen, because it is a little bit longer than
                    // WaterTemperatureF-WaterTemperature.  When the string gets longer than
                    // two tabs or clicks, we want to retain only the last two.
                    if ($scope.EventTriggeredBy.length > 42)
                    {
                        var strTmp = $scope.EventTriggeredBy;
                        var intHyphenLoc = strTmp.indexOf("-");
                        strTmp = strTmp.slice(intHyphenLoc + 1);

                        $scope.EventTriggeredBy = strTmp;
                    }

                    // Set various click/tab possibilities to check for.
                    // For example:  WaterTemperatureF-WaterTemperature- means that
                    // the user clicked or tabbed into WaterTemperature, right after
                    // WaterTemperatureF.  We don't want to double-trigger the temperature
                    // conversions.  If they do double-trigger, it can at a minimum make the 
                    // value werid, due to the fractional calculations (5 vs 4.99996), or
                    // cause a repeated calculation, because the grid detects when the value
                    // gets changed.
                    //$scope.EventTriggeredBy = event.colDef.DbColumnName + "-";
                    var textToCheckWtc = "WaterTemperatureF-WaterTemperature-";
                    var textToCheckAtc = "AirTemperatureF-AirTemperature-";
                    var textToCheckWtf = "WaterTemperature-WaterTemperatureF-";
                    var textToCheckAtf = "AirTemperature-AirTemperatureF-";
                    var textToCheckAtcf = "AirTemperature-WaterTemperatureF-";
                    var textToCheckWtfAtf = "WaterTemperatureF-AirTemperatureF-";
                    var textToCheckAtfWtf = "AirTemperatureF-WaterTemperatureF-";
                    var intTextToCheckWtLoc = $scope.EventTriggeredBy.indexOf(textToCheckWtc);
                    var intTextToCheckAtLoc = $scope.EventTriggeredBy.indexOf(textToCheckAtc);
                    var intTextToCheckWtfLoc = $scope.EventTriggeredBy.indexOf(textToCheckWtf);
                    var intTextToCheckAtfLoc = $scope.EventTriggeredBy.indexOf(textToCheckAtf);
                    var intTextToCheckAtcfLoc = $scope.EventTriggeredBy.indexOf(textToCheckAtcf);
                    var intTextToCheckWtfAtfLoc = $scope.EventTriggeredBy.indexOf(textToCheckWtfAtf);
                    var intTextToCheckAtfWtFLoc = $scope.EventTriggeredBy.indexOf(textToCheckAtfWtf);

                    //if ($scope.EventTriggeredBy === "WaterTemperature-WaterTemperatureF-")
                    if ((intTextToCheckWtLoc > -1) || (intTextToCheckAtLoc > -1) ||
                        (intTextToCheckWtfLoc > -1) || (intTextToCheckAtfLoc > -1) ||
                        (intTextToCheckAtcfLoc > -1) || (intTextToCheckAtcfLoc > -1) ||
                        (intTextToCheckWtfAtfLoc > -1) || (intTextToCheckAtfWtFLoc > -1)
                    )
                    {
                        //$scope.EventTriggeredBy = "";
                        return;
                    }
                    else
                    {
                        GridService.fireRule("OnChange", event, $scope); //only fires when valid change is made
                    }
                }

                $scope.PageErrorCount = $scope.getPageErrorCount();

                GridService.refreshRow(event);
                //console.log("--- on cell editing stopped --")

                $scope.dataAgGridOptions.dataChanged = true;

                if (event.data.Id && (!$scope.dataAgGridOptions.editedRowIds.containsInt(event.data.Id))){
                    $scope.dataAgGridOptions.editedRowIds.push(event.data.Id);
                };
                $scope.$apply();

                //special case for water temp: fire dupecheck if readingdatetime changed
                if($scope.dataset.Datastore.TablePrefix == "WaterTemp" && event.colDef.DbColumnName == "ReadingDateTime")
                    $scope.checkForDuplicates();

                //bah - another special case for water quality: fire dupecheck if sampledate changed
                if(($scope.dataset.Datastore.TablePrefix == "WaterQuality" || $scope.dataset.Datastore.TablePrefix == "MetStation")
                    && event.colDef.DbColumnName == "SampleDate"){
                    $scope.checkForDuplicates();
                }

            },
        };

        $scope.bubbleErrors = function () {
            GridService.bubbleErrors($scope.dataAgGridOptions);
            console.log("bubbling!");
        };

        $scope.onHeaderEditingStopped = function (field) { //fired onChange for header fields (common/templates/form-fields)
            //build event to send for validation
            //console.log("onHeaderEditingStopped: " + field.DbColumnName);

            if(field.DbColumnName == 'LocationId'){
                console.log("checking location " + $scope.row.Activity.LocationId);
                if($scope.row.Activity.Location && isNaN($scope.row.Activity.LocationId)){
                    console.log("setting back to value from NaN to " + $scope.row.Activity.Location.Id)
                    $scope.row.Activity.LocationId = $scope.row.Activity.Location.Id; //reset it back to the correct value if it was NaN'd
                }
            }

            //timezone - TODO: until we just use the id, we need to set the object (since the ID gets changed but not the whole object...)
            if (field.DbColumnName == 'Timezone') {
                if (!$scope.row.Activity.Timezone)
                {
                    $scope.TimezoneMissing = true;
                }
                else
                {
                    $scope.TimezoneMissing = false;
                    $scope.row.Activity.Timezone = getById(SystemTimezones, $scope.row.Activity.Timezone.Id);
                }
            }

            var event = {
                colDef: field,
                node: { data: $scope.row },
                value: $scope.row[field.DbColumnName],
                type: 'onHeaderEditingStopped'
            };

            if (GridService.validateCell(event)) {
                //console.log("ran validation and is valid")
                GridService.fireRule("OnChange", event); //only fires when valid change is made
            }
            else
            {
                //console.log("ran validation and is INVALID")
                //console.dir($scope.row)
            }

            //update our collection of header errors if any were returned
            $scope.headerFieldErrors = [];
            if ($scope.row.rowHasError) {
                $scope.row.validationErrors.forEach(function (error) {
                    if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
                        $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
                    } else {
                        $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
                    }
                });
            }

            //update the error count -- determine if this bogs down on big datasets                 TODO
            $scope.PageErrorCount = $scope.getPageErrorCount();

            $scope.row.dataChanged = true;

            //if one of the duplicatecheck fields change then check for duplicates.
            if($scope.dataset.Config.DuplicateCheckFields && $scope.dataset.Config.DuplicateCheckFields.contains(field.DbColumnName))
                $scope.checkForDuplicates();

            //console.dir($scope.row);
        };

        //add a row
        $scope.addNewRow = function () {
            var new_row = GridService.getNewRow($scope.dataAgColumnDefs.DetailFields);
            new_row.QAStatusId = $scope.dataset.DefaultRowQAStatusId;

            //set defaults for detail fields
            $scope.dataAgColumnDefs.DetailFields.forEach(function (detail) {
                if (detail.DefaultValue) {
                    new_row[detail.DbColumnName] = detail.DefaultValue;
                    console.log("Setting default value for " + detail.DbColumnName + " to " + detail.DefaultValue);
                }
            });

            var result = $scope.dataAgGridOptions.api.updateRowData({add: [new_row]});
            $scope.dataAgGridOptions.dataChanged = true;
        };

        //remove a row
        $scope.removeRow = function () {
            var rows_to_delete = $scope.dataAgGridOptions.api.getSelectedRows();

            //add selected rows to deleted rows (might already be some in there, just add ours, too)
            rows_to_delete.forEach(function (item) {
                $scope.dataAgGridOptions.deletedItems.push(item);
            });

            //note the currently deleted row(s) in case they want to undo
            $scope.deletedRows = rows_to_delete;

            //do the remove from the grid.
            $scope.dataAgGridOptions.api.updateRowData({ remove: rows_to_delete });

            $scope.dataAgGridOptions.dataChanged = true;
        };

        //undo remove row
        $scope.undoRemoveRow = function () {
            $scope.dataAgGridOptions.api.updateRowData({ add: $scope.deletedRows });

            //remove these deleted rows from deleted items since we're undeleting
            var new_deleted = [];
            $scope.dataAgGridOptions.deletedItems.forEach(function (deleted) {
                var is_being_undeleted = false
                $scope.deletedRows.forEach(function (undelete) {
                    if (deleted.hasOwnProperty('Id') && undelete.hasOwnProperty('Id') && deleted.Id == undelete.Id)
                        is_being_undeleted = true;
                });
                if(!is_being_undeleted)
                    new_deleted.push(deleted)
            });
            $scope.dataAgGridOptions.deletedItems = new_deleted;

            //clear our deleted rows buffer
            $scope.deletedRows = [];

        };


        //call to fire up the grid after the $scope.dataset is ready
        $scope.activateGrid = function () {

            //setup grid and coldefs and then go!
            $timeout(function () {

                $scope.dataAgColumnDefs = GridService.getAgColumnDefs($scope.dataset);
                $scope.dataAgGridOptions.columnDefs = $scope.dataAgColumnDefs.DetailFields;
                $scope.NumberOfCols = $scope.dataAgGridOptions.columnDefs.length;
                $scope.fields = { header: $scope.dataAgColumnDefs.HeaderFields, detail: $scope.dataAgColumnDefs.DetailFields };

                //spin through and set any sytem field detail possible values -----------------       //////TODO move the config.js fields into a systems dataset, then can use the Datasource technique to load these.
                angular.forEach($scope.fields.detail, function (fieldDef) {
                    if (fieldDef.field == "QAStatusId") { //RowQAStatusId
                        fieldDef.setPossibleValues(makeObjects($scope.dataset.RowQAStatuses, 'Id', 'Name'));
                    }
                });

                //multiselect header fields - convert from json
                var HeaderMultiselectFields = getAllMatchingFromArray($scope.dataAgColumnDefs.HeaderFields, 'multiselect', 'ControlType');

                HeaderMultiselectFields.forEach(function (multiselect_field) {
                    if ($scope.row[multiselect_field.DbColumnName]) {
                        $scope.row[multiselect_field.DbColumnName] = getParsedMetadataValues($scope.row[multiselect_field.DbColumnName]);
                        if (!Array.isArray($scope.row[multiselect_field.DbColumnName])) {  //backwards compatible - some multiselects just have the value saved... turn it into an array
                            $scope.row[multiselect_field.DbColumnName] = [$scope.row[multiselect_field.DbColumnName]];
                        }
                    }
                    console.dir($scope.row[multiselect_field.DbColumnName]);
                });

                //if we are on data entry page (and not importing), set the header values to any defaults.
                if ($scope.pagemode == 'dataentryform' && !$rootScope.hasOwnProperty('imported_rows')) {
                    console.log("applying defaults to header fields");
                    $scope.dataAgColumnDefs.HeaderFields.forEach(function (header) {
                        //console.dir(header);
                        if (header.DefaultValue) {
                            $scope.row[header.DbColumnName] = header.DefaultValue;
                            console.log("Setting default value for " + header.DbColumnName + " to " + $scope.row[header.DbColumnName]);
                        }
                    });
                }

                var ag_grid_div = document.querySelector('#data-edit-grid') as HTMLElement;    //get the container id...
                $scope.ag_grid = new Grid(ag_grid_div, $scope.dataAgGridOptions); //bind the grid to it.
                $scope.dataAgGridOptions.api.showLoadingOverlay(); //show loading...

                $scope.NumberOfRows = $scope.dataset_activities.Details.length;

                //set the detail values into the grid
                $scope.dataAgGridOptions.api.setRowData($scope.dataset_activities.Details);

                //console.dir($scope.dataAgColumnDefs);

                //convert timezone to object if it exists
                if ($scope.row.Activity && $scope.row.Activity.Timezone) {
                    $scope.row.Activity.Timezone = angular.fromJson($scope.row.Activity.Timezone);
                    //workaround - sometimes the stored timezone hasn't always included an Id for some reason...
                    if ($scope.row.Activity.Timezone && !$scope.row.Activity.Timezone.hasOwnProperty("Id")) {
                        $scope.row.Activity.Timezone = getByField(SystemTimezones, $scope.row.Activity.Timezone.Name, "Name");
                    }
                }
                $scope.project.$promise.then(function () {
                    try {
                        $scope.project.Config = ($scope.project.Config) ? angular.fromJson($scope.project.Config) : {};
                    } catch (e) {
                        console.error("config could not be parsed for project" + $scope.project.Config);
                        console.dir(e);
                    }

                    //set our location if only one project location exists. - this async request was started as soon as the project was loaded, but we need the headerfields...
                    $scope.project.Locations.$promise.then(function(){
                        var HeaderLocation = getAllMatchingFromArray($scope.dataAgColumnDefs.HeaderFields, 'LocationId', 'DbColumnName');
                        if (HeaderLocation && $scope.project.Locations.length == 1 && !$scope.row.Activity.LocationId) {
                            $scope.row.Activity.LocationId = $scope.project.Locations[0].Id;
                            console.log(" --> setting Activity.LocationId because there is only one defined.");
                        } else {
                            if ( (!HeaderLocation || HeaderLocation.length === 0) || ( HeaderLocation.length === 1 && HeaderLocation[0].ControlType === "hidden") ){
                                if($scope.project.Locations.length > 1){
                                    alert("Configuration error: More than one location is defined and location field is configured to be hidden. You will not be able to save a record in this dataset.");
                                }
                            }
                        }

                        //bump the select2 to set the current value
                        if($scope.row.Activity.LocationId){
                          //@ts-ignore
                            $(function () { $("#location-select").select2(); });
                        }
                    })


                    if (($scope.project.Config) && ($scope.project.Config.Lookups))
                    {
                        $scope.project.Config.Lookups.forEach(function (item){
                            if (item.Label === "Characteristics")
                            {
                                $scope.Characteristics = CommonService.getLookupItems(item);
                                $scope.Characteristics.$promise.then(function () {
                                    $scope.dataset_activities.Details.forEach(function(detail){
                                        // If we are on WaterQuality, the Characteristics options were originally
                                        // stored in dbo.Fields.PossibleValues as Text.  Later (because there are
                                        // so many), we moved them out to their own table.  Now they come in with
                                        // Id, so the Id must be converted to the Name, because we don't want to
                                        // convert Characteristic from text to int for the millions of records
                                        // in WaterQuality_Detail.
                                        var blnFoundIt = false;
                                        $scope.Characteristics.forEach(function(aCharacteristic){
                                            if ((!blnFoundIt) && (aCharacteristic.CharacteristicName === detail.CharacteristicName))
                                            {
                                                detail.CharacteristicName = aCharacteristic.Id;
                                                blnFoundIt = true;
                                            }
                                        });
                                    });
                                });
                            }
                        });
                    }
                });


                GridService.validateGrid($scope.dataAgGridOptions);

                if ($rootScope.hasOwnProperty('imported_rows')) {
                    //when importing fire any onchange rules (like watertemp C to F)
                    GridService.fireAllOnChange($scope.dataAgGridOptions);
                }

                $scope.dataAgGridOptions.api.redrawRows();

                $scope.PageErrorCount = $scope.getPageErrorCount();

                if ($rootScope.imported_rows)
                    $scope.bubbleErrors();

                $scope.dataAgGridOptions.api._headerrow = $scope.row;

                //make sure the locationid dropdown selects the selected location if it is set
                //console.log(" -- row --")
                //console.dir($scope.row);
                //if($scope.row.Activity.LocationId){
                //    $(function () { $("#location-select").select2(); });
                //}

                ChartService.buildChart($scope, $scope.dataset_activities.Details, $scope.dataset.Datastore.TablePrefix);

            }, 0);




        };

        //called after the dataset is loaded
        $scope.afterDatasetLoadedEvent = function () {

            $scope.project = ProjectService.getProject($scope.dataset.ProjectId);

            //get this started as soon as we have the project.
            $scope.project.$promise.then(function () {
                //in the new location design, we have to fetch the locations separately - these are all the valid locations for a project
                $scope.project.Locations = ProjectService.getDatasetLocations($scope.project.Id, $scope.dataset.Id);
            });

            DatasetService.configureDataset($scope.dataset); //bump to load config since we are pulling it directly out of the activities

            if ((typeof $scope.row.Activity.AccuracyCheck !== 'undefined' && $scope.row.Activity.AccuracyCheck !== null) &&
                (typeof $scope.row.Activity.PostAccuracyCheck !== 'undefined' && $scope.row.Activity.PostAccuracyCheck !== null) ) {
                // We don't need a line return on the form.
                $scope.row.AccuracyCheckBreak = false;
                $scope.row.Activity.AccuracyCheckText = $scope.row.Activity.AccuracyCheck.Bath1Grade + "-" + $scope.row.Activity.AccuracyCheck.Bath2Grade + " on " + moment($scope.row.Activity.AccuracyCheck.CheckDate).format('MMM DD YYYY');
                $scope.row.Activity.PostAccuracyCheckText = $scope.row.Activity.PostAccuracyCheck.Bath1Grade + "-" + $scope.row.Activity.PostAccuracyCheck.Bath2Grade + " on " + moment($scope.row.Activity.PostAccuracyCheck.CheckDate).format('MMM DD YYYY');
            }
            else {
                // We need line return on the form.
                $scope.row.AccuracyCheckBreak = true;
                $scope.row.AccuracyCheckText = null;
                $scope.row.Activity.PostAccuracyCheckText = null;
            }

            //console.log(">>> before activate grid")
            $scope.activateGrid();
            //console.log(">>> after activate grid")

            //load the files related to this dataset
            $scope.dataset.Files = DatasetService.getDatasetFiles($scope.dataset.Id);

            //once the dataset files load, setup our file handler
            $scope.dataset.Files.$promise.then(function () {
                //mixin the properties and functions to enable the modal file chooser for this controller...
                //console.log("---------------- setting up dataset file chooser ----------------");
                //modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.dataset.Files);
                $scope.typeOfFilesToCheck = "dataset";
                modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.typeOfFilesToCheck);  
            });

            //if the activity qa status is already set in the header (editing), copy it in to this row's activityqastatus
            if ($scope.dataset_activities.Header.Activity && $scope.dataset_activities.Header.Activity.ActivityQAStatus) {
                //console.warn("Header activity copying in activityqastatus");
                $scope.row.ActivityQAStatus = {
                    QAStatusId: "" + $scope.dataset_activities.Header.Activity.ActivityQAStatus.QAStatusId,
                    Comments: $scope.dataset_activities.Header.Activity.ActivityQAStatus.Comments,
                }
            }
            //otherwise (new record), set it to the default.
            else  {
                console.warn("The ActivityQAStatus for this activity is not set, setting to default.");
                $scope.row.ActivityQAStatus = {
                    QAStatusId: "" + $scope.dataset.DefaultRowQAStatusId,
                    Comments: ""
                }
            }

            //set the disabled fields from the dataset config, if present
            if ($scope.dataset.Config.DataEntryPage && $scope.dataset.Config.DataEntryPage.DisabledFields
                && Array.isArray($scope.dataset.Config.DataEntryPage.DisabledFields))
            {
                $scope.DisabledFields = {};
                $scope.dataset.Config.DataEntryPage.DisabledFields.forEach(function(item){
                    $scope.DisabledFields[item] = {disabled: true};
                });
            }

        };

        //this is a workaround for angularjs' either too loose matching or too strict...
        $scope.locequals = function (actual, expected) { return actual == expected; };

        $scope.setSelectedBulkQAStatus = function (rowQAId) {
            angular.forEach($scope.dataAgGridOptions.selectedItems, function (item, key) {
                //console.log("bulk changing: ");
                //console.dir(item);
                item.QAStatusId = rowQAId;
                GridService.refreshGrid($scope.dataAgGridOptions);
                //mark the row as updated so it will get saved.
                if (item.Id && $scope.dataAgGridOptions.editedRowIds.indexOf(item.Id) == -1) {
                    $scope.dataAgGridOptions.editedRowIds.push(item.Id);
                }
            });

            $scope.dataAgGridOptions.dataChanged = true;

            $scope.dataAgGridOptions.api.deselectAll();
        };

        $scope.editCellFiles = function () {
            console.log("edit cell files:");
            var the_cell = $scope.dataAgGridOptions.api.getFocusedCell();
            var the_row = $scope.dataAgGridOptions.api.getDisplayedRowAtIndex(the_cell.rowIndex)
            //console.dir(the_cell);
            //console.dir(the_row);
            $scope.openFileModal(the_row.data, the_cell.column.colDef.DbColumnName, $scope.afterEditCellFiles);
        }

        $scope.afterEditCellFiles = function () {
            console.log("after edit cell files!");
            var the_cell = $scope.dataAgGridOptions.api.getFocusedCell();
            var the_row = $scope.dataAgGridOptions.api.getDisplayedRowAtIndex(the_cell.rowIndex)
        //    console.dir(the_cell);
            console.dir(the_row);
            delete the_row.file_height;

            $scope.dataAgGridOptions.api.redrawRows();
            $scope.dataAgGridOptions.api.resetRowHeights(); //redraw so that the files in the cell are displayed properly.

            //mark the row as changed and edited.
            $scope.row.dataChanged = true;

            if (the_row.data.Id && $scope.dataAgGridOptions.editedRowIds.indexOf(the_row.data.Id) == -1) {
                $scope.dataAgGridOptions.editedRowIds.push(the_row.data.Id);
                console.log("edited row: " + the_row.data.Id);
            }

        };

        $scope.afterFileModal = function () {
            $scope.row.dataChanged = true;
        }

        $scope.openBulkQAChange = function () {

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-rowqaupdate.html',
                controller: 'ModalBulkRowQAChangeCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });

        };

		$scope.openWaypointFileModal = function(row, field)
        {
            $scope.file_row = row;
            $scope.file_field = field;

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/file/templates/modal-waypoint-file.html',
                controller: 'WaypointFileModalCtrl',
                scope: $scope, //scope to make a child of
                backdrop: "static",
                keyboard: false
            });
        };

        $scope.openEdit = function () {
            $location.path("/edit/" + $scope.dataset_activities.Header.Activity.Id);
        }


        $scope.createInstrument = function () {
            //$scope.viewInstrument = null;
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-create-instrument.html',
                controller: 'ModalCreateInstrumentCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_instrument) {
                //add saved_instrument to our list.
                saved_instrument.AccuracyChecks = [];
                $scope.project.Instruments.push(saved_instrument);
                $scope.row.Activity.InstrumentId = saved_instrument.Id;
                $scope.selectInstrument();
                $scope.row.dataChanged = true; //we have changed!
                //aha! this is a trick to get the instruments select to rebuild after we change it programatically
                //@ts-ignore
                $(function () { $("#instruments-select").select2(); });

            });
        };

        $scope.openAccuracyCheckModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-new-accuracycheck.html',
                controller: 'ModalQuickAddAccuracyCheckCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_AC) {
                //add saved_AC to our list.
                $scope.project.Instruments.forEach(function (inst) {
                    if (inst.Id == $scope.row.Activity.InstrumentId) {
                        console.log("found that instrument!"); console.dir(inst);
                        inst.AccuracyChecks.push(saved_AC);
                        $scope.row.Activity.Instrument = inst;
                    }
                });
                $scope.selectInstrument();
                $scope.row.dataChanged = true; //we have changed a header!

            });
        };

        $scope.changeQa = function () {

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/datasets/components/dataset-view/templates/changeqa-modal.html',
                controller: 'ModalQaUpdateCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });
        };

        $scope.getDataGrade = function (check) { return getDataGrade(check) }; //alias

        //when user selects an instrument, the directive model binding sets the row.Activity.InstrumentId.
        // we need to set the row.Activity.Instrument to the matching one from project.Instruments
        // and then select the last AccuracyCheck and set it in row.Activity.AccuracyCheckId
        $scope.selectInstrument = function (field) {
            console.log("selectInstrument. InstrumentId is " + $scope.row.Activity.InstrumentId);
            if (isNaN($scope.row.Activity.InstrumentId)) {

                // If $scope.row.Activity.Instrument.Id exists, we will set the nan back to a real id.
                if (($scope.row.Activity.Instrument) && ($scope.row.Activity.Instrument.Id))
                {
                    console.log("setting back to value from NaN to " + $scope.row.Activity.Instrument.Id)
                    $scope.row.Activity.InstrumentId = $scope.row.Activity.Instrument.Id; //reset it back to the correct value if it was NaN'd
                }
                // Otherwise, we will backout any additions.
                else
                {
                    //delete $scope.row.Activity.AccuracyCheck; delete $scope.row.Activity.AccuracyCheckId; delete $scope.row.Activity.PostAccuracyCheck; delete $scope.row.Activity.PostAccuracyCheck.Id;
                    if ($scope.row.Activity.AccuracyCheck)
                        delete $scope.row.Activity.AccuracyCheck;

                    if ($scope.row.Activity.AccuracyCheckId)
                        delete $scope.row.Activity.AccuracyCheckId;

                    if ($scope.row.Activity.PostAccuracyCheck)
                        delete $scope.row.Activity.PostAccuracyCheck;
                }

                return;
            }

            $scope.project.Instruments.forEach(function (inst) {
                if (inst.Id == $scope.row.Activity.InstrumentId) {
                    console.log("found that instrument!"); console.dir(inst);
                    $scope.row.Activity.Instrument = inst;
                }
            });

            //get latest accuracy check
            $scope.row.Activity.AccuracyCheck = $scope.row.Activity.Instrument.AccuracyChecks[$scope.row.Activity.Instrument.AccuracyChecks.length - 1];
            $scope.row.DataGradeText = getDataGrade($scope.row.Activity.AccuracyCheck);

            if ($scope.row.Activity.AccuracyCheck)
                $scope.row.Activity.AccuracyCheckId = $scope.row.Activity.AccuracyCheck.Id;

            if(field)
                $scope.onHeaderEditingStopped(field);

        };

        $scope.cancel = function () {
            if ($scope.dataAgGridOptions.dataChanged) {
                if (!confirm("Looks like you've made changes.  Are you sure you want to leave this page?"))
                    return;
            }
            //console.dir($scope.dataset);
            //console.log("#!/" + $scope.dataset.activitiesRoute + "/" + $scope.dataset.Id);

            $location.path("/" + $scope.dataset.activitiesRoute + "/" + $scope.dataset.Id);
        };


        //click "save" on dataset edit form
        $scope.saveData = function () {

            if (!$scope.dataAgGridOptions.dataChanged && !$scope.row.dataChanged) {
                if (confirm("Nothing to save. Return to Activities?")) {
                    $location.path("/" + $scope.dataset.activitiesRoute + "/" + $scope.dataset.Id);
                }
                else {
                    return;
                }
            }

            var HeaderLocation = getAllMatchingFromArray($scope.dataAgColumnDefs.HeaderFields, 'LocationId', 'DbColumnName');

            console.log("typeof $scope.row.Activity.LocationId = " + typeof $scope.row.Activity.LocationId);
            //testing to see if the LocationId field doesn't exist or exists and it set to hidden
            if ( (!HeaderLocation || HeaderLocation.length === 0) || ( HeaderLocation.length === 1 && HeaderLocation[0].ControlType === "hidden") ){

                    console.log("Location is removed or hidden - set it to the assigned single location");
                    //console.dir(HeaderLocation);
                    //console.dir($scope.project.Locations);

                    if($scope.project.Locations.length == 1) //we have only one location
                        $scope.row.Activity.LocationId = $scope.project.Locations[0].Id;
                    else{
                        alert("Configuration error: A dataset with a hidden location must have one and only one location set in order to save.")
                        return;
                    }

            }

            //final test to make sure we have a LocationId - required to save
            // The following line does not work.  When the location is omitted, $scope.row.Activity.LocationId shows as NaN.
            //if (!$scope.row.Activity.hasOwnProperty("LocationId"))
            // The following line DOES work.
            if ((typeof $scope.row.Activity.LocationId === "undefined") || (isNaN(parseInt($scope.row.Activity.LocationId))))
            {
                    alert("Location is required. Please choose a location and try again.");
                    console.dir(HeaderLocation);
                    console.dir($scope.row);
                    return;
            }

            //fire validation on all header fields
            for (let headerField of $scope.dataAgColumnDefs.HeaderFields){
                $scope.onHeaderEditingStopped(headerField);
            }

            // We know by now, if the Timezone is missing.  If so, abort.
            if ($scope.TimezoneMissing)
            {
                alert("Timezone cannot be blank!");
                return;
            }

            //if ($scope.PageErrorCount > 0) {
            //if (($scope.dataset.Config) && ($scope.dataset.Config.AllowSaveWithErrors))
            //{
            //    //if ($scope.PageErrorCount > 0) {
            //    if (($scope.dataset.Config.AllowSaveWithErrors === false) && ($scope.PageErrorCount > 0)) {
            //        alert("There are errors on the page. Please fix them before saving.");
            //        console.dir($scope.headerFieldErrors);
            //        return;
            //    }
            //}

            if ($scope.PageErrorCount > 0)
            {
                if (($scope.dataset.Config) && ($scope.dataset.Config.AllowSaveWithErrors))
                {
                    if ($scope.dataset.Config.AllowSaveWithErrors === false)
                    {
                        alert("There are errors on the page. Please fix them before saving.");
                        console.dir($scope.headerFieldErrors);
                        return;
                    }
                    else
                    {
                        // We have a dataset.Config, AND AllowSaveWithErrors, AND it is true...
                        // Continue with the save, even with the errors.
                    }
                }
                else
                {
                    // We have a dataset.Config, but no AllowSaveWithErrors,
                    // So we don't save with the errors.
                    alert("There are errors on the page. Please fix them before saving.");
                    console.dir($scope.headerFieldErrors);
                    return;
                }
            }

            // Several items on the page key off of $scope.saveResult.saving.
            // If $scope.saveResult.saving = true, it shows various items, and disables some things.
            // $scope.background_save gets set to true by $scope.addSection (dataset Creel Survey)
            // The idea is, when adding a new section, we want to stay on the same page, with some items set, 
            // so that we don't have to reenter things.  After we save the record, we need to return to
            // $scope.addSection to reset then applicable items.
            // So, if we ARE NOT adding a new section, set $scope.saveResult.saving to true.
            // Otherwise, we must leave it alone, with the save action happing in the background.
            if(!$scope.background_save)
                $scope.saveResult.saving = true;

            // Used in the modal-files.ts
            $scope.loading = true;

            console.log(" -- save -- ");

            // The flow...
            // Open modal-save-status.html
            // modal-save-status.ts calls $scope.handleFilesToUploadRemove
            // $scope.handleFilesToUploadRemove does work and then calls dataset-edit-form.ts, $scope.modalFile_saveParentItem
            // dataset-edit-form.ts, $scope.modalFile_saveParentItem sets $scope.showFish to false in modal-save.status.ts
            // The user clicks Close on modal-save-status.html
            //      - If the user was on dataset Creel Harvest, the page remains where it is
            //      - If the user was on some other dataset, the page then navigations to the Dataset Activities page.

            $scope.CallingPage = "DatasetEditForm";

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/datasets/components/dataset-editor/templates/modal-save-status.html',
                controller: 'ModalSaveStatusCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });

            // -- we dynamically duplicate check, so don't check AGAIN --
            // var dupe_check = $scope.checkForDuplicates();
            // if (dupe_check) {
            //     dupe_check.$promise.then(function () {
            //         if (!$scope.hasDuplicateError)
            //             $scope.modalFile_saveParentItem(); //saverow - this is just for temporary TODO......
            //         else
            //             console.log("Aborting saving because we have a duplicate error");
            //     });
            // } else {
            //     $scope.modalFile_saveParentItem(); //saverow - this is just for temporary TODO......
            // }
            //

            //console.dir($scope.row);

            //************************************************/
            // Move this stuff below to modal-save-status.ts
            //handle saving the files.
//             var data = {
//                 ProjectId: $scope.project.Id,
//                 DatasetId: $scope.dataset.Id,
//             };

//             var target = '/api/v1/file/uploaddatasetfile';

// 			//console.log("$scope.row is next...");
// //			console.dir($scope.row);
//             var saveRow = $scope.row;

//             $scope.handleFilesToUploadRemove(saveRow, data, target, Upload, $rootScope, $scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFile_saveParentItem below.
            //*/
        };

        //** special buttons for creel data entry **
        $scope.addNewInterview = function () {
            $scope.addNewRow();
        };

        //this just duplicates the current row
        $scope.addAnotherFish = function () {
            var the_cell = $scope.dataAgGridOptions.api.getFocusedCell();

            if (!the_cell) {
                alert("Please select an Interview row that you want to copy");
                return;
            }

            var the_row = $scope.dataAgGridOptions.api.getDisplayedRowAtIndex(the_cell.rowIndex);
            var result = $scope.dataAgGridOptions.api.updateRowData({ add: [the_row.data] });
            $scope.dataAgGridOptions.dataChanged = true;
            //console.dir(the_row);
        };

        //open the add fisherman modal
        $scope.addFisherman = function () {
            $scope.viewFisherman = null;
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-create-fisherman.html',
                controller: 'ModalCreateFishermanCtrl',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });
        };

        //callback after adding a fisherman -- push it into the dropdown list.
        $scope.postSaveFishermanUpdateGrid = function (new_fisherman) {
            var col = $scope.dataAgGridOptions.columnApi.getColumn('FishermanId');
            col.colDef.PossibleValues[new_fisherman.Id] = new_fisherman.FullName;
            col.colDef.setPossibleValues(col.colDef.PossibleValues);
        };

        //save this record and reset a few fields.
        $scope.addSection = function () {

            $scope.background_save = true;

            //console.log("$scope is next...");
            //console.dir($scope);

            // First save the record.
            $scope.saveData();

            // Next we need to delete the following items, because they will change.
            //****************************************************************************
            // Note:  To reset items on the page, we must do so AFTER the save takes place
            // in $scope.modalFile_saveParentItem.  See note there.
            // Otherwise, as JavaScript is asynchronous, reset actions here will take place
            // before the save action completes, resulting in null values getting passed
            // to the backend.
            //****************************************************************************

            // Had to move this stuff to the promise as well.
            // // Dump the contents of the datasheet and add the new row.
            // $scope.dataAgGridOptions.api.setRowData([]);
            // //$scope.addNewRow();

            // // Set the file buckets for Creel to undefined or empty; otherwise, the last saved file will still be
            // // dangling and interphere with the save operation (trying to resave the same file - a duplicate).
            // if($scope.originalExistingFiles && $scope.row.originalExistingFiles)
            //     $scope.originalExistingFiles.FieldSheetFile = $scope.row.originalExistingFiles.FieldSheetFile = undefined;

            // $scope.row.fieldFilesToUpload = [];

            // // If this is not set to undefined, it will incorrectly register an empty FieldSheetFile as 1,
            // // and cause problems during the save process.
            // $scope.filesToUpload = undefined;

            // // This pops the Save Success modal after Add Section.
            // var modalInstance = $uibModal.open({
            //     templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-save-success.html',
            //     controller: 'ModalSaveSuccess',
            //     scope: $scope, //very important to pass the scope along...
            //     backdrop: "static",
            //     keyboard: false
            // });

        };

        //** end special creel buttons **

        //remove file from dataset.
        $scope.modalFile_doRemoveFile = function (file_to_remove, saveRow) {
            return DatasetService.deleteDatasetFile($scope.project.Id, $scope.dataset.Id, file_to_remove);
        };


        //check for duplicates
        $scope.checkForDuplicates = function () {
            var promise = GridService.checkForDuplicates($scope.dataset, $scope.dataAgGridOptions, $scope.row, $scope.saveResult);

            $scope.hasDuplicateError = $scope.saveResult.hasError;
            return promise;
        };

        //finish saving after file saving completes...
        $scope.modalFile_saveParentItem = function (saveRow) {
            console.log("Inside modalFile_saveParentItem...");
            console.log("$scope.saveResult.saving = " + $scope.saveResult.saving);

            //clean up some things from the copy of activity that we don't need to send to the backend.
            var new_activity = angular.copy($scope.row.Activity);
            delete new_activity.AccuracyCheck;
            delete new_activity.ActivityType;
            delete new_activity.Instrument;
            delete new_activity.Location;
            delete new_activity.Source;
            delete new_activity.User;
            delete new_activity.ActivityQAStatus;
            new_activity.Timezone = angular.toJson(new_activity.Timezone); //TODO: why don't we just save the id?

            //add the ActivityQAStatus back in with values from the activity
            new_activity.ActivityQAStatus = {
                'Comments': $scope.row.Activity.ActivityQAStatus.Comments,
                'QAStatusId': $scope.row.Activity.ActivityQAStatus.QAStatusId,
            };

            //clean up some things from the row (header fields)
            var new_row = angular.copy($scope.row);
            delete new_row.Activity;
            delete new_row.ActivityQAStatus;
            delete new_row.ByUser;

            // todo: why is this being deleted
            // delete dataChanged;

            //compose our payload
            var payload = {
                'Activity': new_activity,
                'DatasetId': $scope.dataset.Id,
                'ProjectId': $scope.project.Id,
                'UserId': $rootScope.Profile.Id,
                'deletedRowIds': [],
                'editedRowIds': [],
                'header': new_row,
                'details': [],
            };

            // 1) all current detail records from the grid if it is new OR just edited details if editing
            $scope.dataAgGridOptions.api.forEachNode(function (node) {
                //console.log("Node id: " + node.data.Id);
                //console.dir($scope.dataAgGridOptions.editedRowIds);
                //console.log(" does editedRowIds contain this id? " + $scope.dataAgGridOptions.editedRowIds.containsInt(node.data.Id));

                if(!$scope.row.ActivityId || !node.data.Id || $scope.dataAgGridOptions.editedRowIds.containsInt(node.data.Id)){

                    if (($scope.project.Config) && ($scope.project.Config.Lookups))
                    {
                        $scope.project.Config.Lookups.forEach(function (item){
                            if (item.Label === "Characteristics")
                            {
                                var blnFoundIt = false;
                                $scope.Characteristics.forEach(function(aCharacteristic){
                                    if ((!blnFoundIt) && (aCharacteristic.Id === parseInt(node.data.CharacteristicName)))
                                    {
                                        node.data.CharacteristicName = aCharacteristic.CharacteristicName;
                                        blnFoundIt = true;
                                    }
                                });
                            }
                        });
                    }

                    console.log("adding row id " + node.data.Id + " to be saved...");
                    var data = angular.copy(node.data);
                    payload.details.push(data);
                }
            });

			// If the user removed a row, the grid no longer contains that row.
			// However, when we remove a row, it is not deleted from the database; it is marked as deleted in the backend (ROWSTATUS_DELETED).
			// Therefore, we need to add the removed row back into the list that we send to the database, but we DO NOT want to add it back
			// into the grid.
			// 2) add the deleted record to the detail payload, mark it as deleted
			$scope.dataAgGridOptions.deletedItems.forEach(function(deletedItem){
                if (deletedItem.Id) { //only push ones that were existing already (new rows that are deleted are ignored)
                    var data = angular.copy(deletedItem);
                    data.RowStatusId = ROWSTATUS_DELETED;
                    payload.details.push(data);
                    payload.deletedRowIds.push(data.Id);
                }
			});

            // 3) note which are edited
            payload.editedRowIds = $scope.dataAgGridOptions.editedRowIds;

            // 4) convert multiselects from array to json
            //get all multiselect fields we need to convert
            var DetailMultiselectFields = getAllMatchingFromArray($scope.dataAgColumnDefs.DetailFields, 'multiselect', 'ControlType');
            var HeaderMultiselectFields = getAllMatchingFromArray($scope.dataAgColumnDefs.HeaderFields, 'multiselect', 'ControlType');

            payload.details.forEach(function (data) {
                DetailMultiselectFields.forEach(function (multiselect_field) {
                    if (Array.isArray(data[multiselect_field.DbColumnName]) && data[multiselect_field.DbColumnName].length > 0) {
                        data[multiselect_field.DbColumnName] = angular.toJson(data[multiselect_field.DbColumnName]);
                        //console.log(" -- multiselect " + multiselect_field.DbColumnName + " == " + data[multiselect_field.DbColumnName]);
                    }
                });
            });

            HeaderMultiselectFields.forEach(function (multiselect_field) {
                if (Array.isArray(payload.header[multiselect_field.DbColumnName]) && payload.header[multiselect_field.DbColumnName].length > 0) {
                    payload.header[multiselect_field.DbColumnName] = angular.toJson(payload.header[multiselect_field.DbColumnName]);
                }
            });


            // 5) convert time fields in header to be based on activity date
            var HeaderTimeFields = getAllMatchingFromArray($scope.dataAgColumnDefs.HeaderFields, 'time', 'ControlType');
            HeaderTimeFields.forEach(function (time_field) {
                var activity_date = moment(payload['Activity']['ActivityDate']);
                //console.log(" our activity date: " + activity_date.format('L'));
                var the_date = moment(payload.header[time_field.DbColumnName], ["HH:mm"], true);

                if (!the_date.isValid())
                    return; //continue

                var the_combined_date = the_date.set(
                    {
                        year: activity_date.year(),
                        month: activity_date.month(),
                        date: activity_date.date()
                    });

                payload.header[time_field.DbColumnName] = the_combined_date.format('YYYY-MM-DD HH:mm');
                //console.log(" >> final date = " + payload.header[time_field.DbColumnName]);
            });

            //console.dir(payload);

            var save_promise = null;

            if ($scope.pagemode == 'edit')
                save_promise = DatasetService.updateActivities(payload);
            else if ($scope.pagemode == 'dataentryform')
                save_promise = DatasetService.saveActivities(payload);
            else
                console.log("this shouldn't be possible - pagemode is unexpected: "+ $scope.pagemode);

            //console.log("$scope.saveResult.saving = " + $scope.saveResult.saving);

            // Moved the delay timer up here, because when it is inside the $promise.then below,
            // The results of the save action have already come back, which defeats the purpose
            // of the delay timer...
            // Also, in a testing example, there were 12,000+ records.  However, only 4 records were changed.
            // So only 4 records were getting saved.
            // Yet, with the original equation ($scope.NumberOfRows * ($scope.NumberOfCols)), the delay
            // ended up being about 4-5 minutes -- far too long for 4 records.

            // Real-world situation.  A user was importing WQ data via large file.
            // The Status message would show Success!, some time later, the success message
            // would be replaced with an error message.  The error message was due to an SQL timeout on the update command.
            // The file had 50,000 rows of details.
            // 50,000 rows makes the delay be 50 seconds.  
            // So, the frontend displayed the success message after 50 seconds, 
            // and the backend was still working, and ended up timing out.
            // With timeout disabled, 50,000 records took about 125 seconds to save.
            // 125 / 50000 = 0.0025.
            // if ((payload.details) || (payload.header))
            // {
            //     $scope.saveResult.saving = true;

            //     var numberOfMilliSeconds = 3; // Default.
            //     if (payload.details)
            //         numberOfMilliSeconds = payload.details.length * .003; // Allow a little extra time.
            //     else if (payload.header)
            //         numberOfMilliSeconds = 5000; // This may require adjusting.

            //     setTimeout(() => {
            //         $scope.DelayEnablingButtons();
            //     }, numberOfMilliSeconds);
            // }

            save_promise.$promise.then(
                function (theResult) {
                    console.log("Inside save_promise.$promise.then...");
                    console.log("theResult is next...");
                    console.dir(theResult);
                    console.log("$scope.saveResult.saving = " + $scope.saveResult.saving);
                    
                    // For some reason, when the save or update action is finished, $scope.saveResult.saving is false.
                    // Setting it back to true here, so that we can control it from $scope.DelayEnablingButtons,
                    // and allow time for the frontend processing to complete, before enabling the buttons again.

                    if ((theResult[0]) && (theResult[0].ErrorMessage))
                    {
                        let theErrorText: any;

                        theErrorText = theResult[0].ErrorMessage;

                        console.error("Save error:  theErrorText = " + theErrorText);

                        $scope.saveResult.error = "There was a problem saving your data (" + theErrorText + ").  Please try again or contact support.";
                    }
                    else
                    {
                        // If we are adding a section, we DON"T want to switch the form, to show
                        // the alert-success section, which keys off of the saveResult.success.
                        // We need the form to stay how it is, so that we can add another section,
                        // if necessary.
                        if (!$scope.background_save)
                        {
                            $scope.saveResult.success = true; // Turn on the Back to List button.
                            $scope.saveResult.saving = false; // Stop the spinning fish, enable the buttons. Note:  If a dataset allows saving with errors, the Save and close button WILL NOT disable.
                            
                            // If, during the save process, we opened the a modal for the saving action,
                            // that modal is on top, and we need to turn off its spinning fish, so that we can close the modal.
                            if (($scope.$$childTail) && ($scope.$$childTail.showFish))
                                $scope.$$childTail.showFish = false;
                        }
                        else
                        {
                            // When $scope.background_save = true, it is because we are adding a section (Creel Survey).
                            // We need to reset (delete) the entry to the Location selectbox.
                            // If we do it in function AddSection, after calling saveData(), because JavaScript is
                            // asynchronous, it deletes $scope.row.Activity.LocationId before the save action completes
                            // before the backend gets called and passes the LocationId, and thus LocationId ends up being null,
                            // and causes the save to fail.  So, delete LocationId here, after the save completes.
                            $scope.row.Activity.LocationId = null;

                            // In either case, we still need to stop the spinning fish so that the "Close" button
                            // becomes enabled again.
                            if (($scope.$$childTail) && ($scope.$$childTail.showFish))
                                $scope.$$childTail.showFish = false;

                            // These lines reset the applicable items in the row.
                            if (($scope.dataset) && 
                                ($scope.dataset.Config) && 
                                ($scope.dataset.Config.DataEntryPage) &&
                                ($scope.dataset.Config.DataEntryPage.FieldsToResetForAddSection))
                            {
                                for (var item in $scope.dataset.Config.DataEntryPage.FieldsToResetForAddSection)
                                {
                                    //console.log(item + ": " + $scope.dataset.Config.DataEntryPage.FieldsToResetForAddSection[item]);
                                    $scope.row[item] = $scope.dataset.Config.DataEntryPage.FieldsToResetForAddSection[item];
                                }

                                //console.log("$scope.row is next...");
                                //console.dir($scope.row);
                            }

                            // Dump the contents of the datasheet and add the new row.
                            $scope.dataAgGridOptions.api.setRowData([]);
                            //$scope.addNewRow();

                            // Set the file buckets for Creel to undefined or empty; otherwise, the last saved file will still be
                            // dangling and interphere with the save operation (trying to resave the same file - a duplicate).
                            if($scope.originalExistingFiles && $scope.row.originalExistingFiles)
                                $scope.originalExistingFiles.FieldSheetFile = $scope.row.originalExistingFiles.FieldSheetFile = undefined;

                            $scope.row.fieldFilesToUpload = [];

                            // If this is not set to undefined, it will incorrectly register an empty FieldSheetFile as 1,
                            // and cause problems during the save process.
                            $scope.filesToUpload = undefined;

                            // This pops the Save Success modal after Add Section.
                            var modalInstance = $uibModal.open({
                                templateUrl: 'appjsLegacy/core/common/components/modals/templates/modal-save-success.html',
                                controller: 'ModalSaveSuccess',
                                scope: $scope, //very important to pass the scope along...
                                backdrop: "static",
                                keyboard: false
                            });

                            // Set this back to false; otherwise, if the user clicks Add Section a boult times, 
                            // then clicks Save and Close, this will still be set to true.

                            // CD 09/22/2023 - We can't set this to false here, because this fires before the modal-save-status
                            // runs. Because of this, it will never reload the form for Creel. Moving to modal-save-status.ts (Line 62)
                            //$scope.background_save = false;
                            //console.log("Line 1484: $scope.background_save = " + $scope.background_save);
                        }
                    }
                },
                function (data) {
                    $scope.saveResult.success = false;
                    console.log("Failure!");
                    console.dir(data);
                    $scope.saveResult.saving = false;
                    let theErrorText: any;
                    let strAdminError: any;
                    if (typeof data.data !== 'undefined') {
                        //if (typeof data.data.ExceptionMessage !== 'undefined') {
                        if (typeof data.data.InnerException.ExceptionMessage !== 'undefined') {

                            strAdminError = theErrorText = data.data.InnerException.ExceptionMessage + " >>> " + data.data.InnerException.ExceptionType;
                            if (typeof data.data.InnerException.StackTrace != 'undefined')
                            {
                                let strError = data.data.InnerException.StackTrace;
                                let intStartPoint = strError.indexOf("services\\");
                                let intLineNumber = strError.indexOf("cs:line");
                                let intEndPoint = intLineNumber + 20;

                                // If the string has a line number in it, we need to grab some extra characters, to allow for the added line number text.
                                if (intStartPoint > -1)
                                {
                                    strError = strError.substring(intStartPoint, intEndPoint);
                                    strAdminError += " >>> " + strError + "...";  

                                    // We only send all the info to the screen, if the user is an admin.
                                    if (AdminUserIds.includes($scope.userId))
                                    {
                                        theErrorText = strAdminError;
                                    }
                                }

                            }
                            // We want to send all the info to the debug console, whether they are admin or not.
                            console.error("Save error:  strAdminError = " + strAdminError);
                        }
                        else {

                            theErrorText = data.data;

                            var titleStartLoc = theErrorText.indexOf("<title>") + 7;
                            console.log("titleStartLoc = " + titleStartLoc);

                            var titleEndLoc = theErrorText.indexOf("</title>");
                            console.log("titleEndLoc = " + titleEndLoc);

                            theErrorText = theErrorText.substr(titleStartLoc, titleEndLoc - titleStartLoc);
                        }
                    }

                    $scope.saveResult.error = "There was a problem saving your data (" + theErrorText + ").  Please try again or contact support.";
                }
            );
        };

        $scope.doneButton = function () {
            $scope.activities = undefined;
            $location.path("/" + $scope.dataset.activitiesRoute + "/" + $scope.dataset.Id);
        };

        $scope.DelayEnablingButtons = function () {
            // This gets called from $scope.modalFile_saveParentItem, shortly after passing data do the backend,
            // via DatasetService.saveActivities, or DatasetService.updateActivities.
            // We want to delay enabling some buttons at the bottom of the form; otherwise, 
            // if we enable them too early, it is confusing to the user.

            //console.log("$scope.saveResult.saving = " + $scope.saveResult.saving);
            if ((!$scope.background_save) && ($scope.saveResult.saving != true)) // This gets set to true in function $scope.addSection; otherwise, it is false.
            {
                $scope.saveResult.success = "Save successful.";
            }

            $scope.background_save = false; //reset

            $scope.saveResult.error = false;
            //log success
            console.log("Success!");
            $scope.saveResult.saving = false;

            $scope.$apply();
        };

        // var saveResultWatcher = $scope.$watch('saveResult', function () {
        //     // If the save action is still running, we don't want to do anything.
        //     if ($scope.saveResult.saving == true)
        //         return;

        //     if ($scope.saveResult.success)
        //     {
        //         $scope.saveResult.success = "Save successful.";
        //         console.log("Success!");

        //         $scope.saveResult.error = false;
        //         console.log("Success!");
        //         $scope.saveResult.saving = false;
                
        //         saveResultWatcher();
        //     }
        //     else
        //     {
        //         $scope.saveResult.error = false;
        //         console.log("Failed!");
        //         $scope.saveResult.saving = false;

        //         //saveResultWatcher();
        //     }

        //     $scope.background_save = false; //reset

        //     $scope.$apply();
        // });
    }
];

export default dataset_edit_form;




