//CONFIGURATION

//CDMS - Centralized Data Management System
//Written by Ken Burcham, George Clark, Colette Coiner
//Instructions on getting started can be found here: https://github.com/CTUIR/cdms-docs/wiki/Getting-Started

/*

This system is (C) 2014, 2019 by the Confederated Tribes of the Umatilla Indian Reservation.
Any use is subject to our license agreement included with this code.
A copy is currently located here: https://github.com/CTUIR/cdms-dev/blob/master/LICENSE

THE CDMS AND COVERED CODE IS PROVIDED UNDER THIS LICENSE ON AN "AS IS" BASIS,
WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
WARRANTIES THAT THE COVERED CODE IS FREE OF DEFECTS, MERCHANTABLE, FIT FOR A PARTICULAR
PURPOSE OR NON-INFRINGING. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE COVERED CODE
 IS WITH LICENSEE. SHOULD ANY COVERED CODE PROVE DEFECTIVE IN ANY RESPECT, LICENSEE (NOT THE CTUIR
 OR ANY OTHER CONTRIBUTOR) ASSUMES THE COST OF ANY NECESSARY SERVICING, REPAIR OR CORRECTION.
 THIS DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS LICENSE. NO USE OF
  ANY COVERED CODE IS AUTHORIZED HEREUNDER EXCEPT UNDER THIS DISCLAIMER.

*/


//  This file contains all of the various config values.
//  Many of these items will need to be configured when you implement this system
//  in a new environment.

import {LicenseManager} from "ag-grid-enterprise";

export let CODE_BASE = 'ctuir';
export let ENVIRONMENT = 'prod'; // dev, test, or prod
export let DISPLAY_NAME = 'PALUUT 2';
export let CURRENT_VERSION = '2.0';
export let PUBLIC_OR_PRIVATE = 'public'; // Paluut = public, CDMS_Priv = private.
export let HOST_SERVER = 'null';

//Change these to point to your own webserver directories
export let serverUrl = 'https://paluut.ctuir.org';       //location of cdms-dev files (Javascript+HTML application)
export let serviceUrl = serverUrl + '/services'; //location of cdms-services deployment (REST Services)
export let cdmsShareUrl = serviceUrl + '/uploads/';  // Location of project/dataset/subproject files
export let proxyUrl = serverUrl + '/DotNet/proxy.ashx';
export let ANALYTICS_CODE = 'UA-105142901-1'; //this should be your own analytics code for google analytics.

export let REPORTSERVER_URL = 'https://paluutreports.ctuir.org/Reports'; //the Datastore "name" will be appended here, so make sure your report server folders are named the same as your datastore
export let PROJECT_REPORT_URL = 'https://paluutreports.ctuir.org/ReportServer/Pages/ReportViewer.aspx?%2fQuadReport_Prototype%2fQuadReport_Single&rs:Command=Render&Id='; //this is the report called from the "Quad REport" button on the project view page
export let CDMS_DOCUMENTATION_URL = "https://gis.ctuir.org/wp-content/uploads/2019/03/CDMSVersion2UserGuide.pdf";
export let CTUIR_AGOL_URL = "https://ctuirgis.maps.arcgis.com/home/gallery.html?view=grid&sortOrder=true&sortField=relevance";
export let CTUIR_GIS_URL = "https://gis.ctuir.org";
export let GIS_TAX_PARCELS_URL = "https://gisportal.ctuir.org/portal/apps/webappviewer/index.html?id=54fd175f4e2745ff92b278d04b987ad9";
export let TPO_REPORTS_URL = 'https://paluutreports.ctuir.org/Reports/browse/TPO';
export let YOUR_FEATURE_SERVICE_DOMAIN = "restdata.ctuir.org";
export let ESRI_AGOL_URL = 'null';
export let NUM_FLOAT_DIGITS = 3;
export let SystemDefaultColumnWidth = 150; //default column width for ag-grid
export let AdminUserIds = [1081,2,4247];

// For Dev/Test environments
export let CDMS_POINTS_TEST_URL = "https://restdata.ctuir.org/server/rest/services/CDMS_Points_TEST/FeatureServer/0";
// For Dev/Test environment
export let CDMS_POINTS_PROD_URL = "https://restdata.ctuir.org/server/rest/services/CDMS_ProjectPoints/FeatureServer/0";

//const AG_GRID_LICENSE = "CompanyName=Confederated Tribes of the Umatilla Indian Reservation,LicensedApplication=CDMS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-015120,ExpiryDate=29_May_2022_[v2]_MTY1Mzc3ODgwMDAwMA==e10e92e983bf16f828e8482a29ee0184";
//export let AG_GRID_LICENSE = "CompanyName=Confederated Tribes of The Umatilla Indian Reservation,LicensedApplication=CDMS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-027270,SupportServicesEnd=1_July_2023_[v2]_MTY4ODE2NjAwMDAwMA==c3d7750e2cac72dfc40ffddd542d7e87";
export let AG_GRID_LICENSE = "Using_this_AG_Grid_Enterprise_key_( AG-042094 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Confederated Tribes of The Umatilla Indian Reservation )_is_granted_a_( Single Application )_Developer_License_for_the_application_( CDMS )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( CDMS )_need_to_be_licensed___( CDMS )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 30 June 2024 )____[v2]_MTcxOTcwMjAwMDAwMA==ca87f515bbea573673274a76316d1128";

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

//GIS defaults - change these to point to your own ArcGIS server
export let GEOMETRY_SERVICE_URL = "https://restdata.ctuir.org/server/rest/services/Utilities/Geometry/GeometryServer";

export let NAD83_SPATIAL_REFERENCE = 'PROJCS["NAD83(NSRS2007) / UTM zone 11N",GEOGCS["NAD83(NSRS2007)",DATUM["D_",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-117],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["Meter",1]]';
export let DEFAULT_LOCATION_PROJECTION_ZONE = {
                Projection: "NAD83",
                UTMZone: "11",
            };   //Used as default when creating a new location

export let BING_KEY = "AuaAtT6zhx1rfuP1hR_e0zh5pxy6u7Echhe9KbwfCcQRG_Y_ewSg5OcFDm-DF-8w"; //CTUIR's bing key -- only licensed from our server, so get a public/free one and use it
//export let DEFAULT_BASEMAP = "BingMapsRoad";
export let SDE_FEATURECLASS_TAXLOTQUERY = 4;

export let MAX_DATASET_RECORDS_THRESHOLD = 300000;

//database index constant values
// NOTE: you may need to change these to match index values in your own database
export let DEFAULT_PROJECT_LOCATION_TYPE = 3; // Primary Project Location, from table LocationTypes
export let PRIMARY_PROJECT_LOCATION_TYPEID = 3;
export let LOCATION_TYPE_Hab = 118;
export let LOCATION_TYPE_APPRAISAL = 8;

export let DATASTORE_ACTIVITYSYSTEMFIELDS = 23; //id for activitysystemfields datastore
export let SYSTEM_LOCATION_DATASET = 1262; //id for systemlocation dataset

//metadata entities and properties
export let METADATA_ENTITY_CORRESPONDENCE = 7;
export let METADATA_ENTITY_HABSITES = 8;
export let METADATA_ENTITY_PROJECT = 1;
export let METADATA_ENTITY_HABITAT = 2;
export let METADATA_ENTITY_DATASETTYPEID = 5;
export let METADATA_PROPERTY_PROGRAM = 23;
export let METADATA_PROPERTY_SUBPROGRAM = 24;
export let METADATA_PROPERTY_MAPIMAGE = 26;
export let METADATA_PROPERTY_MAPIMAGE_HTML = 25;
export let METADATA_PROPERTY_SUMMARYIMAGE = 11;
export let METADATA_PROPERTY_SUMMARYIMAGE_HTML = 13;
export let METADATA_ENTITY_OLCEVENTS = 11;


//datasets that are lookups/lists
export let FISHERMEN_DATASETID = 1275;
export let SEASONS_DATASETID = 1276;

//PERMITS
export let PERMIT_DATASETID = 1281;
export let PERMITEVENTS_DATASETID = 1282;
export let PERMIT_PROJECTID = 11044;
export let PERMITCONTACTS_DATASETID = 1284;
export let METADATA_PROPERTY_PERMIT_FILETYPES = 64;
export let PERMIT_WRP_DATASETID = 1329;

//EHS
export let EHS_DATASETID = 1294;
export let EHS_PROJECTID = 11049;
export let EHS_EVENTS_DATASETID = 1295;
export let EHS_CODES_DATASETID = 1297;

//BOT dashboard - runs on private, but here for consistency
export let PROJECT_TYPE_BOTACTIONS = 17;
export let PROJECT_TYPE_BOTPROJECTS = 18;
export let PROJECT_TYPE_OEDPROJECTS = 19;

//Default system-wide project permission
export let DEFAULT_PROJECT_PERMISSION = {Permission: { OrgUser: "View", Public: "Hidden" }};

// Arbitrary values for testing leasing
//export let METADATA_PROPERTY_LEASING_LEASETYPES = 1;
//export let METADATA_PROPERTY_LEASING_PRODUCTIONDELIVERY = 1;
//export let METADATA_PROPERTY_LEASING_CROPOPTIONS= 1;
//export let METADATA_PROPERTY_LEASING_WEEDS= 1;
//export let METADATA_PROPERTY_LEASING_RESIDUETYPES= 1;
//export let METADATA_PROPERTY_LEASING_ANIMALTYPES= 1;
//export let METADATA_PROPERTY_LEASING_SUBPRACTICES= 1;
//export let METADATA_PROPERTY_LEASING_PRODUCTIONTYPES= 1;
//export let METADATA_PROPERTY_LEASING_OPSTATES= 1;
//export let METADATA_PROPERTY_LEASING_OPCITIES= 1;
//export let METADATA_PROPERTY_LEASING_COMPLIANCE_INSP_TYPES= 1;
//export let METADATA_PROPERTY_LEASING_VIOLATION_TYPES= 1;
//export let METADATA_PROPERTY_LEASING_INSPECTIONVIOLATIONS= 1;
//export let METADATA_PROPERTY_LEASING_SYSTEM_LASTLEASENUMBER= 1;

export let METADATA_ENTITY_LEASING = 9;
export let METADATA_ENTITY_LEASING_SYSTEM = 10;
export let METADATA_PROPERTY_LEASING_SYSTEM_LASTLEASENUMBER = 63;
export let METADATA_PROPERTY_LEASING_LEASETYPES = 60;
export let METADATA_PROPERTY_LEASING_PRODUCTIONDELIVERY = 59;
export let METADATA_PROPERTY_LEASING_INSPECTIONVIOLATIONS = 58;
export let METADATA_PROPERTY_LEASING_PRODUCTIONTYPES = 57;
export let METADATA_PROPERTY_LEASING_ANIMALTYPES = 56;
export let METADATA_PROPERTY_LEASING_SUBPRACTICES = 55;
export let METADATA_PROPERTY_LEASING_RESIDUETYPES = 54;
export let METADATA_PROPERTY_LEASING_WEEDS = 53;
export let METADATA_PROPERTY_LEASING_CROPOPTIONS = 52;
export let METADATA_PROPERTY_LEASING_OPSTATES = 62;
export let METADATA_PROPERTY_LEASING_OPCITIES = 61;
export let METADATA_PROPERTY_LEASING_COMPLIANCE_INSP_TYPES = 65;
export let METADATA_PROPERTY_LEASING_VIOLATION_TYPES = 66;

//export let METADATA_ENTITY_OLCEVENTS = 1;

//export let METADATA_ENTITY_LEASING_SYSTEM= 1;
//export let METADATA_ENTITY_LEASING= 1;

export let LEASE_STATUS_ACTIVE= 1;
export let LEASE_STATUS_PENDING= 3;
export let LEASE_STATUS_CANCELLED= 2;


//probably don't need to change anything below this line --------------------------------------

//constants
export let FIELD_ROLE_HEADER = 1;
export let FIELD_ROLE_DETAIL = 2;
export let FIELD_ROLE_SUMMARY = 3;
export let FIELD_ROLE_CALCULATED = 4;
export let FIELD_ROLE_VIRTUAL = 5;
export let USER_PREFERENCE_LANDINGPAGE = "LandingPage"; //Name of UserPreference that contains a user's landing page (if specified)

export let profile = null;
export let LOGIN_URL = serverUrl + '/login.html';
export let WHOAMI_URL = serviceUrl + '/api/v1/account/whoami';
export let date_pattern = "/[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}/";

//login-controller
//export let successUrl = serverUrl + '/index.html';
export let successUrl = 'dashboard';
export let loginUrl = serverUrl + '/login';


//import-controller uses these constants
// note: we did have to hard-code these on the dataset-import.html page in ng-disabled attributes
export let DO_NOT_MAP = 0;
export let ACTIVITY_DATE = 1;
export let INDEX_FIELD = 2;
export let LOCATION_ID = 2;
export let ROW_QA_STATUS_ID = 3;
export let FISHERMAN = 3;

export let MAP_DO_NOT_MAP_VALUE = "[-- Do not map --]";  //new ones
export let MAP_ACTIVITY_DATE = "[-- Activity Date --]";
export let MAP_LOCATION = "[-- Location --]";

export let SHARINGLEVEL_PRIVATE = 1;
export let SHARINGLEVEL_GROUPREAD = 2;
export let SHARINGLEVEL_PUBLICREAD = 3;
export let SHARINGLEVEL_GROUPWRITE = 4;
export let SHARINGLEVEL_PUBLICWRITE = 5;
export let SHARINGLEVEL_REFERENCE_LIBRARY = 6;

export let SharingLevel = {
    'SHARINGLEVEL_PRIVATE' : "CDMS Only",
    'SHARINGLEVEL_PUBLICREAD' : "Share to web",
    'SHARINGLEVEL_GROUPREAD' : "Collaborators",
    'SHARINGLEVEL_REFERENCE_LIBRARY' : "Reference Library"
};

export let DEFAULT_IMPORT_QACOMMENT = "Initial Import";

export let ROWSTATUS_ACTIVE = 0;
export let ROWSTATUS_DELETED = 1;

//System Timezones
export let millis_per_h = 3600000;
export let SystemTimezones = [
  {
	"Id" : 0,
    "Name" : "Pacific Standard Time (GMT-08:00)",
    "Description" : "(GMT-08:00) Pacific Standard Time (US & Canada)",
    "TimezoneOffset" : -8 * millis_per_h,
  },
  {
	"Id" : 1,
    "Name" : "Pacific Daylight Time (GMT-07:00)",
    "Description" : "(GMT-07:00) Pacific Daylight Time (US & Canada)",
    "TimezoneOffset" : -7 * millis_per_h,
  },
  //{
  //  "Name" : "Mountain Standard Time (GMT-07:00)",
  //  "Description" : "(GMT-07:00) Mountain Standard Time (US & Canada)",
  //  "TimezoneOffset" : -7 * millis_per_h,
  //},
  //{
  //  "Name" : "Central Standard Time",
  //  "Description" : "(GMT-06:00) Central Standard Time (US & Canada)",
  //  "TimezoneOffset" : -6 * millis_per_h,
  //},
  //{
  //  "Name" : "Eastern Standard Time",
  //  "Description" : "(GMT-05:00) Eastern Standard Time (US & Canada)",
  //  "TimezoneOffset" : -5 * millis_per_h,
  //},
  //{
  //  "Name" : "Mountain Daylight Time (GMT-06:00)",
  //  "Description" : "(GMT-06:00) Mountain Daylight Time (US & Canada)",
  //  "TimezoneOffset" : -6 * millis_per_h,
  //},

  {
	"Id" : 7,
    "Name" : "Greenwich Mean Time (GMT-00:00)",
    "Description" : "(GMT-00:00) Greenwich Mean Time",
    "TimezoneOffset" : 0,
  },


  //{
  //  "Name" : "Central Daylight Time",
  //  "Description" : "(GMT-05:00) Central Daylight Time (US & Canada)",
  //  "TimezoneOffset" : -5 * millis_per_h,
  // }, {
  //  "Name" : "Eastern Daylight Time",
  //  "Description" : "(GMT-04:00) Eastern Daylight Time (US & Canada)",
  //  "TimezoneOffset" : -4 * millis_per_h,
  //}
];

//Data Grade Methods -- shows up as a select list in accuracy check
export let DataGradeMethods = [];
DataGradeMethods.push(""); // 0
DataGradeMethods.push("NIST check in BOTH warm and ice baths");
DataGradeMethods.push("NIST check in ice bath only");
DataGradeMethods.push("Manufacturer Calibration");
DataGradeMethods.push("No Accuracy Check Conducted");
DataGradeMethods.push("Unknown Accuracy Method");

//Appraisal Map config
//export let security_token = "XHJGK7m0g3_RwZv9Z57OErIXkE-1KwT3nyvuuxZgZnROXr55r0q87YpUAY1m-bEMVAlkj2fZidrWZwCke9gwlA.."; // updated 4/19/2017 https://cdms.ctuir.org
export let security_token = "x3X0E2fiVdWmuLupSgGk3Moeg075l-CO6xpTQ9mkdQDLbLEGcGGdX64GxOgWKZLc8lHHejhpPTDBMfSBVq-0iA..";
export let defaultLayer = "imageryLayer";

//used for dataset-activities (standard datasets)
export let datasetActivitiesBasemapConfig = {
       // { library: "Esri", type: 'streets', title: 'ESRI Roads'},
        //{ library: "Esri", type: 'topo', title: 'Topographical'},
        //{ library: "Esri", type: 'hybrid', title: 'Hybrid' },

		// We had problems with Bing, down below, so we switched back to the ESRI map.
		// Note:  The roadsLayer, imageryLayer, and hybridLayer constants are not in the ESRI map,
		// but we use the library to tell which map service to call ~/js/directives/Maps.js
        roadsLayer: { library: "Esri", type: "//www.arcgis.com/home/webmap/viewer.html?url=http%3A%2F%2Fserver.arcgisonline.com%2Farcgis%2Frest%2Fservices%2FWorld_Street_Map%2FMapServer&source=sd", Display: 'Roads' },
        imageryLayer: { library: "Esri", type: "//server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer", Display: 'Aerial' },

		// The Bing map is better, but we had problems with it.
        //roadsLayer: { library: "Bing", type: 'BingMapsRoad', Display: 'Roads' },
        //imageryLayer: { library: "Bing", type: 'BingMapsAerial', Display: 'Aerial' },
        //hybridLayer: { library: "Bing", type: 'BingMapsHybrid', Display: 'Hybrid' },
};


//This is the list of Basemaps that show up on the Appraisals custom dataset page --- Private side only
export let parcelLayerConfig =
      {
        taxParcelLayer: {
          Display: "Tax Parcel",
          QueryURL: "https://restdata.ctuir.org/server/rest/services/TaxParcelQueryCTUIR/MapServer/0",//?token=" + security_token,
          ServiceURL: "https://restdata.ctuir.org/server/rest/services/BasemapParcelViewerCTUIR/MapServer",//?token=" + security_token,
          OutFields: "PARCELID, Address",
          isAddressSearchService: true,
          ParcelQuery: "PARCELID LIKE '%${0}%' OR ALLOTMENT LIKE '%${0}%' OR ADDRESS LIKE '%${0}%'",
          LocateParcelQuery: "PARCELID = '${0}' OR ALLOTMENT = '${0}'",
          DisplayFields: ["PARCELID", "Address"],
          UseColor: true,
          objectIDField: "PARCELID",
          Color: "#FF6600",
          Alpha: 0.25,
        },
      imageryLayer: {
          Display: "Imagery",
          ServiceURL: "https://restdata.ctuir.org/server/rest/services/BasemapImagery/MapServer",//?token=" + security_token,
          QueryURL: "https://restdata.ctuir.org/server/rest/services/Parcels_LIVE/MapServer/0",
          OutFields: "PARCELID, Address",
          isAddressSearchService: true,
          ParcelQuery: "PARCELID LIKE '%${0}%'",
          LocateParcelQuery: "PARCELID = '${0}'",
          DisplayFields: ["PARCELID", "Address"],
          UseColor: true,
          objectIDField: "PARCELID",
          Color: "#FF6600",
          Alpha: 0.25,
        },

      platLayer: {
          Display: "Plat",
          QueryURL: "https://restdata.ctuir.org/server/rest/services/TaxParcelQueryCTUIR/MapServer/0",//?token=" + security_token,
          ServiceURL: "https://restdata.ctuir.org/server/rest/services/DECD/BasemapsDECD_Plat/MapServer",//?token=" + security_token,
          OutFields: "PARCELID, Address",
          isAddressSearchService: true,
          ParcelQuery: "PARCELID LIKE '%${0}%' OR ALLOTMENT LIKE '%${0}%' OR ADDRESS LIKE '%${0}%'",
          LocateParcelQuery: "PARCELID = '${0}' OR ALLOTMENT = '${0}'",
          DisplayFields: ["PARCELID", "Address"],
          UseColor: true,
          objectIDField: "PARCELID",
          Color: "#FF6600",
          Alpha: 0.25,
        },
      soilLayer: {
          Display: "Soil",
          QueryURL: "https://restdata.ctuir.org/server/rest/services/TaxParcelQueryCTUIR/MapServer/0",//?token=" + security_token,
          ServiceURL: "https://restdata.ctuir.org/server/rest/services/DECD/BasemapsDECD_Soils/MapServer",//?token=" + security_token,
          OutFields: "PARCELID, Address",
          isAddressSearchService: true,
          ParcelQuery: "PARCELID LIKE '%${0}%' OR ALLOTMENT LIKE '%${0}%' OR ADDRESS LIKE '%${0}%'",
          LocateParcelQuery: "PARCELID = '${0}' OR ALLOTMENT = '${0}'",
          DisplayFields: ["PARCELID", "Address"],
          UseColor: true,
          objectIDField: "PARCELID",
          Color: "#FF6600",
          Alpha: 0.25,
        },
      topoLayer: {
          Display: "Topography",
          QueryURL: "https://restdata.ctuir.org/server/rest/services/TaxParcelQueryCTUIR/MapServer/0",//?token=" + security_token,
          ServiceURL: "https://restdata.ctuir.org/server/rest/services/DECD/BasemapsDECD_Topo/MapServer",//?token=" + security_token,
          OutFields: "PARCELID, Address",
          isAddressSearchService: true,
          ParcelQuery: "PARCELID LIKE '%${0}%' OR ALLOTMENT LIKE '%${0}%' OR ADDRESS LIKE '%${0}%'",
          LocateParcelQuery: "PARCELID = '${0}' OR ALLOTMENT = '${0}'",
          DisplayFields: ["PARCELID", "Address"],
          UseColor: true,
          objectIDField: "PARCELID",
          Color: "#FF6600",
          Alpha: 0.25,
        },
      zoneLayer: {
          Display: "Zoning",
          QueryURL: "https://restdata.ctuir.org/server/rest/services/TaxParcelQueryCTUIR/MapServer/0",//?token=" + security_token,
          ServiceURL: "https://restdata.ctuir.org/server/rest/services/BasemapZoningParcels/MapServer",//?token=" + security_token,
          OutFields: "PARCELID, Address",
          isAddressSearchService: true,
          ParcelQuery: "PARCELID LIKE '%${0}%' OR ALLOTMENT LIKE '%${0}%' OR ADDRESS LIKE '%${0}%'",
          LocateParcelQuery: "PARCELID = '${0}' OR ALLOTMENT = '${0}'",
          DisplayFields: ["PARCELID", "Address"],
          UseColor: true,
          objectIDField: "PARCELID",
          Color: "#FF6600",
          Alpha: 0.25,
        },

      };

//This is the list of services that show up in the layers selector for the custom Appraisals webapp.
export let servicesLayerConfig =
{
  streamBuffers: {
    Display: "Stream Buffers",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/StreamBuffers/MapServer",//?token=" + security_token,
  },
  utilityLines: {
    Display: "Utilities",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/UtilityLines/MapServer",//?token=" + security_token,
  },
  waterSewer: {
    Display: "Water / Sewer",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/WaterSewer/MapServer",//?token=" + security_token,
  },
  roads: {
    Display: "Roads",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/Roads/MapServer",//?token=" + security_token,
  },
  range: {
    Display: "Grazing Range",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/RangeUnits/MapServer",//?token=" + security_token,
  },
  forest: {
    Display: "Timber Stands",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/ForestStands/MapServer",//?token=" + security_token,
  },
 parcels: {
        Display: "Parcels",
        ServiceURL: "https://restdata.ctuir.org/server/rest/services/Parcels_LIVE/MapServer/0",
    },
  parcels_outline: {
    Display: "ParcelOutline",
    ServiceURL: "https://restdata.ctuir.org/server/rest/services/Parcels_outline/MapServer",
  }
};

export let PARCEL_LAYER = "https://restdata.ctuir.org/server/rest/services/Parcels_LIVE/MapServer/0";
export let ZONING_LAYER = "https://restdata.ctuir.org/server/rest/services/Zoning/MapServer/4";
//export let MapPointsLayer = "https://restdata.ctuir.org/server/rest/services/CDMS_ProjectPoints/FeatureServer/0";

//analytics configuration
declare const ga: any;
// @ts-ignore
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},
  // @ts-ignore
  i[r].l=1*new Date();
  a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
ga('create', ANALYTICS_CODE, 'auto');
ga('send', 'pageview');
