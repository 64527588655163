//modal to choose project config lists
var modal_permission_change_owner = ['$scope', '$uibModal','$uibModalInstance','ProjectService','CommonService',

    function ($scope, $uibModal, $uibModalInstance, ProjectService, CommonService) {

        $scope.save = function () {

            var save_project = ProjectService.changeProjectOwner($scope.project.Id, $scope.newOwner.Id);

            save_project.$promise.then(function(){
                $scope.SaveMessage = "Success";
                $uibModalInstance.close(save_project);
            }, function(){
                $scope.SaveMessage = "An error occurred.";
            })

        };

        $scope.cancel = function () {
            $scope.project.Config.Lookups = $scope.savedLookups;
            $uibModalInstance.dismiss();
        };

    }
];

export default modal_permission_change_owner;
