// defines the preferences module
import * as angular from 'angular';
// require([
//load components
import my_preferences from "./components/my-preferences/my-preferences";
import dataset_preferences from "./components/dataset-preferences/dataset-preferences";
import project_preferences from "./components/project-preferences/project-preferences";
import landing_page from "./components/landing-page/landing-page";
import user_analytics from "./components/analytics/user-analytics";

//load preference service
import user_service from "./user-service";
import {serviceUrl} from "../../config";

//add the controllers and services to the module once the files are loaded!
const user_module = angular.module('UserModule', ['ui.bootstrap', 'ngResource']);
//controllers
user_module.controller('MyPreferencesCtrl', my_preferences);
user_module.controller('MyDatasetsCtrl', dataset_preferences);
user_module.controller('MyProjectsCtrl', project_preferences);
user_module.controller('UserAnalyticsCtrl', user_analytics);
user_module.controller('LandingPage', landing_page);


user_module.factory('SaveUserInfo', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/saveuserinfo');
}]);

user_module.factory('GetMyProjectsAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getmyprojects', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('GetMyProjectsList', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getmyprojectslist', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('SaveUserPreferenceAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/saveuserpreference', {}, {
    save: {method: 'POST', isArray: false}
  });
}]);

user_module.factory('GetMyDatasetsAction', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getmydatasets', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('GetMyDatasetsList', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/getmydatasetslist', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('GetMyLastUpdatedDatasets', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/user/GetMyLastUpdatedDatasets', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

//analytics

user_module.factory('UserLoginsPastMonth', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/analytics/UserLoginsPastMonth', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);


user_module.factory('UserRequestsTotalPastMonth', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/analytics/UserRequestsTotalPastMonth', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('DatasetRequestsTotalPastMonth', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/analytics/DatasetRequestsTotalPastMonth', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('LastUpdatedDatasets', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/analytics/LastUpdatedDatasets', {}, {
    query: {method: 'GET', params: {}, isArray: true}
  });
}]);

user_module.factory('SearchProjects', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/search/searchprojects', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

user_module.factory('SearchDatasets', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/search/searchdatasets', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

user_module.factory('SearchFiles', ['$resource', function ($resource) {
  return $resource(serviceUrl + '/api/v1/search/searchfiles', {}, {
    save: {method: 'POST', isArray: true}
  });
}]);

user_module.service('UserService', user_service);

export default user_module;


