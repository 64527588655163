//modal to edit org/group
var modal_admin_item = ['$scope', '$uibModal','$uibModalInstance','AdminService',

    function ($scope, $uibModal, $uibModalInstance, AdminService) {


        $scope.save = function () {

            if($scope.itemType == "Group"){
                $scope.item.OrganizationId = $scope.orgGrid.selectedItem.Id;
                var saved_item = AdminService.saveGroup($scope.item);
                saved_item.$promise.then(function () {
                    saved_item.Members = [];
                    $uibModalInstance.close(saved_item);
                }, function (error) {
                    $scope.ModalErrorMessage = "Error: " + error.data.ExceptionMessage;
                });
            }

            if($scope.itemType == "Department"){
                $scope.item.OrganizationId = $scope.orgGrid.selectedItem.Id;
                var saved_item = AdminService.saveDepartment($scope.item);
                saved_item.$promise.then(function () {
                    $uibModalInstance.close(saved_item);
                }, function (error) {
                    $scope.ModalErrorMessage = "Error: " + error.data.ExceptionMessage;
                });
            }

            if($scope.itemType == "Organization"){
                var saved_item = AdminService.saveOrganization($scope.item);
                saved_item.$promise.then(function () {
                    $uibModalInstance.close(saved_item);
                }, function (error) {
                    $scope.ModalErrorMessage = "Error: " + error.data.ExceptionMessage;
                });
            }


        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

    }
];

export default modal_admin_item;
