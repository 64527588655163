//modal to add/edit permit event
import * as angular from 'angular';
import * as moment from 'moment';
import {modalFiles_setupControllerForFileChooserModal} from "../../../../core/common/components/file/modal-files";
import {PERMIT_PROJECTID} from "../../../../config";

var requests_modal = ['$rootScope','$scope', '$uibModal','$uibModalInstance','GridService','Upload','PermitService',

    function ($rootScope, $scope, $uibModal, $uibModalInstance, GridService, $upload, PermitService) {

        $scope.permit = $scope.row;
        $scope.row = $scope.activity_modal; //note: this creates a LOCAL scope variable of ROW that will go away when this scope goes away...

        $scope.Results = {
            SuccessMessage: null,
            FailureMessage: null,
            DoneSaving: false,
        };

        $scope.mode = "edit"; //we only edit

        if(!$scope.row.ResponseDate)
            $scope.row.ResponseDate = moment().format('L');

        if(!$scope.row.Reviewer)
            $scope.row.Reviewer = $scope.Profile.Fullname;

        $scope.permitEventsGrid.columnDefs.forEach(function (coldef) {
            if (coldef.DbColumnName == 'Reference')
                coldef.Label = "Reference";

            if (coldef.DbColumnName == 'RequestDate')
                coldef.Label = "Date Requested";
        });



        if (!$scope.row.Files)
            $scope.row.Files = [];

        //set up for attaching files - just make sure the permitfiles are loaded.
        $scope.PermitFiles.$promise.then(function(){
            modalFiles_setupControllerForFileChooserModal($scope, $uibModal, $scope.PermitFiles);
        });

        $scope.save = function () {

            $scope.Results.DoneSaving = false;
            $scope.Results.IsSaving = true;

            $scope.row.ByUser = $scope.Profile.Id;

            var target = '/api/v1/permit/uploadfile';

            var data = {
                ProjectId: PERMIT_PROJECTID,
                SubprojectId: $scope.row.PermitId,
                ItemId: $scope.row.Id
            };

            if (Array.isArray($scope.row.Files)) {
                if ($scope.row.Files.length == 0)
                    delete $scope.row.Files;
                else
                    $scope.row.Files = angular.toJson($scope.row.Files);
            }

            var to_save = angular.copy($scope.row);

            if($scope.row.FilesToInclude){
                var new_files = [];
                $scope.row.FilesToInclude.forEach(function(file){
                    console.dir(file);
                    file = angular.fromJson(file);
                    delete file.User;
                    new_files.push(file);
                })
                to_save.FilesToInclude = new_files;
            }else{
                to_save.FilesToInclude = [];
            }

            $scope.handleFilesToUploadRemove($scope.row, data, target, $upload, $rootScope, $scope); //when done (handles failed files, etc., sets in scope objects) then calls modalFiles_saveParentItem below.

        };

        //callback that is called from modalFile to do the actual file removal (varies by module)
        $scope.modalFile_doRemoveFile = function (file_to_remove, saveRow) {
            //console.dir(file_to_remove);
            return PermitService.deletePermitFile(PERMIT_PROJECTID, $scope.row.PermitId, saveRow.Id, file_to_remove);
        };

        //call back from save above once the files are done processing and we're ready to save the item
        $scope.modalFile_saveParentItem = function (saveRow) {

            //save again to update with the files we uploaded
            $scope.saved_event = PermitService.savePermitEvent(saveRow);

            $scope.saved_event.$promise.then(function () {

                $scope.Results.DoneSaving = true;

                console.log("done and success updating the files");

                $scope.Results.SuccessMessage = "Saved.";

                //note: we don't close - let the user do that...

            }, function (data) {
                console.error("failure!");
                console.dir(data);
                $scope.Results.FailureMessage = "There was a problem saving or sending notifications.";
                $scope.Results.DoneSaving = false;
            });

        };

        $scope.close = function () {
            $uibModalInstance.close($scope.saved_event);
        };

        //fire validation for all columns when we load (if we are editing)
        if ($scope.mode === 'edit') {
            $scope.permitEventsGrid.columnDefs.forEach(function (field) {
                $scope.onHeaderEditingStopped(field);
            });
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };


        $scope.getFileLabel = function(file){
            return file.Name + ((file.Description) ? " ("+file.Description+")" : "");
        }

}];

export default requests_modal;
