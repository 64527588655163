//modal to edit group members
var modal_admin_group_members = ['$scope', '$uibModal','$uibModalInstance','AdminService','CommonService',

    function ($scope, $uibModal, $uibModalInstance, AdminService, CommonService) {


        $scope.users = CommonService.getOrgUsers($scope.group.OrganizationId);

        $scope.add = function () {

            if($scope.alreadyMember($scope.group, $scope.userId))
                return;

			var saved_member = AdminService.saveGroupMember($scope.group.Id, $scope.userId);
            saved_member.$promise.then(function () {
                $scope.group.Members.push(saved_member);
            }, function (error) {
                $scope.SaveMessage = "Error: " + error.data.ExceptionMessage;
            });

        };

        $scope.close = function () {
            $uibModalInstance.close();
        };

        $scope.removeMember = function(userId) {
            var removed_member = AdminService.removeGroupMember($scope.group.Id, userId);

            removed_member.$promise.then(function(){
                $scope.group.Members.forEach(function(item, index){
                    if(item.Id == userId){
                        $scope.group.Members.splice(index, 1);
                    }
                })
            }, function(error){
                $scope.SaveMessage = "Error";
                console.dir(error);
            })
        }

        $scope.alreadyMember = function(group, userId){
            var isMember = false;
            group.Members.forEach(function(user){
                if(user.Id == userId)
                    isMember = true;
            });
            return isMember;
        }

    }
];

export default modal_admin_group_members;
