import * as angular from 'angular';
import * as moment from 'moment';
import {
  BooleanCellRenderer,
  BooleanEditor, valueFormatterArrayToList,
  valueFormatterBoolean,
  valueFormatterDate
} from "../../../../core/common/common-functions";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';
import {METADATA_PROPERTY_LEASING_INSPECTIONVIOLATIONS} from "../../../../config";

var inspection_violations = ['$scope', '$route', '$routeParams', '$uibModal', '$location', '$window', '$rootScope', 'LeasingService',
    function ($scope, $route, $routeParams, $uibModal, $location, $window, $rootScope,LeasingService) {

        $rootScope.inModule = "leasing";

        if (!$scope.Profile.hasRole("Leasing"))
            angular.rootScope.go("/unauthorized");

        //$scope.FromDate = moment().subtract(10, "years"); //how many violations to show?
        $scope.ShowResolved = true;

        $scope.violations = LeasingService.getInspectionViolations({ShowResolved: $scope.ShowResolved});

        $scope.violationResolutions = $scope.leaseTypes = LeasingService.getLookupValues(METADATA_PROPERTY_LEASING_INSPECTIONVIOLATIONS);

        $scope.violations.$promise.then(function () {
            $scope.violationsGridDiv = document.querySelector('#inspection-violations-grid');
            new Grid($scope.violationsGridDiv, $scope.violationsGrid);

            if ($rootScope.Profile.hasRole("LeasingEditor")) {
                $scope.violationsGrid.columnApi.setColumnVisible("EditLinks", true);
                $scope.violationsGrid.api.refreshHeader();
            }

        });

        var EditLinksTemplate = function (param) {

            if(!$rootScope.Profile.hasRole('LeasingEditor')){
                return;
            }

            var div = document.createElement('div');

            var editBtn = document.createElement('a'); editBtn.href = '#'; editBtn.innerHTML = 'Edit';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                $scope.openInspectionViolationModal(param.data);
            });
            div.appendChild(editBtn);

            return div;
        };

        var violationsColumnDefs = [
            { colId: 'EditLinks', width: 90, cellRenderer: EditLinksTemplate, menuTabs: [], hide: true },

            {
                headerName: "Inspection Date", field: "InspectionDateTime", width: 160,
                valueGetter: function (params) { return moment(params.node.data.InspectionDateTime) },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.InspectionDateTime);
                },
                menuTabs: ['filterMenuTab'],
                filter: 'date',
                sort: 'desc',
                filterParams: { apply: true }
            },
            {
                headerName: "Resolved?", field: "ViolationIsResolved", width: 120,
                valueFormatter: function (params) {
                    if (params && params.node) {
                        return valueFormatterBoolean(params.node.data.ViolationIsResolved);
                    } else {
                        return valueFormatterBoolean(params.value === "true"); //for filter!
                    }
                },
                menuTabs: ['filterMenuTab'],
            },

            { headerName: "Allotment", field: "AllotmentName", width: 120, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Crop Present", field: "CropPresent", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Inspection Type", field: "InspectionType", width: 160, menuTabs: ['filterMenuTab'], filter: true},
            { headerName: "Violation Type", field: "ViolationType", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Inspection Notes", field: "InspectionNotes", width: 300, menuTabs: ['filterMenuTab'], filter: "text" },

            {
                headerName: "Resolution", field: "ViolationResolution", width: 160, menuTabs: ['filterMenuTab'], filter: true
            },
            { headerName: "Fee Collected", field: "ViolationFeeCollected", width: 160, menuTabs: ['filterMenuTab'], filter: "number" },
            {
                headerName: "Date Fee Collected", field: "ViolationDateFeeCollected", width: 160,
                valueGetter: function (params) { return moment(params.node.data.ViolationDateFeeCollected) },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.ViolationDateFeeCollected);
                }, menuTabs: ['filterMenuTab'], filter: "number"
            },
            { headerName: "Fee Collected By", field: "ViolationFeeCollectedBy", width: 160, menuTabs: ['filterMenuTab']},
            { headerName: "Hours Spent", field: "ViolationHoursSpent", width: 160,menuTabs: ['filterMenuTab'], filter: "number" },
            { headerName: "Comments", field: "ViolationComments", width: 160,menuTabs: ['filterMenuTab'], filter: "text"},

            //more lease fields
            { headerName: "Lease Number", field: "LeaseNumber", width: 160, menuTabs: ['filterMenuTab'], filter: "text" },
            { headerName: "Lease Type", field: "LeaseType", width: 160, menuTabs: ['filterMenuTab'] , filter: true},
            { headerName: "Lease Acres", field: "LeaseAcres", width: 160, menuTabs: ['filterMenuTab'], filter: "number" },
            {
                headerName: "Optional Alt Crop", field: "OptionalAlternativeCrop", width: 160,
                valueGetter: function (params) {
                    return valueFormatterBoolean(params.node.data.OptionalAlternativeCrop);
                },
                menuTabs: ['filterMenuTab']
            },
            { headerName: "Graze Animal", field: "GrazeAnimal", width: 160, menuTabs: ['filterMenuTab'],
                valueFormatter: function (params) {
                    return valueFormatterArrayToList(params.node.data.GrazeAnimal);
                }
            },
            { headerName: "TAAMSNumber", field: "TAAMSNumber", width: 160, menuTabs: ['filterMenuTab'], filter: "text" },
            { headerName: "Lease Year", field: "LeaseYear", width: 160, menuTabs: ['filterMenuTab'], filter: "number" },
            { headerName: "Inspected By", field: "InspectedBy", width: 160, menuTabs: ['filterMenuTab'], filter: true },

        ];

        $scope.rowsChanged = {};

        $scope.violationsGrid = {
            columnDefs: violationsColumnDefs,
            rowData: $scope.violations,
            rowSelection: 'single',
            selectedItem: null,
            onSelectionChanged: function (params) {
                if(!$rootScope.Profile.hasRole('LeasingEditor')){ return; }
                $scope.violationsGrid.selectedItem = $scope.violationsGrid.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            components: {
                booleanEditor: BooleanEditor,
                booleanCellRenderer: BooleanCellRenderer,
            },
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
        }

        $scope.openInspectionViolationModal = function (params) {

            $scope.violation_modal = params;

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/inspection-violation-modal.html',
                controller: 'InspectionViolationModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            });
        }

        $scope.removeInspection = function(){
            if(!confirm("Are you sure you want to remove this inspection? There is no undo.")){
                return;
            }

            var remove = LeasingService.removeInspection($scope.violationsGrid.selectedItem.InspectionId); //note: the Id is not the InspectionId
            remove.$promise.then(function(){
                $scope.violations.forEach(function(violation, index){
                    if(violation.InspectionId == $scope.violationsGrid.selectedItem.InspectionId){
                        $scope.violations.splice(index,1);
                    }
                })

                $scope.violationsGrid.api.setRowData($scope.violations);
                $scope.violationsGrid.selectedItem = null;
            }, function(data){
                alert("There was a problem removing that inspection violation.");
                console.dir(data);
            })
        }

        $scope.saveInspectionCallback = function () {
            $scope.violations = LeasingService.getInspectionViolations({ ShowResolved: $scope.ShowResolved });
            $scope.violations.$promise.then(function () {
                $scope.violationsGrid.api.setRowData($scope.violations);
            });
        }

}];

export default inspection_violations;
