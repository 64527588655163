import {Injectable} from '@angular/core';
import {UpgradeModule} from '@angular/upgrade/static';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AppjsLoaderService {
  // private app: angular.auto.IInjectorService;
  private app: any;

  constructor(private upgrade: UpgradeModule) {
  }

  load(el: HTMLElement): void {
    // import('../appjs/app').then(app => {
    try {

      if (!this.app) {
        this.app = this.upgrade.bootstrap(document.body, ['app'], {strictDi: true});
      }

    } catch (e) {
      console.error(e);
    }
  }

  destroy() {
    if (this.app) {
      this.app.get('$rootScope').$destroy();
    }
  }
}
