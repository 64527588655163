//subproject (crpp + habitat) factories and service

//NB: this is not the final form - we want to create an actual
//    subproject feature in the system and refactor this
/*
* subprojects service (includes Project factory which is defined in projects-service.js)
*/
import * as angular from 'angular';

const subproject_service = ['$q',
  'ProjectSubprojects',
  'SubprojectFiles',
  'OlcSubprojectFiles',
  'SaveCorrespondenceEvent',
  'SaveOlcEvent',
  'SaveHabitatItem',
  'DeleteCorresEventFile',
  'DeleteHabitatItemFile',
  'DeleteHabSubprojectFile',
  'SaveSubproject',
  'SaveCrppSubproject',
  'SaveHabSubproject',
  'SaveOlcSubproject',
  'GetSubprojects',
  'GetHabSubproject',
  'GetHabSubprojects',
  'GetOlcSubprojects',
  'QueryOlcSubprojectsForSearch',
  'RemoveSubproject',
  'RemoveHabSubproject',
  'RemoveOlcSubproject',
  'RemoveCorrespondenceEvent',
  'RemoveOlcEvent',
  'RemoveHabitatItem',
  'MigrateOlcEvent',
  'SaveFileItemId',

  function ($q,
            ProjectSubprojects,
            SubprojectFiles,
            OlcSubprojectFiles,
            SaveCorrespondenceEvent,
            SaveOlcEvent,
            SaveHabitatItem,
            DeleteCorresEventFile,
            DeleteHabitatItemFile,
            DeleteHabSubprojectFile,
            SaveSubproject,
            SaveCrppSubproject,
            SaveHabSubproject,
            SaveOlcSubproject,
            GetSubprojects,
            GetHabSubproject,
            GetHabSubprojects,
            GetOlcSubprojects,
            QueryOlcSubprojectsForSearch,
            RemoveSubproject,
            RemoveHabSubproject,
            RemoveOlcSubproject,
            RemoveCorrespondenceEvent,
            RemoveOlcEvent,
            RemoveHabitatItem,
            MigrateOlcEvent,
            SaveFileItemId) {

    var service = {

      //we'd like to move this subproject stuff all out soon
      subproject: null,
      subprojects: null,
      subprojectType: null,

      clearSubproject: function () {
        service.subproject = null;
      },
      clearSubprojects: function () {
        service.subprojects = null;
      },
      getSubproject: function (id) {
        console.log("Inside subproject-service, getSubproject...");
        if (service.subproject && service.subproject.Id === id)
          return service.subproject;
      },
      setServiceSubprojectType: function (spType) {
        console.log("Inside subproject-service, setServiceSubprojectType, spType = " + spType);
        service.subprojectType = spType;
        console.log("service.subprojectType = " + service.subprojectType);
      },
      getSubprojects: function () {
        return GetSubprojects.query();
      },
      getHabSubproject: function (id) {
        console.log("Inside subproject-service, getHabSubproject...");
        return GetHabSubproject.query({id: id});
      },
      getHabSubprojects: function ()
        //getHabSubprojects: function(id)
      {
        console.log("Inside subproject-service, getHabSubprojects");
        //console.log("id = " + id);
        return GetHabSubprojects.query();
        //return GetHabSubprojects.query({id: id});
      },
      getOlcSubprojects: function () {
        console.log("Inside subproject-service, getOlcSubprojects");
        //console.log("id = " + id);
        return GetOlcSubprojects.query();
      },
      getOlcSubprojectsForSearch: function (datasetId) {
        console.log("Inside subproject-service, getOlcSubprojectsForSearch");
        //console.log("id = " + id);
        var searchCriteria = {
          DatasetId: datasetId
        }
        return QueryOlcSubprojectsForSearch.save(searchCriteria);
      },
      saveSubproject: function (projectId, subproject, saveResults) {
        console.log("Inside subproject-service, saveSubproject...");
        saveResults.saving = true;
        console.log("saveResults.saving = " + saveResults.saving);

        return SaveSubproject.save({ProjectId: projectId, Subproject: subproject});
      },
      saveCrppSubproject: function (projectId, subproject, saveResults) {
        console.log("Inside subproject-service, saveCrppSubproject...");
        saveResults.saving = true;
        console.log("saveResults.saving = " + saveResults.saving);

        return SaveSubproject.save({ProjectId: projectId, Subproject: subproject});
      },
      saveHabSubproject: function (projectId, subproject, saveResults) {
        console.log("Inside services.js, saveHabSubproject...");
        saveResults.saving = true;
        console.log("saveResults.saving = " + saveResults.saving);

        return SaveHabSubproject.save({ProjectId: projectId, Subproject: subproject});
      },
      saveOlcSubproject: function (projectId, subproject, saveResults) {
        console.log("Inside services.js, saveOlcSubproject...");
        saveResults.saving = true;
        console.log("saveResults.saving = " + saveResults.saving);

        return SaveOlcSubproject.save({ProjectId: projectId, Subproject: subproject});
      },
      removeSubproject: function (projectId, subprojectId) {
        return RemoveSubproject.save({ProjectId: projectId, SubprojectId: subprojectId});
      },
      //removeHabSubproject: function(projectId, subprojectId){
      removeHabSubproject: function (projectId, subprojectId, locationId) {
        //return RemoveHabSubproject.save({ProjectId: projectId, SubprojectId: subprojectId});
        return RemoveHabSubproject.save({ProjectId: projectId, SubprojectId: subprojectId, LocationId: locationId});
      },
      removeOlcSubproject: function (projectId, subprojectId) {
        return RemoveOlcSubproject.save({ProjectId: projectId, SubprojectId: subprojectId});
      },
      //removeCorrespondenceEvent: function(projectId, subprojectId, correspondenceEventId){
      removeCorrespondenceEvent: function (projectId, subprojectId, correspondenceEventId, datastoreTablePrefix) {
        console.log("Inside removeCorrespondenceEvent...");
        console.log("projectId = " + projectId + ", subprojectId = " + subprojectId + ", correspondenceEventId = " + correspondenceEventId + ", datastoreTablePrefix = " + datastoreTablePrefix);
        //return RemoveCorrespondenceEvent.save({ProjectId: projectId, SubprojectId: subprojectId, CorrespondenceEventId: correspondenceEventId});
        return RemoveCorrespondenceEvent.save({
          ProjectId: projectId,
          SubprojectId: subprojectId,
          CorrespondenceEventId: correspondenceEventId,
          DatastoreTablePrefix: datastoreTablePrefix
        });
      },
      removeOlcEvent: function (projectId, subprojectId, olcEventId, datastoreTablePrefix) {
        console.log("Inside removeOlcEvent...");
        console.log("projectId = " + projectId + ", subprojectId = " + subprojectId + ", olcEventId = " + olcEventId + ", datastoreTablePrefix = " + datastoreTablePrefix);
        return RemoveOlcEvent.save({ProjectId: projectId, SubprojectId: subprojectId, OlcEventId: olcEventId});
      },
      removeHabitatItem: function (projectId, subprojectId, habitatItemId, datastoreTablePrefix) {
        console.log("Inside removeHabitatItem...");
        console.log("projectId = " + projectId + ", subprojectId = " + subprojectId + ", habitatItemId = " + habitatItemId + ", datastoreTablePrefix = " + datastoreTablePrefix);
        return RemoveHabitatItem.save({
          ProjectId: projectId,
          SubprojectId: subprojectId,
          HabitatItemId: habitatItemId,
          DatastoreTablePrefix: datastoreTablePrefix
        });
      },
      saveCorrespondenceEvent: function (projectId, subprojectId, ce) {
        console.log("Inside saveCorrespondenceEvent...")
        console.log("projectId = " + projectId);
        console.log("subprojectId = " + subprojectId);
        console.log("ce is next...");
        console.dir(ce);
        return SaveCorrespondenceEvent.save({
          ProjectId: projectId,
          SubprojectId: subprojectId,
          CorrespondenceEvent: ce
        });
      },
      saveOlcEvent: function (projectId, subprojectId, olcEvent) {
        console.log("Inside saveOlcEvent...")
        console.log("projectId = " + projectId);
        console.log("subprojectId = " + subprojectId);
        console.log("olcEvent is next...");
        console.dir(olcEvent);
        return SaveOlcEvent.save({ProjectId: projectId, SubprojectId: subprojectId, OlcEvent: olcEvent});
      },
      saveHabitatItem: function (projectId, subprojectId, hi) {
        console.log("Inside saveHabitatItem...")
        console.log("projectId = " + projectId);
        console.log("subprojectId = " + subprojectId);
        console.log("hi is next...");
        console.dir(hi);
        return SaveHabitatItem.save({ProjectId: projectId, SubprojectId: subprojectId, HabitatItem: hi});
      },
      deleteCorresEventFile: function (projectId, subprojectId, ceId, file) {
        console.log("Inside deleteCorresEventFile");
        console.log("SubprojectId = " + subprojectId + ", ceId = " + ceId + " attempting to delete file...");
        console.dir(file);
        //return DeleteFile.save({ProjectId: projectId, File: file});
        return DeleteCorresEventFile.save({ProjectId: projectId, SubprojectId: subprojectId, CeId: ceId, File: file});
      },
      deleteHabitatItemFile: function (projectId, subprojectId, hiId, file) {
        console.log("Inside deleteHabitatItemFile");
        console.log("ProjectId = " + projectId + ", SubprojectId = " + subprojectId + ", hiId = " + hiId + " attempting to delete file...");
        console.dir(file);
        //return DeleteFile.save({ProjectId: projectId, File: file});
        return DeleteHabitatItemFile.save({ProjectId: projectId, SubprojectId: subprojectId, HiId: hiId, File: file});
      },
      deleteHabSubprojectFile: function (projectId, subprojectId, file) {
        console.log("Inside deleteHabSubprojectFile");
        console.log("SubprojectId = " + subprojectId + ", attempting to delete file...");
        console.dir(file);
        return DeleteHabSubprojectFile.save({ProjectId: projectId, SubprojectId: subprojectId, File: file});
      },
      getSubprojectFiles: function (projectId) {
        console.log("Inside getSubprojectFiles...");
        console.log("projectId = " + projectId);
        return SubprojectFiles.query({id: projectId});
      },
      getOlcSubprojectFiles: function (projectId) {
        console.log("Inside getOlcSubprojectFiles...");
        console.log("projectId = " + projectId);
        return OlcSubprojectFiles.query({id: projectId});
      },
      //NB: why is this .save()? -- to get a POST instead of a GET?
      getProjectSubprojects: function (projectId) {
        console.log("Inside getProjectSubprojects, projectId = " + projectId);
        //this.getProject(projectId); //set our local project to the one selected
        return ProjectSubprojects.save({ProjectId: projectId});
      },
      migrateOlcEvent: function (projectId, subprojectId, eventRow, fileNames) {
        console.log("Inside migrateOlcEvent...");
        console.log("projectId = " + projectId);
        console.log("subprojectId = " + subprojectId);
        //console.log("eventRow is next...");
        //console.dir(eventRow);

        //this.getProject(projectId); //set our local project to the one selected
        return MigrateOlcEvent.save({
          ProjectId: projectId,
          SubprojectId: subprojectId,
          OlcEvent: eventRow,
          FileNames: fileNames
        });
      },
      /*
      saveOlcEvent: function (projectId, subprojectId, olcEvent) {
          console.log("Inside saveOlcEvent...")
          console.log("projectId = " + projectId);
          console.log("subprojectId = " + subprojectId);
          console.log("olcEvent is next...");
          console.dir(olcEvent);
          return SaveOlcEvent.save({ ProjectId: projectId, SubprojectId: subprojectId, OlcEvent: olcEvent });
      },
      */
      saveFileItemId: function (projectId, subprojectId, itemId, fileName) {
        console.log("Inside saveFileItemId...");
        console.log("projectid = " + projectId);
        console.log("subprojectId = " + subprojectId);
        console.log("itemId = " + itemId);
        console.log("fileName = " + fileName);

        return SaveFileItemId.save({
          ProjectId: projectId,
          SubprojectId: subprojectId,
          ItemId: itemId,
          FileName: fileName
        });
      }

    };

    return service;
  }
];export default subproject_service;


