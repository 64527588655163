//CommonService includes:
//  Location (since locations exist in projects and dataset activities)
//  User
//  List things (the common ones like: waterbodies, sources, timezones, departments)
//  MetadataProperties (projects, datasets)

import * as angular from 'angular';


const common_service = ['$q',
  'SaveDatasetMetadata',
  'GetMetadataFor',
  'GetWaterBodies',
  'GetSources',
  'GetTimeZones',
  'GetDepartments',
  'Users',
  'GetAllUsers',
  'SaveUser',
  'DeleteLocationAction',
  'GetAllPossibleDatastoreLocations',
  'SaveProjectLocation',
  'UpdateLocationAction',
  'GetMetadataEntities',
  'SaveMetadataProperty',
  'DeleteMetadataProperty',
  'GetLookupItems',
  'SaveLookupTableItem',
  'GetMetadataPropertiesForEntity',
  'SaveFeedback',
  'GetMetadataProperty',
  'DeleteLookupItem',
  'GetGroups',
  'GetOrganizations',
  'GetOrgUsers',
  'GetOrgGroups',
  'GetOrgDepartments',
  'GetOrgAdministrators',
  'DeleteLocations',
  'GetSelectGridData',
  'GetAllInstruments',
  function ($q,
            SaveDatasetMetadata,
            GetMetadataFor,
            GetWaterBodies,
            GetSources,
            GetTimeZones,
            GetDepartments,
            Users,
            GetAllUsers,
            SaveUser,
            DeleteLocationAction,
            GetAllPossibleDatastoreLocations,
            SaveProjectLocation,
            UpdateLocationAction,
            GetMetadataEntities,
            SaveMetadataProperty,
            DeleteMetadataProperty,
            GetLookupItems,
            SaveLookupTableItem,
            GetMetadataPropertiesForEntity,
            SaveFeedback,
            GetMetadataProperty,
            DeleteLookupItem,
            GetGroups,
            GetOrganizations,
            GetOrgUsers,
            GetOrgGroups,
            GetOrgDepartments,
            GetOrgAdministrators,
            DeleteLocations,
            GetSelectGridData,
            GetAllInstruments
  ) {

    var service = {

      datastoreId: null,
      metadataProperties: null,

      clearMetadataProperties: function () {
        service.metadataProperties = null;
      },

      getLocations: function (id) {
        service.datastoreId = id;
        return GetAllPossibleDatastoreLocations.query({id: id});
      },

      getWaterBodies: function () {
        return GetWaterBodies.query();
      },

      getSources: function () {
        return GetSources.query();
      },

      deleteLocation: function (locationId) {
        return DeleteLocationAction.save({LocationId: locationId});
      },

      deleteLocations: function (locationIds) {
        return DeleteLocations.save({LocationIds: locationIds});
      },

      //NB: not used anywhere
      getTimeZones: function () {
        return GetTimeZones.query();
      },

      getGroups: function () {
        return GetGroups.query();
      },

      getOrgGroups: function (id) {
        return GetOrgGroups.query({Id: id});
      },

      getOrgDepartments: function (id) {
        return GetOrgDepartments.query({Id: id});
      },

      getOrgAdministrators: function (id) {
        return GetOrgAdministrators.query({Id: id});
      },

      getOrganizations: function () {
        return GetOrganizations.query();
      },

      getUsers: function () {
        return Users.query();
      },

      getOrgUsers: function (id) {
        return GetOrgUsers.query({Id: id});
      },

      getAllUsers: function () {
        return GetAllUsers.query();
      },

      getDepartments: function () {
        return GetDepartments.query();
      },

      getSelectGridData: function (fieldId) {
        return GetSelectGridData.query({FieldId: fieldId});
      },

      saveNewProjectLocation: function (projectId, location) {
        return SaveProjectLocation.save({ProjectId: projectId, Location: location});
      },

      //updateProjectLocation: function (projectId, location, newSdeObjectId, oldSdeObjectId) {
      //updateLocationAction: function (projectId, location, newSdeObjectId) {
      updateLocationAction: function (projectId, location, newSdeObjectId, row) {
        console.log("Inside CommonService.updateProjectLocation...");
        //console.log("ProjectId = " + projectId + ", location = " + location + ", newSdeObjectId = " + newSdeObjectId); // + ", oldSdeObjectId = " + oldSdeObjectId);
        //console.log("ProjectId = " + projectId + ", location = " + location + ", newSdeObjectId = " + newSdeObjectId + ", row.Label = " + row.Label);
        console.log("ProjectId = " + projectId + ", location = " + location + ", newSdeObjectId = " + newSdeObjectId);
        //return UpdateLocationAction.save({ ProjectId: projectId, Location: location, NewSdeObjectId: newSdeObjectId, OldSdeObjectId: oldSdeObjectId});
        //return UpdateLocationAction.save({ProjectId: projectId, LocationId: location, NewSdeObjectId: newSdeObjectId});
        return UpdateLocationAction.save({ProjectId: projectId, LocationId: location, NewSdeObjectId: newSdeObjectId, Row: row});
      },

      filterListForOnlyActiveInstruments: function (instruments) {
        var newInstrumentList = [];

        angular.forEach(instruments, function (instrument) {
          if (instrument.StatusId === 0)
            newInstrumentList.push(instrument);
        });

        return newInstrumentList;
      },

      checkForDuplicateInstrument: function (instrumentList, instrument) {
        var blnInstrumentExists = false;
        var blnKeepGoing = true;

        angular.forEach(instrumentList, function (item) {
          // Have we found a match yet? If so, we do not need to check the rest of the items.
          if (blnKeepGoing) {
            //console.log("item.Name = " + item.Name + ", instrument.Name = " + instrument.Name + ", item.SerialNumber = " + item.SerialNumber + ", instrument.SerialNumber = " + instrument.SerialNumber);
            if ((item.Name === instrument.Name) && (item.SerialNumber === instrument.SerialNumber)) {
              blnInstrumentExists = true;
              blnKeepGoing = false;
            }
          }
        });

        return blnInstrumentExists;
      },

      getAllInstruments: function () {
        return GetAllInstruments.query();
      },

      getMetadataProperty: function (id) {
        return GetMetadataProperty.query({id: id});
      },

      getMetadataProperties: function (propertyTypeId) {
        return GetMetadataPropertiesForEntity.query({id: propertyTypeId});
      },

      getMetadataEntities: function () {
        return GetMetadataEntities.query();
      },


      getMetadataFor: function (relationId, typeId) {
        return GetMetadataFor.save({RelationId: relationId, EntityTypeId: typeId});
      },

      saveDatasetMetadata: function (datasetId, metadata, saveResults) {
        var payload = {
          DatasetId: datasetId,
          Metadata: metadata
        };

        return SaveDatasetMetadata.save(payload);

      },

      saveFeedback: function (feedback) {
        return SaveFeedback.save({Feedback: feedback});
      },

      saveUser: function (user) {
        return SaveUser.save({User: user});
      },

      saveMetadataProperty: function (property) {
        return SaveMetadataProperty.save(property);
      },

      deleteMetadataProperty: function (property) {
        return DeleteMetadataProperty.save({Id: property.Id});
      },

      getLookupItems: function (lookup) {
        return GetLookupItems.query({id: lookup.Id})
      },

      saveLookupTableItem: function (payload) {
        return SaveLookupTableItem.save(payload);
      },

      deleteLookupItem: function (item, lookupId) {
        console.log('deleteLookupItem');
        var payload = {
          ItemId: item.Id,
          LookupId: lookupId
        };
        return DeleteLookupItem.save({payload});
      }


    };

    return service;
  }
];

export default common_service;
