import * as angular from 'angular';
import * as moment from 'moment';
import {
  METADATA_PROPERTY_PERMIT_FILETYPES,
  PARCEL_LAYER,
  PERMIT_DATASETID, PERMIT_PROJECTID, PERMITCONTACTS_DATASETID, PERMITEVENTS_DATASETID, PERMIT_WRP_DATASETID,
  proxyUrl,
  ZONING_LAYER,
  SYSTEM_LOCATION_DATASET
} from "../../../../config";
import {
  arrayToPossibleValues, BooleanCellRenderer, BooleanEditor, formatUsPhone, getById, getByName,
  getFilesArrayAsList,
  getJsonObjects,
  orderByAlpha, valueFormatterArrayToList
} from "../../../../core/common/common-functions";
// import {loadModules} from "esri-loader";
import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

// import '';
// import '~jquery-ui'
declare const $: any

var list_permits = ['$scope', '$route', '$routeParams', '$uibModal', '$location', '$window', '$rootScope', 'PermitService', 'GridService', 'DatasetService','CommonService','UserService','ProjectService',
    function ($scope, $route, $routeParams, $uibModal, $location, $window, $rootScope, PermitService, GridService, DatasetService, CommonService, UserService, ProjectService) {

        $rootScope.inModule = "permits";
        $rootScope.parcelMapFromList = undefined;
        $scope.currentPage = "All";
        $scope.row = null;
        $scope.clearingFilters = false;

        $scope.PermitContacts = [];
        $scope.PermitLocations = [];
        $scope.PermitParcels = [];
        $scope.PermitEvents = [];
        $scope.PermitFiles = [];
        $scope.ParcelHistory = [];
        $scope.PermitStatus = [];
        $scope.PermitFileTypes = [];
        $scope.ShowPermitListGrid = true;
        $scope.PERMIT_DATASETID = PERMIT_DATASETID;

        $scope.locationDataset = DatasetService.getDataset(SYSTEM_LOCATION_DATASET); //load the dataset with the fields for location grid
        $scope.datasetLocations = null;

        console.log("Inside list-permits.ts...");

        $scope.IsDefaultDataset = function(){
            //true at first, then once defined, true if our TPO permit dataset
            return (!$scope.dataset || ($scope.dataset.Id == PERMIT_DATASETID));
        };

        // Function for detecting window navigation types. Initial thought was to
        // use it to detect a page refresh, but it turned out to not be necessary.
        // Using some of the behavior to detect any sort of navigation (initial
        // vist, refresh, back/forward, etc.). Leaving in commented for reference.

        // $scope.navigationType = function () {

        //     var result;
        //     var p;
        //     let navEntries =  (window.performance.getEntriesByType("navigation")[0] as any);
        
        //     if (window.performance.getEntriesByType("navigation")){
        //         p=navEntries.type;
            
        //         if (p=='navigate'){result=0}
        //         if (p=='reload'){result=1}
        //         if (p=='back_forward'){result=2}
        //         if (p=='prerender'){result=3} //3 is my invention!
        //     }
        //     return result;
        // };

        if (window.performance.getEntriesByType("navigation")){
            if ((typeof $rootScope.clickselect == 'undefined')) {
                if (window.location.href.includes("My")) {
                    $rootScope.clickselect = 'mine';
                } else {
                    $rootScope.clickselect = 'allstaff';
                }
            }
        }

        $scope.PermitTypes = $scope.PermitTypes = PermitService.getPermitTypes();

        $scope.refreshingZones = false;
        $scope.showFeeTab = false;
        $scope.showRoutingSection = false;

        var permitDatasetId = PERMIT_DATASETID; //default to TPO
        var permitEventsDatasetId = PERMITEVENTS_DATASETID; //default to TPO events

        //if we have a particular dataset coming in then use it.
        //alert("$routeParams.DatasetId = " + $routeParams.DatasetId);
        if($routeParams.DatasetId){
            permitDatasetId = $routeParams.DatasetId;
        }

        //load the permits dataset
        $scope.dataset = DatasetService.getDataset(permitDatasetId);
        //console.log('permitDatasetId = ' + permitDatasetId);

        $scope.PermitFileTypes = CommonService.getMetadataProperty(METADATA_PROPERTY_PERMIT_FILETYPES);
        $scope.contactsdataset = DatasetService.getDataset(PERMITCONTACTS_DATASETID);

        $scope.contactsdataset.$promise.then(function () {
            $scope.ContactsDatasetColumnDefs = GridService.getAgColumnDefs($scope.contactsdataset);
        });

        $scope.PermitFileTypes.$promise.then(function () {
            $scope.PermitFileTypes = angular.fromJson($scope.PermitFileTypes.PossibleValues);
        });

        $scope.locationDataset.$promise.then(function () {
            $scope.permitLocationsGrid.columnDefs = GridService.getAgColumnDefs($scope.locationDataset).HeaderFields;

            console.log("project locations resolved");
        });

        $scope.dataset.$promise.then(function () {

            $scope.dataset.Config = angular.fromJson($scope.dataset.Config);

            //set the permitevents datasetid from childdatasetid, if configured
            if($scope.dataset.ChildDatasetId){
                permitEventsDatasetId = $scope.dataset.ChildDatasetId;
            }

            //load the permitevents dataset
            $scope.eventsdataset = DatasetService.getDataset(permitEventsDatasetId);

            $scope.eventsdataset.$promise.then(function () {
                var EventColumnDefs = GridService.getAgColumnDefs($scope.eventsdataset);
                $scope.permitEventsGrid.columnDefs = angular.merge(
                    //[{ colId: 'EditLinks', cellRenderer: EditEventLinksTemplate, width: 60, menuTabs: [], hide: true }],
                    EventColumnDefs.HeaderFields
                );

                //activate the permit events grid
                if (!$scope.permitEventsGridDiv) {
                    $scope.permitEventsGridDiv = document.querySelector('#permit-events-grid');
                    new Grid($scope.permitEventsGridDiv, $scope.permitEventsGrid);
                }

                $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);

                // Get the project, so we can pass it on to the location modal, if necessary.
                $scope.project = ProjectService.getProject($scope.dataset.ProjectId);
                $scope.project.$promise.then(function (){
                    $scope.project.Locations = ProjectService.getLocations($scope.project.Id);
                    $scope.project.Locations.$promise.then(function () {
                        // $scope.project.Locations.forEach( function (aLocation) {
                        //     console.log("aLocation = " + aLocation.Id + ", " + aLocation.Label);
                        // });
                    });
                });

            });

            //first, check permissions - we expect a Permission group to be set on dataset.Config.Permissions
            if(!$scope.dataset.Config.Permissions || !$scope.dataset.Config.Permissions.View || !$scope.dataset.Config.Permissions.Edit){
                alert("Configuration error - the administrator needs to configure the permissions for this dataset on Admin->Dataset->Config->Permissions");
                console.error("Configuration error - the administrator needs to configure the permissions for this dataset on Admin->Dataset->Config->Permissions");
                $scope.permits = [];
                throw ("Failed to load");
            }

            //console.log($scope.Profile.hasRole($scope.dataset.Config.Permissions.View) + ", " + $scope.Profile.hasRole($scope.dataset.Config.Permissions.Edit));
            if (!$scope.Profile.hasRole($scope.dataset.Config.Permissions.View) && !$scope.Profile.hasRole($scope.dataset.Config.Permissions.Edit)){
                angular.rootScope.go("/unauthorized");
            }

            $scope.isFavorite = $rootScope.Profile.isDatasetFavorite($scope.dataset.Id);

            $scope.permitsCanEdit = $scope.Profile.hasRole($scope.dataset.Config.Permissions.Edit);

            $scope.AllColumnDefs = GridService.getAgColumnDefs($scope.dataset);
            $scope.permitsGrid.columnDefs = $scope.AllColumnDefs.HeaderFields;

            $scope.permitsGrid.columnDefs.unshift(
            {
                field: 'PrimaryContact',
                headerName: 'Primary Contact',
                alwaysShowField: true,
                menuTabs: ['filterMenuTab'],
                filter: 'text',
                ColumnIndex: 0
            });

            $scope.PermitTypes.$promise.then(function(){

                //setup some custom "tweaks" to the column definition defaults TODO: might be a better way
                $scope.permitsGrid.columnDefs.forEach(function (coldef) {
                    if (coldef.DbColumnName == 'PermitNumber'){
                        coldef.Disabled = true;
                        coldef.filter='agTextColumnFilter'; //change from the default (checkboxes) to a "contains" filter
                    }
                    if(coldef.DbColumnName == 'ProjectName' || coldef.DbColumnName == 'SiteAddress')
                        coldef.filter='agTextColumnFilter';

                    if(coldef.DbColumnName == 'ReviewsRequired'){
                        coldef.valueFormatter = function (params) {
                            return valueFormatterArrayToList(params.node.data.ReviewsRequired);
                        }
                    }

                    if(coldef.DbColumnName == 'PermitType'){
                        coldef.PossibleValues = arrayToPossibleValues($scope.PermitTypes);
                    }

                    if(coldef.DbColumnName == 'Fee'){
                        $scope.showFeeTab = true;
                    }

                    if(coldef.DbColumnName == 'ReviewsRequired') {
                        $scope.showRoutingSection = true;
                    }

                });

            });

            //activate the grid with the permits data
            $scope.permitsGridDiv = document.querySelector('#active-permits-grid');
            new Grid($scope.permitsGridDiv, $scope.permitsGrid);

            //$scope.permits = PermitService.getAllPermits(); // Original line.

            // We need to check the dataset.Config to get the group (permissions) name.
            // We will use the group name, to get the group Id.
            var permissionsName = "";
            var groupId = "";
            if (($scope.dataset.Config.Permissions) && ($scope.dataset.Config.Permissions.View))
            {
                permissionsName = $scope.dataset.Config.Permissions.View;
                $scope.Profile.Groups.forEach(function(group) {
                    if (group.Name === permissionsName)
                    {
                        groupId = group.Id;
                        //console.log("Want permits of " + permissionsName + ", groupId = " + groupId);
                    }
                });

            }
   
            // PERMIT_WRP_DATASETID is set in the config.ts  Permits key on the groupId, not the dataset.
            // We use PERMIT_WRP_DATASETID, to determine whether to pull all permits, or just the ones for a certain groupId.
            //alert("$scope.dataset.Id = " + $scope.dataset.Id + ", PERMIT_WRP_DATASETID = " + PERMIT_WRP_DATASETID);
            if ($scope.dataset.Id === PERMIT_WRP_DATASETID)
                $scope.permits = PermitService.getGroupPermits(groupId);
            else
                $scope.permits = PermitService.getAllPermits();

            $scope.permits.$promise.then(function () {
                // console.log(" -- permits back -- ");
                $scope.permitsGrid.api.setRowData($scope.permits);

                //if there is an incoming Id, select it.
                if ($routeParams.Id) {
                    $scope.permitsGrid.api.forEachNode(function (node) {
                        if (node.data.Id == $routeParams.Id) {
                            node.setSelected(true);
                            $scope.permitsGrid.api.ensureIndexVisible(node.rowIndex, 'top');
                        }
                    });
                }

                //if there is an incoming filter, select it
                //console.log("$routeParams.filter = " + $routeParams.filter);
                if($routeParams.filter) {
                    $scope['show'+$routeParams.filter]();
                }

                GridService.autosizeColumns($scope.permitsGrid);

            });

            //now do some caching...
            $scope.Persons = PermitService.getAllPersons();
            $scope.CadasterParcels = PermitService.getAllParcels();

            $scope.Persons.$promise.then(function () {
                $scope.Persons.forEach(function (person) {
                    person.Label = $scope.getPersonLabel(person);
                });

                $scope.Persons = $scope.Persons.sort(orderByAlpha);
            });

            $scope.datasetLocations = PermitService.getPermitDatasetLocations($scope.dataset.Id);

            $scope.datasetLocations.$promise.then(function () {
                $scope.datasetLocations = $scope.datasetLocations.sort(orderByAlpha);
            });

        });

        //returns a composed label for a person
        $scope.getPersonLabel = function(person){
            var label = (person.Organization) ? person.Organization : person.FullName;
            if (label == null || label == "")
                label = person.FirstName + " " + person.LastName;

            return label;
        }
        // $scope.getLocationLabel = function(location){
        //     var label = (location.Organization) ? location.Organization : location.FullName;
        //     if (label == null || label == "")
        //         label = location.FirstName + " " + location.LastName;

        //     return label;
        // }

        // NOTE =============== NOTE =============== NOTE =============== NOTE
        // The below functions have outdated or incompatible formats. Possibly
        // deprecated syntax or functionality. They have been re-written with
        // a working syntax. The specific function call in question is:
        // -- filterInstance.setModel()

        // $scope.showIssued = function () {
        //     $scope.clearFilters();

        //     //first select all but archived
        //     var archive_filter_component = $scope.permitsGrid.api.setFilterModel({
        //         'FileStatus': {
        //             values: ['Active'],
        //         },
        //         'PermitStatus': {
        //             values: ['Approved', 'Conditionally Approved']
        //         }
        //     })
        //     $scope.currentPage = "Issued";
        //     $scope.ShowPermitListGrid = true;
        // };

        // $scope.showApplications = function () {
        //     $scope.clearFilters();

        //     var archive_filter_component = $scope.permitsGrid.api.setFilterModel({
        //         'FileStatus': {
        //             values: ['Active'],
        //         },
        //         'PermitStatus': {
        //             values: ['New Application', 'Under Review']
        //         }
        //     })
        //     $scope.currentPage = "Applications";
        //     $scope.ShowPermitListGrid = true;
        // };

        // $scope.showArchived = function () {
        //     $scope.clearFilters();
        //     var archive_filter_component = $scope.permitsGrid.api.setFilterModel({
        //         'FileStatus': {
        //             values: ['Archived'],
        //         },
        //     })
        //     $scope.currentPage = "Archived";
        //     $scope.ShowPermitListGrid = true;
        // };
        // ==== =============== ==== =============== ==== =============== ====

        $scope.showIssued = function () {
            $scope.clearFilters();
          
            // Permit Status = "Approved" or "Conditionally Approved"
            // File Status != "Archived"
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('PermitStatus');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: 'Approved'});
            $scope.permitsGrid.api.onFilterChanged();

            filterInstance = $scope.permitsGrid.api.getFilterInstance('FileStatus');
            filterInstance.setModel({filterType: 'text', type: 'notEqual', filter: 'Archived'});
            $scope.permitsGrid.api.onFilterChanged();
            
            $scope.currentPage = "Issued";
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "Approved" or "Conditionally Approved"
        // File Status != "Archived"
        // ReviewedBy == {current User}
        $scope.showMyIssued = function () {
            $scope.showIssued();

            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: [$scope.Profile.Fullname]});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "MyIssued";
            window.history.replaceState(null, "", '/permits/list?filter=MyIssued');
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "New Application"
        // File Status != "Archived"
        $scope.showApplications = function () {
            $scope.clearFilters();
          
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('PermitStatus');
            filterInstance.setModel({filterType: 'text', type: 'equals', filter: 'New Application'});

            // Reworked to separate these in the list header just like they are
            // on the Dashboard for consistency (see $scope.showUnderReview() below).

            // filterInstance.setModel({
            //     condition1:{
            //         type: 'equals',
            //         filter: 'New Application'
            //     },
            //     condition2:{
            //         type: 'equals',
            //         filter: 'Under Review'
            //     },
            //     operator: 'OR'
            // });

            $scope.permitsGrid.api.onFilterChanged();

            filterInstance = $scope.permitsGrid.api.getFilterInstance('FileStatus');
            filterInstance.setModel({filterType: 'text', type: 'notEqual', filter: ['Archived']});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "Applications";
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "New Application"
        // File Status != "Archived"
        // ReviewedBy == {current User}
        $scope.showMyApplications = function () {
            $scope.showApplications();
          
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: [$scope.Profile.Fullname]});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "MyApplications";
            window.history.replaceState(null, "", '/permits/list?filter=MyApplications');
            $scope.ShowPermitListGrid = true;
        };

        // File Status == "Archived"
        $scope.showArchived = function () {
            $scope.clearFilters();

            var filterInstance = $scope.permitsGrid.api.getFilterInstance('FileStatus');
            filterInstance.setModel({filterType: 'text', type: 'equals', filter: ['Archived']});

            $scope.permitsGrid.api.onFilterChanged();
            $scope.currentPage = "Archived";
            $scope.ShowPermitListGrid = true;
        };

        // File Status == "Archived"
        // ReviewedBy == {current User}
        $scope.showMyArchived = function () {
            $scope.showArchived();
          
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: [$scope.Profile.Fullname]});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "MyArchived";
            window.history.replaceState(null, "", '/permits/list?filter=MyArchived');
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "Incomplete"
        // File Status != "Archived"
        $scope.showIncomplete = function () {
            $scope.clearFilters();

            var filterInstance = $scope.permitsGrid.api.getFilterInstance('PermitStatus');
            filterInstance.setModel({filterType: 'text', type: 'equals', filter: ['Incomplete']});
            $scope.permitsGrid.api.onFilterChanged();

            filterInstance = $scope.permitsGrid.api.getFilterInstance('FileStatus');
            filterInstance.setModel({filterType: 'text', type: 'notEqual', filter: ['Archived']});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "Incomplete";
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "Incomplete"
        // File Status != "Archived"
        // ReviewedBy == {current User}
        $scope.showMyIncomplete = function () {
            $scope.showIncomplete();
          
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: [$scope.Profile.Fullname]});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "MyIncomplete";
            window.history.replaceState(null, "", '/permits/list?filter=MyIncomplete');
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "Under Review"
        // File Status != "Archived"
        $scope.showUnderReview = function () {
            $scope.clearFilters();

            var filterInstance = $scope.permitsGrid.api.getFilterInstance('PermitStatus');
            filterInstance.setModel({filterType: 'text', type: 'equals', filter: ['Under Review']});
            $scope.permitsGrid.api.onFilterChanged();

            filterInstance = $scope.permitsGrid.api.getFilterInstance('FileStatus');
            filterInstance.setModel({filterType: 'text', type: 'notEqual', filter: ['Archived']});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "UnderReview";
            $scope.ShowPermitListGrid = true;
        };

        // Permit Status == "Under Review"
        // File Status != "Archived"
        // ReviewedBy == {current User}
        $scope.showMyUnderReview = function () {
            $scope.showUnderReview();

            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: [$scope.Profile.Fullname]});
            $scope.permitsGrid.api.onFilterChanged();

            $scope.currentPage = "MyUnderReview";
            window.history.replaceState(null, "", '/permits/list?filter=MyUnderReview');
            $scope.ShowPermitListGrid = true;
        };

        $scope.showAll = function () {
            //$scope.clearingFilters = true;
            $scope.clearFilters();
            $scope.permitsGrid.api.getFilterModel(null);
            $scope.currentPage = "All";
            $scope.ShowPermitListGrid = true;
        };

        // ReviewedBy == {current User}
        $scope.showAssignedToMe = function () {
            $scope.clearFilters();
          
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: [$scope.Profile.Fullname]})

            $scope.permitsGrid.api.onFilterChanged();
            $scope.currentPage = "MyPermits";
            $scope.ShowPermitListGrid = true;
        };

        $scope.clearReviewedBy = function () {
            var filterInstance = $scope.permitsGrid.api.getFilterInstance('ReviewedBy');
            filterInstance.setModel({filterType: 'text', type: 'contains', filter: []})

            $scope.permitsGrid.api.onFilterChanged();
            $scope.currentPage = "MyPermits";
            $scope.ShowPermitListGrid = true;
        };

        $scope.clearFilters = function(){
            $scope.clearingFilters = true;
            $scope.permitsGrid.api.setFilterModel(null);
            $scope.currentPage = "All";
        }

        //requirement: can navigate permits by up and down arrow keys
        $scope.keyboardNavigation = function (params) {
            //console.log("my navigation");
            var previousCell = params.previousCellDef;
            var suggestedNextCell = params.nextCellDef;

            var KEY_UP = 38;
            var KEY_DOWN = 40;
            var KEY_LEFT = 37;
            var KEY_RIGHT = 39;

            switch (params.key) {
                case KEY_DOWN:
                    //console.log("down");
                    previousCell = params.previousCellDef;
                    // set selected cell on current cell + 1
                    $scope.permitsGrid.api.forEachNode(function (node) {
                        if (previousCell.rowIndex + 1 === node.rowIndex) {
                            node.setSelected(true);
                        }
                    });
                    return suggestedNextCell;
                case KEY_UP:
                    previousCell = params.previousCellDef;
                    // set selected cell on current cell - 1
                    $scope.permitsGrid.api.forEachNode(function (node) {
                        if (previousCell.rowIndex - 1 === node.rowIndex) {
                            node.setSelected(true);
                        }
                    });
                    return suggestedNextCell;
                case KEY_LEFT:
                case KEY_RIGHT:
                    return suggestedNextCell;
                default:
                    throw "this will never happen, navigation is always one of the 4 keys above";
            }
        };


        $scope.permitsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            hasFilters: false,
            onSelectionChanged: function (params) {

                if ($scope.row && $scope.row.dataChanged) {
                    //warn if they're trying to change the selection when data is changed
                    if ($scope.row.Id != $scope.permitsGrid.api.getSelectedRows()[0].Id) {
                        alert("It looks like you've changed this permit. Please click 'Save' or 'Cancel' before navigating to another permit.");
                        $scope.permitsGrid.selectedNode.setSelected(true);
                    }

                    //in any case, don't change.
                    return false;
                }

                $scope.permitsGrid.selectedItem = $scope.row = angular.copy($scope.permitsGrid.api.getSelectedRows()[0]);
                $scope.permitsGrid.selectedNode = $scope.permitsGrid.api.getSelectedNodes()[0];

                // if($scope.row.LocationId) {
                //     $scope.row.Activity = { LocationId : $scope.row.LocationId };
                // }

                if($scope.IsDefaultDataset()){
                    $('#tab-status').tab('show'); //default to the "Permit Status" tab when select a different permit
                } else {
                    $('#tab-basicinfo').tab('show'); //default to the "Detail" tab when not the default dataset (TPO)
                }

                if ($scope.row)
                {
                    $scope.selectPermit($scope.row.Id);
                    $scope.project.Locations.forEach(function(aLoc) {
                        if (aLoc.Id === $scope.row.LocationId)
                            $scope.row.LocationId = aLoc.Label;
                    });
                }

                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
                //console.dir($scope.row);

                //console.dir($scope.permitsGrid.selectedItem)
            },
            selectedItem: null,
            selectedNode: null,
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            onFilterChanged: function(params){
                if($scope.clearingFilters == true)
                    $scope.permitsGrid.hasFilters = $scope.clearingFilters = false;
                else
                    $scope.permitsGrid.hasFilters = true;

                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            onFirstDataRendered: function(){
                var allColumnIds = [];
                $scope.permitsGrid.columnApi.getAllColumns().forEach(function (columnDef) {
                    allColumnIds.push(columnDef.colId);
                });
                $scope.permitsGrid.columnApi.autoSizeColumns(allColumnIds);
            },
            navigateToNextCell: $scope.keyboardNavigation
        }


        $scope.openPersonModal = function (person_id) {

            $scope.person_modal = getById($scope.Persons, person_id);
            console.dir($scope.person_modal.Id);
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/contacts/templates/add-person-modal.html',
                controller: 'AddPersonModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_person) {
                $scope.Persons = PermitService.getAllPersons();
            });
        }

        $scope.permitContactsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            selectedItem: null,
            components: {
                booleanEditor: BooleanEditor,
                booleanCellRenderer: BooleanCellRenderer,
            },
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            onRowDoubleClicked: function (params) {
                if ($scope.permitsCanEdit) {
                    $scope.openPersonModal($scope.permitContactsGrid.selectedItem.PersonId);
                }
            },
            onSelectionChanged: function (params) {
                $scope.permitContactsGrid.selectedItem = $scope.permitContactsGrid.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
        }

        $scope.permitLocationsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            selectedItem: null,
            components: {
                booleanEditor: BooleanEditor,
                booleanCellRenderer: BooleanCellRenderer,
            },
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            // onRowDoubleClicked: function (params) {
            //     if ($scope.permitsCanEdit) {
            //         //$scope.editLocation_modal = { LocationId: params.data.Id };
            //         $scope.editLocation_modal = params.data;
            //         $scope.editLocation_modal.PermitId = $scope.row.Id;
            //         //$scope.openProjectLocationModal($scope.permitLocationsGrid.selectedItem.Id);
            //         $scope.openProjectLocationModal(params.data);
            //     }
            // },
            onSelectionChanged: function (params) {
                $scope.permitLocationsGrid.selectedItem = $scope.permitLocationsGrid.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
        }

        $scope.permitParcelsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            selectedItem: null,
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            onSelectionChanged: function (params) {
                $scope.permitParcelsGrid.selectedItem = $scope.permitParcelsGrid.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            onRowDoubleClicked: function (params) {
                window.open("permits/map?ParcelId=" + params.data.ParcelId, "_blank");
            },
        }

        $scope.parcelHistoryGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            onRowDoubleClicked: function (params) {
                // The backend (c#) adds IsViolation (it is not in the database) to the data, in the SQL command it uses to pull the records.
                // Check IsViolation, to determine which form should be opened, violations or permits.
                if (params.data.IsViolation === "y")
                {
                    window.open("permits/ehsviolations?Id=" + params.data.Id, "_blank");
                }
                else
                {
                    window.open("permits/list?Id=" + params.data.Id, "_blank");
                }
            },
        }

        $scope.permitEventsGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            selectedItem: null,
            rowSelection: 'single',
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            onRowDoubleClicked: function (params) {
                $scope.openActivityModal($scope.permitEventsGrid.selectedItem);
            },
            onSelectionChanged: function (params) {
                $scope.permitEventsGrid.selectedItem = $scope.permitEventsGrid.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            getRowHeight: function (params) {
                 var file_height = 25 * (getFilesArrayAsList(params.data.Files).length); //count up the number of file lines we will have.
                 var shared_height = 25 * (getFilesArrayAsList(params.data.SharedFiles).length);
                 var final = (shared_height > file_height) ? shared_height : file_height;
                 return (final > 25) ? final : 25;
            },
        }

        $scope.permitFilesGrid = {
            suppressPropertyNamesCheck: true,
            columnDefs: null,
            rowData: null,
            rowSelection: 'single',
            selectedItem: null,
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
            onSelectionChanged: function (params) {
                $scope.permitFilesGrid.selectedItem = $scope.permitFilesGrid.api.getSelectedRows()[0];
                $scope.$apply(); //trigger angular to update our view since it doesn't monitor ag-grid
            },
            onRowDoubleClicked: function (params) {
                $scope.openEditFileTypeModal($scope.permitFilesGrid.selectedItem);
            },
            onFilterChanged: function(params){
                // console.log("Inside $scope.permitFilesGrid.onFilterChanged...");
                // console.log("params is next...");
                // console.dir(params);

            },
        }


        var UploadedByTemplate = function (param) {
            return moment(param.node.data.UploadDate).format('L') + " by " + param.node.data.User.Fullname;
        };

        var EditFileLinksTemplate = function (param) {

            var div = document.createElement('div');

            var editBtn = document.createElement('a'); editBtn.href = '#'; editBtn.innerHTML = 'Edit';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                $scope.openFileModal(param.data);
            });
            div.appendChild(editBtn);

            return div;
        };

        // var EditFileLinksTemplate2 = function (param) {
        //     console.log("Inside EditFileLinksTemplate2...");

        //     var div = document.createElement('div');
        // };

        var EditContactLinksTemplate = function (param) {

            var div = document.createElement('div');

            var editBtn = document.createElement('a');
            editBtn.href = '#';
            editBtn.innerHTML = 'Modify';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                $scope.openContactModal(param.data);
            });

            div.appendChild(editBtn);

            return div;
        };

        var EditLocationLinksTemplate = function (param) {

            var div = document.createElement('div');

            var editBtn = document.createElement('a');
            editBtn.href = '#';
            editBtn.innerHTML = 'Modify';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                $scope.openLocationModal(param.data);
            });

            div.appendChild(editBtn);

            return div;
        };

        $scope.EditEventLinksTemplate = function (param) {

            var div = document.createElement('div');

            var editBtn = document.createElement('a'); editBtn.href = '#'; editBtn.innerHTML = 'Edit';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                $scope.openActivityModal(param.data);
            });
            div.appendChild(editBtn);

            return div;
        };


        var LinkTemplate = function (param) {
            // console.log("Inside LinkTemplate...");
            // console.log("param is next...");
            // console.dir(param);
            // console.log("param.data.Name = " + param.data.Name);

            var div = document.createElement('div');

            var linkBtn = document.createElement('a');
            linkBtn.href = param.data.Link;
            linkBtn.innerHTML = param.data.Name;
            linkBtn.target = "_blank";
            div.appendChild(linkBtn);
            return div;
        };


        $scope.permitContactsGrid.columnDefs = [
            { colId: 'EditLinks',
              // cellRenderer: function(params) {
              // return $scope.EditContactLinksTemplate(params.node)
              // },
              cellRenderer: EditContactLinksTemplate,
              width: 60, menuTabs: []},
            {
                headerName: "Primary", field: "IsPrimary", width: 110,
                cellRenderer: 'booleanCellRenderer',
                sort: 'desc'
            },
            {
                headerName: "Contact", width: 200,
                cellRenderer: function (params) {
                    return $scope.getPersonLabel(params.node.data.Person);
                },
                filter: 'text',
                menuTabs: ['filterMenuTab'],
            },
            { headerName: "Type", field: "ContactType", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            {
                headerName: "Info", width: 120,
                cellRenderer: function (params) {
                    if (params.node.data.Person.CellPhone)
                        return formatUsPhone(params.node.data.Person.CellPhone);

                    if (params.node.data.Person.WorkPhone)
                        return formatUsPhone(params.node.data.Person.WorkPhone);

                    if (params.node.data.Person.HomePhone)
                        return formatUsPhone(params.node.data.Person.HomePhone);

                    return (params.node.data.Person.Email) ? params.node.data.Person.Email : "None provided";

                },
                filter: 'text',
                menuTabs: ['filterMenuTab'],
            },

        ];

        $scope.permitLocationsGrid.columnDefs = [
            //{ colId: 'EditLinks',
            //  cellRenderer: EditLocationLinksTemplate,
            //  width: 60, menuTabs: []},
            { headerName: "Id", field: "Id", width: 200, menuTabs: ['filterMenuTab'], filter: true, hide: true }, // We need the Id, but we don't need to see it.
            { headerName: "Label", field: "Label", width: 200, menuTabs: ['filterMenuTab'], filter: 'text' },
            { headerName: "Easting", field: "GPSEasting", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Northing", field: "GPSNorthing", width: 160, menuTabs: ['filterMenuTab'], filter: true },
        ];

        $scope.permitParcelsGrid.columnDefs = [
            { headerName: "Parcel Id", field: "ParcelId", width: 180, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "PLSS", field: "PLSS", width: 180, menuTabs: ['filterMenuTab'], filter: true },
            //{ headerName: "Acres", field: "Object.Acres_Cty", width: 150, menuTabs: ['filterMenuTab'] },
            { headerName: "GIS", width: 150, menuTabs: ['filterMenuTab'],
                valueGetter: function(param){
                    if(param.data.Object) { //then we have joined cadaster on objectid for this parcel
                        return (param.data.Object.ParcelId == param.data.ParcelId) ? "Cadaster" : "Updated";
                    } else {
                        return "Historical"; // if no cadaster object
                    }
                }
            },
        ];

        $scope.parcelHistoryGrid.columnDefs = [
            { headerName: "Permit Number", field: "PermitNumber", width: 150, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Project Name", field: "ProjectName", width: 220, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Parcel Id", field: "MatchingParcelId", width: 150, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Permit Status", field: "PermitStatus", width: 150, menuTabs: ['filterMenuTab'], filter: true },
        ];

        $scope.permitFilesGrid.columnDefs = [
            //{ headerName: 'File', cellRenderer: LinkTemplate, width: 220, menuTabs: ['filterMenuTab'], filter: true },
            { field: 'Name', headerName: 'File Name', width: 220, menuTabs: ['filterMenuTab'], filter: 'text' },
            { headerName: 'Link', cellRenderer: LinkTemplate, width: 220, filter: false},
            { field: 'Description', headerName: 'File Type', width: 200, menuTabs: ['filterMenuTab'], filter: true },
            { field: 'Uploaded', headerName: "Uploaded", width: 240, valueGetter: UploadedByTemplate, menuTabs: ['filterMenuTab'], filter: 'text' },
        ];

        $scope.openActivityModal = function (params, intent) {

            delete $scope.activity_modal;
            $scope.intent = intent;

            //if editing, we'll have incoming params
            if (params) {
                $scope.activity_modal = params;
            } else {
                $scope.activity_modal = { PermitId: $scope.row.Id };
                if (intent == 'new_route')
                    $scope.activity_modal.EventType = 'Review';
                if (intent == 'new_inspection')
                    $scope.activity_modal.EventType = 'Inspection';
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/list/templates/add-permit-activity-modal.html',
                controller: 'PermitActivityModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_activity) {
                $scope.selectPermit($scope.row.Id);
                $scope.PermitEvents = PermitService.getPermitEvents($scope.row.Id);
                $scope.PermitEvents.$promise.then(function () {
                    $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);
                });
            });
        }

        $scope.openContactModal = function (params) {

            //if editing, we'll have incoming params
            if (params) {
                $scope.contact_modal = params;
                $scope.contact_modal.Person.Label = $scope.getPersonLabel($scope.contact_modal.Person);
            } else {
                $scope.contact_modal = { PermitId: $scope.row.Id };
            }

            $scope.person_modal = null;
            console.dir($scope.contact_modal);

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/list/templates/add-contact-modal.html',
                controller: 'ContactModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_contact) {
                // $scope.PermitContacts = PermitService.getPermitContacts(saved_contact.PermitId);
                // $scope.PermitContacts.$promise.then(function () {
                //     $scope.permitContactsGrid.api.setRowData($scope.PermitContacts);

                //     //try to set the PrimaryContact field if we have one
                //     $scope.PermitContacts.forEach(function(item){
                //         if(item.IsPrimary){
                //             $scope.permits.forEach(function(permit){
                //                 if(permit.Id == $scope.row.Id){
                //                     permit.PrimaryContact = (item.Person.Organization) ? item.Person.Organization : item.Person.FullName;
                //                 }
                //             })
                //             $scope.permitsGrid.api.setRowData($scope.permits);
                //             $scope.permitsGrid.api.redrawRows({rowNodes: [$scope.permitsGrid.selectedNode], force: true});

                //             $scope.permitsGrid.api.forEachNode(function(node){
                //                 if(node.data.Id == $scope.row.Id){
                //                     node.setSelected(true,true);
                //                     setTimeout(() => {
                //                       //@ts-ignore
                //                         $('#tab-basicinfo').tab('show');
                //                     }, 200);
                //                     //$scope.permitsGrid.api.ensureIndexVisible($scope.permitsGrid.selectedNode.childIndex, 'top'); //scroll to the selected row
                //                 }
                //             });
                //         }
                //     })

                // });

                // //ask if they want to copy in the contact info if it is currently blank.
                // if(saved_contact.IsPrimary && !$scope.row.SiteAddress && confirm("Do you want to copy the contact's address into this permit record?")){
                //     $scope.row.SiteAddress = saved_contact.Person.PhysicalAddress1;
                //     $scope.row.SiteCity = saved_contact.Person.PhysicalCity;
                //     $scope.row.SiteState = saved_contact.Person.PhysicalState;
                //     $scope.row.SiteZip = saved_contact.Person.PhysicalZip;
                //     $scope.row.dataChanged = true;
                // }

            });
        }

        $scope.prepLocationModal = function (params) {

            //if editing, we'll have incoming params
            if (params) {
                console.log("Permits...with params; we're editing...");
                $scope.location_modal = params;
                //$scope.location_modal.Location.Label = $scope.getLocationLabel($scope.location_modal.Location);
                $scope.location_modal.PermitId = $scope.row.Id;

                $scope.openLocationModal($scope.location_modal);
            } 
            //else if ($scope.dataset.Datastore.Name === "Permits") { // No params; we are adding a location.
            else if (!params) {
                console.log("Permits...no params; we're adding...");

                $scope.location_modal = { PermitId: $scope.row.Id };

                $scope.openLocationModal($scope.location_modal);

            }
            else if (!$scope.row.Id) {
                console.log("No $scope.row.Id...");
            }
            else {
                $scope.location_modal = { PermitId: $scope.row.Id };
            }

            //$scope.location_modal = null;
            console.dir($scope.location_modal);


        }

        // $scope.openProjectLocationModal = function(params) {

        //     // if editing, we'll have incoming params
        //     if (params) {
        //         $scope.ProjectLocation_modal = params;
        //     } else {
        //         // No incomfing params.
        //         $scope.ProjectLocation_modal = { };
        //     }

        //     //$scope.row = angular.copy(params);

        //     //$scope.project = ProjectService.getProject($scope.dataset.ProjectId);
        //     //$scope.project.$promise.then(function () {
        //         $scope.CalledBy = "permits";
        //         var modalInstance = $uibModal.open({
        //             //templateUrl: 'appjsLegacy/private/permits/components/list/templates/edit-location-modal.html',
        //             templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-location.html',
        //             //controller: 'EditLocationModalController',
        //             controller: 'ModalEditLocationCtrl',
        //             scope: $scope,
        //             backdrop: "static",
        //             keyboard: false
        //         });
        //     //});
        // }

        $scope.openLocationModal = function(params) {
            console.log("Inside openLocationModal");
            console.log("params is next...");
            console.dir(params);

            // We don't need these items, for the Project, when we only work with the permit.
            //$scope.project = ProjectService.getProject($scope.dataset.ProjectId);
            //$scope.project.$promise.then(function () {
                var modalInstance = $uibModal.open({
                    //templateUrl: 'appjsLegacy/core/projects/components/project-detail/templates/modal-edit-location.html',
                    templateUrl: 'appjsLegacy/private/permits/components/list/templates/add-location-modal.html',
                    controller: 'LocationModalController',
                    scope: $scope,
                    backdrop: "static",
                    keyboard: false
                }).result.then(function (saved_location) {
                    $scope.PermitLocations = ProjectService.getDatasetLocations($scope.dataset.ProjectId, $scope.dataset.Id);//saved_location.LocationId);
                    $scope.PermitLocations2 = PermitService.getPermitLocations($scope.dataset.Id);//saved_location.LocationId);
                    $scope.PermitLocations2.$promise.then(function () {
                        console.log("$scope.PermitLocations2 is next...");
                        console.dir($scope.PermitLocations2);
                    });
                    $scope.PermitLocations.$promise.then(function () {
                        console.log("$scope.PermitLocations is next...");
                        console.dir($scope.PermitLocations);
                        $scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);
    
                        //try to set the PermitLocation field if we have one
                        $scope.PermitLocations.forEach(function(item){
                            //if(item.IsPrimary){
                                $scope.permitsGrid.api.setRowData($scope.permits);
                                $scope.permitsGrid.api.redrawRows({rowNodes: [$scope.permitsGrid.selectedNode], force: true});
    
                                $scope.permitsGrid.api.forEachNode(function(node){
                                    if(node.data.Id == $scope.row.Id){
                                        node.setSelected(true,true);
                                        setTimeout(() => {
                                          //@ts-ignore
                                            $('#tab-basicinfo').tab('show');
                                        }, 200);
                                        //$scope.permitsGrid.api.ensureIndexVisible($scope.permitsGrid.selectedNode.childIndex, 'top'); //scroll to the selected row
                                    }
                                });
                            //}
                        })
    
                    });
                });
            //});
        }

        //open a modal for editing only the filetype
        $scope.openEditFileTypeModal = function(params){
            $scope.file_modal = params;
            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/list/templates/modal-edit-file.html',
                controller: 'EditPermitFileTypeModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_file) {
                $scope.PermitFiles.forEach(function (file, index) {
                    if (file.Id == saved_file.Id) {
                        file.Description = saved_file.Description;
                        $scope.permitFilesGrid.api.setRowData($scope.PermitFiles);
                    }
                });
            });
        }

        $scope.openParcelModal = function (params) {

            if ($scope.row.dataChanged){
                alert("Please save or cancel your changes before adding a new parcel.");
                return;
            }

            //if editing, we'll have incoming params
            if (params) {
                $scope.parcel_modal = params;
            } else {
                $scope.parcel_modal = {};
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/list/templates/add-parcel-modal.html',
                controller: 'ParcelModalController',
                scope: $scope,
                backdrop: "static",
                keyboard: false
            }).result.then(function (saved_parcel) {
                $scope.PermitParcels = PermitService.getPermitParcels(saved_parcel.PermitId);
                $scope.PermitParcels.$promise.then(function () {
                    $scope.permitParcelsGrid.api.setRowData($scope.PermitParcels);
                    $scope.refreshZones();
                    $scope.refreshParcelHistory();
                });
            });
        }

        $scope.openFileModal = function (params) {
            console.log("Inside openFileModal...");

            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/permits/components/list/templates/modal-new-file.html',
                controller: 'PermitFileModalController',
                backdrop: 'static',
                keyboard: false,
                scope: $scope,
            }).result.then(function (saved_files) {
                if (Array.isArray(saved_files)) {

                    saved_files.forEach(function (new_file) {
                        $scope.PermitFiles.push(new_file);
                    });

                    $scope.permitFilesGrid.api.setRowData($scope.PermitFiles);
                }
                else
                    console.warn("looks like no files were saved?");
            });
        }

        $scope.removeSelectedFile = function () {

            if (!confirm("Are you sure you want to delete this file?")) {
                return;
            }

            var file_to_remove = $scope.permitFilesGrid.selectedItem;
            //var deleted = PermitService.deletePermitFile(PERMIT_PROJECTID, $scope.row.Id, 0, file_to_remove);
            var deleted = PermitService.deletePermitFile($scope.project.Id, $scope.row.Id, 0, file_to_remove);

            deleted.$promise.then(function () {
                $scope.PermitFiles.forEach(function (file, index) {
                    if (file.Id == file_to_remove.Id) {
                        $scope.PermitFiles.splice(index, 1);
                        $scope.permitFilesGrid.api.setRowData($scope.PermitFiles);
                    }
                });
                $scope.permitFilesGrid.selectedItem = null;  // To hide the Remove button.
                $scope.resetGrids($scope.row.Id);
                //$scope.$apply(); // To make the changes reflect on the page.
            });

        }

        $scope.createNewPermit = function () {

            if ($scope.row && $scope.row.dataChanged && !confirm("It looks like you've made edits on this page. Are you sure you want to clear everything and start a new permit?")) {
                return;
            }

            $scope.PermitTypes = PermitService.getPermitTypes(); //load the permit types fresh -- these have our permitnumber to increment...

            $scope.row = $scope.permitsGrid.selectedItem = GridService.getNewRow($scope.permitsGrid.columnDefs);

            $scope.PermitContacts = [];
            $scope.PermitParcels = [];
            $scope.PermitEvents = [];
            $scope.PermitFiles = [];
            $scope.ParcelHistory = [];

            $scope.resetGrids();
            $scope.togglePermitTypeField();
            //@ts-ignore
            $('#tab-basicinfo').tab('show'); //default to the "Permit Details" tab when select a different permit

        };

        $scope.removeSelectedContact = function () {
            if ($scope.permitContactsGrid.selectedItem && confirm("Are you sure you want to remove this Contact?")) {
                var removed = PermitService.removeContact($scope.permitContactsGrid.selectedItem);
                removed.$promise.then(function () {
                    $scope.PermitContacts.forEach(function (contact, index) {
                        if (contact.PersonId == $scope.permitContactsGrid.selectedItem.PersonId) {
                            $scope.PermitContacts.splice(index,1);
                            $scope.permitContactsGrid.api.setRowData($scope.PermitContacts);
                        }
                    });

                    if($scope.permitContactsGrid.selectedItem.IsPrimary){
                            $scope.permits.forEach(function(permit){
                                if(permit.Id == $scope.row.Id){
                                    permit.PrimaryContact = "";
                                }
                            })
                            $scope.permitsGrid.api.setRowData($scope.permits);
                            $scope.permitsGrid.api.redrawRows({rowNodes: [$scope.permitsGrid.selectedNode], force: true});

                            $scope.permitsGrid.api.forEachNode(function(node){
                                if(node.data.Id == $scope.row.Id){
                                    node.setSelected(true,true);
                                    setTimeout(() => {
                                      //@ts-ignore
                                        $('#tab-basicinfo').tab('show');
                                    }, 200);
                                    //$scope.permitsGrid.api.ensureIndexVisible($scope.permitsGrid.selectedNode.childIndex, 'top'); //scroll to the selected row
                                }
                            });

                    }

                });
            }
        };

        $scope.removeSelectedLocation = function () {
            console.log("Inside removeSelectedLocation...");

            if ($scope.permitLocationsGrid.selectedItem && confirm("Are you sure you want to remove this Location?")) {
                var removed = PermitService.removePermitLocation($scope.permitsGrid.selectedItem.Id);
                removed.$promise.then(function () {
                    $scope.PermitLocations.forEach(function (location, index) {
                        if (location.Id == $scope.permitLocationsGrid.selectedItem.Id) {
                            $scope.PermitLocations.splice(index,1);
                            $scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);
                        }
                    });
                    $scope.permitLocationsGrid.selectedItem = null;  // To hide the Remove button.
                    $scope.$apply(); // To make the changes reflect on the page.
                });

            }
        };

        $scope.removeSelectedParcel = function () {
            if ($scope.permitParcelsGrid.selectedItem && confirm("Are you sure you want to remove this Parcel?")) {
                var removed = PermitService.removePermitParcel($scope.permitParcelsGrid.selectedItem);
                removed.$promise.then(function () {
                    $scope.PermitParcels = PermitService.getPermitParcels($scope.row.Id);
                    $scope.PermitParcels.$promise.then(function () {
                        $scope.permitParcelsGrid.api.setRowData($scope.PermitParcels);
                        $scope.refreshZones();
                        $scope.refreshParcelHistory();
                    });

                });
            }
        };

        $scope.hasPrimaryContact = function() {
            var hasPrimaryContact = false;

            $scope.PermitContacts.forEach(function (contact, index) {
                if(contact.IsPrimary)
                    hasPrimaryContact = true;
            });

            return hasPrimaryContact;
        }

        $scope.openPermitReport = function(){
            if(!$scope.hasPrimaryContact() && $scope.row.IssueDate == null){
                alert("You must specify a primary contact and IssueDate before you can generate a Permit report.")
                return;
            }
            if(!$scope.hasPrimaryContact()){
                alert("You must specify a primary contact before you can generate a Permit report.")
                return;
            }
            if($scope.row.IssueDate == null){
                alert("You must specify an IssueDate before you can generate a Permit report.")
                return;
            }

            window.open("https://paluutreports.ctuir.org/Reports/report/TPO/DevelopmentPermit?PermitNumber=" + $scope.row.PermitNumber, "_blank");
        }

        $scope.openParcelInMap = function(){
          $rootScope.parcelMapFromList = $scope.permitParcelsGrid.selectedItem.ParcelId;
          $location.url("permits/map");

            // window.open("permits/map","_blank");
        }

        $scope.openCOReport = function(){
            if(!$scope.hasPrimaryContact()){
                alert("You must specify a primary contact before you can generate a Certificate of Occupancy report.")
                return;
            }
            window.open("https://paluutreports.ctuir.org/Reports/report/TPO/CertificateOfOccupancy?PermitNumber=" + $scope.row.PermitNumber, "_blank");
        }

        $scope.resetGrids = function () {
            //activate the permit contacts grid
            if (!$scope.permitContactsGridDiv) {
                $scope.permitContactsGridDiv = document.querySelector('#permit-contacts-grid');
                new Grid($scope.permitContactsGridDiv, $scope.permitContactsGrid);
            }

            //activate the permit locations grid
            if (!$scope.permitLocationsGridDiv) {
                $scope.permitLocationsGridDiv = document.querySelector('#permit-locations-grid');
                new Grid($scope.permitLocationsGridDiv, $scope.permitLocationsGrid);
            }

            //activate the permit parcels grid
            if (!$scope.permitParcelsGridDiv) {
                $scope.permitParcelsGridDiv = document.querySelector('#permit-parcels-grid');
                new Grid($scope.permitParcelsGridDiv, $scope.permitParcelsGrid);
            }

            //activate the parcel history grid
            if (!$scope.parcelHistoryGridDiv) {
                $scope.parcelHistoryGridDiv = document.querySelector('#parcel-history-grid');
                new Grid($scope.parcelHistoryGridDiv, $scope.parcelHistoryGrid);
            }

            //activate the permit files grid
            if (!$scope.permitFilesGridDiv) {
                $scope.permitFilesGridDiv = document.querySelector('#permit-files-grid');
                new Grid($scope.permitFilesGridDiv, $scope.permitFilesGrid);
            }

            $scope.permitContactsGrid.api.setRowData($scope.PermitContacts);
            $scope.permitParcelsGrid.api.setRowData($scope.PermitParcels);
            $scope.permitFilesGrid.api.setRowData($scope.PermitFiles);
            $scope.parcelHistoryGrid.api.setRowData($scope.ParcelHistory);
            //$scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);

            if ($scope.permitEventsGrid && $scope.permitEventsGrid.api)
                $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);

            if ($scope.permitsCanEdit) {
                $scope.permitContactsGrid.columnApi.setColumnVisible("EditLinks", true);
                $scope.permitParcelsGrid.columnApi.setColumnVisible("EditLinks", true);
                $scope.permitFilesGrid.columnApi.setColumnVisible("EditLinks", true);
            }

        };

        $scope.selectPermit = function (Id) {

            console.log("Inside selectPermit, Permit Id = " + Id);
            $scope.PermitContacts = PermitService.getPermitContacts(Id);
            $scope.PermitLocations = PermitService.getPermitLocations(Id);
            $scope.PermitParcels = PermitService.getPermitParcels(Id);
            $scope.PermitEvents = PermitService.getPermitEvents(Id);
            $scope.PermitFiles = PermitService.getPermitFiles($scope.project.Id, Id);

            $scope.row.ReviewsRequired = ($scope.row.ReviewsRequired) ? angular.fromJson($scope.row.ReviewsRequired) : [];

            if (!Array.isArray($scope.row.ReviewsRequired))
                $scope.row.ReviewsRequired = [];

            $scope.PermitContacts.$promise.then(function () {
                $scope.permitContactsGrid.api.setRowData($scope.PermitContacts);
                $scope.permitContactsGrid.selectedItem = null;
            });

            $scope.PermitLocations.$promise.then(function () {
                //console.log("$scope.PermitLocations in selectPermit is next...");
                //console.dir($scope.PermitLocations);
                $scope.permitLocationsGrid.api.setRowData($scope.PermitLocations);
                $scope.permitLocationsGrid.selectedItem = null;
            });

            // if($scope.row.LocationId) {
            //     console.log("Inside selectPermit, LocationId = " + $scope.row.LocationId);
            //     $scope.row.Activity = { LocationId : $scope.row.LocationId };
            // }

            $scope.PermitParcels.$promise.then(function () {
                $scope.permitParcelsGrid.api.setRowData($scope.PermitParcels);
                $scope.permitParcelsGrid.selectedItem = null;
                $scope.refreshParcelHistory();
                $scope.refreshZones();
            });

            $scope.PermitEvents.$promise.then(function () {
                $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);
                $scope.permitEventsGrid.selectedItem = null;

                $scope.PermitStatus = [];

                //setup our handy array for the Status tab
                $scope.row.ReviewsRequired.forEach(function (review) {

                    var route = {
                        ItemType: review,
                        EventType: 'Review',
                    };

                    $scope.PermitEvents.forEach(function (event) {
                        if (event.ItemType == review) //should only be one, really...
                        {
                          // @ts-ignore
                            route.RequestDate = event.RequestDate;
                            // @ts-ignore
                            route.ResponseDate = event.ResponseDate;
                            // @ts-ignore
                            route.Comments = event.Comments;
                        }
                    });

                    $scope.PermitStatus.push(route);

                });

                //and now once for inspections
                $scope.PermitEvents.forEach(function (event) {
                    var route = {};
                    if (event.EventType == "Inspection")
                    {
                      // @ts-ignore
                        route.RequestDate = event.RequestDate;
                        // @ts-ignore
                        route.ResponseDate = event.ResponseDate;
                        // @ts-ignore
                        route.Comments = event.Comments;
                        // @ts-ignore
                        route.EventType = event.EventType;
                        // @ts-ignore
                        route.ItemType = event.ItemType;

                        $scope.PermitStatus.push(route);
                    }
                });

                $scope.togglePermitTypeField();

                //stretch the textareas to the height of the content

                $('textarea').each(function () {

                    this.setAttribute('style', 'min-height: 130px, height:auto; height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
                  }).on('input', function () {
                    this.style.height = 'auto';
                    this.style.minHeight = '130px';
                    this.style.height = (this.scrollHeight) + 'px';
                     // @ts-ignore value is present on HTMLInputElement (which this is in actuality) but typescript is typesafe and returns the HTMLElement which does not have a value property
                    this.value = this.value.replace(/\n/g, ""); //do not allow hard-returns
                  });

            });

            $scope.PermitFiles.$promise.then(function () {
                $scope.permitFilesGrid.api.setRowData($scope.PermitFiles);
                $scope.permitFilesGrid.selectedItem = null;
            });

            if(!Array.isArray($scope.row.Zones)){
                $scope.row.Zones = [];

                if ($scope.row.Zoning) {
                    $scope.row.Zoning = getJsonObjects($scope.row.Zoning);
                    //console.warn(" -- Zoning -- ");
                    //console.dir($scope.row.Zoning);

                } else {
                    $scope.row.Zoning = [];
                }
            }

            //$scope.$apply();
        };

        console.log("setting up grids")
        $scope.resetGrids();

        //if the permit is already saved, PermitType should be disabled
        $scope.togglePermitTypeField = function(){
            if($scope.row.Id){
                $("#field-PermitType select.form-control").attr("disabled","disabled");
            }
            else{
                $("#field-PermitType select.form-control").removeAttr("disabled");
            }
        }

        $scope.refreshParcelHistory = function () {
            $scope.ParcelHistory = [];
            $scope.parcelHistoryGrid.api.setRowData($scope.ParcelHistory);

            //iterate parcels to find any related permits
            $scope.PermitParcels.forEach(function (parcel) {
                console.log("Getting related permits for this parcel:  " + parcel.ParcelId);
                var related_permits = PermitService.getPermitsByRelatedParcels(parcel.ParcelId);
                related_permits.$promise.then(function () {
                    related_permits.forEach(function (permit) {
                        if (permit.Id !== $scope.row.Id) {
                            permit.MatchingParcelId = parcel.ParcelId;
                            $scope.ParcelHistory.push(permit);
                        }
                    });
                    $scope.parcelHistoryGrid.api.setRowData($scope.ParcelHistory);
                });
            });
        };

        //if we are configured with a zoning field, true/false
        $scope.hasZoningField = function(){
            if(!$scope.permitsGrid.columnDefs)
                return false;

            return (getByName($scope.permitsGrid.columnDefs, 'Zoning') != null);
        }

        //are there any fields in col_index ?
        $scope.hasColumnIndexFields = function(col_index){
            if(!$scope.permitsGrid.columnDefs)
                return false;

            var has_col_index = false;
            $scope.permitsGrid.columnDefs.forEach(function(item){
                if (item.cdmsField && item.cdmsField.ColumnIndex == col_index)
                    has_col_index = true;
            })

            return has_col_index;
        }


        $scope.refreshZones = function () {
            if(!$scope.hasZoningField){
                return;
            }

            $scope.row.Zones.length = 0;
            $scope.PermitParcels.forEach(function (parcel) {
                if(parcel.Object){
                    var the_zones = parcel.Object.ZoneCode.split(":");

                    if (Array.isArray(the_zones)) {
                        the_zones.forEach(function (zone) {
                            if (!$scope.row.Zones.contains(zone))
                                $scope.row.Zones.push(zone);
                        });
                    };
                }
            });
        };


        //this function populates the zones using the live ArcGIS server layers but it is too slow,
        //  so we'll use the above strategy instead and keep this around in case we change our mind.
      // apparently not used so commenting out - KS
        // $scope.refreshZonesLive = function () {
        //     $scope.refreshingZones = true;
        //     $scope.row.Zones.length = 0;
        //     loadModules([
        //         'esri/config',
        //         'esri/tasks/query',
        //         'esri/tasks/QueryTask'], {version: '3.37'}).then(([esriConfig, Query, QueryTask]) => {
        //             esriConfig.defaults.io.proxyUrl = proxyUrl; // From the config.js file.
        //             esriConfig.defaults.io.alwaysUseProxy = true;
        //
        //             //get the geometries of our related parcels
        //             var parcelids = [];
        //             $scope.PermitParcels.forEach(function (parcel) {
        //                 parcelids.push(parcel.ParcelId);
        //             });
        //             //2N30900000105
        //
        //             if (parcelids.length == 0) {
        //                 console.warn("no parcelids to search for");
        //                 $scope.refreshingZones = false;
        //                 return;
        //             }
        //
        //             // @ts-ignore
        //             parcelids = parcelids.join("','");
        //
        //             var queryTask = new QueryTask(PARCEL_LAYER);
        //             var query = new Query();
        //
        //             query.where = "PARCELID in ('" + parcelids + "')";
        //
        //             console.log("looking for parcels: " + query.where);
        //
        //             query.outSpatialReference = { wkid: 102100 };
        //             query.returnGeometry = true;
        //             query.outFields = ["*"];
        //
        //             queryTask.execute(query, function (result) {
        //                 console.dir(result);
        //
        //                 result.features.forEach(function (feature) {
        //                     console.log("Ok - trying to find the zones for: ");
        //                     console.dir(feature);
        //
        //                     var zoneQueryTask = new QueryTask(ZONING_LAYER);
        //                     var zoneQuery = new Query();
        //
        //                     zoneQuery.outSpatialReference = { wkid: 102100 };
        //                     zoneQuery.returnGeometry = true;
        //                     zoneQuery.outFields = ["*"];
        //                     zoneQuery.geometry = feature.geometry;
        //                     zoneQuery.spatialRelationship = Query.SPATIAL_REL_INTERSECTS; //Query.SPATIAL_REL_INTERSECTS; //SPATIAL_REL_OVERLAPS?
        //                     zoneQuery.where = "1=1";
        //                     zoneQuery.maxAllowableOffset = 0;
        //                     zoneQuery.distance = 0;
        //
        //                     zoneQueryTask.execute(zoneQuery, function (zqresult) {
        //                         console.log("back from zone query with a result: ");
        //                         console.dir(zqresult);
        //                         zqresult.features.forEach(function (zfeature) {
        //                             if (!$scope.row.Zones.contains(zfeature.attributes.ZONECODE))
        //                                 $scope.row.Zones.push(zfeature.attributes.ZONECODE);
        //                             console.dir($scope.row.Zones);
        //                         });
        //
        //                         //refresh our view
        //                         setTimeout(function () {
        //                             $scope.$apply(function () {
        //                                 //$scope.row.Zoning = zones; //zones.join(",");
        //                                 $scope.refreshingZones = false;
        //                             });
        //                         }, 500);
        //
        //                     }, function (err) {
        //                         console.log("Failure executing query!");
        //                         console.dir(err);
        //                         console.dir(zoneQuery);
        //                         $scope.refreshingZones = false;
        //                     });
        //
        //                 });
        //
        //                 if (result.features.length == 0)
        //                     $scope.refreshingZones = false;
        //
        //             }, function (err) {
        //                 console.log("Failure executing query!");
        //                 console.dir(err);
        //                 // @ts-ignore
        //                 console.dir(zoneQuery);
        //                 $scope.refreshingZones = false;
        //             });
        //
        //         });
        //
        //
        // };


        $scope.onHeaderEditingStopped = function (field) { //fired onChange for header fields (common/templates/form-fields)

            //console.log("onHeaderEditingStopped: " + field.DbColumnName);
            //console.dir(this);

            if ($scope.row)
            {
                if (field.DbColumnName == "LocationId")
                {
                    // if (this.row[field.DbColumnName])
                    //     $scope.row[field.DbColumnName] = this.row[field.DbColumnName];
                    // else if (this.row.Activity[field.DbColumnName])
                    // {
                    //     $scope.row[field.DbColumnName] = this.row.Activity[field.DbColumnName];
                    //     $scope.row[field.DbColumnName].innerHTML = $scope.row[field.DbColumnName];
                    // }
                    $scope.row[field.DbColumnName] = this.row.Activity[field.DbColumnName];
                }

                if (field.DbColumnName == "IssuedBy")
                {
                    var dtToday = moment().format('MM/DD/YYYY');
                    $scope.row.IssueDate = dtToday;

                    var dtExpireDate = moment().add(12, 'M').format('MM/DD/YYYY');
                    $scope.row.ExpireDate = dtExpireDate;
                }


                //build event to send for validation
                var event = {
                    colDef: field,
                    node: { data: $scope.row },
                    value: $scope.row[field.DbColumnName],
                    type: 'onHeaderEditingStopped',
                    scope: $scope
                };

                if (GridService.validateCell(event)) {
                    GridService.fireRule("OnChange", event); //only fires when valid change is made
                }

                //update our collection of header errors if any were returned
                $scope.headerFieldErrors = [];
                if ($scope.row.rowHasError) {
                    $scope.row.validationErrors.forEach(function (error) {
                        if (Array.isArray($scope.headerFieldErrors[error.field.DbColumnName])) {
                            $scope.headerFieldErrors[error.field.DbColumnName].push(error.message);
                        } else {
                            $scope.headerFieldErrors[error.field.DbColumnName] = [error.message];
                        }
                    });
                }

                if ($scope.row.hasOwnProperty(field.DbColumnName)) { //make sure it is a header field from the permit

                    //did the data actually change?
                    //the selected original permit
                    var selected = $scope.permitsGrid.api.getSelectedRows()[0];

                    //if we've lost our original selection, find it in the permits
                    if (!selected || selected.Id != $scope.row.Id) {
                        $scope.permits.forEach(function (itr_permit) {
                            if (itr_permit.Id == $scope.row.Id)
                                selected = itr_permit;
                        });
                    }

                    if (selected && selected[field.DbColumnName] != $scope.row[field.DbColumnName]) {
                        $scope.row.dataChanged = true;
                    }

                    if(!selected && !$scope.row.Id) //then it is a new record
                        $scope.row.dataChanged = true;

                }

                $rootScope.$emit('headerEditingStopped', field); //offer child scopes a chance to do something, i.e. add activity modal...

                //if this is a new permit and they changed the Permit Type, then update the permit number
                //console.log(field.DbColumnName);
                //console.log($scope.row.Id);
                if (field.DbColumnName == 'PermitType' && !$scope.row.Id) {
                    $scope.generatePermitNumber();
                }
            }

        };

        $scope.generatePermitNumber = function () {
            var permitnumber = "XXX";
            $scope.PermitTypes.forEach(function (type) {
                if (type.Id === $scope.row.PermitType) {
                    if (moment().year() > type.CurrentPermitYear)
                        type.CurrentPermitNumber = 1;

                    //permitnumber = (type.CurrentPermitNumber + 1+"").padStart(3, '0');
                    permitnumber = type.PermitNumberPrefix + "-" + moment().format('YY') + "-" + permitnumber;

                    $scope.row.PermitNumber = permitnumber;
                }
            });
        };

        $scope.submitSearch = function() {
            console.log("Inside $scope.submitSearch...");
            console.log("search_term = " + $scope.search_term);

            //var objSearchTerm = "{" + $scope.search_term + "}";

            //var searchForItems = JSON.parse(objSearchTerm);

            // for (var item in searchForItems)
            // {
            //     console.log("item = " + item + ", value = " + searchForItems[item]);
            // }

            //for (var permit in $scope.permits)
            // for (var i = 0; i < $scope.permits.length; i++)
            // {
            //     for (var prop in $scope.permits[i])
            //     {
            //         for (var item in searchForItems)
            //         {
            //             console.log("item = " + item + ", value = " + searchForItems[item]);
            //             //if (prop == item)
            //             if ($scope.permits[i][prop] == searchForItems[item])
            //             {
            //                 console.log("Found one...");
            //             }
            //         }
            //     }
            // }

            $scope.search_permits = PermitService.searchPermits($scope.search_term);
            $scope.search_permits.$promise.then(function(){
                // $scope.search_permits.forEach(function(permit){
                //     $scope.results.push(
                //         {
                //             // "Name": project.Name,
                //             // "Description": project.Description,
                //             // "Type": "Project",
                //             // "Link": "/projects/"+project.Id

                //             permit
                //         })
                // })
                //$scope.results = angular.copy($scope.search_permits);
                //$scope.permitsGrid.api.setRowData($scope.results)
                $scope.permitsGrid.api.setRowData($scope.search_permits)
            })


        };

        $scope.cancel = function () {

            $scope.permitsGrid.selectedItem = $scope.row = null;
            $scope.permitsGrid.api.deselectAll();

        };

        $scope.getGroupIdFromPermitType = function(permitTypeId){
            var groupId = 0;
            var searchResult = "";
            var foundIt = 1;
            $scope.PermitTypes.forEach(function(type){
                if(type.Id == permitTypeId)
                {
                    groupId = type.GroupId;
                    foundIt = 0;
                }
            })
            if (foundIt === 0)
                searchResult = "Found match for permitTypeId in PermitTypes...; permitTypeId = " + permitTypeId;
            else
                searchResult = "Could not find permitTypeId in PermitTypes...; permitTypeId = " + permitTypeId;

            console.log(searchResult);

            return groupId;
        }

        $scope.save = function () {

            if(!$scope.row.PermitType)
            {
                alert("You must select a Permit Type");
                return;
            }

            var to_save = angular.copy($scope.row);
            $scope.row.isSaving = true;
            to_save.ReviewsRequired = angular.toJson(to_save.ReviewsRequired);
            to_save.Zoning = angular.toJson(to_save.Zoning);
            to_save.GroupId = $scope.getGroupIdFromPermitType(to_save.PermitType);

            if ($scope.row.LocationId)
            {
                $scope.PermitLocations.forEach(function(aLoc) {
                    if (aLoc.Label === to_save.LocationId)
                        to_save.LocationId = aLoc.Id;
                });
            }

            var saved_permit = PermitService.savePermit(to_save);

            saved_permit.$promise.then(function () {
                $scope.row.isSaving = false;
                // console.log("permit saved: ");
                // console.dir(saved_permit);

                //requirement: if we saved a new status, add a record to the permitsevents
                if ($scope.row.Id && $scope.row.PermitStatus !== $scope.permitsGrid.api.getSelectedRows()[0].PermitStatus) {
                    var new_event = {
                        PermitId: $scope.row.Id,
                        ByUser: $scope.Profile.Id,
                        EventDate: moment().format('L'),
                        RequestDate: moment().format('L'),
                        ResponseDate: moment().format('L'),
                        EventType: "Record",
                        ItemType: "TPO",
                        Reviewer: $scope.Profile.Fullname,
                        Comments: "Update Status from " + $scope.permitsGrid.api.getSelectedRows()[0].PermitStatus + " to " + $scope.row.PermitStatus
                    };
                    console.log("saving a permitevent since we updated the status"); console.dir(new_event);
                    var save_event = PermitService.savePermitEvent(new_event);
                    save_event.$promise.then(function () {
                        //refresh the activities now that we've saved a new one.
                        $scope.PermitEvents = PermitService.getPermitEvents($scope.row.Id);
                        $scope.PermitEvents.$promise.then(function () {
                            $scope.permitEventsGrid.api.setRowData($scope.PermitEvents);
                        });
                    });
                }

                if (!$scope.row.Id) {
                    $scope.permits.push(saved_permit);
                    $scope.permitsGrid.api.setRowData($scope.permits);
                    $scope.row = saved_permit;
                    $scope.row.dataChanged = false;
                    $scope.showAll();
                }
                else {
                    $scope.permits.forEach(function (existing_permit) {
                        if (existing_permit.Id == $scope.row.Id) {
                            angular.extend(existing_permit, saved_permit);
                        }
                    });

                    $scope.selectPermit($scope.row.Id);
                    $scope.ShowPermitListGrid = true;

                    $scope.permitsGrid.api.redrawRows();
                    //$scope.permitsGrid.api.setRowData($scope.permits);

                    $scope.row.dataChanged = false;

                }

                //select the permit we just saved/updated
                $scope.permitsGrid.api.forEachNode(function(node){
                    if(node.data.PermitNumber == $scope.row.PermitNumber){
                        node.setSelected(true);
                        $scope.permitsGrid.api.ensureIndexVisible(node.childIndex, 'bottom'); //scroll to the selected row
                    }
                })

            },function(data){
                $scope.row.isSaving = false;
                $scope.row.hasError = true;
                $scope.row.errorMessage = "There was a problem saving."
            });
        };

        //handle favorite toggle
        $scope.toggleFavorite = function () {
            UserService.toggleFavoriteDataset($scope, $rootScope);
        }
}];

export default list_permits;
