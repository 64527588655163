// defines the appraisals module
import * as angular from 'angular';

// require([
//controllers
import appraisal_map from "./components/appraisal-activities/appraisal-map";
import {downgradeComponent} from "@angular/upgrade/static";
import {AppraisalMapComponent} from "../../../app/map/appraisal-map/appraisal-map.component";


// ], function () {
const appraisals_module = angular.module('AppraisalsModule', ['ui.bootstrap', 'ngResource']);
appraisals_module.controller('AppraisalMapCtrl', appraisal_map);
appraisals_module.directive('appraisalMap', downgradeComponent({component: AppraisalMapComponent}));

export default appraisals_module;

// });



