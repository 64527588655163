import * as angular from 'angular';
import * as moment from 'moment';
import {getAgGridFilterByType, valueFormatterDate} from "../../../../core/common/common-functions";
import {LeaseModalScope} from "./lease-scope";
// import leasing_module from "../../leasing-module";
import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';
import {LEASE_STATUS_PENDING} from "../../../../config";

var available_land = ['$scope', '$route', '$filter', '$uibModal', '$location', '$window', '$rootScope', 'LeasingService',
    function ($scope, $route, $filter, $uibModal, $location, $window, $rootScope, LeasingService) {

        $rootScope.inModule = "leasing";

        if (!$scope.Profile.hasRole("Leasing"))
            angular.rootScope.go("/unauthorized");

        $scope.currentPage = "Available";

        $scope.fields = LeasingService.getAvailableFields();

        LeaseModalScope.prepareLeaseModalScope($scope, LeasingService);

        $scope.fields.$promise.then(function () {
            $scope.leaseGridDiv = document.querySelector('#available-land-grid');
            new Grid($scope.leaseGridDiv, $scope.leaseGrid);
        });

        $scope.countLandUse = function (landuse) {
            return $filter('filter')($scope.fields, { FieldLandUse: landuse }).length;
        };

        $scope.showAll = "No";
        $scope.toggleShowAll = function () {
            $scope.showAll = ($scope.showAll == 'Yes') ? 'No' : 'Yes';
            if ($scope.showAll == 'Yes') {
                $scope.fields = LeasingService.getAvailableAllotments();
                $scope.fields.$promise.then(function () {
                    $scope.leaseGrid.api.setRowData($scope.fields);
                });
            } else {
                $scope.fields = LeasingService.getAvailableFields();
                $scope.fields.$promise.then(function () {
                    $scope.leaseGrid.api.setRowData($scope.fields);
                });
            }
        }

        var EditLinksTemplate = function (param) {

            var div = document.createElement('div');

            //Modify editBtn.href from '#' to GIS Tax Parcel Viewer URL
            var editBtn = document.createElement('a'); editBtn.href = 'https://gisportal.ctuir.org/portal/apps/webappviewer/index.html?id=54fd175f4e2745ff92b278d04b987ad9'; editBtn.innerHTML = 'Map';
            editBtn.addEventListener('click', function (event) {
                event.preventDefault();
                //Change "viewOnMap" functionality to window.open() to open the GIS Tax Parcel
                // Viewer rather than the embedded map

                //$scope.viewOnMap(param.data);
                window.open(editBtn.href, '_blank');
            });
            div.appendChild(editBtn);

            return div;
        };

        var leaseColumnDefs = [
            { colId: 'EditLinks', width: 60, cellRenderer: EditLinksTemplate, menuTabs: [] },
            { headerName: "Allotment", field: "AllotmentName", width: 120, menuTabs: ['filterMenuTab'], filter: "text", sort: 'asc' },
            { headerName: "Field Land Use", field: "FieldLandUse", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Field #", field: "FieldId", width: 100, menuTabs: ['filterMenuTab'], filter: "number" },
            { headerName: "Field Acres", field: "FieldAcres", width: 140, menuTabs: ['filterMenuTab'], filter: "number" },
            { headerName: "Expires", field: "Expiration", width: 140, menuTabs: ['filterMenuTab'], filter: true },
            {
                headerName: "Date Available", field: "DateAvailable", width: 160,
                valueGetter: function (params) { return moment(params.node.data.DateAvailable) },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.DateAvailable);
                },
                menuTabs: ['filterMenuTab'], filter: "agDateColumnFilter"
            },
            //{ headerName: "AY Income", field: "AvgAnnualIncome", width: 140, menuTabs: ['filterMenuTab'], filter: "number" },
            //{ headerName: "AYI Per Acre", field: "AvgAnnualIncomePerAcre", width: 140, menuTabs: ['filterMenuTab'], filter: "number" },
            //{ headerName: "Yield Per Unit", field: "NumUnit", width: 140, menuTabs: ['filterMenuTab'], filter: "number" },
            { headerName: "FSA Tract #", field: "FSATractNumber", width: 140, menuTabs: ['filterMenuTab'], filter: "text" },
            //{ headerName: "HEL", field: "HEL", width: 100 },
            { headerName: "TAAMS Number", field: "TAAMSNumber", width: 140, menuTabs: ['filterMenuTab'], filter: "text" },
        ];


        $scope.leaseGrid = {
            columnDefs: leaseColumnDefs,
            rowData: $scope.fields,
            rowSelection: 'multiple',
            selectedItems: [],
            onSelectionChanged: function () {
                $scope.leaseGrid.selectedItems = $scope.leaseGrid.api.getSelectedRows();
                $scope.$apply(); //we're changing something that angular isn't aware of
            },
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
            },
        }

        $scope.openNewLeaseModal = function (params) {

            var selected_land = $scope.leaseGrid.selectedItems[0];

            $scope.lease = {
                FSATractNumber: selected_land.FSATractNumber,
                TAAMSNumber: selected_land.TAAMSNumber,
                AllotmentName: selected_land.AllotmentName,
                LeaseType: selected_land.FieldLandUse,
                LeaseAcres: selected_land.FieldAcres,
                ProductiveAcres: selected_land.FieldAcres,
                LeaseStart: selected_land.DateAvailable,
                Level: 1, //Level 1

                Status: LEASE_STATUS_PENDING, //Pending
                FieldsToLink: [], //comma separated list of fields we will join this lease to
                LeaseFields: [],
                StatusDate: $scope.currentDay,
                StatusBy: $scope.currentUser,
            };

            $scope.leaseGrid.selectedItems.forEach(function (item) {
                if (item.FieldId) {
                    $scope.lease.FieldsToLink.push(item.FieldId);
                    $scope.lease.LeaseFields.push(item);
                }
            });


            var modalInstance = $uibModal.open({
                templateUrl: 'appjsLegacy/private/leasing/components/manage/templates/lease-modal.html',
                controller: 'LeaseModalController',
                scope: $scope, //very important to pass the scope along...
                backdrop: "static",
                keyboard: false
            });
        }

        $scope.saveLeaseCallback = function (saved_lease) {
            $scope.fields = LeasingService.getAvailableFields();
            $scope.fields.$promise.then(function () {
                if ($scope.leaseGrid.api)
                    $scope.leaseGrid.api.setRowData($scope.fields);
            });
        }


        $scope.currentUser = $rootScope.Profile.Fullname;
        $scope.currentDay = moment().format();

        $scope.viewOnMap = function (params) {
            $location.url("leasing?allotment=" + params.AllotmentName);
        }




}];

export default available_land;
