import {Component, OnInit} from '@angular/core';
import {ConfirmService} from "../confirm.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-confirm-review',
  templateUrl: './confirm-review.component.html',
  styleUrls: ['./confirm-review.component.scss']
})
export class ConfirmReviewComponent implements OnInit {
  public error = undefined
  public data: any;
  public PermitEventId: any;
  public confirmData: { PermitEventId: any, Name?: any, Email?: any, Comments?: any, Confirmation?: any, ProposedTime?: any };
  public downloadLink: string;
  public savedMessage: string;
  public downloadName: string;
  private routeSub: Subscription;
  public loading: boolean = true;
  public confirmName: string;
  public confirmEmail: string;
  public confirmComments: string;
  public confirmConfirmation: any;

  constructor(private confirmService: ConfirmService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.PermitEventId = params['Id'];
      console.log(this.PermitEventId)
    });

    // let PermitEventId = $routeParams["Id"];
    // this.data = this.confirmService.getConfirmationData(this.PermitEventId).subscribe(val => this.data = val);
    this.data = this.confirmService.getConfirmationData(this.PermitEventId).toPromise();

    this.data.then((res) => {
      this.data = res;
      this.confirmData = {"PermitEventId": this.data.PermitEvent.Id};

      if (this.data.User) {
        //@ts-ignore
        this.confirmData.Name = this.data.User.Fullname;
        //@ts-ignore
        this.confirmData.Email = this.data.PermitEvent.AssigneeEmail
      }
      this.loading = false
    })
    this.downloadLink = "";
    // this.confirm();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  confirm() {
    if (this.confirmData) {
      if (!this.confirmData.Name || !this.confirmData.Email || !this.confirmData.Confirmation) {
        alert("Please fill in all required form fields.");
        return;
      }
      console.log(this.confirmData);
      let save_result = this.confirmService.saveConfirmation(this.confirmData).toPromise();
      save_result.then(() => {

        if (this.data.PermitEvent.EventType == "Inspection") {
          this.savedMessage = "Successfully confirmed. Generating inspection form...";
          let template = this.confirmService.downloadInspectionDocument(this.data.PermitEvent.Id)
          template.toPromise().then((res) => {
            this.savedMessage = "Successfully confirmed.";
            //@ts-ignore
            this.downloadLink = res.InspectionFormUrl;
            this.downloadName = "InspectionForm_" + this.data.PermitEvent.Id + ".pdf";
          });
        } else {
          this.savedMessage = "Successfully confirmed.";
        }

      }, (data) => {
        this.error = "There was a problem saving your confirmation. Please call the CTUIR TPO office to confirm (541-276-3099) or try again."
        console.dir(data);
      })
    }


  }
}
