import * as angular from 'angular';
import * as moment from 'moment';
import {PROJECT_TYPE_BOTACTIONS, PROJECT_TYPE_BOTPROJECTS, PROJECT_TYPE_OEDPROJECTS} from "../../../../config";
import {valueFormatterCurrency, valueFormatterDate} from "../../../../core/common/common-functions";

import {Grid} from "ag-grid-community";
import 'ag-grid-enterprise';

var bot_projects = ['$scope', '$route', '$routeParams', '$uibModal', '$location', '$window', '$rootScope', 'BOTService', 'GridService', 'DatasetService','CommonService',
    function ($scope, $route, $routeParams, $uibModal, $location, $window, $rootScope, BOTService, GridService, DatasetService, CommonService) {

        $scope.filter_projects = "All";

		$scope.PROJECT_TYPE_BOTACTIONS = PROJECT_TYPE_BOTACTIONS;
		$scope.PROJECT_TYPE_BOTPROJECTS = PROJECT_TYPE_BOTPROJECTS;
		$scope.PROJECT_TYPE_OEDPROJECTS = PROJECT_TYPE_OEDPROJECTS;

        $scope.filterProjects = function(project_type){

            $scope.filter_projects = project_type;

            if($scope.projectsGrid){
                $scope.projectsGrid.api.setRowData([]);
                $scope.projectsGrid.api.showLoadingOverlay();
                $scope.projectsGrid.selectedItem = null;
                $scope.objectivesGrid.selectedItem = null;
            }

            $scope.projects = BOTService.getBOTProjects(project_type);

            $scope.projects.$promise.then(function(){
                //console.dir($scope.projects);
                $scope.activateGrid();
                $scope.projectsGrid.api.setRowData($scope.projects);

                //GridService.autosizeColumns($scope.projectsGrid);
                //console.log("done!")
            })

        }

        $scope.showAllProjects = function(){
            if($scope.projectsGrid && $scope.projectsGrid.api){
                $scope.projectsGrid.api.setRowData([]);
                $scope.projectsGrid.api.showLoadingOverlay();
                $scope.projectsGrid.selectedItem = null;
                $scope.objectivesGrid.selectedItem = null;
            }

            $scope.projects = BOTService.getAllBOTProjects();

            $scope.projects.$promise.then(function(){
                $scope.activateGrid();
                $scope.projectsGrid.api.setRowData($scope.projects);
            })

            $scope.filter_projects = "All";

        }

        //cool note: the brackets mean it uses the variable in config.js as the value so: 17: "BOT Actions"
        $scope.projectTypes = {
            [PROJECT_TYPE_BOTACTIONS]: "BOT Actions",
            [PROJECT_TYPE_BOTPROJECTS]: "BOT Projects",
            [PROJECT_TYPE_OEDPROJECTS]: "OED Projects"
        }

console.dir($scope.projectTypes);

        var projectsColumnDefs = [
            { headerName: "Type", field: "ProjectTypeId", width: 200, menuTabs: ['filterMenuTab'], filter: true,

                valueFormatter: function(params) {
                    return $scope.projectTypes[params.value]
                },
                valueSetter: function(params){

                    Object.keys($scope.projectTypes).forEach(function(key){
                        if($scope.projectTypes[key] == params.newValue){
                            params.data.ProjectTypeId = key
                            return true;
                        }
                    })
                    return false;
                },

                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Object.values($scope.projectTypes)
                }
            },
            { headerName: "Priority", field: "Priority", width: 200, menuTabs: ['filterMenuTab'], filter: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [
                        'Organizational Excellence',
                        'Land Restoration',
                        'Comm & Econ Dev',
                        'Treaty Rights',
                        'Housing',
                        'Education',
                        'Health & Human',
                        'Infrastructure',
                        'Transportation',
                        'Public Safety'
                    ]
                }
            },
            { headerName: "Resolution/Motion", field: "Name", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Title", field: "Description", width: 430, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Due Date", field: "DueDate", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                valueGetter: function (params) { return moment(params.node.data.DueDate).format('MM/DD/YYYY') },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.DueDate);
                },
                //cellEditor: 'datePicker',
            },
            { headerName: "Budget", field: "Budget", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                valueFormatter: function (params) {
                    return valueFormatterCurrency(params.node.data.Budget);
                },
            },
            { headerName: "Spending", field: "Spending", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                valueFormatter: function (params) {
                    return valueFormatterCurrency(params.node.data.Spending);
                },
            },
            { headerName: "Status", field: "Status", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [
                        'Not Started',
                        'On Hold',
                        'Complete',
                        'In Progress'
                    ]
                }
            },
            { headerName: "Status Updated", field: "LastStatusUpdate", width: 120, menuTabs: ['filterMenuTab'], filter: true, editable: false,
                valueGetter: function (params) { return moment(params.node.data.LastStatusUpdate).format('MM/DD/YYYY') },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.LastStatusUpdate);
                },
            },
            { headerName: "# Tasks", width: 100, menuTabs: ['filterMenuTab'], filter: true, editable: false,
                valueFormatter: function (params) {
                    return params.node.data.ProjectObjectives.length;
                },
            },

        ];


        $scope.projectsGrid = {
            columnDefs: projectsColumnDefs,
            rowData: [],
            enableSorting: true,
            enableFilter: true,
            rowSelection: 'single',
            editType: 'fullRow',
            defaultColDef: {
                editable: true,
                sortable: true,
                resizable: true
            },
            selectedItem: null,
            selectedCell: null,
            selectedNode: null,
            onSelectionChanged: function (params) {
                $scope.projectsGrid.selectedItem = angular.copy($scope.projectsGrid.api.getSelectedRows()[0]);
                $scope.projectsGrid.selectedNode = $scope.projectsGrid.api.getSelectedNodes()[0];

                $scope.objectivesGrid.api.setRowData($scope.projectsGrid.selectedItem.ProjectObjectives);
                $scope.objectivesGrid.selectedItem = $scope.objectivesGrid.selectedNode = null;

                //set ontrack
                $scope.projectsGrid.selectedItem.OnTrack = (moment($scope.projectsGrid.selectedItem.DueDate).isBefore(moment()) && $scope.projectsGrid.selectedItem.Status != "Complete") ? "No" : "Yes";

                //setTimeout(function(){
                    //GridService.autosizeColumns($scope.objectivesGrid);
                //},200);
                //console.dir($scope.projectsGrid.selectedItem)
                $scope.$apply();
            },
            //components: {
            //    datePicker: getDatePicker(),
            //},
            onRowEditingStopped: function(params){
                console.log("row editing stopped. save");
                console.dir(params);
                $scope.saveProject(params.data);
            }
        }

        $scope.addProject = function(){
            $scope.projects.push({
                ProjectObjectives: [],
                LastStatusUpdate: moment().format('MM/DD/YYYY')
            });
            $scope.projectsGrid.api.setRowData($scope.projects);
        }

        $scope.saveProject = function(data){
            //data.ProjectTypeId = $scope.filter_projects;
            data.OrganizationId = 1; //default to CTUIR
            var saved = BOTService.saveBOTProject(data);
            saved.$promise.then(function(){
                $scope.projects.forEach(function(project, index){
                    if(!project.Id) {
                        $scope.projects.splice(index, 1, saved);
                        $scope.projectsGrid.api.setRowData($scope.projects);
                    }
                });
            })
        }

        //activate the grid once we have the employees back.
        $scope.activateGrid = function(){

            //activate the grid
            if (!$scope.GridDiv) {
                $scope.GridDiv = document.querySelector('#projects-grid');
                new Grid($scope.GridDiv, $scope.projectsGrid);

                $scope.ObjectivesGridDiv = document.querySelector('#objectives-grid');
                new Grid($scope.ObjectivesGridDiv, $scope.objectivesGrid);

                $scope.ReportsGridDiv = document.querySelector('#reports-grid');
                new Grid($scope.ReportsGridDiv, $scope.reportsGrid);
            }

        }



        var objectivesColumnDefs = [
            { headerName: "Objective", field: "Name", width: 300, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Description", field: "Description", width: 350, menuTabs: ['filterMenuTab'], filter: true, cellStyle: {'white-space': 'normal'} },
            { headerName: "Department", field: "Department", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Responsible Staff", field: "Owner", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Due Date", field: "DueDate", width: 120, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc',
                valueGetter: function (params) { return moment(params.node.data.DueDate).format('MM/DD/YYYY') },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.DueDate);
                },
                //cellEditor: 'datePicker',
            },
            { headerName: "Budget", field: "Budget", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                valueFormatter: function (params) {
                    return valueFormatterCurrency(params.node.data.Budget);
                },
            },
            { headerName: "Spending", field: "Spending", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                valueFormatter: function (params) {
                    return valueFormatterCurrency(params.node.data.Spending);
                },
            },
            { headerName: "Status", field: "Status", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [
                        'Not Started',
                        'On Hold',
                        'Complete',
                        'In Progress'
                    ]
                }
            },
            { headerName: "Status Updated", field: "LastUpdated", width: 120, menuTabs: ['filterMenuTab'], filter: true, editable: false,
                valueGetter: function (params) { return moment(params.node.data.LastUpdated).format('MM/DD/YYYY') },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.LastUpdated);
                },
            },
            { headerName: "Percent Complete", field: "PercentComplete", width: 120, menuTabs: ['filterMenuTab'], filter: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [
                        100,
                        75,
                        50,
                        25,
                        0
                    ]
                }
            },


        ];


        $scope.objectivesGrid = {
            columnDefs: objectivesColumnDefs,
            rowData: [],
            enableSorting: true,
            enableFilter: true,
            rowSelection: 'single',
            defaultColDef: {
                editable: true,
                sortable: true,
                resizable: true
            },
            selectedItem: null,
            selectedCell: null,
            selectedNode: null,
            editType: 'fullRow',
            onSelectionChanged: function (params) {
                //console.dir(params)
                $scope.objectivesGrid.selectedItem = angular.copy($scope.objectivesGrid.api.getSelectedRows()[0]);
                $scope.objectivesGrid.selectedNode = $scope.objectivesGrid.api.getSelectedNodes()[0];

                //set ontrack
                $scope.objectivesGrid.selectedItem.OnTrack = (moment($scope.objectivesGrid.selectedItem.DueDate).isBefore(moment()) && $scope.objectivesGrid.selectedItem.Status != "Complete") ? "No" : "Yes";

                $scope.reportsGrid.api.setRowData([]);
                $scope.reportsGrid.api.showLoadingOverlay();

                if($scope.objectivesGrid.selectedItem.Id)
                {
                    $scope.objectiveReports = BOTService.getProjectObjectiveReports($scope.objectivesGrid.selectedItem.Id);
                    $scope.objectiveReports.$promise.then(function(){
                        $scope.reportsGrid.api.setRowData($scope.objectiveReports);
                    });
                }

                $scope.$apply();
            },
            onRowEditingStopped: function(params){
                $scope.saveObjective(params.data);
            },
            getRowHeight: function (params) {
                var comment_length = (!params.data.Description) ? 1 : params.data.Description.length;
                var comment_height = 25 * (Math.floor(comment_length / 45) + 1); //base our detail height on the comments field.
                return comment_height;
            },
            //components: {
            //    datePicker: getDatePicker(),
            //},
        }

        $scope.addObjective = function(){
            $scope.projectsGrid.selectedItem.ProjectObjectives.push({
                LastUpdated: moment().format('MM/DD/YYYY'),
                ProjectId: $scope.projectsGrid.selectedItem.Id
            });
            $scope.objectivesGrid.api.setRowData($scope.projectsGrid.selectedItem.ProjectObjectives);
        }

        $scope.saveObjective = function(data){

            var saved = BOTService.saveBOTProjectObjective(data);

            saved.$promise.then(function(){
                //add to the project objectives
                $scope.projects.forEach(function(project, index){
                    if(project.Id == data.ProjectId){
                        if(data.Id) { //if there is an Id then go find and replace
                            project.ProjectObjectives.forEach(function(objective, oindex){
                                if(objective.Id == data.Id){
                                    project.ProjectObjectives.splice(oindex,1,saved);
                                    $scope.objectivesGrid.api.setRowData(project.ProjectObjectives)
                                }
                            });
                        } else {
                            project.ProjectObjectives.push(saved);
                            $scope.objectivesGrid.api.setRowData(project.ProjectObjectives)
                        }

                    }
                });

            })
        }

        var reportsColumnDefs = [
            { headerName: "Title", field: "Title", width: 300, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Description", field: "Description", width: 350, maxwidth: 400, menuTabs: ['filterMenuTab'], filter: true, cellStyle: {'white-space': 'normal'} },
            { headerName: "Reporter", field: "Reporter", width: 160, menuTabs: ['filterMenuTab'], filter: true },
            { headerName: "Report Date", field: "ReportDateTime", width: 160, menuTabs: ['filterMenuTab'], filter: true, sort: 'asc',
                valueGetter: function (params) { return moment(params.node.data.ReportDateTime).format('MM/DD/YYYY') },
                valueFormatter: function (params) {
                    return valueFormatterDate(params.node.data.ReportDateTime);
                },
                //cellEditor: 'datePicker',
            },
        ];

        $scope.reportsGrid = {
            columnDefs: reportsColumnDefs,
            rowData: [],
            enableSorting: true,
            enableFilter: true,
            rowSelection: 'single',
            defaultColDef: {
                editable: true,
                sortable: true,
                resizable: true
            },
            selectedItem: null,
            selectedCell: null,
            selectedNode: null,
            editType: 'fullRow',
            onRowEditingStopped: function(params){
                $scope.saveReport(params.data);
            },
            getRowHeight: function (params) {
                var comment_length = (!params.data.Description) ? 1 : params.data.Description.length;
                var comment_height = 25 * (Math.floor(comment_length / 45) + 1); //base our detail height on the comments field.
                return comment_height;
           },
        }

        $scope.addReport = function(){
            $scope.objectiveReports.push({
                ReportDateTime: moment().format('MM/DD/YYYY'),
                ProjectId: $scope.projectsGrid.selectedItem.Id,
                ProjectObjectiveId: $scope.objectivesGrid.selectedItem.Id
            });

            $scope.reportsGrid.api.setRowData($scope.objectiveReports);
        }

        $scope.saveReport = function(data){
            var saved = BOTService.saveProjectObjectiveReport(data);
            saved.$promise.then(function(){
                $scope.objectiveReports.forEach(function(report, index){
                    if(!report.Id){
                        $scope.objectiveReports.splice(index,1,saved);
                        $scope.reportsGrid.api.setRowData($scope.objectiveReports);
                    }
                })
            });
        }

        $scope.showAllProjects();

}];

export default bot_projects;
